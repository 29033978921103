<script setup>
import { onMounted, ref } from 'vue';
import { reusableProjectTypes } from '@watchtowerbenefits/es-utils-public';
import { useRoute, useRouter } from 'vue-router';
import { Column, DataTable, Paginator, ScrollPanel, Tag } from 'primevue';
import ServiceProject from '@/services/project.js';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter.js';
import Filters from './Filters/index.vue';

// Use DayJs Plugin
dayjs.extend(isSameOrAfter);

// const statusSortOrderMap = {
//   New: 1,
//   'In progress': 2,
//   'Past due': 3,
//   Submitted: 4,
//   Sold: 5,
//   Declined: 6,
//   Closed: 7,
// };

// Props
const props = defineProps({
  dashboardType: {
    type: String,
    required: true,
  },
});
// Grab route and router for URL params like page and sorting
const route = useRoute();
const router = useRouter();
const routeFilters = JSON.parse(atob(route.params.filters || '') || '{}');
// Data
const errorMessage = ref('');
const isLoaded = ref(false);
const tableData = ref([]);
const currentPage = ref(route.params.pageSelected || 1);
const rowsPerPage = ref(20);
const totalRecords = ref(100);
const sorting = ref([
  route.params.sortProp || 'broker_name',
  route.params.sortDirection || 'asc',
]);
const filters = ref([
  {
    key: 'employer_name',
    label: 'Employer name',
    type: 'multiselect',
    options: [],
    value: routeFilters.employer_name || [],
  },
  {
    key: 'broker_name',
    label: 'Broker name',
    type: 'multiselect',
    options: [],
    value: routeFilters.broker_name || [],
  },
  {
    key: 'project_type',
    label: 'Event type',
    options: ['Renewal collection', 'Full marketing'],
    value: routeFilters.project_type || [],
  },
]);
// Methods
/** Get table data from the API */
const getTableData = async () => {
  isLoaded.value = false;

  try {
    const { projects, meta_data: meta } = await ServiceProject.getProjects({
      dashboardType: props.dashboardType,
      currentPage: currentPage.value,
      rowsPerPage: rowsPerPage.value,
      sorting: sorting.value,
      filters: Object.fromEntries(
        filters.value
          .filter(({ value }) => value.length)
          .map(({ key, value }) => {
            if (key === 'project_type') {
              return [
                key,
                value.map((type) =>
                  type === 'Renewal collection'
                    ? reusableProjectTypes().renewal
                    : reusableProjectTypes().goToMarket,
                ),
              ];
            }

            return [key, value];
          }),
      ),
    });

    totalRecords.value = meta.total_record_count;

    // Format the data for the table
    tableData.value = projects.reduce(
      (
        accRows,
        {
          broker_name: brokerName,
          effective_date: effectiveDate,
          employer_name: employerName,
          employer_total_lives: employerTotalLives,
          opportunity_status: opportunityStatus,
          project_due_date: dueDate,
          project_id: id,
          project_type: type,
        },
      ) => {
        // We want to prevent the user from accessing RFPs w/o a due date.
        if (!dueDate) {
          return accRows;
        }

        let pastDue = false;
        let statusName = opportunityStatus.toLowerCase(); // Use the original status for intermediary calculations
        // We want to determine the Event Type based on the project type for each row (RLH 3/31/21)
        const eventType =
          type === reusableProjectTypes().renewal
            ? 'Renewal collection'
            : 'Full marketing';

        if (['incomplete', 'new'].includes(statusName)) {
          pastDue = dayjs().isSameOrAfter(dayjs(dueDate));

          if (pastDue) {
            statusName = 'past_due';
          }
        }

        // Status tags
        let statusLabel;
        let statusIcon;

        switch (statusName) {
          case 'new':
            statusLabel = 'New';
            statusIcon = 'fa-solid fa-circle';
            break;
          case 'in_progress':
            statusLabel = 'In progress';
            statusIcon = 'fa-solid fa-circle';
            break;
          case 'submitted':
          case 'firm_requested':
          case 'firm':
          case 'firm_expired':
            statusLabel = 'Submitted';
            statusIcon = 'fa-solid fa-circle';
            break;
          case 'sold':
            statusLabel = 'Sold';
            statusIcon = 'fa-solid fa-circle';
            break;
          case 'past_due':
            statusLabel = 'Past due';
            statusIcon = 'fa-sharp fa-solid fa-triangle';
            break;
          case 'declined':
            statusLabel = 'Declined';
            statusIcon = 'fa-regular fa-circle-xmark';
            break;
          case 'closed':
            statusLabel = 'Closed';
            break;
          default:
            break;
        }

        const accRow = {
          id,
          eventType,
          broker_name: brokerName,
          due_date: dayjs(dueDate).format('MM/DD/YYYY'),
          effective_date: effectiveDate,
          employer_name: employerName,
          employer_total_lives: employerTotalLives,
          pastDue,
          statusName,
          statusLabel,
          statusIcon,
        };

        return accRows.concat(accRow);
      },
      [],
    );
  } catch (error) {
    errorMessage.value = `Error loading projects: ${error}`;
  } finally {
    isLoaded.value = true;
  }
};
// Update the URL when the user changes the page, sorting, or filters
const updateRouteParams = () => {
  const params = {
    pageSelected: currentPage.value,
    sortProp: sorting.value[0],
    sortDirection: sorting.value[1],
  };
  const appliedFilters = {};

  filters.value.forEach((filter) => {
    if (filter.value.length) {
      appliedFilters[filter.key] = filter.value;
    }
  });

  params.filters = Object.keys(appliedFilters).length
    ? btoa(JSON.stringify(appliedFilters))
    : '';

  router.replace({ params });
  getTableData();
};
// Get new page of table data when the user changes the current page
const updateCurrentPage = (page) => {
  if (currentPage.value === page) return;
  currentPage.value = page;
  updateRouteParams();
};
const updateRowsPerPage = (rows) => {
  if (rowsPerPage.value === rows) return;
  rowsPerPage.value = rows;
  getTableData();
};
// Get new page of table data when the user changes the sorting
const updateSorting = ([sortProp, sortDirection]) => {
  const direction = sortDirection === 1 ? 'asc' : 'desc';

  if (sorting.value[0] === sortProp && sorting.value[1] === direction) return;
  sorting.value[0] = sortProp;
  sorting.value[1] = direction;
  updateRouteParams();
};
// Update the filters and route params when the user changes a filter
const updateFilters = (newFilters) => {
  Object.keys(newFilters).forEach((key) => {
    const index = filters.value.findIndex((filter) => filter.key === key);

    if (index > -1) {
      filters.value[index].value = newFilters[key];
    }
  });
  updateRouteParams();
};

// Get filter options
onMounted(async () => {
  const { project_filters: options } =
    await ServiceProject.getDashboardFilterOptions(props.dashboardType);

  filters.value.forEach((filter, index) => {
    if (options[filter.key]) {
      filters.value[index].options = options[filter.key];
    }
  });
  updateRouteParams();
});
</script>

<template>
  <div class="dashboard-table">
    <ScrollPanel class="filter-panel">
      <Filters :filters="filters" @update:filters="updateFilters" />
    </ScrollPanel>
    <ScrollPanel class="table-panel">
      <DataTable
        :value="tableData"
        :first="0"
        :rows="20"
        :rows-per-page-options="[10, 20, 50]"
        :loading="!isLoaded"
        :row-hover="true"
        :total-records="100"
        :sort-field="sorting[0]"
        :sort-order="sorting[1] === 'asc' ? 1 : -1"
        @sort="updateSorting([$event.sortField, $event.sortOrder])"
      >
        <Column field="eventType" header="Event type" sortable>
          <template #body="slotProps">
            <RouterLink
              :data-test="`to ${slotProps.data.employer_name} opportunity`"
              :to="{
                name: 'RfpOverview',
                params: { projectId: slotProps.data.id },
              }"
            >
              {{ slotProps.data.eventType }}
            </RouterLink>
          </template>
        </Column>
        <Column field="broker_name" header="Broker name" sortable filterable />
        <Column field="employer_name" header="Employer name" sortable />
        <Column field="status_label" header="Status" sortable>
          <template #body="slotProps">
            <Tag severity="secondary">
              <i
                v-if="slotProps.data.statusIcon"
                :class="slotProps.data.statusIcon"
                :style="`color: var(--tf-opportunity-status-${slotProps.data.statusName})`"
              />
              {{ slotProps.data.statusLabel }}
            </Tag>
          </template>
        </Column>
        <Column field="due_date" header="Due date" />
        <Column field="employer_total_lives" header="Group size" />
      </DataTable>
      <Paginator
        :rows="20"
        :total-records="totalRecords"
        :first="(currentPage - 1) * rowsPerPage"
        :rows-per-page-options="[10, 20, 50]"
        @page="updateCurrentPage($event.page + 1)"
        @update:rows="updateRowsPerPage($event)"
      />
    </ScrollPanel>
  </div>
</template>

<style lang="scss" scoped>
.dashboard-table {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
}
.filter-panel {
  width: 250px;
}
.table-panel {
  flex: 1 0 auto;
}
</style>
