/* eslint-disable camelcase */
import { defineStore } from 'pinia';
import planDesignService from '@/services/planDesign.js';
import useContainerStore from '@/stores/planDesign/containers.js';
// eslint-disable-next-line import/no-cycle
import { useProductStore } from '@/stores/product.js';
import { useRateEntryStore } from '@/stores/rateEntry.js';
import { sourceMap } from '@/utils/planDesignValues.js';
import productService from '@/services/product.js';

const sourceOrder = Object.keys(sourceMap);

export const usePlanDesignStore = defineStore('planDesign', {
  state: () => ({
    ignoreCategories: {},
    info: null,
    loadingPlanDesign: false,
    planDesignErrors: [],
    subtypeColspans: {
      policy: 1,
      proposal: 1,
    },
    validPlanDesign: false,
    values: {},
  }),
  getters: {
    planDesignIsPlan: (state) =>
      state.info?.container_type?.toLowerCase() === 'plan' ?? null,
    isPlanDesignValid: (state) =>
      Object.keys(state.values).every(
        (key) =>
          !!state.values[key].value ||
          state.values[key].plan_design_attribute_id ===
            useRateEntryStore().rateGuarantee?.plan_design_attribute_id,
      ),
    uniqueSources: (state) => {
      const sources = [
        ...new Set(
          Object.keys(state.values).map(
            (id) => state.values[id].automated_by?.value,
          ),
        ),
      ]
        .filter(Boolean)
        .sort((a, b) => sourceOrder.indexOf(a) - sourceOrder.indexOf(b));

      return useProductStore().isProductNotStartedOrNotSubmitted
        ? sources.filter((source) => source !== 'not_found')
        : sources;
    },
  },
  actions: {
    /**
     * Sets values and sorts classAttribute.groupings based on payload.
     *
     * @param {object} payload
     * @param {object} payload.attribute
     * @param {number} payload.attributeId
     * @param {number} payload.categoryId
     * @param {number} payload.productId
     */
    rollOutPlanDesign({ attribute, attributeId, categoryId, productId }) {
      const category = this.info.categories.find(({ id }) => id === categoryId);
      const classAttribute = category.plan_design_attributes.find(
        ({ id }) => id === attributeId,
      );
      const groupIdx = classAttribute.groupings.findIndex(({ ids }) =>
        ids.every((id) => attribute.ids.includes(id)),
      );
      const grouping = classAttribute.groupings[groupIdx];
      const removeId = `${grouping.ids.join('_')}_${categoryId}_${attributeId}`;
      const tierGroup = grouping.products.find(
        (product) => product.document_type.toLowerCase() !== 'policy',
      ).tier_group;

      grouping.ids.forEach((id, idx) => {
        const originalValue = { ...this.values[removeId] };

        // TODO: check if works as expected, migrated from old code
        this.values[`${id}_${categoryId}_${attributeId}`] = {
          plan_design_attribute_id: attributeId,
          product_id: productId,
          tier_group_id: tierGroup.id,
          tier_subtype_id: tierGroup.tier_subtypes[0].id,
          project_products_container_ids: [id],
          value: originalValue.value,
          comparison_flag: originalValue.comparison_flag,
          inforceValue: originalValue.inforceValue,
          automated_by: originalValue.automated_by,
        };

        // Add new groups to the tree
        classAttribute.groupings.push({
          ids: [id],
          label: grouping.names[idx],
          names: grouping.names[idx],
          products: grouping.products,
          rolledOut: id,
          subtype_by_product: grouping.subtype_by_product,
        });
      });

      // remove the old single grouping and value object
      classAttribute.groupings.splice(groupIdx, 1);
      // TODO: check if works as expected, migrated from old code
      this.values[removeId] = undefined;

      // Sort alphabetically
      classAttribute.groupings.sort((a, b) => {
        const nameA = a.names[0];
        const nameB = b.names[0];

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    },
    /**
     * Sets values, projectProduct, & ignoreCategories and sets containers in
     * containers store.
     *
     * @param {object} params
     * @param {object} params.subtypeColspans
     * @param {object} params.projectProduct
     * @param {object} params.ignoreCategories
     */
    setContainers({
      subtypeColspans,
      projectProduct = null,
      ignoreCategories = {},
    }) {
      const containerStore = useContainerStore();

      this.info = projectProduct || null;

      if (!projectProduct) {
        containerStore.highlightedMap = {};
        // TODO: check if works as expected, migrated from old code
        this.ignoreCategories = {};
        containerStore.containers = [];
        // TODO: check if works as expected, migrated from old code
        this.subtypeColspans = {};

        return;
      }

      // Set categories titles to ignore for each category
      // TODO: check if works as expected, migrated from old code
      this.ignoreCategories = ignoreCategories;
      this.subtypeColspans = subtypeColspans;

      if (projectProduct.container_type.toLowerCase() === 'plan') {
        // All containers are set to highlight on initial loading
        projectProduct.container_info.forEach((containerInfo, idx) => {
          const container = projectProduct.containers[idx];
          const { id } = containerInfo;
          const tierGroups = container.available_plan_design_tier_groups;
          const selectedTierGroup = tierGroups.find(
            (tierGroup) => tierGroup.selected,
          );
          const type = containerInfo.container_type_name;
          // ATM dental is the only Plan Based product that will come back with a selected tier group 100% of the time
          const tierGroupId = selectedTierGroup ? selectedTierGroup.id : null;

          // TODO: check if works as expected, migrated from old code
          containerStore.highlightedMap[id] = true;

          // For plan-based products, we're using the container.description as the "name" and the container.container_type_name as the "description".
          containerStore.containers.push({
            description: [type], // see above description
            id,
            name: containerInfo.description || type, // see above description
            tierGroups,
            tierGroupId,
            type: type.toLowerCase(),
          });
        });

        return;
      }
      // All containers are set to highlight on initial loading
      // TODO: check if works as expected, migrated from old code
      containerStore.highlightedMap[
        projectProduct.container_info.map((container) => container.id).join('_')
      ] = true;
    },
    /**
     * Sets state values.
     *
     * @param {object} values
     */
    setPlanDesignValues(values) {
      if (!values) {
        // TODO: check if works as expected, migrated from old code
        this.values = {};

        return;
      }

      Object.keys(values).forEach((id) => {
        // TODO: check if works as expected, migrated from old code
        this.values[id] = values[id];
      });
    },
    /** Clears containers in container store and state vlaues. */
    clearPlanDesign() {
      this.setContainers({});
      this.setPlanDesignValues();
    },
    /**
     * Copies plan design values and/or changes the product state, error toast
     * on service failure.
     *
     * @param {object} params
     * @param {string} params.productState
     * @param {number | string} params.productId
     * @param {number | string} params.sourceId
     */
    async copyPlanDesign({ productState, productId, sourceId }) {
      const copyPlanDesign = async () => {
        try {
          const { project_product: projectProduct } =
            await planDesignService.copyPlanDesign({
              productId,
              sourceId,
            });

          this.clearPlanDesign();
          this.setPlanDesign({
            productId,
            projectProduct,
          });
        } catch (error) {
          // TODO: replace Vue.prototype.$message for vue 3
          // const message = error?.response?.data?.message ?? '';
          // Vue.prototype.$message({
          //   duration: 10000,
          //   message: `There was an error copying the plan design. ${message}`,
          //   showClose: true,
          //   type: 'error',
          // });
        }
      };

      if (productId) {
        const endpointState =
          productState === 'completed' || productState === 'pending_review'
            ? 'edit'
            : 'start';

        await useProductStore().updateProductState({
          endpointState,
          productId,
        });
      }

      await copyPlanDesign();

      // Because triggering updates to the plan design updates the product state, for now we have to manually refetch the
      // product so we can get the updated product state.
      // This will be fixed with https://watchtower.atlassian.net/browse/LC-1724
      if (productId) {
        const { product } = await productService.getProduct(productId);

        useProductStore().updateProduct(product);
        useProductStore().currentProduct = product;
      }
    },
    /**
     * Fetches new project_product for user preferred values.
     *
     * @param {number | string} productId
     * @returns {Promise}
     */
    applyUserPreferredValues(productId) {
      // Set loading status and clear old product Rate Entry
      this.loadingPlanDesign = true;

      // Test for no product or currentProduct hasn't been loaded yet
      if (!productId) {
        return undefined;
      }

      // Axios call to get product info
      return planDesignService
        .applyUserPreferredValues(productId)
        .then((data) => {
          this.setPlanDesign({
            productId,
            projectProduct: data.project_product,
          });
        })
        .catch(() => {});
      // show error state (todo)
    },
    /**
     * Fetches new project_product for user preferred values.
     *
     * @param {number | string} productId
     * @returns {Promise}
     */
    getPlanDesign(productId) {
      // Set loading status and clear old product Rate Entry
      this.loadingPlanDesign = true;

      // Test for no product or currentProduct hasn't been loaded yet
      if (!productId) {
        return undefined;
      }

      // Axios call to get product info
      return planDesignService
        .getPlanDesign(productId)
        .then(({ project_product: projectProduct }) => {
          this.setPlanDesign({
            productId,
            projectProduct,
          });
        })
        .catch(() => {});
      // show error state (todo)
    },
    /**
     * Updates a plan design attribute.
     *
     * @param {object} params
     * @param {number | string} params.id
     * @param {number | string} params.productId
     * @param {string} params.productState
     */
    async patchPlanAttribute({ id, productId, productState }) {
      const patchPlanAttribute = () =>
        planDesignService.patchPlanAttribute(this.values[id]);

      // Change validPlanDesign to false since Plan Design will need to be validated again.
      if (this.validPlanDesign) {
        this.validPlanDesign = false;
      }
      if (productId) {
        const endpointState =
          productState === 'completed' || productState === 'pending_review'
            ? 'edit'
            : 'start';

        await useProductStore().updateProductState({
          endpointState,
          productId,
        });
      }
      await patchPlanAttribute();

      // Because triggering updates to the plan design updates the product state, for now we have to manually refetch the
      // product so we can get the updated product state.
      // This will be fixed with https://watchtower.atlassian.net/browse/LC-1724
      if (productId) {
        const { product } = await productService.getProduct(productId);

        useProductStore().updateProduct(product);
        useProductStore().currentProduct = product;
      }
    },
    /**
     * Updates a plan design plan value tier group.
     *
     * @param {object} params
     * @param {number | string} params.containerId
     * @param {number | string} params.productId
     * @param {number | string} params.tierGroupId
     * @returns {Promise}
     */
    async patchPlanDesignPlanValueTierGroup({
      containerId,
      productId,
      tierGroupId,
    }) {
      this.loadingPlanDesign = true;

      try {
        const response = await planDesignService.patchPlanDesignValueTierGroup({
          containerId,
          productId,
          tierGroupId,
        });

        this.clearPlanDesign();
        this.loadingPlanDesign = false;
        // Fetch updated plan design
        this.getPlanDesign(productId);

        return response;
      } catch (error) {
        this.loadingPlanDesign = false;
        throw error;
      }
    },
    /**
     * Sets state values and containers based on params.
     *
     * @param {object} params
     * @param {object} params.projectProduct
     * @param {number | string} params.productId
     */
    setPlanDesign({ projectProduct, productId }) {
      const colspans = {
        policy: 1,
        proposal: 1,
      };
      const subtypeColspans = {};
      const ignoreCategories = {};
      const values = {};
      // Get the Least Common Multiple of subtypes.
      // https://www.w3resource.com/javascript-exercises/javascript-math-exercise-10.php
      const gcd = (x, y) => {
        let absoluteX = Math.abs(x);
        let absoluteY = Math.abs(y);

        while (absoluteY) {
          const t = absoluteY;

          absoluteY = absoluteX % absoluteY;
          absoluteX = t;
        }

        return absoluteX;
      };
      const lcm = (x, y) => (!x || !y ? 0 : Math.abs((x * y) / gcd(x, y)));
      const setValue = ({
        inforceValue,
        planDesignAttributeId,
        // eslint-disable-next-line no-shadow
        productId,
        projectProductsContainerIds,
        tierGroupId,
        tierSubtypeId,
        value,
        validationType,
        automated_by,
      }) => ({
        inforceValue,
        plan_design_attribute_id: planDesignAttributeId,
        product_id: productId,
        project_products_container_ids: projectProductsContainerIds,
        tier_group_id: tierGroupId,
        tier_subtype_id: tierSubtypeId,
        value,
        validationType,
        automated_by,
      });
      let containerId;
      let id;
      let tierGroupId;
      let tierSubtypeId;
      let validationType;

      // Create value object for each subtype for inputs to store/patch
      // Value ids should be `${ containerId }_${ categoryId }_${ attributeId }`
      // `_${ tierGroupId }_${ tierSubtypeId }` IDs are only required for those values that are a subtype.
      if (projectProduct.container_type.toLowerCase() === 'plan') {
        projectProduct.containers.forEach((container) => {
          containerId = container.id;

          container.categories.forEach((category) => {
            const categoryId = category.id;

            category.plan_design_attribute_groups.forEach((group) => {
              if (!ignoreCategories[containerId]) {
                ignoreCategories[containerId] = [];
              }

              // Currently the only cases where we don't want to show the Category Titles is for if `Rate Guarantee` is the only plan design attribute
              if (
                category.plan_design_attribute_groups.length === 1 &&
                group.plan_design_attributes.length === 1 &&
                group.plan_design_attributes[0].name.toLowerCase() ===
                  'rate guarantee'
              ) {
                ignoreCategories[containerId].push(categoryId);
              }

              group.plan_design_attributes.forEach((attribute) => {
                const attributeId = attribute.id;
                const policy = attribute.products.find(
                  (product) => product.document_type.toLowerCase() === 'policy',
                );
                const proposal = attribute.products.find(
                  (product) => product.document_type.toLowerCase() !== 'policy',
                );

                // Long term, we might put this on the BE but that's a larger discussion.
                // For now, we're only going to look for 'Aggregate Corridor'

                switch (attribute.name.toLowerCase()) {
                  case 'aggregate corridor':
                    validationType = 'percentage-whole-number';
                    break;
                  default:
                    validationType = 'string';
                }

                // IF there are no values for the policy/proposal:
                // Add the category ID the ignore pile.
                if (
                  !policy.tier_group &&
                  !proposal.tier_group &&
                  !ignoreCategories[containerId].includes(categoryId)
                ) {
                  ignoreCategories[containerId].push(categoryId);
                }

                if (
                  !proposal.tier_group ||
                  attribute.name.toLowerCase() === 'rate guarantee'
                ) {
                  return;
                }

                // tier group can come back as `null`
                if (policy.tier_group) {
                  colspans.policy = lcm(
                    policy.tier_group.tier_subtypes.length,
                    colspans.policy,
                  );
                }

                colspans.proposal = lcm(
                  proposal.tier_group.tier_subtypes.length,
                  colspans.proposal,
                );

                proposal.tier_group.tier_subtypes.forEach((subtype, idx) => {
                  const inforceValue =
                    policy.tier_group && policy.tier_group.tier_subtypes[idx]
                      ? policy.tier_group.tier_subtypes[idx].plan_design_value
                          .value
                      : null;

                  id = `${containerId}_${categoryId}_${attributeId}`;
                  tierGroupId = proposal.tier_group.id;
                  tierSubtypeId = subtype.id;

                  if (tierGroupId && tierSubtypeId) {
                    id += `_${tierGroupId}_${tierSubtypeId}`;
                  }

                  values[id] = setValue({
                    inforceValue,
                    planDesignAttributeId: attributeId,
                    productId,
                    tierGroupId: proposal.tier_group.id,
                    tierSubtypeId: subtype.id,
                    projectProductsContainerIds: [containerId],
                    validationType,
                    value: subtype.plan_design_value.value,
                    automated_by: subtype.plan_design_value.automated_by,
                  });
                });
              });
            });
          });

          subtypeColspans[containerId] = colspans;
        });
      } else {
        projectProduct.categories.forEach((category) => {
          const categoryId = category.id;

          category.plan_design_attributes.forEach((attribute) => {
            const attributeId = attribute.id;

            attribute.groupings.forEach((grouping) => {
              const policy = grouping.products.find(
                (product) => product.document_type.toLowerCase() === 'policy',
              );
              const proposal = grouping.products.find(
                (product) => product.document_type.toLowerCase() !== 'policy',
              );

              if (!proposal.tier_group) {
                return;
              }
              containerId = grouping.ids.join('_');

              if (!ignoreCategories[containerId]) {
                ignoreCategories[containerId] = [];
              }

              // tier group can come back as `null`
              if (policy.tier_group) {
                colspans.policy = lcm(
                  policy.tier_group.tier_subtypes.length,
                  colspans.policy,
                );
              }

              colspans.proposal = lcm(
                proposal.tier_group.tier_subtypes.length,
                colspans.proposal,
              );

              proposal.tier_group.tier_subtypes.forEach((subtype, idx) => {
                const inforceValue = policy.tier_group
                  ? policy.tier_group.tier_subtypes[idx].plan_design_values[0]
                      .value
                  : null;

                id = `${containerId}_${categoryId}_${attributeId}`;
                tierGroupId = proposal.tier_group.id;
                tierSubtypeId = subtype.id;

                if (tierGroupId && tierSubtypeId) {
                  id += `_${tierGroupId}_${tierSubtypeId}`;
                }

                values[id] = setValue({
                  inforceValue,
                  planDesignAttributeId: attributeId,
                  productId,
                  tierGroupId: proposal.tier_group.id,
                  tierSubtypeId: subtype.id,
                  projectProductsContainerIds: grouping.ids,
                  validationType: 'string',
                  value: subtype.plan_design_values[0].value,
                  automated_by: subtype.plan_design_values[0].automated_by,
                });
              });
            });
          });
        });

        subtypeColspans.classBased = colspans;
      }

      this.setContainers({
        subtypeColspans,
        projectProduct,
        ignoreCategories,
      });
      this.setPlanDesignValues(values);
      this.loadingPlanDesign = false;
    },
    /**
     * Validates plan design based on id.
     *
     * @param {number | string} id
     * @returns {Promise}
     */
    validatePlanDesign(id) {
      if (!id) {
        return undefined;
      }

      return new Promise((resolve, reject) => {
        planDesignService.validatePlanDesign(
          id,
          (response) => {
            this.validPlanDesign = response.product.valid_plan_design;
            resolve(response.product.valid_plan_design);
          },
          (error) => {
            // If the user credentials have expired ||
            // the user has signed out while on the Plan Design screen,
            // `error.data` will return w/o a `product` object.
            if (error?.data?.product) {
              this.validPlanDesign = error.data.product.valid_plan_design;
            }
            reject(error);
          },
        );
      });
    },
  },
});
