<template>
  <div class="my-quotes-content-grid">
    <div>
      <AppMessage
        v-if="showSuccessfulSummaryAlert || showActionNeededSummaryAlert"
        class="summary-alert"
        icon="fa-kit fa-tf-ai-magic"
        :data-test="
          showSuccessfulSummaryAlert
            ? 'products processed successfully summary alert'
            : 'some products have action needed summary alert'
        "
        :severity="showSuccessfulSummaryAlert ? 'success' : 'neutral'"
      >
        <h3 class="uppercase">{{ summaryAlertTitle }}</h3>
        <p>{{ summaryAlertText }}</p>
      </AppMessage>
      <section>
        <h1 data-test="product table header">
          {{ tableHeader }}
        </h1>
        <ProductTable />
        <div
          v-if="!noTableActionAvailable"
          class="button-container flex gap-4 mt-4"
        >
          <SplitButton
            v-if="showSplitButton"
            split-button
            :label="buttonOptions.submitActionText"
            size="medium"
            severity="primary"
            data-test="split button"
            :model="splitButtonItems"
            @click="submitRenewalOrProposal"
          />
          <template v-else>
            <Button
              v-if="showPrimaryButton"
              severity="primary"
              :label="buttonOptions.primaryText"
              :data-test="`${buttonOptions.primaryText.toLowerCase()} primary action`"
              @click="handleSmartRenewalOrProposal"
            />
          </template>
          <Button
            v-if="showSecondaryButton"
            :data-test="`${buttonOptions.secondaryText.toLowerCase()} secondary action`"
            :label="buttonOptions.secondaryText"
            severity="secondary"
            @click="buttonOptions.secondaryAction"
          />
          <Button
            v-if="showFirmQuoteButton"
            :data-test="`firm quote secondary action`"
            :icon="
              someProductsHaveStatus(
                'Firm',
                'Firm Expired',
                'Closed',
                'Selected',
              )
                ? 'fa-solid fa-pencil'
                : ''
            "
            :label="buttonOptions.firmButtonText"
            severity="secondary"
            @click="firmButtonClicked"
          />
        </div>
        <FirmQuoteDateModal
          v-model:visible="showFirmQuoteModal"
          :products="stopLossProducts"
          data-test="firm quote modal"
        />
        <SelectDeclineModal
          v-if="showSelectDeclineModal"
          v-model:visible="showSelectDeclineModal"
          :products="declinableProducts"
          data-test="select decline products modal"
          @close-dialog="handleSelectDeclineClose"
        />
        <DeclineToQuoteModal
          v-if="showDeclineDialog"
          v-model:visible="showDeclineDialog"
          :products-to-decline="productsToDecline"
          data-test="decline all products confirmation"
        />
        <StartOrUpdateRenewalModal
          v-if="isStartOrUpdateRenewalModalOpen"
          v-model:visible="isStartOrUpdateRenewalModalOpen"
          :all-products-not-started="allProductsHaveStatus('Not Started')"
          :document-id="proposalDocument.id"
          :products="products"
          data-test="start or update renewal modal"
          @close-dialog="closeStartOrUpdateRenewalModal"
          @submit-document="showThreeFlowAssistModal = $event"
        />
        <ThreeFlowAssistModal
          v-if="showThreeFlowAssistModal"
          v-model:visible="showThreeFlowAssistModal"
          :proposal-document-id="proposalDocument.id"
          @close-dialog="showThreeFlowAssistModal = false"
          @show-product-table-info-message="showProductTableInfoMessage"
        />
        <ThreeFlowAssistAndDiveUnsupportedModal
          v-if="showThreeFlowAssistAndDiveUnsupportedModal"
          v-model:visible="showThreeFlowAssistAndDiveUnsupportedModal"
          :tf-assist-and-dive-unsupported-product-types="
            tfAssistAndDiveUnsupportedProductTypes
          "
          @close-dialog="showThreeFlowAssistAndDiveUnsupportedModal = false"
        />
        <TermsModal
          v-if="showTermsModal"
          v-model:visible="showTermsModal"
          data-test="terms modal"
          @close-dialog="showTermsModal = false"
        />
        <AppModalNotify
          :visible="showNotifyModal"
          append-to-body
          center-text
          confirm-icon=""
          data-test="confirm failure notification"
          type="danger"
          @confirm="closeNotifyModal"
        >
          <template #title> Please try again</template>
          <template #ctaConfirmContent> Done</template>
          <p>
            ThreeFlow encountered an error processing the documents. Re-upload
            your document to try again.
            <br />
            If the problem persists,
            <a href="mailto:support@threeflow.com" @click="closeNotifyModal">
              contact support </a
            >.
          </p>
        </AppModalNotify>
      </section>
    </div>
    <p>
      By submitting, you agree to our
      <Button
        data-test="open terms modal"
        variant="text"
        label="terms."
        @click="showTermsModal = !showTermsModal"
      />
    </p>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { storeToRefs } from 'pinia';

// Stores
import { useProductStore } from '@/stores/product.js';
import { useProductTableStore } from '@/stores/productTable.js';
import { useProjectStore } from '@/stores/project.js';

// Utils & Services
import {
  trackSegmentEvent,
  isStopLossProduct,
} from '@watchtowerbenefits/es-utils-public';
import { formatArrayIntoListOfStrings } from '@/utils/general.js';
import { firmQuoteCheck } from '@/utils/product.js';
import { captureException, addBreadcrumb } from '@sentry/vue';
import DocumentsService from '@/services/documents.js';

// Components
import Button from 'primevue/button';
import SplitButton from 'primevue/splitbutton';

import ProductTable from '@/components/Product/ProductTable.vue';
import StartOrUpdateRenewalModal from '@/components/Modals/StartOrUpdateRenewalModal/index.vue';
import DeclineToQuoteModal from '@/components/Modals/DeclineToQuoteModal.vue';
import SelectDeclineModal from '@/components/Modals/SelectDeclineModal.vue';
import TermsModal from '@/components/Modals/TermsModal.vue';
import FirmQuoteDateModal from '@/components/Modals/FirmQuoteDateModal.vue';
import ThreeFlowAssistModal from '@/components/Modals/StartOrUpdateRenewalModal/ThreeFlowAssist.vue';
import ThreeFlowAssistAndDiveUnsupportedModal from '@/components/Modals/ThreeFlowAssistAndDiveUnsupportedModal.vue';
import AppMessage from '@/shared/components/AppMessage.vue';
import AppModalNotify from '@/shared/components/AppModalNotify/index.vue';

import { useRoute } from 'vue-router';
import { useToast } from 'primevue/usetoast';

defineOptions({
  name: 'MyQuotes',
});

// Store references
const projectStore = useProjectStore();
const productStore = useProductStore();
const productTableStore = useProductTableStore();
const route = useRoute();
const toast = useToast();
// Store state refs
const {
  broker,
  projectId,
  proposalDocument,
  waitForDocumentsToProcess,
  processingDocumentsNotification,
  showSummaryAlert,
  proposalDocumentId,
} = storeToRefs(projectStore);
const {
  allThreeflowAssistProducts,
  diveUnsupportedProductTypes,
  isProcessingDocuments,
  isSmartProposal,
  isAllNewCoverage,
  isMixOfNewAndRenewingCoverage,
  products,
  productsToDecline,
  threeflowAssistSupported,
} = storeToRefs(productStore);
const { productTableData, proposalRateGuarantees } =
  storeToRefs(productTableStore);
// Local state
const showDeclineDialog = ref(false);
const isStartOrUpdateRenewalModalOpen = ref(false);
const showSelectDeclineModal = ref(false);
const showTermsModal = ref(false);
const showFirmQuoteModal = ref(false);
const showThreeFlowAssistModal = ref(false);
const showNotifyModal = ref(false);
const showThreeFlowAssistAndDiveUnsupportedModal = ref(false);
const infoMessage = ref(null);
const errorMessage = ref(null);
const declinableStates = ref([
  'Not Started',
  'Not Submitted',
  'Ready to Submit',
  'Action Needed',
  'ThreeFlow Assist',
]);
/**
 * Distill the statuses for the products in the table down into a single array
 * of statuses.
 *
 * @returns {Array}
 */
const productStatuses = computed(() =>
  productTableData.value.map(({ status }) => status.status),
);
/**
 * Check if every one of the product states are in the specified status.
 *
 * @param {...string} statuses
 * @returns {boolean}
 */
const allProductsHaveStatus = (...statuses) =>
  productStatuses.value.every((productState) =>
    statuses.some((status) =>
      new RegExp(`^${status}$`, 'i').test(productState),
    ),
  );
/**
 * Generates an array of product type names in the project that are Tf Assist
 * and DIVE unsupported and also in a not started state.
 *
 * @returns {Array}
 */
const tfAssistAndDiveUnsupportedProductTypes = computed(() => [
  ...new Set(
    productTableData.value
      .filter(
        ({ isTfAssistAndDiveUnsupportedProduct, status }) =>
          isTfAssistAndDiveUnsupportedProduct &&
          status.status === 'Not Started',
      )
      .map(({ productTypeName }) => productTypeName),
  ),
]);
const splitButtonItems = [
  {
    label: 'Decline Products',
    command: () => {
      // eslint-disable-next-line no-use-before-define
      onDeclineProductsClick({}, 'split button');
    },
  },
];
/**
 * Array of products in declinable states.
 *
 * @returns {Array}
 */
const declinableProducts = computed(() => {
  const declinableTableProductIds = productTableData.value
    .filter(({ status }) => declinableStates.value.includes(status.status))
    .map(({ id }) => id);

  return products.value.filter(({ id }) =>
    declinableTableProductIds.includes(id),
  );
});
/** Returns an array of Stop Loss products. */
const stopLossProducts = computed(() =>
  products.value.filter((product) => isStopLossProduct(product)),
);
/**
 * Determine whether to show the successful summary alert based on product
 * statuses.
 */
const showSuccessfulSummaryAlert = computed(
  () =>
    showSummaryAlert.value &&
    productStatuses.value.includes('Ready to Submit') &&
    !productStatuses.value.includes('Action Needed'),
);
/**
 * Check if any of the product states are in the specified status.
 *
 * @param {...string} statuses
 * @returns {boolean}
 */
const someProductsHaveStatus = (...statuses) =>
  productStatuses.value.some((state) =>
    statuses.some((status) => new RegExp(`^${status}$`, 'i').test(state)),
  );
/**
 * Determine whether to show the action needed summary alert based on product
 * statuses.
 *
 * @returns {boolean}
 */
const showActionNeededSummaryAlert = computed(
  () => showSummaryAlert.value && someProductsHaveStatus('Action Needed'),
);
/**
 * Text for the summary alert (blurbiage)
 *
 * @returns {string}
 */
const summaryAlertText = computed(() =>
  showSuccessfulSummaryAlert.value
    ? 'ThreeFlow found and entered your rates. You are encouraged to review your quotes before submission. Quotes that are ready to submit will be auto-submitted in 24 hours.'
    : 'Some rate information is missing and requires your review. Quotes that are ready to submit will be auto-submitted in 24 hours.',
);
/** Title text for the summary alert */
const summaryAlertTitle = computed(() => {
  if (showSummaryAlert.value === 'update') {
    return showSuccessfulSummaryAlert.value
      ? 'Your quote update is ready to review and submit'
      : 'Your quote update requires action';
  }

  return showSuccessfulSummaryAlert.value
    ? 'Your quote is ready to review and submit'
    : 'Your quote requires action';
});
/** Handles opening the start or update renewal modal. */
const handleSmartRenewalOrProposal = () => {
  isStartOrUpdateRenewalModalOpen.value = true;
};
/**
 * Handle click on 'Decline' products button
 *
 * @param {object} event
 * @param {string} buttonType
 */
const onDeclineProductsClick = (event, buttonType = 'primary') => {
  showSelectDeclineModal.value = !showSelectDeclineModal.value;
  trackSegmentEvent(`Smart Decline Products (${buttonType}) clicked`, {
    project_id: projectStore.projectId,
  });
};
/**
 * Decides the correct renewal and decline button text based on product states.
 * Primary button:
 *
 * - At least one product in Not Started: 'Start Renewal'
 * - NO products in Not Started: 'Update Renewal'
 *
 * Firm quote button text:
 *
 * - Any products have a stop_loss_state of firm_quote_requested
 *
 * @returns {string}
 */
const buttonOptions = computed(() => {
  const firmQuote = products.value.find(
    ({ firm_quote_expires_on: exp }) => exp,
  );
  const firmQuoteDate = firmQuote
    ? new Date(firmQuote.firm_quote_expires_on)
    : null;
  const quoteOrRenewal = productStore.smartDocType(true);
  const updateOrStart = allProductsHaveStatus('Not Started')
    ? 'Start'
    : 'Update';

  return {
    primaryText: threeflowAssistSupported.value
      ? 'Send to ThreeFlow Assist'
      : `${updateOrStart} ${quoteOrRenewal}`,
    secondaryText: someProductsHaveStatus('Ready to Submit')
      ? `Update ${quoteOrRenewal}`
      : 'Decline Products',
    secondaryAction: someProductsHaveStatus('Ready to Submit')
      ? handleSmartRenewalOrProposal
      : onDeclineProductsClick,
    firmButtonText: firmQuote
      ? `Firm Quote: ${firmQuoteDate.getMonth() + 1}/${firmQuoteDate.getDate()}/${firmQuoteDate.getFullYear()}`
      : 'Set Firm Quote',
    submitActionText: `Submit ${quoteOrRenewal}`,
  };
});
/**
 * Show the primary button if:
 *
 * - NO products are Processing
 * - NO products are Ready to Submit
 * - ALL products are not in ThreeFlow Assist
 * - ALL products are not tfAssistAndDiveUnsupportedProductTypes
 */
const showPrimaryButton = computed(() => {
  const allProductsAreTfAssistAndDiveUnsupported = productTableData.value.every(
    ({ isTfAssistAndDiveUnsupportedProduct }) =>
      isTfAssistAndDiveUnsupportedProduct,
  );

  return (
    !someProductsHaveStatus('processing') &&
    !someProductsHaveStatus('ready to submit') &&
    !allProductsHaveStatus('threeflow assist') &&
    !allProductsAreTfAssistAndDiveUnsupported &&
    !(
      allThreeflowAssistProducts.value && allProductsHaveStatus('Action Needed')
    )
  );
});
/**
 * Show the secondary button if:
 *
 * - EVERY product is NOT in Processing, Submitted, Firm Requested, Firm, Firm
 *   Expired, Declined, Selected, or Closed
 * - ANY products are in a Declinable state
 * - NOT all products are stop loss or critical illness products in Ready to
 *   Submit state
 */
const showSecondaryButton = computed(
  () =>
    !allProductsHaveStatus(
      'Processing',
      'Submitted',
      'Firm Requested',
      'Firm',
      'Firm Expired',
      'Declined',
      'Selected',
      'Closed',
    ) &&
    someProductsHaveStatus(...declinableStates.value) &&
    !(
      allThreeflowAssistProducts.value &&
      allProductsHaveStatus('Ready to Submit')
    ),
);
/** Show the split button if ANY product is Ready to Submit. */
const showSplitButton = computed(
  () =>
    someProductsHaveStatus('Ready to Submit') ||
    (allThreeflowAssistProducts.value &&
      allProductsHaveStatus('Ready to Submit')),
);
/** Show Firm Quote button if any products are stop loss. */
const showFirmQuoteButton = computed(
  () =>
    stopLossProducts.value.length &&
    stopLossProducts.value.some((product) =>
      firmQuoteCheck(product.stop_loss_state),
    ),
);
/** Check if there are no actions available for the table. */
const noTableActionAvailable = computed(() => {
  const noActionStates = ['Processing', 'Declined'];
  const productsCompletedButNoFirmQuoteRequested =
    allThreeflowAssistProducts.value &&
    products.value.every(
      (product) =>
        product.state === 'completed' &&
        !firmQuoteCheck(product.stop_loss_state),
    );

  return (
    allProductsHaveStatus(...noActionStates) ||
    productsCompletedButNoFirmQuoteRequested
  );
});
/** Displays table header based on table type and product status. */
const tableHeader = computed(() => {
  if (productStore.isSmartProposal) {
    if (isAllNewCoverage.value) return 'New Coverage';
    if (isMixOfNewAndRenewingCoverage.value) return 'New & Renewing Coverage';
  }

  return 'Renewing Coverage';
});

/** Watch for document processing state changes */
watch(
  isProcessingDocuments,
  async (newVal, oldVal) => {
    if (newVal) {
      processingDocumentsNotification.value = {
        summary: 'ThreeFlow AI',
        detail:
          'Your documents are being processed and will be ready for review in 1-5 minutes.',
        group: 'bottom-right',
        contentStyleClass: 'snackbar',
        closable: false,
      };

      toast.add(processingDocumentsNotification.value);
    } else {
      toast.remove(processingDocumentsNotification.value);

      const doneProcessing = oldVal === true && newVal === false;
      const unreadNotificationCount =
        await projectStore.getUnreadDiveFailureNotificationsCount();

      showNotifyModal.value = unreadNotificationCount > 0;

      if (
        doneProcessing &&
        proposalDocument.value?.id &&
        isSmartProposal.value
      ) {
        productTableStore.getPlanDesignCompleteness(proposalDocument.value.id);
      }
    }
  },
  { immediate: true },
);

/**
 * Gets Rate Guarantees.
 *
 * @returns {Promise<void>}
 */
const getProposalRateGuarantees = async () => {
  try {
    const data = await DocumentsService.getRateGuaranteeValues(
      proposalDocumentId.value,
    );

    products.value.forEach(({ id }) => {
      try {
        proposalRateGuarantees.value[id].rate_guarantee =
          data[id]?.rate_guarantee?.toString() || '';
      } catch (error) {
        addBreadcrumb({
          message: 'Rate guarantee not in response',
          category: 'error',
          level: 'error',
          data: { data, error },
        });
        captureException(error);
      }
    });
  } catch (error) {
    addBreadcrumb({
      message: 'Error getting rate guarantees',
      category: 'error',
      level: 'error',
      data: { error },
    });
    captureException(error);
    toast.add({
      life: 3000,
      description: 'There was an error getting rate guarantees.',
      closable: true,
      severity: 'error',
    });
  }
};
/**
 * Get product and proposal rate guarantees for each product.
 *
 * @returns {Promise<void>}
 */
const getProductAndProposalRateGurantees = async () => {
  products.value.forEach(({ id, product_type_name: name }) => {
    proposalRateGuarantees.value[id] = {
      id,
      name,
      rate_guarantee: '',
      error: false,
    };
  });

  await productTableStore.getProductRateGuarantees();
  await getProposalRateGuarantees();
};

watch(
  () => products.value,
  () => getProductAndProposalRateGurantees(),
);

/**
 * Show an info message in the product table.
 *
 * @param {string} description
 */
const showProductTableInfoMessage = (description) => {
  infoMessage.value = {
    closable: true,
    description,
    iconClass: 'fa-regular fa-clock tf-blue-icon', // TODO: During the polish phase, handle all toast styles and icons. It seems like we need to use toasts with templates, etc
    severity: 'info',
  };

  toast.add(infoMessage.value);
};
/**
 * Close DIVE failure notification modal and hit API to indicate modal has been
 * seen
 */
const closeNotifyModal = async () => {
  await projectStore.markUnreadDiveFailureNotificationsAsRead();
  showNotifyModal.value = false;
};
/**
 * Closes the start or update renewal modal and sends an analytics call.
 *
 * @param {number} formDisplayedUponClose - Which form was displaying before the
 *   user closed the modal
 */
const closeStartOrUpdateRenewalModal = (formDisplayedUponClose) => {
  isStartOrUpdateRenewalModalOpen.value = false;
  trackSegmentEvent(
    `Start or update renewal/quote modal abandoned on the ${formDisplayedUponClose} form`,
    {
      product_ids: products.value.map(({ id }) => id),
    },
  );
};
/** Submits a renewal or proposal. */
const submitRenewalOrProposal = async () => {
  const showValidateOrSubmitError = (
    detail = 'Your request did not go through. Please check the values on the Rate Entry page for any errors.',
  ) => {
    errorMessage.value = {
      closable: true,
      detail,
      severity: 'error',
    };

    toast.add(errorMessage.value);
  };
  const readyToSubmitProductIds = productTableData.value
    .filter(({ status }) => status.status === 'Ready to Submit')
    .map(({ id }) => id);

  trackSegmentEvent('Submit Smart Renewal from below table clicked', {
    product_ids: readyToSubmitProductIds,
  });

  try {
    await Promise.all(
      readyToSubmitProductIds.map(async (id) =>
        productStore.validateProduct(id),
      ),
    );
  } catch {
    showValidateOrSubmitError();

    return;
  }

  try {
    await Promise.all(
      readyToSubmitProductIds.map(async (id) => productStore.submitQuote(id)),
    );
  } catch {
    showValidateOrSubmitError(
      'Not all products were successfully submitted for renewal. Please try again.',
    );
  }
};
/** Opens DeclineToQuoteModal if there are products to decline and closes select */
const handleSelectDeclineClose = () => {
  showSelectDeclineModal.value = false;
  if (productsToDecline.value.length > 0)
    showDeclineDialog.value = !showDeclineDialog.value;
};
/** Handle clicks of the Firm Quote button. Show the firm quote modal */
const firmButtonClicked = () => {
  showFirmQuoteModal.value = true;
};

watch(
  [threeflowAssistSupported, diveUnsupportedProductTypes],
  ([threeflowAssistSupportedNew, diveUnsupportedProductTypesNew]) => {
    if (threeflowAssistSupportedNew && diveUnsupportedProductTypesNew?.length) {
      const message = `${formatArrayIntoListOfStrings(diveUnsupportedProductTypesNew)} supported by ThreeFlow Assist. ThreeFlow will enter your quote within two business days and send it to you to review and submit.`;

      showProductTableInfoMessage(message);
    }
  },
);

watch(isProcessingDocuments, (isProcessingDocumentsNew) => {
  if (isProcessingDocumentsNew) {
    projectStore.checkForProcessingDocuments(proposalDocumentId.value);
    projectStore.pollProposalDocumentProcessingState(proposalDocumentId.value);
  }
});

const containsNotStartedtfAssistAndDiveUnsupportedProducts = computed(() =>
  productTableData.value.some(
    ({ isTfAssistAndDiveUnsupportedProduct, status: { status } }) =>
      isTfAssistAndDiveUnsupportedProduct && status === 'Not Started',
  ),
);

if (containsNotStartedtfAssistAndDiveUnsupportedProducts.value) {
  showThreeFlowAssistAndDiveUnsupportedModal.value = true;
}

watch(containsNotStartedtfAssistAndDiveUnsupportedProducts, (newVal) => {
  if (newVal) {
    showThreeFlowAssistAndDiveUnsupportedModal.value = true;
  }
});

onMounted(async () => {
  if (route.query.fromEmail) {
    showSummaryAlert.value = true;
  }

  if (isProcessingDocuments.value) {
    projectStore.checkForProcessingDocuments(proposalDocumentId.value);
    projectStore.pollProposalDocumentProcessingState(proposalDocumentId.value);
  }

  if (containsNotStartedtfAssistAndDiveUnsupportedProducts.value) {
    showThreeFlowAssistAndDiveUnsupportedModal.value = true;
  }

  trackSegmentEvent('Visited My Quotes page', {
    broker_name: broker.value.name,
    broker_id: broker.value.id,
    project_id: projectId.value,
  });
});

/** Cleanup on component unmount */
onBeforeUnmount(() => {
  showSummaryAlert.value = false;

  if (waitForDocumentsToProcess.value) {
    projectStore.clearDocumentsInterval();
  }

  toast.remove(processingDocumentsNotification.value);
  toast.remove(infoMessage.value);
  toast.remove(errorMessage.value);
});
</script>

<style scoped lang="scss">
.button-container {
  margin-bottom: 30px;
  display: flex;
  justify-content: end;
}

.summary-alert {
  :deep(.p-message-content) {
    width: clamp(375px, 760px, 760px);
    margin-bottom: 20px;
    border-radius: 50%;

    .p-message-icon {
      font-size: 3rem;
      width: 3rem;
      height: 3rem;
    }
  }
}

.snackbar {
  background: var(--tf-gray-dark);
  width: auto;
  padding: 10px 16px;
}

.my-quotes-content-grid {
  display: grid;
  place-content: center;
  align-content: space-between;
  height: calc(100vh - 350px);
}
</style>

<style lang="scss">
i.tf-blue-icon {
  color: var(--tf-blue);
}

.snackbar {
  background: var(--tf-gray-dark);
  width: auto;
  padding: 10px 16px;

  &:before {
    font-family: 'Font Awesome 6 Pro', sans-serif;
    content: '\f1ce'; // fa-circle-notch
    color: var(--tf-green);
    height: 40px;
    width: 40px;
    font-size: 40px;
    animation: fa-spin 2s infinite linear;
    animation-name: fa-spin;
  }

  i {
    color: var(--tf-green);
    height: 40px;
    width: 40px;
    font-size: 40px;
  }

  .p-toast & {
    .p-toast-message-content,
    .p-toast-summary,
    .p-toast-detail {
      color: var(--tf-white);
    }
  }

  .p-toast & .p-toast-summary {
    font-weight: bold;
    font-size: 18px;
  }

  .intercom-loaded & {
    margin-right: 70px;
  }
}
</style>
