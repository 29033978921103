<template>
  <div class="table">
    <div class="table-title">
      <h3>{{ formattedTitle }}</h3>

      <Button
        v-if="title === 'Carrier team'"
        icon="fa-solid fa-plus"
        label="Invite teammate"
        data-test="invite-teammate"
        @click="$emit('update:visible', true)"
      />
    </div>
    <DataTable :value="users">
      <template #empty>
        There are currently no {{ title.toLowerCase() }} contacts.
      </template>

      <Column
        v-for="(column, index) in columns"
        :key="index"
        :field="column.prop"
        :header="column.label"
        :sortable="column.sortable"
        :style="{ width: column.width }"
      />
      <Column :style="{ width: '5%' }">
        <template #body="slotProps">
          <Button
            v-if="title === 'Carrier team'"
            label="Unsubscribe"
            data-test="unsubscribe user"
            variant="text"
            severity="danger"
            :style="{ padding: 0 }"
            @click="$emit('unsubscribe-user', slotProps.data.email)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup>
/**
 * Table component that displays provided users in a table
 *
 * @exports ContactTable
 */

import { reactive, computed } from 'vue';
import { Column, DataTable, Button } from 'primevue';

defineOptions({
  name: 'ContactTable',
});

const props = defineProps({
  /** Carrier or broker users table header */
  title: {
    type: String,
    required: true,
  },
  /** Users to display in the table */
  users: {
    type: Array,
    required: true,
  },
});
/** Rules for the tables */
const columns = reactive([
  {
    prop: 'name',
    label: 'Name',
    sortable: true,
    width: '30%',
  },
  {
    prop: 'email',
    label: 'Email',
    sortable: true,
    width: '40%',
  },
  {
    prop: 'dateAdded',
    label: 'Date added',
    sortable: true,
  },
]);
/**
 * Grab the broker company from the first user for the broker team title
 *
 * @returns {string}
 */
const company = computed(() =>
  props.users.length > 0 ? props.users[0].company : '',
);
/**
 * Format the table header to add company if it's a broker
 *
 * @returns {string}
 */
const formattedTitle = computed(() =>
  props.title === 'Broker team'
    ? `Broker team - ${company.value}`
    : props.title,
);
</script>

<style scoped lang="scss">
.table {
  border-bottom: none;
  padding: 20px;
}

h3 {
  margin: 0;
}

.table-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 15px;
}

:deep(.p-datatable) {
  margin-bottom: 30px;

  .p-datatable-tbody td {
    padding: 15px;
  }
}
</style>
