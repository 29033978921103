<template>
  <div>
    <p>Project link</p>
    <div class="copy-link-container">
      <div>
        <Button variant="text" :label="props.link" data-test="share link" />
      </div>
      <Button
        v-tooltip.focus.top="{
          value: 'Copied',
        }"
        icon="fa-regular fa-copy"
        icon-pos="right"
        size="medium"
        data-test="copy link"
        label="Copy link"
        @click="copyLink"
      />
    </div>
  </div>
</template>

<script setup>
import { Button } from 'primevue';
import { useToast } from 'primevue/usetoast';

defineOptions({
  name: 'CopyLinkContainer',
});

const props = defineProps({
  text: {
    type: String,
    default: null,
  },
  link: {
    type: String,
    default: null,
  },
});
const toast = useToast();
/** Copy the "Share Link" to the user's clipboard. */
const copyLink = async () => {
  try {
    await navigator.clipboard.writeText(props.link);
  } catch (err) {
    toast.add({
      severity: 'error',
      summary: 'Failed to copy text',
      detail: err,
      life: 3000,
    });
  }
};
</script>

<style lang="scss" scoped>
.copy-link-container {
  background: var(--tf-gray-light-medium);
  border: 1px var(--tf-gray-medium) solid;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;

  button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
