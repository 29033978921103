<template>
  <Dialog
    header="Non-PDF file type uploaded"
    :visible="visible"
    :closable="false"
    data-test="non pdf uploaded modal"
    modal
    style="width: 500px"
    @update:visible="closeDialog"
  >
    <h4 class="header">
      ThreeFlow only accepts PDF documents for automatic rate entry at this
      time.
    </h4>
    <p>
      Upload PDFs for automatic rate entry or enter rates manually. The files
      you just uploaded will be added to the project’s supplemental documents.
    </p>
    <p>
      <a
        data-test="upload pdf help link"
        href="https://support.threeflow.com/en/articles/9179732-how-do-i-save-a-document-as-a-pdf"
        target="_blank"
      >
        How to save my .doc or .xls as a PDF.
      </a>
    </p>
    <div class="gap-2 flex justify-end">
      <Button
        outlined
        data-test="enter rates manually"
        size="large"
        label="Enter rates manually"
        severity="primary"
        @click="handleEnterRatesManually"
      />
      <Button
        data-test="upload pdf"
        size="large"
        label="Upload PDF"
        severity="primary"
        @click="closeDialog"
      />
    </div>
  </Dialog>
</template>

<script setup>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

/**
 * When a user uploads a non-PDF file for the renewal process, this modal is
 * displayed.
 *
 * @exports NonPdfModal
 */
defineOptions({
  name: 'NonPdfModal',
});

defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:visible', 'submitRenewal']);
const closeDialog = () => {
  emit('update:visible', false);
};
const handleEnterRatesManually = () => {
  emit('submitRenewal');
  closeDialog();
};
</script>
