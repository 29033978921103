<script setup>
/**
 * AppRegisterConfirmation Component
 *
 * @exports AppRegisterConfirmation
 */
import { onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import AuthPageHeader from '@/shared/components/AuthPages/Header.vue';
import AuthPageFooter from '@/shared/components/AuthPages/Footer.vue';

defineOptions({
  name: 'AppRegisterConfirmation',
});

const props = defineProps({
  linkLogin: {
    type: [String, Object],
    default: '',
  },
  linkResend: {
    type: [String, Object],
    default: '',
  },
  linkRegister: {
    type: [String, Object],
    default: '',
  },
  userInfo: {
    type: Object,
    default: () => ({}),
  },
});
const router = useRouter();
const route = useRoute();

/** Redirect to login if the user's email is missing. */
onMounted(() => {
  // We want the email address to persist across all login/forgotPW/registration forms
  if (!props.userInfo?.email) {
    const redirectURL = route.query.redirect || props.linkLogin;

    // If we don't know your email address then you obviously don't know your info and will redirect to the login screen)
    router.replace(redirectURL);
  }
});
</script>

<template>
  <section class="auth-form">
    <AuthPageHeader title="Almost Done!" />
    <p>
      Please check <strong>{{ userInfo.email }}</strong> to confirm your
      account.
    </p>
    <p class="links-container">
      <RouterLink :to="linkResend"> Didn’t receive the email?</RouterLink>
    </p>
    <AuthPageFooter />
  </section>
</template>

<style lang="scss" scoped>
.auth-form {
  max-width: 500px;
}

.links-container {
  justify-content: space-around;
  margin-top: 60px;
}
</style>
