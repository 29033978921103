import { config } from '@/utils/config.js';
/**
 * Checks to see if the provided name exists within the THIRD_PARTIES
 * environment variable. finding a match dictates whether or not the third party
 * should be enabled.
 *
 * @param {string} name - The name of a third party library to check for
 * @returns {boolean} - Whether or not the third party should be enabled
 */
export const canUseThirdParty = (name) => {
  if (!name) {
    throw new Error('canUseThirdParty requires a name to check for');
  }

  const nameCaseInsensitive = new RegExp(name, 'i');
  const thirdParties = config.VUE_APP_THIRD_PARTIES ?? '';

  return thirdParties.match(nameCaseInsensitive) !== null;
};

export default {
  canUseThirdParty,
};

export const generatePassword = () => {
  const numberChars = '0123456789';
  const lowerChars = 'abcdefghijklmnopqrstuvwxyz';
  const specialChars = '!@#$%^&*()_+-=';
  const allChars = numberChars + lowerChars + specialChars;
  let randPasswordArray = Array(10);

  randPasswordArray[0] = numberChars;
  randPasswordArray[1] = lowerChars.toUpperCase();
  randPasswordArray[2] = lowerChars;
  randPasswordArray[3] = specialChars;
  randPasswordArray = randPasswordArray.fill(allChars, 4);

  /* eslint-disable no-param-reassign */
  // disabling this rule because this shuffle function
  // is purposely mutating the array in place
  const shuffleArray = (arr) => {
    for (let i = arr.length - 1; i > 0; i -= 1) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = arr[i];

      arr[i] = arr[j];
      arr[j] = temp;
    }

    return arr;
  };
  /* eslint-enable no-param-reassign */

  return shuffleArray(
    randPasswordArray.map((x) => x[Math.floor(Math.random() * x.length)]),
  ).join('');
};

/**
 * Format array into single string for regex and then check if the param passed
 * in is present in the passed in array.
 *
 * @param {string} item - String to check if it exists in the array
 * @param {Array} array - Array of strings, regex, or a mix of both
 * @returns {boolean}
 */
export const arrayHasItem = (item, ...array) => {
  const lowerItem = item.toLowerCase();
  // Helper function to process array items
  const matchItem = (arrayItem) => {
    if (typeof arrayItem === 'string') {
      return lowerItem === arrayItem;
    }
    if (arrayItem instanceof RegExp) {
      return arrayItem.test(lowerItem);
    }

    return false;
  };

  // Check if any item in the flattened array matches
  return array.flat(Infinity).some(matchItem);
};

/**
 * Takes an array as an argument and converts it into a comma seperate list.
 * Adds the word 'and' before the last array item and then adds either 'is' or
 * 'are' to the end of the list for use in a sentence.
 *
 * EXAMPLE: Array Item 1, Array Item 2, and Array Item 3 are...
 *
 * @param {Array} array
 * @returns {RegExp}
 */
export const formatArrayIntoListOfStrings = (array) => {
  if (!array) return '';
  const arr = [...array];
  let last;

  switch (arr.length) {
    case 0:
      return '';
    case 1:
      return `${arr[0]} is`;
    case 2:
      return `${arr[0]} and ${arr[1]} are`;
    default:
      last = arr.pop();

      return `${arr.join(', ')}, and ${last} are`;
  }
};
