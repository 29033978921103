<template>
  <div>
    <h4>Upload document</h4>
    <p>
      Benefits, rates, and provisions are populated directly from the uploaded
      file.
    </p>
    <p>
      Note: At this time only stop loss products can be submitted using a .CSV
      file upload. Any other coverages must be submitted using manual rate
      entry.
    </p>
    <!-- upload drag/drop window -->
    <FileUpload
      v-if="!csvQuoteStore.isCsvValidated"
      :url="`${apiUrl}/v1/carrier_portal/csv_bid_submissions`"
      :disabled="Boolean(csvQuoteStore.file.name && !csvQuoteStore.id)"
      :class="{
        disabled: Boolean(csvQuoteStore.file.name && !csvQuoteStore.id),
      }"
      name="csv_submission"
      accept=".csv"
      auto
      @before-send="validateFile"
      @progress="updateProgress"
      @upload="validateCSV"
      @error="checkError"
    >
      <template #header="{ chooseCallback }">
        <Teleport defer to="#document-file-upload-teleport">
          <div class="cursor-pointer flex flex-grow" @click="chooseCallback">
            <!-- TODO remove/rename el-* classes and add appropriate styling -->
            <img
              class="icon-upload"
              :src="uploadFilesIcon"
              alt="No documents icon"
            />
            <div class="upload-text">
              Drop file here <br />
              or <em>click to upload</em>
            </div>
          </div>
        </Teleport>
      </template>

      <template #content>
        <div id="document-file-upload-teleport"></div>
      </template>
    </FileUpload>
    <div v-else class="file-uploaded">
      <span class="fa-solid fa-arrow-right" /> Your quote file has been
      processed. Please <strong>continue</strong>
      to validation.
    </div>
    <p>
      Other documents accepted (.doc, .xls, .rtf files) will be added as
      supplemental documents. File size is limited to 20MB.
    </p>
  </div>
</template>

<script setup>
import { useCsvQuoteStore } from '@/stores/csvQuote.js';
import { useProjectStore } from '@/stores/project.js';
import { getCookie } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';
import { useAccountComposable } from '@/composables/useAccountComposable.js';
import ProposalService from '@/services/proposal.js';
import {
  isValidFileSize,
  isValidExtension,
  flattenObjectValues,
} from '@/utils/file.js';

// PrimeVue components
import FileUpload from 'primevue/fileupload';
import uploadFilesIcon from '@/assets/upload-files-icon.svg';

defineOptions({
  name: 'QuoteFileUpload',
});

defineProps({
  parentStep: {
    required: true,
    validator: (propValue) => ['upload', 'submit'].includes(propValue),
  },
});
// Constants
const apiUrl = config.VUE_APP_API_URL;
const authKey = 'X-API-AUTHTOKEN';
const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
// Stores
const csvQuoteStore = useCsvQuoteStore();
const projectStore = useProjectStore();
const authToken = getCookie(`${cookieNamespace}-auth-token`);
const { inactiveLogout } = useAccountComposable();
/**
 * If we get an unauthorized, it likely means someone signed in under the same
 * email while you were on this modal. in this case, sign the user out
 * accordingly
 *
 * @param {object} root0
 * @param {object} root0.xhr
 */
const checkError = ({ xhr }) => {
  csvQuoteStore.$reset();
  if ([401, 403].includes(xhr.status)) {
    inactiveLogout();
  }
};
/**
 * Used to update visual progress bar for file uploading
 *
 * @param {object} event
 * @param {number} event.progress
 */
const updateProgress = ({ progress }) => {
  let fileUploadProgress = progress;

  // show the progress component as %3 less then it really
  // is (this way it doesn't look like it hangs at 100%)
  fileUploadProgress -= fileUploadProgress * 0.03;
  csvQuoteStore.setQuote({ uploadProgress: Math.floor(fileUploadProgress) });
};
/**
 * Attach new headers to current XMLHttpRequest
 *
 * @param {object} xhr - XMLHttpRequest
 */
const attachHeaders = (xhr) => {
  xhr.setRequestHeader(authKey, authToken);
};
/**
 * Attach document_id to current formData, document_id is required on backend
 * side
 *
 * @param {object} formData - The FormData that will be uploaded.
 */
const attachDocumentId = (formData) => {
  // ✅ Append the document_id to the formData
  formData.append('document_id', projectStore.proposalDocumentId);
};
/**
 * Validate and upload CSV file
 *
 * @param {object} root0
 * @param {XMLHttpRequest} root0.xhr
 * @param {object} root0.formData
 * @returns {boolean}
 */
const validateFile = ({ xhr, formData }) => {
  csvQuoteStore.resetAlert();
  const file = formData.get('csv_submission');
  const error = {
    isError: false,
    messages: [],
  };

  // sign out if the cookie is expired
  if (authToken !== getCookie(`${cookieNamespace}-auth-token`)) {
    csvQuoteStore.$reset();
    inactiveLogout();

    xhr.abort();

    return false;
  }

  if (
    !isValidExtension({
      file,
      acceptedExtensions: ['csv'],
      acceptedFileTypes: ['text/csv'],
    })
  ) {
    error.isError = true;
    error.messages.push(
      'We do not accept this file type. Select a CSV file and try again.',
    );
  }

  if (!isValidFileSize(file)) {
    error.isError = true;
    error.messages.push(
      'File is too large. Uploads must be 20MB or less. Select a new file and try again',
    );
  }

  // if invalid, show error message and don't allow upload
  if (error.isError) {
    csvQuoteStore.alert = {
      type: 'error',
      messages: error.messages,
    };
    xhr.abort();

    return false;
  }

  // if valid, add file to store and continue
  csvQuoteStore.setQuote({ file });
  attachDocumentId(formData);
  attachHeaders(xhr);

  return true;
};
/**
 * Validate CSV bid submission
 *
 * @param {object} response
 * @param {XMLHttpRequest} response.xhr
 */
const validateCSV = ({ xhr }) => {
  const data = JSON.parse(xhr.response);
  const { id } = data.csv_bid_submission;

  csvQuoteStore.setQuote({
    id,
    uploadProgress: 100,
  });

  ProposalService.validateCsvFile(id, projectStore.proposalDocumentId)
    .then(
      ({
        csv_bid_submission: {
          bid_evaluation: { product_types: productTypes },
        },
      }) => {
        csvQuoteStore.setQuote({ isCsvValidated: true });

        // accumulate all proposals from the submission that will overwrite any previously submitted proposals
        const proposalsThatWillBeOverwritten = Object.values(
          productTypes,
        ).reduce(
          (overwriteAccumulator, { product_proposals: proposalArray }) => {
            proposalArray.forEach(
              ({ existing_quote: existingQuote, label }) => {
                if (existingQuote) {
                  overwriteAccumulator.push(label);
                }
              },
            );

            return overwriteAccumulator;
          },
          [],
        );

        if (proposalsThatWillBeOverwritten.length) {
          const labelString = proposalsThatWillBeOverwritten.join(', ');

          csvQuoteStore.alert = {
            type: 'warn',
            messages: [
              // eslint-disable-next-line max-len
              `There is already a quote submitted for ${labelString} in this RFP. Submitting a new quote will replace the existing one.`,
            ],
          };
        }
      },
    )
    .catch(
      ({
        response: {
          data: { code, message },
        },
      }) => {
        csvQuoteStore.$reset(); // Reset the store

        // anything without a code is not coming from BE therefore
        // given a generic error message
        if (!code) {
          csvQuoteStore.alert = {
            type: 'error',
            messages: [
              'Something went wrong when attempting to upload your file. Please upload a CSV file to try again.',
            ],
          };

          return;
        }
        const scopedMessage = message;

        // for sun life, there can be a product_identification property that is an object containing arrays
        // we have to flatten this too if it exists.
        if (scopedMessage.product_identification) {
          // eslint-disable-next-line max-len
          scopedMessage.product_identification = flattenObjectValues(
            scopedMessage.product_identification[0],
          );
        }

        // error.message is an object where each property is an array of strings
        // we convert the object to an array of arrays and then flatten
        csvQuoteStore.alert = {
          type: 'error',
          messages: flattenObjectValues(message),
        };
      },
    );
};
</script>

<style lang="scss" scoped>
.file-uploaded {
  color: var(--tf-gray-dark);
  font-size: 20px;
  padding: 100px 0;
  text-align: center;

  svg {
    height: 17px;
    width: 18px;
  }
}

// TODO: move these to primeVueConfig if they are global
:deep() {
  .p-fileupload-content {
    width: 100%;
  }

  .p-fileupload-header {
    padding: 0;
  }
}
</style>
