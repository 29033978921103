<template>
  <DataTable
    :value="productTableData"
    style="width: 100%"
    lazy
    selection-mode="single"
    :loading="productTableData.length < 1"
    row-hover
    data-test="product table"
    @row-click="handleDetails($event.data)"
  >
    <Column
      v-bind="{
        field: 'product',
      }"
      style="width: 250px"
    >
      <template #header>
        <h4>Product</h4>
      </template>
      <template #body="{ data: { product, status } }">
        <span
          :class="!status.rowCtaEnabled && 'cta-text-disabled'"
          class="cta-text"
        >
          {{ product }}
        </span>
      </template>
    </Column>
    <Column v-if="projectHasAlts || projectHasRatePass" style="width: 100px">
      <template #body="{ data: { product, alternative, ratePass } }">
        <Badge
          v-if="alternative"
          :data-test="`${product.toLowerCase()} alternative badge`"
          severity="secondary"
          value="Alternate"
        />
        <Badge
          v-if="ratePass"
          :data-test="`${product.toLowerCase()} rate pass badge`"
          severity="success"
          value="Rate pass"
        />
      </template>
    </Column>
    <Column
      v-bind="{
        field: 'status',
      }"
      style="width: 200px"
    >
      <template #header>
        <h4>Status</h4>
      </template>
      <template #body="{ data: { product, status } }">
        <span :data-test="`${product.toLowerCase()} status`">
          <span :class="[status.icon, 'is-' + status.iconType]" />
          {{ status.status }}
        </span>
      </template>
    </Column>
    <Column
      v-bind="{
        align: 'right',
        field: 'policyNumber',
      }"
      style="width: 150px"
    >
      <template #header>
        <h4>Policy number</h4>
      </template>
      <template #body="{ data: { product, policyNumber } }">
        <span :data-test="`${product.toLowerCase()} policy number`">
          <ul>
            <li v-for="number in policyNumber" :key="number">
              {{ number }}
            </li>
          </ul>
        </span>
      </template>
    </Column>
    <Column
      v-if="isUploadRenewalRatePass"
      v-bind="{
        align: 'right',
        field: 'renewalDate',
      }"
      style="width: 130px"
    >
      <template #header>
        <h4>Renewal date</h4>
      </template>
      <template #body="{ data: { product, renewalDate } }">
        <span :data-test="`${product.toLowerCase()} renewal date`">{{
          renewalDate
        }}</span>
      </template>
    </Column>
    <Column
      width="200"
      v-bind="{
        field: 'rateGuarantee',
      }"
      style="width: 160px"
    >
      <template #header>
        <h4>Rate guarantee</h4>
        <span
          v-tooltip.top="{
            value: `Rate guarantee will display here after your quote is submitted.`,
            escape: false,
          }"
          class="fa-regular fa-circle-info"
        />
      </template>
      <template #body="{ data: { product, rateGuarantee } }">
        <span :data-test="`${product.toLowerCase()} rate guarantee`">{{
          rateGuarantee
        }}</span>
      </template>
    </Column>
    <Column
      v-if="isSmartProposal && showPlanDesignProgress"
      v-bind="{
        field: 'planDesign',
      }"
      data-test="plan design column"
      style="width: 150px"
    >
      <template #header>
        <h4>Plan design</h4>
        <span
          v-tooltip.top="{
            value: `The plan design progress bar shows the completion status of the plan design for this product. You can
            submit your quote even if the plan design is incomplete, provided the rate information is complete.`,
            escape: false,
          }"
          class="fa-regular fa-circle-info"
        />
      </template>
      <template #body="{ data: { product, percentageComplete } }">
        <div
          :data-test="`${product.toLowerCase()} plan design`"
          class="plan-design-progress"
        >
          <ProgressBar
            v-if="percentageComplete <= 0"
            :value="percentageComplete"
            :stroke-width="16"
            color="transparent"
            class="progress"
            :data-test="`${product.toLowerCase()} progress ${percentageComplete}%`"
          />
          <ProgressBar
            v-else
            :value="percentageComplete"
            :stroke-width="18"
            color="var(--tf-blue)"
            class="progress"
            :data-test="`${product.toLowerCase()} progress ${percentageComplete}%`"
          />

          <div v-if="percentageComplete <= 0" class="progress-icon">
            <span class="fa-regular fa-circle-exclamation" />
          </div>
          <div v-else-if="percentageComplete >= 100" class="progress-icon">
            <span class="fa-regular fa-circle-check" />
          </div>
        </div>
      </template>
    </Column>
    <Column
      v-bind="{
        field: 'details',
        align: 'right',
      }"
      style="width: 195px"
    >
      <template #body="{ data }">
        <Button
          :disabled="btnUndoing || !data.status.rowCtaEnabled"
          :label="data.status.rowCtaText"
          variant="text"
          severity="primary"
          :data-test="`${data.product.toLowerCase()} details button`"
          @click="handleDetails(data)"
        />
      </template>
    </Column>
  </DataTable>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useProjectStore } from '@/stores/project.js';
import { useProductStore } from '@/stores/product.js';
import { useProductTableStore } from '@/stores/productTable.js';
import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
import { useToast } from 'primevue/usetoast';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import ProgressBar from 'primevue/progressbar';
import Badge from 'primevue/badge';

defineOptions({
  name: 'ProductTable',
});

// Stores
const projectStore = useProjectStore();
const productStore = useProductStore();
const productTableStore = useProductTableStore();
// Router
const router = useRouter();
// Toast Notifications
const toast = useToast();
// Store References
const { proposalDocument } = storeToRefs(projectStore);
const { isUploadRenewalRatePass, isSmartProposal, smartDocType } =
  storeToRefs(productStore);
const { productTableData } = storeToRefs(productTableStore);
// Reactive State
const btnUndoing = ref(false);
const showPlanDesignProgress = ref(true);
// Computed Properties
/**
 * Determines if project has any alt products, to determine if the badge column
 * needs to be shown
 *
 * @returns {boolean}
 */
const projectHasAlts = computed(() =>
  productTableData.value.some(({ alternative }) => alternative),
);
/**
 * Determines if project has any rate passed products to show badge or badge
 * column
 *
 * @returns {boolean}
 */
const projectHasRatePass = computed(() =>
  productTableData.value.some(({ ratePass }) => ratePass),
);
// Methods
/**
 * Handle route change on click of row CTA
 *
 * @param {object} data
 */
const handleDetails = async (data) => {
  const { productId, state } = data.details;
  const { rowCtaText, rowCtaEnabled } = data.status;

  if (!rowCtaEnabled) return;

  if (state === 'declined') {
    // Disable the button until product has been undeclined
    btnUndoing.value = true;
    try {
      await productStore.updateProductState({
        productId,
        endpointState: 'undecline',
      });
    } catch {
      toast.add({
        severity: 'error',
        detail:
          'There was an error undeclining this product. Please try again.',
      });
    } finally {
      btnUndoing.value = false;
    }

    return;
  }

  const analyticsCtaText = [
    'review details',
    'enter rates',
    'enter rates manually',
    'complete rates',
  ];

  if (analyticsCtaText.includes(rowCtaText.toLowerCase())) {
    trackSegmentEvent(
      `Smart ${smartDocType.value(true)} ${rowCtaText} clicked`,
      {
        product_id: productId,
      },
    );
  }

  const routeName =
    rowCtaText.toLowerCase() === 'complete plan design'
      ? 'PlanDesign'
      : 'RateEntry';

  await router.push({
    name: routeName,
    params: { productId },
  });
};
/** Fetches plan design completeness data */
const fetchPlanDesignCompleteness = async () => {
  showPlanDesignProgress.value = true;
  try {
    await productTableStore.getPlanDesignCompleteness(
      proposalDocument.value.id,
    );
  } catch {
    showPlanDesignProgress.value = false;
  }
};

// Lifecycle Hooks
onMounted(() => {
  if (isSmartProposal.value) {
    fetchPlanDesignCompleteness();
  }

  watch(
    isSmartProposal,
    (newValue) => newValue && fetchPlanDesignCompleteness(),
  );
});
</script>

<style scoped lang="scss">
h4 {
  margin: 0;
}

.p-datatable-table-container {
  margin-bottom: 30px;

  :deep(td) {
    border-bottom: 1px solid var(--tf-gray-medium);
  }

  :deep(.p-datatable-header-cell) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 5px;
  }

  .plan-design-progress {
    display: flex;
    align-items: center;
    width: 100px;

    :deep(.el-progress-bar__outer) {
      background-color: var(--tf-gray-medium);
      border-color: var(--tf-gray-medium);
      border-radius: 24px;
    }

    .progress {
      width: 82px;
    }

    .progress-icon {
      width: 14px;
      height: 14px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      font-weight: bold;
      background-color: #fff;
      color: var(--tf-blue);
      margin-left: 2px;
    }
  }
}

.cta-text {
  color: var(--tf-blue);
  font-weight: 600;
}

:deep(.p-datatable-tbody > tr:has(.cta-text-disabled)) {
  &:hover {
    cursor: not-allowed;
  }

  &:hover > td {
    background-color: var(--tf-white);
  }

  .cta-text {
    color: var(--tf-disabled);
  }
}
</style>
