import {
  createRouter,
  createWebHistory,
  NavigationFailureType,
} from 'vue-router';
import { routes } from '@/routes.js';
import userHomepages from '@/utils/homepages.js';
import { getCookie } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

import { useBrokerUpdatesStore } from '@/stores/brokerUpdates.js';
import { useCommentsStore } from '@/stores/comments.js';
import { useQuoteEditsStore } from '@/stores/quoteEdits.js';
import { useAccountStore } from '@/stores/account.js';

// Create the router instance
const router = createRouter({
  history: createWebHistory(), // Replace `mode: 'history'` with `createWebHistory()`
  routes,
  /**
   * Checks for saved position to scroll to
   *
   * @param {object} to
   * @param {object} from
   * @param {object} savedPosition
   * @returns {object} The location to scroll to
   */
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
});

/**
 * Go to sign in page
 *
 * @param {object} to
 * @returns {object} Route
 */
function goToSignIn(to) {
  const { projectId } = to.params;
  const route = {
    name: 'SignIn',
  };

  if (to.fullPath !== '/' && to.name !== 'SignOut') {
    route.query = { ...to.query, redirect: to.fullPath };
  }

  if (projectId) {
    route.name = 'ProjectSignIn';
    route.params = { projectId };
  }

  return route;
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.noAuth)) {
    return next();
  }

  if (window.localStorage.getItem('auth') !== 'true') {
    return next(goToSignIn(to));
  }

  if (window.localStorage.getItem('confirmed') !== 'true') {
    return next({
      name: 'RegisterConfirmation',
    });
  }

  const protectedRouteRoles = to.matched
    // this reduce will only keep records that contain
    // an allow array in its meta property
    .reduce((acc, record) => {
      const roles = record.meta?.allow;

      if (roles) return acc.concat([roles]);

      return acc;
    }, [])
    // we only need to check the nearest route match
    // with an allowed array which will be the last item
    .pop();

  if (protectedRouteRoles) {
    const allUserRoles = getCookie(
      `${config.VUE_APP_COOKIE_NAMESPACE}-user-roles`,
    ).split(',');

    if (!protectedRouteRoles.some((role) => allUserRoles.includes(role))) {
      return next(userHomepages[allUserRoles[0]]);
    }
  }

  return next();
});

router.afterEach((to, from) => {
  // import pinia toggle panel actions
  const { toggleBrokerPanel } = useBrokerUpdatesStore();
  const { toggleCommentsPanel } = useCommentsStore();
  const { toggleQuoteEditsPanel } = useQuoteEditsStore();
  // panel to toggle hash map
  const panels = {
    comments: toggleCommentsPanel,
    'broker-updates': toggleBrokerPanel,
    'quote-edits': toggleQuoteEditsPanel,
  };
  /**
   * Checks if panel name is in route
   *
   * @param {string} path - The fullPath to check contains panelName
   * @param {string} panelName - PanelName to check
   * @returns {bool}
   */
  const isPanelRoute = (path, panelName) => path.indexOf(panelName) !== -1;
  /**
   * Check path in full path and run mapped action
   *
   * @param {string} fullPath - The fullPath to check contains path
   * @param {boolean} bool - Tells action whether to open (true) or close
   *   (false) panel on toggle
   */
  const pathCheck = (fullPath, bool) => {
    Object.keys(panels).forEach((panel) => {
      if (isPanelRoute(fullPath, panel)) panels[panel](bool);
    });
  };

  // after we load the route we should check to see if any paths in panelsMap is present
  // in the fullPath and if it is we toggle the appropriate panel
  if (to.name !== 'RfpOverview') {
    pathCheck(to.fullPath, true);
  }
  pathCheck(from.fullPath, false);

  // Add project and/or product IDs when Plan Design or Rate Entry route is left.
  if (['PlanDesign', 'RateEntry'].includes(from.name)) {
    const productId = Number(from.params.productId);

    if (
      from.name === 'PlanDesign' &&
      !useAccountStore().planDesignProductsVisited.includes(productId)
    ) {
      useAccountStore().addPlanDesignProductVisited(productId);
    }

    if (
      from.name === 'RateEntry' &&
      !useAccountStore().rateProductsVisited.includes(productId)
    ) {
      useAccountStore().addRateProductVisited(productId);
    }
  }
});

// Handle navigation failures
router.onError((err) => {
  if (err.type !== NavigationFailureType.redirected) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
});

export default router;
