<script setup>
/**
 * Forgot Password Form
 *
 * @exports AppForgotPasswordForm
 */

import { ref, reactive, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Form, FormField } from '@primevue/forms';
import InputText from 'primevue/inputtext';
import Message from 'primevue/message';
import Button from 'primevue/button';
import AuthPageHeader from '@/shared/components/AuthPages/Header.vue';
import AuthPageFooter from '@/shared/components/AuthPages/Footer.vue';
import { zodResolver } from '@primevue/forms/resolvers/zod';
import { z } from 'zod';

defineOptions({
  name: 'AppForgotPasswordForm',
});

const props = defineProps({
  accountService: {
    type: Object,
    default: () => ({}),
  },
  checkInvited: {
    type: Boolean,
    default: false,
  },
  documentId: {
    type: String,
    default: null,
  },
  linkLogin: {
    type: [String, Object],
    required: true,
  },
  projectId: {
    type: [String, Number],
    default: null,
  },
  userInfo: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits([
  'setUserEmail',
  'update:email',
  'setTempPasswordStatus',
]);
const route = useRoute();
const router = useRouter();
const notInvited = ref(false);
const btnDisable = ref(false);
const forgotPasswordForm = reactive({
  email: '',
});

/**
 * Method to validate the email address and see if this user was invited to the
 * project
 *
 * @param {string} email
 * @returns {Promise<string | boolean>}
 */
async function validateEmail(email) {
  if (!email || !props.checkInvited) {
    return true;
  }

  try {
    await props.accountService?.checkInvitedToProject(email, props.projectId);

    return true;
  } catch (error) {
    notInvited.value = true;
    btnDisable.value = false;

    return error.response && error.response.status === 404
      ? "Sorry, this email doesn't exist in our system. Ensure you're using the company email you registered with, or contact support@threeflow.com."
      : 'This email is not invited. Ensure you’re using your company email and that it has been invited, or contact support@threeflow.com.';
  }
}

const zodEmailValidation = z
  .string()
  .min(1, { message: 'Please enter your company email.' })
  .email({ message: 'Invalid email address.' })
  .superRefine(async (email, ctx) => {
    const validationResult = await validateEmail(email);

    if (typeof validationResult === 'string') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: validationResult, // Custom error message
      });
    }
  });
const emailResolver = zodResolver(zodEmailValidation);

/**
 * Watching the email field - and updating it
 *
 * @param {string} email
 */
watch(
  () => forgotPasswordForm.email,
  (email) => {
    emit('setUserEmail', email);
    emit('update:email', email);
    notInvited.value = false;
  },
  { deep: true },
);

/** Initialize email on component mount. */
onMounted(() => {
  forgotPasswordForm.email = props.userInfo?.email;
});

/**
 * On form submission we should validate and call the service to update the
 * password (if valid).
 *
 * @param {{ valid: boolean }} form
 * @returns {boolean}
 */
function submitForgotPassword({ valid }) {
  btnDisable.value = true;

  if (!props.accountService?.passwordReset) {
    return valid;
  }

  if (valid) {
    const data = {
      email: forgotPasswordForm.email,
      projectId: props.projectId,
    };

    // Attach documentId if present
    if (props.documentId) {
      data.documentId = props.documentId;
    }
    props.accountService?.passwordReset(data).then(() => {
      /* We should set a flag to show the success message on
     the login screen and then redirect to the login form */
      emit('setTempPasswordStatus', true);
      const redirectURL = route.query.redirect || props.linkLogin;

      btnDisable.value = false;
      router.push(redirectURL);
    });
  }

  btnDisable.value = false;

  return valid;
}
</script>

<template>
  <Form
    class="auth-form forgot-password"
    :initial-values="forgotPasswordForm"
    validate-on-submit
    validate-on-blur
    @submit="submitForgotPassword"
  >
    <AuthPageHeader title="Forgot Password" />
    <p>Enter your email address below to be sent a temporary password.</p>
    <div class="inputs-container">
      <FormField
        v-slot="$field"
        :class="{ 'not-invited-error': notInvited }"
        name="email"
        class="email-container flex flex-col gap-2"
        :resolver="emailResolver"
      >
        <label for="email-input">Company email</label>
        <InputText
          id="email-input"
          v-model="forgotPasswordForm.email"
          placeholder="Enter email"
          data-test="enter email"
        />
        <Message
          v-if="$field?.invalid"
          severity="error"
          size="small"
          variant="simple"
          >{{ $field.error?.message }}
        </Message>
      </FormField>
      <div>
        <slot name="ctaSubmit">
          <Button
            :disabled="!forgotPasswordForm.email || btnDisable"
            name="submit-button"
            type="submit"
            label="Email temporary password"
            data-test="submit account"
            severity="help"
            variant="outlined"
            fluid
          />
        </slot>
      </div>
      <p class="text-center">
        <RouterLink :to="linkLogin">Sign in</RouterLink>
      </p>
    </div>
    <AuthPageFooter />
  </Form>
</template>
