<script setup>
import { ref, computed, onBeforeUnmount, onMounted, watch } from 'vue';
import {
  reusableAcceptableUploadFileTypes,
  trackSegmentEvent,
  getCookie,
} from '@watchtowerbenefits/es-utils-public';
import { captureException, addBreadcrumb } from '@sentry/vue';
import { useAccountComposable } from '@/composables/useAccountComposable.js';
import { isValidFileSize } from '@/utils/file.js';
import { segmentData } from '@/utils/analytics.js';
import { config } from '@/utils/config.js';

import Button from 'primevue/button';
import FileUpload from 'primevue/fileupload';
import ProgressBar from 'primevue/progressbar';
import InputText from 'primevue/inputtext';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import AppMessage from '@/shared/components/AppMessage.vue';
import uploadFilesIcon from '@/assets/upload-files-icon.svg';

const props = defineProps({
  documents: {
    type: Array,
    default: () => [],
  },
  name: {
    type: String,
    required: true,
  },
  url: {
    type: String,
    required: true,
  },
  isDeleting: {
    type: Boolean,
    default: false,
  },
  isDownloading: {
    type: Boolean,
    default: false,
  },
  fileExtensions: {
    type: Array,
    required: true,
  },
});
const emit = defineEmits([
  'downloadAllDocuments',
  'uploadSuccess',
  'deleteDocument',
  'documentSaved',
  'update:isUploading',
  'beforeUpload',
]);
const { inactiveLogout } = useAccountComposable();
// Constants
const authKey = 'X-API-AUTHTOKEN';
const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
// grab the cookienamespace and the auth token to pass to the upload component
const authToken = getCookie(`${cookieNamespace}-auth-token`);
const headers = { [authKey]: authToken };
// refs
const uploadError = ref('');
const isUploadingRef = ref(false);
const uploadRef = ref(null);
const uploadingFiles = ref([]);
const clonedDocuments = ref(JSON.parse(JSON.stringify(props.documents)) || []);
// computed
const isUploading = computed({
  get: () => isUploadingRef.value,
  set: (value) => {
    isUploadingRef.value = value;
    emit('update:isUploading', value);
  },
});

watch(
  () => props.documents,
  (newValue) => {
    clonedDocuments.value = JSON.parse(JSON.stringify(newValue)) || [];
  },
);

// Methods
/**
 * Splits the document filename into name and extension for editing purposes.
 *
 * @param {object} document - The document object
 * @returns {object} - Document with `inputName` and `extension` separated
 */
const splitDocumentFileName = (document) => {
  const scopedDocument = { ...document, editing: false };

  // editing flags whether or not the user is editing the file name
  if (scopedDocument.name) {
    // we split the files name into parts so the user can edit the name but not the extension
    const extensionArray = scopedDocument.name.split('.');

    scopedDocument.extension = extensionArray[extensionArray.length - 1];
    extensionArray.splice(extensionArray.length - 1, 1);
    scopedDocument.inputName = extensionArray.join('.');
  } else {
    scopedDocument.inputName = '';
    scopedDocument.extension = '';
  }

  return scopedDocument;
};
/**
 * Saves the updated file name and updates the store.
 *
 * @param {object} file - The file object being edited.
 */
const onSaveChanges = (file) => {
  const index = clonedDocuments.value.findIndex(
    (document) => document.id === file.id,
  );

  if (index === -1) return;

  const document = JSON.parse(JSON.stringify(clonedDocuments.value[index]));

  document.name = `${document.inputName}.${document.extension}`;

  document.editing = false;
  clonedDocuments.value.splice(index, 1, document);

  emit('documentSaved', document);
};
/**
 * Calls `onSaveChanges` when the user presses Enter while editing the file
 * name.
 *
 * @param {object} file - The file object being edited.
 */
const onInputEnter = (file) => {
  onSaveChanges(file);
};
/**
 * Cancels the editing of a file's name and restores its original state.
 *
 * @param {object} file - The file object being edited.
 */
const onCancelEdit = (file) => {
  const index = clonedDocuments.value.findIndex(
    (document) => document.id === file.id,
  );

  if (index === -1) return;

  const document = JSON.parse(JSON.stringify(clonedDocuments.value[index]));
  const extensionArray = document.name.split('.');

  document.extension = extensionArray[extensionArray.length - 1];
  extensionArray.splice(extensionArray.length - 1, 1);
  document.inputName = extensionArray.join('.');

  document.editing = false;
  clonedDocuments.value.splice(index, 1, document);
};
/**
 * Enables editing mode for a file name.
 *
 * @param {object} file - The file object to be edited.
 */
const onEditClick = (file) => {
  const index = clonedDocuments.value.findIndex(({ id }) => id === file.id);

  if (index === -1) return;

  const scopedFile = { ...file, editing: true }; // Avoid mutation

  clonedDocuments.value.splice(index, 1, scopedFile);
};
const removeUploadingFile = (fileUid) => {
  uploadingFiles.value = uploadingFiles.value.filter(
    ({ uploadingFile }) => uploadingFile.uid !== fileUid,
  );
};
/**
 * Cancel an upload in progress.
 *
 * @param {object} file - The file object being uploaded.
 */
const onCancelUploadClick = (file) => {
  file.abort?.(); // Force xhr abort, it triggers onUploadError method
  removeUploadingFile(file.uid);
};
/**
 * On Upload Success: Remove the file from `uploadingFiles` and update documents
 * in the store.
 *
 * @param {XMLHttpRequest} xhr
 * @param {object} file
 */
const onUploadSuccess = (xhr, file) => {
  removeUploadingFile(file.uid);

  emit('uploadSuccess', { xhr, file });
  isUploading.value = false;
};
/**
 * Attach new headers to current XMLHttpRequest
 *
 * @param {object} xhr - XMLHttpRequest
 */
const attachHeaders = (xhr) => {
  xhr.setRequestHeader('X-API-AUTHTOKEN', authToken);
};
/**
 * Validates the file before upload, checking size and type.
 *
 * @param {XMLHttpRequest} xhr
 * @param {object} file
 * @returns {boolean} - Whether the file is valid for upload.
 */
const onBeforeUpload = (xhr, file) => {
  let valid = false;

  if (headers[authKey] !== getCookie(`${cookieNamespace}-auth-token`)) {
    inactiveLogout();

    return false;
  }

  const extensionArray = file.name.split('.');
  const extension = extensionArray[extensionArray.length - 1];

  if (!isValidFileSize(file)) {
    uploadError.value = 'File too large. Uploads must be 20MB or less.';
  } else {
    valid = file.type
      ? reusableAcceptableUploadFileTypes().includes(file.type)
      : props.fileExtensions.includes(`.${extension}`);

    if (!valid) {
      uploadError.value =
        'Invalid file type. Only PDF, DOC, Excel, or RTF are accepted.';
    }
  }

  if (valid) {
    const { name } = file;

    uploadingFiles.value = [].concat(
      {
        name,
        uploadingFile: file,
        uid: file.uid,
        progress: 0,
        abort: () => xhr.abort.call(xhr),
      },
      uploadingFiles.value,
    );

    uploadError.value = '';
    trackSegmentEvent('Upload documents', segmentData());
  }

  return valid;
};
/**
 * Manually set the progress attribute so we can update the progress elements on
 * the page
 *
 * @param {number} progress - Files upload progress
 * @param {object} file - File object
 */
const onProgressUpdate = (progress, file) => {
  const fileUploadProgress = progress - progress * 0.03; // Prevents UI from "hanging" at 100%
  const uploadingFile = uploadingFiles.value.find((x) => x.name === file.name);

  if (uploadingFile) {
    uploadingFile.progress = Math.floor(fileUploadProgress);
  }
};
/**
 * If there is an error on upload we do a combination of the following: Sign the
 * user out (on 401), Throw a Sentry Error, Display a generic error message to
 * the user
 *
 * @param {string} xhr - XMLHttpRequest
 * @param {object} file
 */
const onUploadError = (xhr, file) => {
  const message = `Upload failed for file: ${file.name}`;

  if (xhr.status === 401) {
    inactiveLogout();
  }

  addBreadcrumb({
    data: { xhr, file },
    level: 'info',
    message,
  });
  captureException(message);

  isUploading.value = false;
  uploadError.value = `Upload failed for ${file.name}. Please try again.`;
};
/**
 * Reworked copy of native FileUpload uploader function that fit our needs Main
 * function job is to create a reauest per each selected file
 *
 * @param {object} event
 */
const customUploader = (event) => {
  const { files } = event;

  files.forEach((file) => {
    const xhr = new XMLHttpRequest();
    const formData = new FormData();

    // eslint-disable-next-line no-param-reassign
    file.uid = file.uid || crypto.randomUUID();

    formData.append('files[]', file, file.name);

    // Validate file before upload
    if (!onBeforeUpload(xhr, file)) {
      return; // Stop upload if the file is invalid
    }

    emit('beforeUpload', { xhr, file });

    xhr.upload.addEventListener('progress', (progressEvent) => {
      if (progressEvent.lengthComputable) {
        onProgressUpdate(
          Math.round((progressEvent.loaded * 100) / progressEvent.total),
          file,
        );
      }
    });

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        onUploadSuccess(xhr, file);
      } else {
        onUploadError(xhr, file);
      }
    };

    xhr.onerror = () => {
      onUploadError(xhr, file);
    };

    isUploading.value = true;
    xhr.open('POST', props.url, true);

    attachHeaders(xhr);
    xhr.send(formData);
  });
};

onMounted(() => {
  // split up the document file name by extension for each document
  clonedDocuments.value = clonedDocuments.value?.map(splitDocumentFileName);
});

onBeforeUnmount(() => {
  // Clear errors
  uploadError.value = '';
});
</script>

<template>
  <div class="file-upload-container">
    <slot name="header" />

    <AppMessage
      v-if="uploadError"
      :closable="false"
      class="upload-failure-disclaimer"
      severity="error"
      show-icon
    >
      {{ uploadError }}
      Select a new file and try again. If the problem persists, please
      <a href="mailto:support@threeflow.com" class="is-danger"
        >contact support</a
      >.
    </AppMessage>

    <FileUpload
      ref="uploadRef"
      data-test="drag and drop files"
      :accept="fileExtensions.join(',')"
      :show-file-list="false"
      :name="name"
      multiple
      auto
      custom-upload
      @uploader="customUploader"
    >
      <template #header="{ chooseCallback }">
        <Teleport defer to="#document-file-upload-teleport">
          <div class="cursor-pointer flex flex-grow" @click="chooseCallback">
            <!-- TODO remove/rename el-* classes and add appropriate styling -->
            <img :src="uploadFilesIcon" alt="No documents icon" />
          </div>
        </Teleport>
      </template>

      <template #content>
        <div id="document-file-upload-teleport"></div>
      </template>
    </FileUpload>

    <slot name="acceptedFileTypes">
      <p>
        Other documents accepted (.doc, .xls, .rtf files) will be added as
        supplemental documents. File size is limited to 20MB.
      </p>
    </slot>

    <div class="manage-documents">
      <div class="manage-documents-heading items-center">
        <h3>
          <slot name="documentListTitle"> Manage documents</slot>
        </h3>
        <Button
          :loading="isDownloading"
          :disabled="isUploading || !clonedDocuments.length || isDeleting"
          data-test="download all documents"
          icon="fa-solid fa-arrow-down-to-line"
          icon-pos="right"
          size="text"
          variant="text"
          label="Download all"
          @click="emit('downloadAllDocuments')"
        />
      </div>

      <p
        v-if="
          clonedDocuments &&
          !clonedDocuments.length &&
          !Object.keys(uploadingFiles).length
        "
        class="no-documents-paragraph"
      >
        No documents uploaded.
      </p>

      <ul v-else class="file-list" data-test="file list">
        <li
          v-for="file in uploadingFiles"
          :key="file.uid"
          class="flex justify-between"
        >
          <span>
            {{ file.name }}
          </span>

          <ProgressBar
            class="progress"
            :stroke-width="18"
            :value="file.progress"
          />
          <Button
            class="cancel-upload-button"
            data-test="cancel file upload"
            icon="fa-solid fa-xmark"
            icon-pos="right"
            size="text-small"
            label="Cancel upload"
            severity="danger"
            variant="text"
            @click="onCancelUploadClick(file)"
          />
        </li>
        <li v-for="document in clonedDocuments" :key="document.id">
          <span
            v-if="!document.editing"
            :data-test="`file ${document.name}`"
            class="name"
          >
            {{ document.name }}
          </span>
          <span v-else class="edit-container">
            <form @submit.prevent="onInputEnter(document)">
              <InputGroup>
                <InputText v-model="document.inputName"> </InputText>
                <InputGroupAddon> .{{ document.extension }} </InputGroupAddon>
              </InputGroup>
            </form>
          </span>
          <div class="flex gap-2">
            <template v-if="document.editing">
              <Button
                class="cancel-button"
                data-test="cancel edit filename"
                icon="fa-solid fa-xmark"
                icon-pos="right"
                size="text-small"
                label="Cancel changes"
                severity="danger"
                variant="text"
                @click="onCancelEdit(document)"
              />
              <Button
                data-test="save filename changes"
                icon="fa-solid fa-check"
                icon-pos="right"
                size="text-small"
                label="Save changes"
                severity="success"
                variant="text"
                @click="onSaveChanges(document)"
              />
            </template>
            <template v-else>
              <Button
                data-test="edit filename"
                size="text-small"
                label="Edit"
                variant="text"
                @click="onEditClick(document)"
              />
              <Button
                :data-test="`delete file ${document.name}`"
                size="text-small"
                label="Delete"
                variant="text"
                @click="$emit('deleteDocument', document)"
              />
            </template>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.upload-failure-disclaimer {
  margin-bottom: 20px !important; // stylelint-disable-line
}

.no-documents-paragraph {
  margin: 16px 0;
}

.manage-documents {
  border-bottom: 1px solid var(--tf-gray-light-medium);
  color: var(--tf-gray-dark);
  margin-bottom: 20px;
}

.manage-documents-heading {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--tf-gray-light-medium);
  padding-top: 12px;

  h4 {
    margin: 0;
  }
}

.file-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  border-bottom: 1px solid var(--tf-gray-light-medium);
  min-height: 55px;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.progress {
  width: 137px;
  margin-right: 24px;
  align-self: center;
}

.edit-container {
  flex-grow: 1;
  margin-right: 10px;
}

:deep(.p-fileupload.p-fileupload-advanced.p-component) {
  background: var(--tf-blue-light);
  border-radius: 8px;
  display: flex;
  border: 2px dashed var(--tf-gray-medium);
  align-items: center;
}

:deep() {
  .p-fileupload-content {
    width: 100%;
    padding: 0;
  }

  .p-fileupload-header {
    padding: 0;
  }
}

:deep(#document-file-upload-teleport) {
  width: 100%;
  padding: 25px;
  display: flex;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
