<template>
  <Dialog
    header="Invite Teammate"
    :visible="visible"
    :closable="false"
    modal
    @hide="email = ''"
    @update:visible="$emit('update:visible', false)"
  >
    <p class="description">
      New teammates will receive email notifications about this opportunity.
    </p>
    <Form
      v-slot="$form"
      :model="{ email }"
      :resolver="formValidationResolver"
      @submit.prevent
    >
      <FormField name="email">
        <label for="email">Email address</label>
        <InputText
          id="email"
          v-model="email"
          :style="input"
          data-test="Enter email"
          placeholder="Enter email"
          fluid
        />
        <Message
          v-if="$form.email?.invalid"
          severity="error"
          size="small"
          variant="simple"
        >
          {{ $form.email.error?.message }}
        </Message>
      </FormField>
    </Form>
    <template #footer>
      <Button
        label="Cancel"
        severity="secondary"
        variant="text"
        @click="emit('update:visible', false)"
      />
      <Button
        :disabled="isDisabled"
        label="Send invite"
        data-test="send invite"
        @click="sendInvite"
      />
    </template>
  </Dialog>
</template>

<script setup>
/**
 * Table component that displays provided users in a table
 *
 * @exports InviteTeammateModal
 */

import { Dialog, Button, Message } from 'primevue';
import { Form, FormField } from '@primevue/forms';
import InputText from 'primevue/inputtext';
import { ref, computed } from 'vue';
import { z } from 'zod';
import { zodResolver } from '@primevue/forms/resolvers/zod';

defineOptions({
  name: 'InviteTeammateModal',
});

const props = defineProps({
  /** Visibility of the modal */
  visible: {
    type: Boolean,
    default: false,
  },
  /** Project id for the project we are sending invites to */
  projectId: {
    type: [String, Number],
    required: true,
  },
  /** Domains of the carrier for invite rules */
  carrierDomains: {
    type: Array,
    required: true,
  },
  /** Users who are already subscribed for invite rules */
  existingEmails: {
    type: Array,
    required: true,
  },
});
const emit = defineEmits(['invite-teammate']);
const email = ref('');
const formRef = ref(null);
const formValidationResolver = zodResolver(
  z.object({
    email: z
      .string()
      .min(1, { message: 'Email address is required.' })
      .email({ message: 'Invalid email address.' })
      .refine(
        (input) => {
          const domain = input.split('@')[1];

          if (!domain || !props.carrierDomains.includes(domain)) {
            return false;
          }

          return true;
        },
        {
          message: `Email must end in ${props.carrierDomains
            .filter((domain) => domain !== 'threeflow.com')
            .map((d) => `@${d}`)
            .join(', ')}.`,
        },
      )
      .refine(
        (input) => {
          if (props.existingEmails.includes(input)) {
            return false;
          }

          return true;
        },
        {
          message: 'This user is already subscribed.',
        },
      ),
  }),
);
/**
 * Disable the 'invite' button if the email is invalid.
 *
 * @returns {boolean} True if the invite button should be disabled, otherwise
 *   false
 */
const isDisabled = computed(() => {
  const domain = email.value.split('@')[1];

  if (email.value.trim() === '' || !props.carrierDomains.includes(domain)) {
    return true;
  }

  if (props.existingEmails.includes(email.value)) {
    return true;
  }

  let hasValidationErrors = false;

  if (formRef.value) {
    formRef.value.validate((valid) => {
      hasValidationErrors = !valid;
    });
  }
  if (hasValidationErrors) {
    return true;
  }

  return false;
});

/** Send a project invitation and close the modal. */
async function sendInvite() {
  emit('invite-teammate', email.value);
  emit('update:visible', false);
}
</script>

<style scoped>
.description {
  padding-bottom: 10px;
}
.p-formfield label {
  margin-bottom: 8px;
  display: block;
}
.p-button-text.p-button-secondary {
  border: none;
}
</style>
