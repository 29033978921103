<template>
  <div>
    <div class="dropdown-column">
      <div class="dropdown-header">Views</div>
      <PvSelect
        v-model="selectedChart"
        :options="charts"
        option-label="name"
        placeholder="Select a view"
        @change="chartChange"
      />
    </div>
    <AppMessage
      v-if="errorMessage"
      severity="error"
      data-test="insights error disclaimer"
    >
      {{ errorMessage }}
      <a href="mailto:support@threeflow.com" class="is-danger"
        >contact support</a
      >.
    </AppMessage>

    <div data-test="Insight View Dashboard">
      <div
        v-if="isLoading && !Boolean(errorMessage)"
        class="flex flex-col items-center"
      >
        <ProgressSpinner />
        <p>Loading {{ splitChartName }}...</p>
      </div>
      <iframe
        v-show="!isLoading && !Boolean(errorMessage)"
        id="looker"
        data-test="looker information"
        scrolling="no"
        :style="{ height: iframeHeight }"
        :src="embedUrl"
      />
    </div>
    <BackToTop />
  </div>
</template>

<script setup>
import { ref, computed, onBeforeUnmount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import BackToTop from '@/components/Insights/BackToTop.vue';
import { getLookerUrl } from '@/services/insights.js';
import { segmentData } from '@/utils/analytics.js';
import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
import PvSelect from 'primevue/select';
import ProgressSpinner from 'primevue/progressspinner';
import AppMessage from '@/shared/components/AppMessage.vue';

/** Dashboard for Carrier Insights. */
defineOptions({
  name: 'CarrierInsights',
});

const router = useRouter();
const route = useRoute();
const lookerEventOrigin = ref('');
const charts = [
  {
    id: 'carrier_business_overview',
    name: 'Book of business',
  },
  {
    id: 'carrier_new_business',
    name: 'New business',
  },
  {
    id: 'carrier_renewal_business',
    name: 'Renewal business',
  },
];
const embedUrl = ref('');
const embedDomain = window.location.origin;
const errorMessage = ref('');
const isLoading = ref(true);
const pageChange = ref(null);
const selectedChart = ref(route.query.view || 'carrier_business_overview');
/**
 * Sets iframe height based on chart selection
 *
 * @returns {string}
 */
const iframeHeight = computed(() => `${pageChange.value}px`);
/**
 * Splits selectedChart
 *
 * @returns {string}
 */
const splitChartName = computed(() => selectedChart.value.replace(/_/g, ' '));
/**
 * Push the passed param to the router.
 *
 * @param {object} params
 */
const changeRouteParams = (params) => {
  const query = { ...route.query, ...params };

  // this is to prevent a redundant route push that VueRouter now warns us about
  if (params.view === route.query.view) return;
  router.replace({ query });
};
/** Sets loading state and fetches looker url based on dropdown value */
const fetchUrl = () => {
  performance.mark('start');
  errorMessage.value = '';
  isLoading.value = true;
  getLookerUrl({ dashboardType: selectedChart.value, embedDomain })
    .then((urlToEmbed) => {
      embedUrl.value = urlToEmbed;
      lookerEventOrigin.value = new URL(urlToEmbed).origin;
    })
    .catch((error) => {
      errorMessage.value = `${error.message} please refresh page or `;
      isLoading.value = false;
    });
};
/** Set all timer info to null */
const resetTimers = () => {
  performance.clearMarks();
  performance.clearMeasures();
  performance.clearResourceTimings();
};
/**
 * Sets selectedChart based on change event then fetches new url
 *
 * @param {string} view
 */
const chartChange = (view) => {
  resetTimers();
  selectedChart.value = view.value.id;
  changeRouteParams({ view: selectedChart.value });
  fetchUrl();
};
/**
 * Returns duration in seconds from start mark to mark parameter
 *
 * @param {string} mark - Name of mark
 * @returns {string} Results in seconds or no duration message
 */
const getDuration = (mark) => {
  const { duration } = performance.measure(mark, 'start', mark);

  return duration ? Math.round(duration / 10) / 100 : null; // rounded to the nearest 100th second (2438.9403854ms -> 2.44s)
};
/**
 * Called by window message listener to set iframe height
 *
 * @param {Event} event
 */
const lookerEvent = (event) => {
  // list of looker embed events: https://docs.looker.com/reference/embedding/embed-javascript-events
  if (event.origin === lookerEventOrigin.value) {
    const data = JSON.parse(event.data);
    const dashboardId = data.dashboard?.id.replace(/.*external_/g, '');

    switch (data.type) {
      case 'page:properties:changed':
        pageChange.value = data.height;
        break;

      case 'dashboard:run:start':
        isLoading.value = false;
        // events from a previous chart can still be sent through the listener
        // but we don't want to capture those analytics if we've moved into a
        // different chart
        if (selectedChart.value !== dashboardId) return;

        performance.mark('firstSeen');
        trackSegmentEvent(
          'Insights iframe appeared',
          segmentData({
            view: selectedChart.value,
            filters: data.dashboard.dashboard_filters,
            seconds: getDuration('firstSeen'),
          }),
        );
        break;

      case 'dashboard:download':
        trackSegmentEvent(
          'Insights PDF download',
          segmentData({
            view: selectedChart.value,
            title: data.dashboard.title,
          }),
        );
        break;

      case 'dashboard:run:complete':
        // events from a previous chart can still be sent through the listener
        // but we don't want to capture those analytics if we've moved into a
        // different chart
        if (selectedChart.value !== dashboardId) return;

        performance.mark('loaded');
        trackSegmentEvent(
          'Insights iframe finished loading',
          segmentData({
            view: selectedChart.value,
            filters: data.dashboard.dashboard_filters,
            seconds: getDuration('loaded'),
          }),
        );
        break;

      default:
    }
  }
};

/** Fetches looker url, and sets listener for looker events */
window.addEventListener('message', lookerEvent);
changeRouteParams({ view: selectedChart.value });
fetchUrl();

/** Remove listener for looker events, and timer to null */
onBeforeUnmount(() => {
  window.removeEventListener('message', lookerEvent, false);
  resetTimers();
});
</script>

<style lang="scss" scoped>
// .el-spinner {
//   margin: 0 auto;
//   display: table;
// }

iframe {
  width: 100%;
  border: none;
  overflow: hidden;
}

.dropdown-column {
  width: 200px;
  margin: 30px 0;
  display: flex;
  flex-direction: column;

  .dropdown-header {
    font-weight: bold;
  }
}
</style>
