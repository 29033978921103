<template>
  <div>
    <Button
      icon="fa-solid fa-bars"
      aria-haspopup="true"
      aria-controls="overlay_menu"
      variant="text"
      size="large"
      :badge="notificationCount"
      badge-severity="danger"
      @click="toggleMenu"
    />
    <Menu
      id="overlay_menu"
      ref="menuRef"
      :model="menuItems"
      :popup="true"
      data-test="hamburger menu"
    >
      <template #item="{ item, props }">
        <RouterLink
          v-bind="props.action"
          :to="item.to"
          class="flex justify-between"
          @click="item.track && sendAnalytics(item.label)"
        >
          <span class="ml-2">{{ item.label }}</span>
          <Badge
            v-if="item.badgeCount"
            class="ml-auto"
            :value="item.badgeCount"
          />
        </RouterLink>
      </template>
    </Menu>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';

// Pinia Stores
import { useNotificationsStore } from '@/stores/notifications.js';
import { useBrokerUpdatesStore } from '@/stores/brokerUpdates.js';
import { useCommentsStore } from '@/stores/comments.js';
import { useProjectStore } from '@/stores/project.js';
import { useAccountStore } from '@/stores/account.js';

// Utils
import { segmentData } from '@/utils/analytics.js';
import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';

// Composable
import { useRfpOverview } from '@/composables/useRfpOverview.js';

// Components
import Menu from 'primevue/menu';
import Button from 'primevue/button';
import Badge from 'primevue/badge';

/**
 * Global Navigation
 *
 * @exports GlobalHamburgerMenu
 */
defineOptions({
  name: 'GlobalHamburgerMenu',
});

// Stores
const notificationsStore = useNotificationsStore();
const brokerUpdatesStore = useBrokerUpdatesStore();
const commentsStore = useCommentsStore();
const projectStore = useProjectStore();
const accountStore = useAccountStore();
// Store References
const { notifications: unreadBrokerUpdates } = storeToRefs(brokerUpdatesStore);
const { unreadEdits } = storeToRefs(notificationsStore);
const { unreadComments } = storeToRefs(commentsStore);
const { projectId } = storeToRefs(projectStore);
const { roleNames } = storeToRefs(accountStore);
const route = useRoute();
const { rfpOverviewTabs } = useRfpOverview();
// Reactive
const menuRef = ref();
// **Computed Properties**
/**
 * Adds the unread notifications for non-dashboard pages.
 *
 * @returns {number}
 */
const isAdminAndMore = computed(
  () => roleNames.value.length > 1 && roleNames.value.includes('admin'),
);
/**
 * Evaluate the $route.meta.pageType to see if its a management page.
 *
 * @returns {boolean}
 */
const isManagement = computed(() => route.meta.pageType === 'management');
/**
 * Evaluate the $route.meta.pageType to see if its a dashboard.
 *
 * @returns {boolean}
 */
const isDashboard = computed(() => route.meta.pageType === 'dashboard');
/**
 * Adds the unread notifications for non-dashboard pages.
 *
 * @returns {string}
 */
const notificationCount = computed(() =>
  !isDashboard.value && !isManagement.value
    ? String(
        unreadBrokerUpdates.value + unreadComments.value + unreadEdits.value,
      )
    : '0',
);
/**
 * Check if the page type is a "Dashboard" or "Management" to determine what the
 * sign out route should be.
 *
 * @returns {object}
 */
const signOutRoute = computed(() => {
  if (!isDashboard.value && !isManagement.value) {
    return {
      name: 'ProjectSignOut',
      params: { projectId: projectId.value },
    };
  }

  return { name: 'SignOut' };
});
/**
 * Sends event to segment
 *
 * @param {string} title
 */
const sendAnalytics = (title) => {
  const menuItemsForAnalytics = [
    'My quotes',
    'Support',
    'RFP info',
    'Comments',
  ];

  if (menuItemsForAnalytics.includes(title)) {
    trackSegmentEvent(`${title} in main menu clicked`, segmentData());
  }
};
const projectMenuItems = computed(() => {
  const dataEntryItems = [
    {
      label: 'RFP Overview',
      to: { name: 'MyQuotes' },
    },
    {
      label: 'RFP Updates',
      to: { path: `${route.fullPath}/broker-updates` },
      badgeCount: unreadBrokerUpdates.value,
    },
    {
      label: 'Quote Edits',
      to: { path: `${route.fullPath}/quote-edits` },
      badgeCount: unreadEdits.value,
    },
    {
      label: 'Comments',
      to: { path: `${route.fullPath}/comments` },
      badgeCount: unreadComments.value,
    },
  ];

  return route.meta.pageType === 'dataEntry'
    ? dataEntryItems.map((item) => ({
        ...item,
        dataTest: `to ${item.name}`,
        index: item.to.path || item.to.name,
        track: true,
      }))
    : rfpOverviewTabs.value.map((tab) => ({
        ...tab,
        index: tab.name,
        dataTest: `to ${tab.name}`,
        to: { name: tab.name },
        track: true,
      }));
});
/** Menu Items - Convert TfHamburgerMenu into PrimeVue Menu */
const menuItems = computed(() => {
  const items = route.params.projectId ? projectMenuItems.value : [];

  // Add carrier management if user has admin role
  if (isAdminAndMore.value) {
    items.push({
      label: 'Carrier Management',
      to: { name: 'CarrierManagement' },
    });
  }

  // Add Home and Support
  if (!isDashboard.value) {
    items.push({
      label: 'Home',
      to: { name: 'Dashboard' },
      dataTest: 'to home',
    });
  }

  items.push({
    label: 'Support',
    to: { name: 'Support' },
    track: true,
    dataTest: 'to threeflow support',
  });

  // Sign out option
  items.push({
    label: 'Sign out',
    to: signOutRoute.value,
    class: 'signOut',
    dataTest: 'sign user out',
  });

  return items;
});
/**
 * Close menu
 *
 * @param {Event} event
 */
const toggleMenu = (event) => {
  menuRef.value.toggle(event);
};
</script>

<style lang="scss" scoped>
button.rfp-updates {
  svg.fa-icon.exclamation-yellow {
    position: relative;
    top: -2px;
    margin-left: 2px;
  }
}
</style>
