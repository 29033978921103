import LogRocket from 'logrocket';

/**
 * Logrocket logging plugin for pinia
 *
 * @param {object} context
 */
export const logrocketPlugin = (context) => {
  context.store.$subscribe((mutation, state) => {
    LogRocket.log(`Mutation: ${mutation.storeId}`, { mutation, state });
  });

  context.store.$onAction((action) => {
    LogRocket.log(`Action: ${action.name} started`, action);

    action.after((result) => {
      LogRocket.log(`Action: ${action.name} finished`, result);
    });
  });
};

/**
 * A Pinia plugin for mocking getters in component tests.
 *
 * @param {object} gettersMap
 * @returns {Function}
 */
export const createTestingPiniaGetters =
  (gettersMap) =>
  ({ store, options }) => {
    if (gettersMap[store.$id]) {
      Object.keys(options?.getters ?? []).forEach((getter) => {
        Object.defineProperty(store, getter, {
          get: () =>
            gettersMap[store.$id][getter]?.(store) ??
            options.getters[getter](store),
        });
      });
    }
  };
