<template>
  <Dialog
    header="Send to ThreeFlow Assist"
    :visible="visible"
    :closable="false"
    data-test="threeflow assist modal"
    modal
    style="width: 500px"
    @update:visible="closeDialog"
  >
    <div v-if="threeflowAssistProductSnapshot.length > 0">
      <h4 data-test="modal header">
        The following products are not eligible for an AI populated quote:
      </h4>
      <ul class="product-list" data-test="unsupported product types list">
        <li
          v-for="(product, idx) in threeflowAssistProductSnapshot"
          :key="`${product}-${idx}`"
        >
          {{ product }}
        </li>
      </ul>
    </div>
    <p>
      ThreeFlow Assist will review, enter, and send your proposal within two
      business days.
      <b
        >You will be locked out of the product while ThreeFlow Assist processes
        your product.</b
      >
      Alternatively, you can enter the quote manually.
    </p>

    <Checkbox
      v-model="boxChecked"
      input-id="assist-checkbox"
      binary
      data-test="threeflow assist checkbox"
    />
    <label for="assist-checkbox">
      I acknowledge that while products are with ThreeFlow Assist I will<br />
      not be able to access these products or quote manually.</label
    >
    <div class="gap-2 flex justify-end cta-container">
      <Button
        class="enter-rates-button"
        data-test="enter rates manually"
        outlined
        label="Enter Manually"
        severity="primary"
        @click="handleEnterRatesManually"
      />
      <Button
        :disabled="!boxChecked"
        data-test="send to threeflow assist"
        size="large"
        :label="
          boxChecked ? 'Send to ThreeFlow Assist' : 'Confirm statement above'
        "
        severity="primary"
        @click="sendToThreeFlowAssist"
      />
    </div>
  </Dialog>
</template>

<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useToast } from 'primevue/usetoast';
import { useProductStore } from '@/stores/product.js';
import { sendPlanAltsToThreeFlowAssist } from '@/services/documents.js';
import { patchUserSavedPreferences } from '@/services/product.js';
import { formatArrayIntoListOfStrings } from '@/utils/general.js';

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';

/**
 * Allow user to choose to either send plan Alts to ThreeFlow Assist or manually
 * enter rates for alternates
 *
 * @exports ThreeFlowAssist
 */
defineOptions({
  name: 'ThreeFlowAssist',
});
// Props
const props = defineProps({
  /**
   * Controls the visibility of the modal.
   *
   * @default false
   * @type {boolean}
   */
  visible: { type: Boolean, default: false },

  /**
   * Document ID associated with the proposal.
   *
   * @type {number}
   */
  proposalDocumentId: { type: Number, required: true },
});
// Emits
const emit = defineEmits(['update:visible', 'showProductTableInfoMessage']);
// Store Access
const productStore = useProductStore();
const { threeflowAssistProductSnapshot } = storeToRefs(productStore);
const toast = useToast();
// Reactive State
const boxChecked = ref(false);
/** Closes the dialog and resets ThreeFlow Assist snapshot. */
const closeDialog = () => {
  threeflowAssistProductSnapshot.value = [];
  emit('update:visible', false);
};
/** Handles manual rate entry. */
const handleEnterRatesManually = async () => {
  try {
    await patchUserSavedPreferences(props.proposalDocumentId);
  } catch (error) {
    toast.add({
      severity: 'error',
      detail:
        error.response?.data?.message ||
        'There was an error applying your user preferences and carrier defaults. Please try again.',
    });
  } finally {
    closeDialog();
  }
};
/** Sends plan alternatives to ThreeFlow Assist. */
const sendToThreeFlowAssist = async () => {
  emit(
    'showProductTableInfoMessage',
    `${formatArrayIntoListOfStrings(threeflowAssistProductSnapshot.value)} supported by ThreeFlow Assist. ThreeFlow will enter your quote within two business days and send it to you to review and submit.`,
  );

  try {
    const {
      document: { products },
    } = await sendPlanAltsToThreeFlowAssist(props.proposalDocumentId);

    productStore.setProducts(products);
  } catch (error) {
    toast.add({
      severity: 'error',
      detail:
        error.response?.data?.message ||
        'There was an error sending your alternates to ThreeFlow Assist. Please try again.',
    });
  } finally {
    closeDialog();
  }
};
</script>

<style lang="scss" scoped>
.cta-container {
  margin-top: 20px;
}
</style>
