import { defineStore } from 'pinia';
import { clearNotifications, getUpdates } from '@/services/brokerUpdates.js';

export const useBrokerUpdatesStore = defineStore('brokerUpdates', {
  state: () => ({
    brokerPanel: {
      visible: false,
      open: false,
    },
    updates: null,
    notifications: 0,
  }),

  actions: {
    /**
     * Tells API to set is_new to false for all new updates per given projectId
     * then clears the notification count
     *
     * @param {number} projectId
     * @returns {Promise} Resolves to undefined
     */
    async markNotificationsAsRead(projectId) {
      if (!this.notifications) {
        return;
      }

      await clearNotifications(projectId);
      this.notifications = 0;
    },
    /**
     * Gets all updates for a given projectId and saves them along with a count
     * of all updates listed as is_new
     *
     * @param {number} projectId
     * @returns {Promise} Resolves to undefined
     */
    async getUpdatesAndNotifications(projectId) {
      const updates = await getUpdates(projectId);
      const newUpdateCount = updates.filter((update) => update.is_new).length;

      this.notifications = newUpdateCount;
      this.updates = updates;
    },
    /**
     * Sets the visible and open booleans for panel by setting one first then
     * waiting for the open/close animation to finish (500ms) before setting the
     * other. We delay in order to add it to the dom and then trigger a class
     * change and a transition
     *
     * @param {boolean} open - The boolean to set the states to
     */
    toggleBrokerPanel(open) {
      if (open) {
        this.brokerPanel.visible = open;
        setTimeout(() => {
          this.brokerPanel.open = open;
        }, 500);
      } else {
        this.brokerPanel.open = open;
        setTimeout(() => {
          this.brokerPanel.visible = open;
        }, 500);
      }
    },
  },
});
