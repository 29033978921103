<template>
  <div class="document-section">
    <h4>{{ title }}</h4>
    <template v-for="sourceType in sourceGroup">
      <RfpDocumentCategory
        v-if="sourceType.sources.length"
        :key="sourceType.title"
        :file-list="sourceType.sources"
        :title="sourceType.title"
        data-test="download file"
        @downloaded="emits('downloaded', $event)"
      />
    </template>
  </div>
</template>

<script setup>
import RfpDocumentCategory from '@/components/RfpDocuments/RfpDocumentCategory.vue';

defineOptions({
  name: 'RfpDocumentSection',
});

/**
 * RFP Document Section
 *
 * @exports RfpDocumentSection
 */
defineProps({
  sourceGroup: {
    type: Array,
    default: () => [],
  },
  title: {
    type: String,
    default: null,
  },
});

const emits = defineEmits(['downloaded']);
</script>

<style lang="scss" scoped>
h4 {
  margin-bottom: 7px;
  font-size: 18px;
  text-transform: uppercase;
}

.document-section {
  padding-top: 25px;
  border-top: 1px solid var(--tf-gray-light-medium);
}
</style>
