<template>
  <div class="rfp-column">
    <slot name="title" />
    <section class="document-manager">
      <ProgressSpinner v-if="!inforceDocument" />
      <section v-else>
        <header>
          <Button
            class="px-0"
            icon="fa-solid fa-file-download"
            icon-pos="right"
            variant="text"
            label="Download all files"
            data-test="download all files"
            @click="onDownload('downloadAll')"
          />
        </header>
        <div id="rfp-documents">
          <!-- Plan Summary Documents -->
          <RfpDocumentCategory
            key="Plan summary documents"
            title="Plan summary"
          >
            <Button
              :loading="isDownloadingPlanSummary"
              data-test="download file button"
              class="px-0"
              icon="fa-solid fa-file-download"
              icon-pos="right"
              variant="text"
              label="plan_summary.xlsx"
              @click="onDownload('downloadPlanSummary')"
            />
          </RfpDocumentCategory>
          <!-- Census Documents -->
          <RfpDocumentCategory
            v-if="censusSources.sources.length"
            key="Census documents"
            title="Census"
            :file-list="censusSources.sources"
            @downloaded="onDownload('startDocument')"
          />
          <!-- Stop Loss Documents -->
          <RfpDocumentSection
            v-if="
              stopLossSources.some((sourceType) => sourceType.sources.length)
            "
            :source-group="stopLossSources"
            title="Stop loss"
            @downloaded="onDownload('startDocument')"
          />
          <!-- Non-Medical Documents -->
          <RfpDocumentSection
            v-if="
              nonMedicalSources.some((sourceType) => sourceType.sources.length)
            "
            :source-group="nonMedicalSources"
            title="Non-medical"
            @downloaded="onDownload('startDocument')"
          />
        </div>
      </section>
    </section>
    <!-- This is a hidden link to download the inforce packet triggered with `onDownloadAll` -->
    <a
      id="download-all-link"
      ref="downloadAllRef"
      :href="project.inforce_packet_url"
      :download="`${employerName.replace(/ /g, '_')}_inforce_packet.zip`"
      value="Download"
      target="_blank"
      data-test="hidden download all files"
    />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useToast } from 'primevue/usetoast';
import { useProjectStore } from '@/stores/project.js';
import ProjectService from '@/services/project.js';
import RfpDocumentCategory from '@/components/RfpDocuments/RfpDocumentCategory.vue';
import RfpDocumentSection from '@/components/RfpDocuments/RfpDocumentSection.vue';
import { segmentData } from '@/utils/analytics.js';
import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';

import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';

defineOptions({
  name: 'RfpDocuments',
});

const projectStore = useProjectStore();
const { employerName, inforceDocument, project, projectId } =
  storeToRefs(projectStore);
const isDownloadingPlanSummary = ref(false);
const downloadAllRef = ref(null);
const toast = useToast();
/**
 * Array of Census Sources.
 *
 * @returns {Array}
 */
const censusSources = computed(() => ({
  sources:
    inforceDocument.value.sources?.filter(
      ({ type }) => type.toLowerCase() === 'censussource',
    ) ?? [],
  title: 'Census',
}));
/**
 * Array of Non-Medical Sources.
 *
 * @returns {Array}
 */
const nonMedicalSources = computed(() =>
  inforceDocument.value?.sources
    ? [
        {
          sources: inforceDocument.value.sources.filter(
            (source) => source.type === 'PolicySource',
          ),
          title: 'Non-medical policy / certificates',
          type: 'PolicySource',
        },
        {
          sources: inforceDocument.value.sources.filter(
            (source) => source.type === 'RateSource',
          ),
          title: 'Non-medical rates and enrollment',
          type: 'RateSource',
        },
        {
          sources: inforceDocument.value.sources.filter(
            (source) => source.type === 'ExperienceSource',
          ),
          title: 'Non-medical experience',
          type: 'ExperienceSource',
        },
      ]
    : [],
);
/**
 * Array of Stop Loss Sources.
 *
 * @returns {Array}
 */
const stopLossSources = computed(() =>
  inforceDocument.value?.sources
    ? [
        {
          sources: inforceDocument.value.sources.filter(
            (source) => source.type === 'StopLoss::PolicySource',
          ),
          title: 'Stop loss policy / Certificates',
          type: 'StopLoss::PolicySource',
        },
        {
          sources: inforceDocument.value.sources.filter(
            (source) => source.type === 'StopLoss::RateSource',
          ),
          title: 'Stop loss rates and enrollment',
          type: 'StopLoss::RateSource',
        },
        {
          sources: inforceDocument.value.sources.filter(
            (source) => source.type === 'StopLoss::ExperienceSource',
          ),
          title: 'Stop loss experience',
          type: 'StopLoss::ExperienceSource',
        },
      ]
    : [],
);
/** Generate and download the plan summary excel file. */
const downloadPlanSummary = async () => {
  isDownloadingPlanSummary.value = true;
  const filename = 'plan_summary.xlsx';

  try {
    const data = await ProjectService.getPlanSummary(projectId.value);
    const u8 = new Uint8Array(data);
    const blob = new Blob([u8], { type: 'application/octet-stream' });

    isDownloadingPlanSummary.value = false;

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    }
    await projectStore.startDocument();
  } catch {
    toast.add({
      severity: 'error',
      detail: 'There was a problem downloading the file. Please try again.',
    });
    isDownloadingPlanSummary.value = false;
  }
};
/** Click the hidden "Download All" button. */
const downloadAll = () => {
  if (downloadAllRef.value) {
    downloadAllRef.value.click();
    projectStore.startDocument();
  }
};
/**
 * Calls analytics then runs the necessary callback per download type
 *
 * @param {Function} callbackName - What method to call to initiate download
 */
const onDownload = (callbackName) => {
  const analyticsEventTitle = {
    downloadAll: 'Download all files RFP info',
    downloadPlanSummary: 'Download plan summary',
  };

  // TODO: create some kind of enum for callbackName
  if (callbackName !== 'startDocument') {
    trackSegmentEvent(analyticsEventTitle[callbackName], segmentData());
  }

  if (callbackName === 'startDocument') {
    projectStore.startDocument();
  }

  if (callbackName === 'downloadPlanSummary') {
    downloadPlanSummary();
  }

  if (callbackName === 'downloadAll') {
    downloadAll();
  }
};
</script>

<style lang="scss" scoped>
header {
  padding: 28px 0;
  background-color: var(--tf-gray-light);
  text-align: center;
}

.document-manager {
  border: 1px var(--tf-gray-light-medium) solid;
  background: #fff;
}

#download-all-link {
  display: none;
}

.rfp-column {
  margin: 0 20px 23px;

  @media (min-width: 1024px) {
    width: calc(50% - 40px);
  }
}

#rfp-documents {
  padding: 12px 18px;
}
</style>
