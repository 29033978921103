import {
  reusableAcceptableUploadFileTypes,
  reusableAcceptableUploadFileExtensions,
} from '@watchtowerbenefits/es-utils-public';

export const TWENTY_MB = 20971520;

/**
 * Checks the file extension/type given against the accepted extensions and
 * filetypes array, and returns true if a match is found.
 *
 * @param {object} acceptanceCriteria - The file to check and the extensions and
 *   filetypes to check the file against
 * @param {object} acceptanceCriteria.file - The file to check
 * @param {string[]} acceptanceCriteria.acceptedExtensions
 * @param {string[]} acceptanceCriteria.acceptedFileTypes
 * @returns {boolean} The boolean result of the comparison
 */
export const isValidExtension = ({
  file,
  acceptedExtensions = reusableAcceptableUploadFileExtensions(),
  acceptedFileTypes = reusableAcceptableUploadFileTypes(),
}) => {
  const fileExtension = file.name.split('.').pop();
  const fileType = file.raw && file.raw.fileType;

  return (
    (fileType && acceptedFileTypes.includes(fileType)) ||
    acceptedExtensions.includes(fileExtension)
  );
};
/**
 * Checks the size of the file given against the accepted fileSize, and returns
 * true if size is less than or equal to fileSize. defaults to 20mb
 *
 * @param {object} file - The file to check
 * @param {number} file.size - The size of the file
 * @param {number} maxSize - The file size limit
 * @returns {boolean} The boolean result of the comparison
 */
export const isValidFileSize = ({ size }, maxSize = TWENTY_MB) =>
  size <= maxSize;
/**
 * Flattens an objects values into an array
 *
 * @param {object} object - Vallues to flatten
 * @returns {Array} Flattened values
 */
export const flattenObjectValues = (object) =>
  [].concat(...Object.values(object));
