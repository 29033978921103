<script setup>
/**
 * Forgot Password
 *
 * @exports ForgotPassword
 */
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useAccountStore } from '@/stores/account.js';
import accountService from '@/services/account.js';
import { storeToRefs } from 'pinia';

import AppForgotPasswordForm from '@/shared/components/AuthPages/AppForgotPasswordForm.vue';

defineOptions({
  name: 'ForgotPassword',
});

const route = useRoute();
const accountStore = useAccountStore();
const { userInfo } = storeToRefs(accountStore);
const { setUserEmail, setTempPasswordStatus } = accountStore;
/**
 * Evaluate the route params and return an object with the appropriate props to
 * pass to appForgotPasswordForm.
 *
 * @returns {object}
 */
const formProps = computed(() => {
  const { projectId } = route.params;
  const name = projectId ? 'ProjectSignIn' : 'SignIn';
  const props = {
    linkLogin: { name },
    linkRedirect: { name },
  };

  if (projectId) {
    props.linkLogin.params = { projectId };
    props.linkRedirect.params = { projectId };
    props.projectId = projectId;
  }

  return props;
});
</script>

<template>
  <section>
    <AppForgotPasswordForm
      v-bind="{
        ...formProps,
        accountService,
        userInfo,
      }"
      @set-user-email="setUserEmail"
      @set-temp-password-status="setTempPasswordStatus(true)"
    />
  </section>
</template>
