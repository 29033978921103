<template>
  <div class="quote-submission">
    <ThreeflowWordmark class="wordmark" />
    <div class="content">
      <div v-if="isLoading" v-loading="[isLoading, 'Loading…']" />
      <template v-else>
        <AppMessage
          v-if="!isLoading"
          class="summary-alert is-rounded"
          v-bind="{
            severity: alertType,
          }"
        >
          {{ alertTitle }}
        </AppMessage>

        <div class="copy">
          <template v-if="isError">
            <p>
              An error occurred while submitting your
              {{ quoteTypeLabel }}.<br />
              <router-link :to="{ name: 'SignIn' }"> Sign in </router-link>
              to review, edit, or check the status of this {{ quoteTypeLabel }}.
            </p>
          </template>
          <template v-else-if="!isPreviouslyCompleted">
            <p>
              Your quotes have been submitted to the broker.<br />
              You’ll receive an email shortly as confirmation.
            </p>
            <p>Feel free to close this window.</p>
          </template>
          <template v-else>
            <p>
              Your quotes have already been submitted to the broker.<br />
              <router-link :to="{ name: 'SignIn' }"> Sign in </router-link>
              to review, edit, or check the status of this {{ quoteTypeLabel }}.
            </p>
            <p>Otherwise, feel free to close this window.</p>
          </template>
        </div>
      </template>
    </div>
    <AuthPageFooter />
  </div>
</template>

<script setup>
import AuthPageFooter from '@/shared/components/AuthPages/Footer.vue';
import ThreeflowWordmark from '@/shared/components/ThreeflowWordmark.vue';
import AppMessage from '@/shared/components/AppMessage.vue';

import { submitQuote } from '@/services/submission.js';
import { onMounted, ref, computed } from 'vue';

const props = defineProps({
  quoteType: {
    type: String,
    required: true,
  },
  token: {
    type: String,
    required: true,
  },
});
const isError = ref(false);
const isLoading = ref(true);
const isPreviouslyCompleted = ref(false);
/**
 * Smart quote type (proposal or renewal) derrived from the mmatched route name.
 * Workaround because we say 'quote' for 'proposal' in the modal.
 *
 * @returns {string}
 */
const quoteTypeLabel = computed(() =>
  props.quoteType === 'renewal' ? 'renewal' : 'quote',
);
/**
 * Alert title
 *
 * @returns {string}
 */
const alertTitle = computed(() => {
  if (isError.value) return 'Something went wrong';

  return `Your ${quoteTypeLabel.value}s have ${isPreviouslyCompleted.value ? 'already ' : ''}been submitted`;
});
const alertType = computed(() => (isError.value ? 'error' : 'success'));

onMounted(async () => {
  try {
    const { previously_completed: previouslyCompleted } = await submitQuote(
      props.token,
      props.quoteType,
    );

    isLoading.value = false;
    isError.value = false;
    isPreviouslyCompleted.value = previouslyCompleted;
  } catch (error) {
    isLoading.value = false;
    isError.value = true;
  }
});
</script>

<style lang="scss" scoped>
.quote-submission {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wordmark {
  position: fixed;
  top: 60px;
  left: 60px;
  width: 220px;

  svg {
    width: 100%;
  }
}

:deep(.summary-alert) {
  max-width: 100%;
  text-align: center;

  .p-message-text {
    font-size: 22px;
    text-transform: uppercase;
    font-weight: normal;
    margin-right: 20px;
  }
}

.copy {
  margin-top: 30px;

  p {
    margin: 0 auto 40px;
    padding-left: 30px;
    max-width: calc(100% - 90px);
    font-size: 20px;
    line-height: 30px;
    text-align: left;
  }
}

:deep(footer) {
  .tf-button {
    display: inline-block;
    font-size: inherit;
  }
}
</style>
