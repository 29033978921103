<template>
  <Dialog
    class="quote-file-dialog"
    modal
    :visible="visible"
    :close-on-click-modal="false"
    :close-on-escape="false"
    :closable="false"
    @update:visible="$emit('update:visible', false)"
    @after-hide="resetModal"
  >
    <h2>Quote File</h2>

    <ProgressSpinner v-if="csvQuoteStore.isLoading" />
    <div v-else>
      <!-- alert messages -->
      <AppMessage
        v-if="csvQuoteStore.alert.type"
        :severity="csvQuoteStore.alert.type"
        class="upload-error-disclaimer"
        data-test="csv quote error"
        show-icon
      >
        <!-- eslint-disable vue/no-v-html -->
        <ul v-if="csvQuoteStore.alert.messages.length > 1">
          <li
            v-for="(message, index) of csvQuoteStore.alert.messages"
            :key="`alert-message-${index}`"
            v-html="message"
          />
        </ul>
        <p v-else v-html="csvQuoteStore.alert.messages[0]" />
      </AppMessage>
      <!-- upload or submit component -->
      <QuoteFileUpload
        v-if="currentStep === 'upload'"
        v-model:parent-step="currentStep"
        :data-test="`${currentStep} step`"
      />
      <QuoteFileSubmit
        v-else
        v-model:parent-step="currentStep"
        :data-test="`${currentStep} step`"
      />
      <!-- file-list section -->
      <div class="processed-document">
        <h4>Document processed</h4>
        <p
          v-if="!csvQuoteStore.file.name"
          data-test="no csv uploaded disclaimer"
        >
          No documents uploaded.
        </p>
        <div v-else class="processed-document-file-list">
          <span
            class="processed-document-filename"
            data-test="processed filename"
          >
            {{ csvQuoteStore.file.name }}
          </span>
          <template v-if="!csvQuoteStore.id">
            <ProgressBar
              :value="csvQuoteStore.uploadProgress"
              stroke-width="18"
              data-test="csv upload progress"
              class="progress"
            />
            <!-- "Cancel upload" doesn't actually stop the file upload request. It only hides the request, but the logic is still processing in the background. -->
            <Button
              data-test="cancel csv upload"
              icon="fa-solid fa-times"
              icon-pos="right"
              variant="text"
              label="Cancel upload"
              severity="danger"
              @click="resetModal"
            />
          </template>
          <Button
            v-else
            data-test="delete csv upload"
            icon="fa-solid fa-trash-can"
            icon-pos="right"
            variant="text"
            label="Delete file"
            severity="danger"
            @click="resetModal"
          />
        </div>
      </div>
    </div>

    <!-- bottom button section -->
    <template #footer>
      <div
        class="footer flex justify-end gap-2"
        :class="{ submit: currentStep === 'submit' }"
      >
        <Button
          data-test="cancel csv quote"
          size="large"
          label="Cancel"
          severity="secondary"
          variant="text"
          @click="$emit('update:visible', false)"
        />
        <Button
          v-if="currentStep === 'upload'"
          :disabled="!csvQuoteStore.isCsvValidated"
          data-test="upload csv quote"
          size="large"
          label="Continue"
          severity="secondary"
          @click="currentStep = 'submit'"
        />
        <Button
          v-if="currentStep === 'submit'"
          :disabled="!isQuoteValuesValid"
          :loading="csvQuoteStore.isLoading"
          data-test="submit csv quote"
          icon="fa-solid fa-paper-plane"
          icon-pos="right"
          severity="success"
          label="Submit quote"
          @click="submitQuote"
        />
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useCsvQuoteStore } from '@/stores/csvQuote.js';
import { useProjectStore } from '@/stores/project.js';
import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
import { segmentData } from '@/utils/analytics.js';
import { useToast } from 'primevue/usetoast';

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import AppMessage from '@/shared/components/AppMessage.vue';
import QuoteFileUpload from '@/components/Modals/QuoteFileModal/QuoteFileUpload.vue';
import QuoteFileSubmit from '@/components/Modals/QuoteFileModal/QuoteFileSubmit.vue';

defineOptions({
  name: 'QuoteFileModal',
});

defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
});
const emits = defineEmits(['update:visible']);
const csvQuoteStore = useCsvQuoteStore();
const projectStore = useProjectStore();
const currentStep = ref('upload');
const isQuoteValuesValid = computed(() => csvQuoteStore.isQuoteValuesValid);
const toast = useToast();
/**
 * Clears all the quote props in the store and sets the modal back to 'upload'
 * (initial step)
 */
const resetModal = () => {
  csvQuoteStore.$reset();
  currentStep.value = 'upload';
};
/**
 * Submits the CSV quote, updates respective products in the store, and closes
 * the modal.
 */
const submitQuote = async () => {
  try {
    await csvQuoteStore.submitCsvQuote(
      csvQuoteStore.id,
      projectStore.proposalDocumentId,
    );
    trackSegmentEvent('Submit quote', segmentData());
    emits('update:visible', false);
  } catch {
    toast.add({
      severity: 'error',
      detail: 'There was an error submitting your quote. Please try again.',
    });
  }
};
</script>

<style lang="scss" scoped>
.upload-error-disclaimer {
  margin-bottom: 25px !important; // stylelint-disable-line
  margin-top: 10px;

  ul {
    margin-left: 15px;
  }

  li {
    list-style-type: disc;
  }

  p {
    margin-bottom: 0;
  }
}

.processed-document {
  border-top: 1px solid var(--tf-gray-light-medium);
  border-bottom: 1px solid var(--tf-gray-light-medium);
  margin-top: 36px;
  padding: 16px 0;

  &-file-list {
    display: flex;
    align-items: center;
  }

  &-filename {
    margin-right: 10px;
    display: flex;
    flex: 1;
    color: var(--tf-gray-dark);
  }
}

.progress {
  width: 137px;
  margin-right: 24px;
}
</style>

<style lang="scss">
.quote-file-dialog {
  max-width: 650px;
  width: 90%;
}
</style>
