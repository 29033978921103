import axios from 'axios';
import { useAccountStore } from '@/stores/account.js';
import { setCookie, getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const normalizedEndpoint = `${config.VUE_APP_API_URL}/v1/carrier_portal`;
const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
const axiosConfig = () => getAxiosConfig(cookieNamespace);

/**
 * All account related ajax calls
 *
 * @exports AccountService
 */
export default {
  /**
   * Check if a specific user was invited a project
   *
   * @param {string} email
   * @param {number} projectId
   * @returns {Promise}
   */
  checkInvitedToProject(email, projectId) {
    const encodedEmail = encodeURIComponent(email);
    const url = `${normalizedEndpoint}/users/invited_to_project?email=${encodedEmail}&project_id=${projectId}`;

    return axios.get(url, axiosConfig()).then(({ data }) => data);
  },

  /**
   * Pass in the user credentials and validate them against the api server
   *
   * @param {object} data
   * @param {boolean} rememberMe
   * @returns {Promise}
   */
  async authenticate(data, rememberMe) {
    const { email } = data;
    const url = `${normalizedEndpoint}/sign_in`;
    const { data: authData } = await axios.post(url, data);

    this.setSignedIn(authData);
    if (rememberMe) {
      window.localStorage.setItem('email', email);
    } else {
      window.localStorage.removeItem('email');
    }
  },

  /**
   * Sign the user out.
   *
   * @returns {Promise}
   */
  signOut() {
    const url = `${normalizedEndpoint}/sign_out`;

    return axios.post(url, {}, axiosConfig()).then(({ data }) => data);
  },

  /**
   * Gets current user's information
   *
   * @param {string} authToken (optional) - if not passed, will use the cookie
   * @returns {Promise}
   */
  getCurrentUser(authToken) {
    const headers = authToken
      ? { headers: { 'X-API-AUTHTOKEN': `${authToken}` } }
      : axiosConfig();
    const url = `${config.VUE_APP_API_URL}/v1/users/me`;

    return axios.get(url, headers).then(({ data }) => data);
  },

  /**
   * Registering a brand new user
   *
   * @param {object} data
   * @param {string} data.department
   * @param {string} data.email
   * @param {string} data.firstName
   * @param {string} data.lastName
   * @param {string} data.password
   * @param {string} data.projectId
   * @returns {Promise}
   */
  registerUser({
    department,
    email,
    firstName,
    lastName,
    password,
    projectId,
  }) {
    const url = `${normalizedEndpoint}/users`;

    return axios
      .post(url, {
        department,
        email,
        first_name: firstName,
        last_name: lastName,
        password,
        project_id: projectId,
      })
      .then(({ data }) => {
        this.setSignedIn(data);

        return data;
      });
  },

  /**
   * Resend the confirmation email for a user.
   *
   * @param {object} data
   * @param {string} data.email
   * @param {number} data.projectId
   * @returns {Promise}
   */
  resendConfirmation({ email, projectId }) {
    const url = `${normalizedEndpoint}/send_confirmation`;

    return axios
      .post(url, { email, project_id: projectId })
      .then(({ data }) => data);
  },

  /**
   * Send the user a new temp password because they forgot theirs.
   *
   * @param {object} data
   * @param {string} data.email
   * @returns {Promise}
   */
  passwordReset({ email }) {
    const url = `${normalizedEndpoint}/forgot_password`;

    return axios
      .post(url, { email })
      .then(({ data }) => data)
      .catch(() => {});
  },

  /**
   * Changing the users password
   *
   * @param {object} data
   * @returns {Promise}
   */
  changePassword(data) {
    const url = `${normalizedEndpoint}/reset_password`;

    return axios
      .put(url, data, axiosConfig())
      .then(({ data: passwordData }) => passwordData);
  },

  /**
   * Confirming a user that comes from the confirmation email
   *
   * @param {object} data
   * @returns {Promise}
   */
  confirmEmail(data) {
    const url = `${normalizedEndpoint}/confirm`;

    return axios
      .post(url, {
        project_id: data.projectId,
        confirmation_token: data.token,
      })
      .then(({ data: emailData }) => {
        this.setSignedIn(emailData);

        return emailData;
      });
  },

  /**
   * Setting all the appropriate info for signing in a user:
   * localStorage/cookies and store states
   *
   * @param {object} data
   * @param {object} data.user Boolean
   */
  setSignedIn({ user }) {
    const accountStore = useAccountStore();

    accountStore.setUserInfo(user);
    window.localStorage.setItem('auth', true);
    window.localStorage.setItem('confirmed', user.confirmed);

    if (user.auth_token) {
      /* Set Cookie of Bearer Token and UserID */
      const expirationTime = new Date(user.auth_token_expires_at);

      setCookie(
        `${cookieNamespace}-auth-token`,
        user.auth_token,
        expirationTime,
      );
      setCookie(
        `${cookieNamespace}-user-roles`,
        user.roles.map((role) => role.name),
        expirationTime,
      );
    }
  },
  /**
   * Sign the user in based on a Google OAuth session code, set localStorage,
   * and load the user info.
   *
   * @param {string} code
   * @returns {Promise}
   */
  async signInWithGoogle(code) {
    const url = `${config.VUE_APP_API_URL}/v1/omniauth/google/callback`;
    const { data: user } = await axios.post(url, { code }, axiosConfig());

    this.setSignedIn(user);
  },
  /**
   * Update a users' Default Plan Design Values (pdv)
   *
   * @param {number} productTypeId
   * @param {object} data - Data to update the user's PDVs
   * @returns {Promise}
   */
  saveUserPredefinedPlanDesignValues(productTypeId, data) {
    const url = `${normalizedEndpoint}/users/preferred_plan_design_values`;
    const payload = {
      product_type_id: productTypeId,
      preferences: Object.values(data),
    };

    return axios.post(url, payload, axiosConfig()).then((response) => response);
  },
  /**
   * Gets a list of a users' product_type_ids with Default Plan Design Values
   * (pdv)
   *
   * @returns {Promise}
   */
  getProductsWithPreferredPlanDesignValues() {
    // # Get /v1/carrier_portal/users/products_with_preferred_plan_design_values
    const url = `${normalizedEndpoint}/users/products_with_preferred_plan_design_values`;

    return axios
      .get(url, axiosConfig())
      .then(({ data }) => data.product_type_ids);
  },
  /**
   * Get the provider for the provided email address.
   *
   * @param {string} email
   * @param {boolean} flag
   * @returns {object}
   */
  async getUserProvider(email, flag) {
    const encodedEmail = encodeURIComponent(email);
    const redirect = window.location.search.match(/redirect=([^&]*)/);
    // rff: workOsCarrierUi https://watchtower.atlassian.net/browse/LC-2036
    const portalParam = flag ? '&portal=carrier' : '';
    const envParam =
      config.VUE_APP_ENV === 'development'
        ? `&url=${window.location.origin}/sso`
        : '';
    const redirectParam = redirect ? `&redirect=${redirect[1]}` : '';
    const url = `${config.VUE_APP_API_URL}/v1/users/provider?email=${encodedEmail}${portalParam}${envParam}${redirectParam}`;

    return axios.get(url, axiosConfig()).then(({ data }) => data);
  },
};
