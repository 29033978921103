import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const normalizedEndpoint = `${config.VUE_APP_API_URL}/v1/carrier_portal/projects`;
const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
const axiosConfig = () => getAxiosConfig(cookieNamespace);

/**
 * All project related ajax calls
 *
 * @exports ProjectService
 */
export default {
  /**
   * Get the projects for dashboards.
   *
   * @param {string} dashboardType
   * @returns {Function} Axios promise
   */
  async getProjects({
    dashboardType,
    currentPage,
    rowsPerPage,
    filters,
    sorting,
  }) {
    const url = `${config.VUE_APP_API_URL}/v1/carrier_portal/project_dashboard/${dashboardType}/view`;
    const payload = {
      filter_by: {
        ...filters,
      },
      order_by: sorting || [],
      current_page: currentPage,
      per_page: rowsPerPage,
    };

    return axios.post(url, payload, axiosConfig()).then(({ data }) => data);
  },
  /**
   * Get dashboard filter options
   *
   * @param {string} dashboardType
   * @returns {Function} Axios promise
   */
  async getDashboardFilterOptions(dashboardType) {
    const url = `${config.VUE_APP_API_URL}/v1/carrier_portal/project_dashboard/${dashboardType}/filters`;

    return axios.get(url, axiosConfig()).then(({ data }) => data);
  },
  /**
   * Get broker info (id/name/logo)
   *
   * @param {number} projectId
   * @returns {object}
   */
  async getBrokerInfo(projectId) {
    const url = `${normalizedEndpoint}/${projectId}/broker_info`;

    try {
      const { data } = await axios.get(url, axiosConfig());

      return data;
    } catch (error) {
      return error;
    }
  },
  /**
   * Get general project info (the employer name/how many carriers have been
   * invited/how many have submitted)
   *
   * @param {number} projectId
   * @returns {Promise}
   */
  getProjectInfo(projectId) {
    const url = `${normalizedEndpoint}/${projectId}/info`;

    return axios
      .get(url, axiosConfig())
      .then(({ data }) => data)
      .catch((error) => error);
  },
  /**
   * Download the plan summary excel file for this project
   *
   * @param {number} projectId
   * @returns {Promise}
   */
  getPlanSummary(projectId) {
    const data = {
      responseType: 'arraybuffer',
      ...axiosConfig(),
    };
    const url = `${normalizedEndpoint}/${projectId}/plan_summary`;

    return axios.get(url, data).then(({ data: planData }) => planData);
  },
  /**
   * Get All Documents Related to this Project
   *
   * @param {number} projectId
   * @returns {Promise}
   */
  getProjectDocuments(projectId) {
    const url = `${normalizedEndpoint}/${projectId}/documents`;

    return axios.get(url, axiosConfig()).then(({ data }) => data);
  },
  /**
   * Get unread DIVE failure notifications
   *
   * @returns {Promise}
   */
  async getUnreadDiveFailureNotifications() {
    const url = `${config.VUE_APP_API_URL}/v1/carrier_portal/notifications/unread_dive_rate_extraction_failures`;

    return axios.get(url, axiosConfig()).then(({ data }) => data);
  },
  /**
   * Mark DIVE failure notifications as having been read
   *
   * @returns {Promise}
   */
  async markUnreadDiveFailureNotificationsAsRead() {
    const url = `${config.VUE_APP_API_URL}/v1/carrier_portal/notifications/mark_dive_rate_extraction_failures_as_read`;

    return axios.patch(url, {}, axiosConfig()).then(({ data }) => data);
  },
  /**
   * Patch the carrier to watch this project when action is "subscribe" and
   * unwatch this project when action is "unsubscribe"
   *
   * @param {number} projectId
   * @param {string} action
   * @param {string} [email] - Optional email address.
   * @param {Function} callback
   * @param {Function} callbackError
   */
  patchCarrierProjectSubscription(
    projectId,
    action,
    email,
    callback,
    callbackError,
  ) {
    const url = `${normalizedEndpoint}/${projectId}/${action}`;
    const body = email ? { email_address: email } : {};

    axios
      .patch(url, body, axiosConfig())
      .then(({ data }) => callback(data))
      .catch((error) => callbackError(error));
  },
  /**
   * Checks if broker for project has hierarchy
   *
   * @param {number} brokerId
   * @returns {Promise}
   */
  brokerHasHierarchy(brokerId) {
    const url = `${normalizedEndpoint}/broker_has_hierarchy/${brokerId}`;

    return axios.get(url, axiosConfig()).then(({ data }) => data);
  },
  /**
   * Get carrier and broker users for a given project
   *
   * @param {number} projectId
   * @returns {Promise}
   */
  getCarrierContacts(projectId) {
    const url = `${normalizedEndpoint}/${projectId}/project_users`;

    return axios.get(url, axiosConfig()).then(({ data }) => data);
  },
  /**
   * Send a project invitation
   *
   * @param {number} projectId
   * @param {string} emailToInvite
   * @param {number} carrierId
   * @returns {Promise}
   */
  sendProjectInvitation(projectId, emailToInvite, carrierId) {
    const url = `${normalizedEndpoint}/${projectId}/project_invitations`;
    const body = {
      email_to_invite: emailToInvite,
      carrier_id: carrierId,
      send_email: true,
    };

    return axios.post(url, body, axiosConfig()).then(({ data }) => data);
  },
};
