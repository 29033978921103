<template>
  <!-- TODO: should this be a primevue table? -->
  <table class="rfp-table">
    <template v-for="dataInfo in tableData">
      <tr
        v-if="!ignoreRows.includes(dataInfo.type)"
        :key="dataInfo.text"
        :data-test="`rfp ${dataInfo.type} information`"
      >
        <td :style="{ width: `${labelCellWidth}px` }" v-text="dataInfo.text" />
        <td>
          <!-- Format Due date && Edit link -->
          <template v-if="dataInfo.type === 'project_due_date'">
            {{
              info[dataInfo.type] !== 'Due Date Not Specified'
                ? formatSimpleDate(info.project_due_date)
                : noValueText
            }}
            <RouterLink v-if="editLink.name" :to="editLink" class="link-edit"
              >Edit</RouterLink
            >
          </template>
          <!--
            Formatted Cover Memo
            Disable no-html since these are paragraphs
          -->
          <!-- eslint-disable vue/no-v-html -->
          <span
            v-else-if="
              dataInfo.type === 'rfp_cover_memo' && info.rfp_cover_memo
            "
            v-html="info.rfp_cover_memo.replace(/(?:\r\n|\r|\n)/g, '<br>')"
          />
          <!-- eslint-enable vue/no-v-html -->
          <!-- Employer Website -->
          <a
            v-else-if="
              dataInfo.type === 'employer_website' && info.employer_website
            "
            target="_blank"
            :href="info.employer_website"
            v-text="
              info.employer_website
                .replace(/^https?:\/\//, '')
                .replace(/\/+$/, '')
            "
          />
          <!-- Formatted Effective Date -->
          <template
            v-else-if="
              dataInfo.type === 'effective_date' && info.effective_date
            "
          >
            {{ formatSimpleDate(info.effective_date) }}
          </template>
          <!-- Also display Open Enrollment Strategy if Open Enrollment is Yes -->
          <template
            v-else-if="
              dataInfo.type === 'open_enrollment_this_year' &&
              formatOpenEnrollment.thisYear
            "
          >
            {{ info.open_enrollment_this_year }}
            {{ formatOpenEnrollment.enrollmentStrategy }}
          </template>
          <!-- Show the value of row or noValueText  -->
          <template v-else>
            {{ info[dataInfo.type] || noValueText }}
          </template>
        </td>
      </tr>
    </template>
    <tr v-if="showDocuments">
      <td>Documents</td>
      <td>
        {{ info.inforce_sources_message }}
        <p v-if="documentManagerLink.name">
          <RouterLink :to="documentManagerLink"> Document manager</RouterLink>
        </p>
      </td>
    </tr>
  </table>
</template>

<script setup>
import { computed } from 'vue';
import {
  reusableProjectTypes,
  formatSimpleDate,
} from '@watchtowerbenefits/es-utils-public';

defineOptions({
  name: 'RfpTable',
});

const props = defineProps({
  documentManagerLink: {
    type: Object,
    default: () => ({}),
  },
  editLink: {
    type: Object,
    default: () => ({}),
  },
  info: {
    type: Object,
    default: () => ({}),
  },
  hasStopLoss: {
    type: Boolean,
    default: false,
  },
  /** Array of parameters to not render in the table. */
  hideParams: {
    type: Array,
    default: () => [],
  },
  labelCellWidth: {
    type: Number,
    default: 172,
  },
  projectType: {
    type: String,
    required: true,
  },
  showDocuments: {
    type: Boolean,
    default: false,
  },
});
// Constants
const noValueText = 'Not provided';
const tableData = [
  { type: 'employer_name', text: 'Employer' },
  { type: 'employer_location', text: 'Employer location' },
  { type: 'employer_website', text: 'Employer website' },
  { type: 'employer_total_lives', text: 'Lives' },
  { type: 'employer_sic', text: 'SIC' },
  { type: 'products', text: 'Products & broker commissions' },
  { type: 'incumbent_carriers', text: 'Incumbents' },
  { type: 'broker_of_record_label', text: 'Broker of record' },
  {
    type: 'broker_contacts',
    text: computed(() =>
      props.info.event_contacts ? 'Broker team contacts' : 'Primary contacts',
    ),
  },
  { type: 'event_contacts', text: 'Placement event contacts' },
  { type: 'stop_loss_tpa_company', text: 'Medical claim administrator' },
  { type: 'stop_loss_rx_vendor', text: 'Prescription drug administrator' },
  {
    type: 'stop_loss_transplant_carrier',
    text: 'Fully insured transplant carrier',
  },
  { type: 'reason_for_rfp', text: 'Reason for marketing' },
  { type: 'benefit_administrator', text: 'Benefit admin' },
  { type: 'open_enrollment_this_year', text: 'Open enrollment' },
  { type: 'hdhp', text: 'High deductible health plan' },
  { type: 'market_activity', text: 'Market activity' },
  { type: 'effective_date', text: 'Coverage effective date' },
  { type: 'project_due_date', text: 'Due date' },
  { type: 'rfp_cover_memo', text: 'Cover memo' },
];
// Computed properties
/**
 * If this project has a Stop Loss Product, we filter out certain rows if there
 * was no data when the RFP was created.
 */
const ignoreRows = computed(() => {
  const generalData = ['rfp_cover_memo'];
  const stopLossData = [
    'stop_loss_tpa_company',
    'stop_loss_rx_vendor',
    'stop_loss_transplant_carrier',
  ];

  if (props.hasStopLoss) {
    generalData.push(...stopLossData);
  }

  const rowsToIgnore = generalData.filter(
    (type) => props.info[type] && props.info[type].match(/not provided/i),
  );

  // Hide event_contacts row if project type is Initial Employer Build
  if (
    reusableProjectTypes()?.isBuildType(props.projectType) ||
    !props.info.event_contacts
  ) {
    rowsToIgnore.push('event_contacts');
  }

  if (props.hideParams.length) {
    rowsToIgnore.push(...props.hideParams);
  }

  return rowsToIgnore;
});
/**
 * Identify if this project is in Open Enrollment and surround Enrollment
 * Strategy in parenthesis if it exists.
 */
const formatOpenEnrollment = computed(() => ({
  thisYear:
    props.info.open_enrollment_this_year &&
    props.info.open_enrollment_this_year.toLowerCase() === 'yes',
  enrollmentStrategy: props.info.open_enrollment_strategy
    ? `(${props.info.open_enrollment_strategy})`
    : '',
}));
</script>

<style lang="scss" scoped>
@use '@/shared/assets/styles/variables';
@use '@/shared/assets/styles/mixins';

.rfp-table {
  width: 100%;
  margin-bottom: 28px;
}

.rfp-table table {
  width: 100%;
  border-collapse: collapse;
}

.rfp-table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}

.rfp-table td:first-child {
  font-weight: bold;
  background-color: #f9f9f9;
}

// stylelint-disable-next-line no-descending-specificity
td {
  padding: 13px;
  border: 1px var(--tf-gray-medium) solid;
  background: var(--tf-white);
  vertical-align: top;
}

.link-edit {
  margin-left: 15px;
}
</style>
