<script setup>
/**
 * Register Confirmation Page
 *
 * @exports RegisterConfirmation
 */
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useAccountStore } from '@/stores/account.js';
import AppRegisterConfirmation from '@/shared/components/AuthPages/AppRegisterConfirmation.vue';

defineOptions({
  name: 'RegisterConfirmation',
});

const route = useRoute();
const accountStore = useAccountStore();
const { userInfo } = storeToRefs(accountStore);
/**
 * Evaluate the route params and return an object with all of the correct data.
 *
 * @returns {object}
 */
const registerProps = computed(() => {
  const { projectId } = route.params;
  const props = {
    linkLogin: { name: 'SignIn' },
    linkRegister: { name: 'RegistrationForm' },
    linkResend: { name: 'ResendConfirmation' },
  };

  if (projectId) {
    Object.keys(props).forEach((key) => {
      props[key] = {
        name: `Project${props[key].name}`,
        params: { projectId },
      };
    });
  }

  return props;
});
</script>

<template>
  <section>
    <AppRegisterConfirmation
      v-bind="{
        ...registerProps,
        userInfo,
      }"
    />
  </section>
</template>
