<template>
  <td
    :class="{
      'cell-empty': !props.cellData.label,
      'cell-show-input': !readonly && props.cellData.label,
    }"
    width="25%"
  >
    <FormField
      v-if="!readonly && props.cellData.label"
      ref="formItem"
      validate-on-blur
    >
      <InputText
        v-model="localValue"
        :name="formProp"
        placeholder="Enter rate"
        data-test="rate field"
        :invalid="isInvalid"
        :class="[
          !value ? 'input-blank' : '',
          {
            updating: updatePending,
          },
        ]"
        @blur="localValueChanged"
      />
      <Message
        v-if="props.fieldState.invalid && props.fieldState.dirty"
        severity="error"
        size="small"
        variant="simple"
      >
        {{ props.fieldState.error?.message }}
      </Message>
    </FormField>
    <!-- Only show values if there is an associated label ) -->
    <span v-else-if="props.cellData.label" data-test="read only rate value">
      {{
        cellData.value && !isNaN(cellData.value)
          ? parseFloat(cellData.value).toFixed(3)
          : '&mdash;'
      }}
    </span>
  </td>
</template>

<script setup>
import { useRateEntryStore } from '@/stores/rateEntry.js';
import { useProductStore } from '@/stores/product.js';
import { ref, computed, inject, watch } from 'vue';
import InputText from 'primevue/inputtext';
import Message from 'primevue/message';
import { FormField } from '@primevue/forms';

/**
 * Rate Entry Table Cell Value
 *
 * @exports RateEntryTdValue
 */
// Stores
const rateEntryStore = useRateEntryStore();
const productStore = useProductStore();
// Inject
const storeAttributeId = inject('storeAttributeId');
const props = defineProps({
  /** Cell Data used for cell value information */
  cellData: {
    type: Object,
    required: true,
    default: () => {},
  },
  /** Readonly boolean used to determine if the proposal table should be inputs. */
  readonly: {
    type: Boolean,
    required: true,
    default: false,
  },
  /**
   * When there are tier groups, subtypeId is used to target the specific value
   * array in the rateAttributes store
   */
  subtypeId: {
    type: [Number, String],
    default: 0,
  },
  /** Form key for validation */
  formKey: {
    type: String,
    default: null,
  },
  /** Field validation state */
  fieldState: {
    type: Object,
    default: () => ({}),
  },
});
const localValue = ref('');
const updatePending = ref(false);
// Computed
/**
 * Form prop for validation
 *
 * @returns {string}
 */
const formProp = computed(() => props.formKey || props.cellData.label);
/**
 * Array of rate value to search through to find cell value
 *
 * @returns {Array}
 */
const rateValues = computed(() => {
  const rateAttribute = rateEntryStore.rateAttributes[storeAttributeId.value];

  return rateAttribute ? rateAttribute.rateValues[props.subtypeId] : null;
});
/**
 * Value string of the cell to use in valueModel
 *
 * @returns {string}
 */
const value = computed(() =>
  rateValues.value
    ? (rateValues.value.values.find((v) => v.label === props.cellData.label)
        ?.value ?? '')
    : '',
);
/** Should the field have an invalid border */
const isInvalid = computed(() => {
  if (props.fieldState.invalid && props.fieldState.dirty) return true;

  return productStore.currentProduct.state === 'action_needed' && !value.value;
});

// Watch
watch(
  () => value.value,
  (v) => {
    localValue.value =
      v !== '' && !Number.isNaN(Number(v)) ? parseFloat(v).toFixed(3) : '';
  },
  {
    immediate: true,
  },
);
// Methods
/**
 * Updates the store rateEntryValues[subtypeId][label].value
 *
 * @param {object} root0
 * @param {string} root0.value
 */
const updateValue = async ({ value: v }) => {
  updatePending.value = true;

  /**
   * Push an IIFE to the rateUpdateRequests queue/array Use an IIFE to maintain
   * the argument values (payload) so that when the method executes it will use
   * the correct data
   */
  rateEntryStore.rateUpdateRequests.push(
    await (async (payload) => async (rateAttributes) => {
      /**
       * The rateAttributes argument, if it exists, will be passed from the
       * previous update
       */
      const { values, type } = rateAttributes
        ? rateAttributes.rateValues[payload.subtypeId]
        : rateEntryStore.rateAttributes[payload.storeAttributeId].rateValues[
            payload.subtypeId
          ];
      const rv = {};

      /** Must pass the entire tier group in each request */
      rv[payload.subtypeId] = {
        type,
        values: values.map((rateValue) => {
          if (rateValue.label === payload.label) {
            return {
              ...rateValue,
              value: v,
            };
          }

          return rateValue;
        }),
      };

      // Do the patch
      const newRateAttributes = await rateEntryStore.updateRateAttribute(
        {
          ...payload,
          rateValues: rv,
        },
        rateAttributes,
      );

      rateEntryStore.rateErrors = [];
      updatePending.value = false;

      // and pass it to (potentially) the next update event IIFE
      return newRateAttributes[payload.storeAttributeId];
    })({
      productId: productStore.productId,
      productState: productStore.productState,
      storeAttributeId: storeAttributeId.value,
      subtypeId: props.subtypeId,
      label: props.cellData.label,
      skipDelete: true,
    }),
  );

  // Kick off the rate update queue
  rateEntryStore.drainUpdateRequests();
};
/**
 * Removes all non-digits and special characters outside `.` and sets format to
 * `X.XXX`.
 */
const localValueChanged = () => {
  let v = localValue.value.toString().replace(/[^0-9.]/g, '');

  v = v && !Number.isNaN(Number(v)) ? parseFloat(v).toFixed(3) : '';

  if (value.value !== v) {
    updateValue({
      value: v,
    });
  }
};
</script>

<style lang="scss" scoped>
.cell-show-input {
  vertical-align: middle;
}
</style>
