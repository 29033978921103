<template>
  <div v-if="isLoaded" class="contact-tables-container">
    <ContactTable
      v-model:visible="showInviteTeammateModal"
      title="Carrier team"
      :users="carrierUsers"
      @unsubscribe-user="unsubscribeUser"
    />

    <ContactTable title="Broker team" :users="brokerUsers" />

    <AppModalNotify
      v-model:visible="showNotifyModal"
      title="Are you sure?"
      :show-cancel-cta="true"
      show-button-group
      data-test="confirm unsubscribe modal"
      type="warning"
      @close="closeNotifyModal"
      @confirm="confirmUnsubscribe"
    >
      <p>
        {{ emailToUnsubscribe === userInfo.email ? 'You' : emailToUnsubscribe }}
        will stop receiving email notifications for this RFP.
      </p>
    </AppModalNotify>

    <InviteTeammateModal
      v-model:visible="showInviteTeammateModal"
      :project-id="projectId"
      :carrier-domains="carrierDomains"
      :existing-emails="existingEmails"
      @invite-teammate="handleInviteTeammate"
    />
  </div>
</template>

<script setup>
/**
 * Displays ContactTable components for carrier/broker users under the contacts
 * tab.
 *
 * @exports EventsContact
 */
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useAccountStore } from '@/stores/account.js';
import { useProjectContactsStore } from '@/stores/projectContacts.js';
import { useProjectStore } from '@/stores/project.js';
import { useToast } from 'primevue/usetoast';
import projectService from '@/services/project.js';
import ContactTable from '@/components/Contacts/ContactTable.vue';
import InviteTeammateModal from '@/components/Modals/InviteTeammateModal.vue';
import AppModalNotify from '@/shared/components/AppModalNotify/index.vue';

defineOptions({
  name: 'EventsContact',
});

const props = defineProps({
  /** Project that we are displaying users for */
  projectId: {
    type: [String, Number],
    required: true,
  },
});
const router = useRouter();
const toast = useToast();
const showInviteTeammateModal = ref(false);
const showNotifyModal = ref(false);
const emailToUnsubscribe = ref(null);
const accountStore = useAccountStore();
const projectStore = useProjectStore();
const projectContactsStore = useProjectContactsStore();
const userInfo = computed(() => accountStore.userInfo);
const isLoaded = computed(() => projectContactsStore.isLoaded);
const carrierUsers = computed(() => projectContactsStore.carrierUsers);
const carrierDomains = computed(() => userInfo.value.email_domains);
const brokerUsers = computed(() => projectContactsStore.brokerUsers);
const existingEmails = computed(() => projectContactsStore.existingEmails);
const carrierId = computed(() => userInfo.value.carrier_id);
const { setProjectInfoParameter } = projectStore;
const { loadContacts } = projectContactsStore;

/** Get the contacts and throw error if fails. */
async function getContacts() {
  try {
    await loadContacts(props.projectId);
  } catch (error) {
    toast.add({
      closable: true,
      life: 3000,
      detail: 'Could not get contacts',
      severity: 'error',
    });
    await router.replace({ name: 'YourQuotes' });
  }
}

/** Get contacts on page load */
onMounted(async () => {
  await getContacts();
});

/**
 * If a user is subscribing or unsubscribing themselves, ensure that
 * projectInfoParameter is updated so that the subscribe value on RfpStatus
 * (header) is updated accordingly.
 */
async function updateProjectSubcribeStatus() {
  const currentUserEmail = userInfo.value.email;
  const isUserSubscribed = carrierUsers.value.some(
    (user) => user.email === currentUserEmail,
  );

  setProjectInfoParameter({
    key: 'is_watching',
    value: isUserSubscribed,
  });
}

/**
 * Send carrier user project invitation.
 *
 * @param {string} email - Email of user to invite, emitted from invite teammate
 *   modal.
 */
async function handleInviteTeammate(email) {
  try {
    const response = await projectService.sendProjectInvitation(
      props.projectId,
      email,
      carrierId.value,
    );
    const knownUser = response?.invitation.user_id;
    const alreadyInvited = response?.invitation.email_sent && !knownUser;

    if (knownUser) {
      await getContacts();
      await updateProjectSubcribeStatus();
    }

    let inviteMessage;

    if (alreadyInvited) {
      inviteMessage = `${email} has already been invited.`;
    } else if (knownUser) {
      inviteMessage =
        email === userInfo.value.email
          ? "You'll now receive email notifications related to this RFP."
          : `${email} will now receive email notifications related to this RFP.`;
    } else {
      inviteMessage = `${email} has been invited to ThreeFlow and will start receiving opportunity updates once their account setup is complete.`;
    }

    toast.add({
      closable: true,
      life: 5000,
      detail: inviteMessage,
      severity: alreadyInvited ? 'error' : 'success',
    });
  } catch (error) {
    toast.add({
      closable: true,
      life: 5000,
      detail: 'There was an error inviting the user',
      severity: 'error',
    });
  }
}

/** Confirm user unsubscription. */
async function confirmUnsubscribe() {
  showNotifyModal.value = false;
  const email = emailToUnsubscribe.value;

  try {
    projectService.patchCarrierProjectSubscription(
      props.projectId,
      'unsubscribe',
      email,
      async () => {
        await getContacts();
        await updateProjectSubcribeStatus();
        toast.add({
          closable: true,
          detail: `${email === userInfo.value.email ? "You'll" : `${email} will`} no longer receive email notifications related to this RFP.`,
          severity: 'success',
        });
      },
    );
  } catch (error) {
    toast.add({
      closable: true,
      detail: 'There was an error unsubscribing the user.',
      severity: 'error',
    });
  }
}

/**
 * Unsubscribe the user from the event. Skip the warning modal if the user is
 * unsubscribing themselves.
 *
 * @param {string} email - Email of the user to unsubscribe.
 */
async function unsubscribeUser(email) {
  emailToUnsubscribe.value = email;
  if (email !== userInfo.value.email) {
    showNotifyModal.value = true;
  } else {
    confirmUnsubscribe();
  }
}

/** Close the notify modal and clear input. */
function closeNotifyModal() {
  showNotifyModal.value = false;
  emailToUnsubscribe.value = null;
}
</script>

<style scoped>
.contact-tables-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}
</style>
