<template>
  <span>
    <TfPdfExport
      :button-size="buttonSize"
      :button-title="buttonTitle"
      :filename="`comments_${projectId}.pdf`"
      :is-disabled="!sortedComments.length"
    >
      <ExportHeader
        :employer-name="employerName"
        :filters="filters"
        title="Comment History"
      />
      <Comment
        v-for="comment in sortedComments"
        :key="comment.id"
        :broker="broker"
        :comment="comment"
        :documents="documents"
        :project-id="projectId"
        :is-pdf-render="true"
        :is-reply="!comment.topComment"
        :user-info="userInfo"
        application-namespace="export"
      />
    </TfPdfExport>
  </span>
</template>

<script setup>
import { computed } from 'vue';
import { sortObjects } from '@watchtowerbenefits/es-utils-public';

import Comment from '@/components/Comment/index.vue';
import ExportHeader from './Header.vue';
import TfPdfExport from './index.vue';

/**
 * PDF Exporter for Comments
 *
 * @exports TfPdfComments
 */

defineOptions({
  name: 'TfPdfComments',
});

const props = defineProps({
  broker: {
    type: Object,
    default: () => ({}),
  },
  buttonSize: {
    type: String,
    default: 'text-icon',
  },
  buttonTitle: {
    type: String,
    default: 'export',
  },
  comments: {
    type: Array,
    default: () => [],
  },
  documents: {
    type: Array,
    default: () => [],
  },
  employerName: {
    type: String,
    default: null,
  },
  filters: {
    type: Object,
    default: () => ({}),
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  projectId: {
    type: Number,
    default: null,
  },
  userInfo: {
    type: Object,
    required: true,
    default: () => ({
      firstName: '',
      lastName: '',
      organization: '',
      type: 'broker',
    }),
  },
});
const sortedComments = computed(() =>
  props.comments.reduce((all, comment) => {
    const newComment = {
      ...comment,
      replies: [],
      topComment: true,
    };
    const sortedReplies = comment.replies
      ? sortObjects(comment.replies, 'created_at', 'desc')
      : [];

    return all.concat([newComment], sortedReplies);
  }, []),
);
</script>
