<template>
  <div>
    <UiFileUpload
      :url="`${apiUrl}/v1/carrier_portal/documents/${proposalDocumentId}/upload_sources`"
      :documents="clonedProposals"
      name="files[]"
      :is-downloading="isDownloading"
      :is-deleting="isDeleting"
      :file-extensions="acceptableFileExtensions"
      @delete-document="handleDeleteClick"
      @update:is-uploading="isUploading = $event"
      @download-all-documents="downloadAllDocuments"
      @upload-success="onUploadSuccess"
      @before-upload="shouldDisplayNonPdfModal"
    >
      <template #header>
        <p
          v-if="!isUploadRenewalRatePass && !isSmartProposal"
          data-test="auto renewal disclaimer"
        >
          <strong data-test="disclaimer text">
            You upload the documents and we do the work!
          </strong>
        </p>
      </template>
    </UiFileUpload>

    <div class="btn-group-right">
      <Button
        data-test="close supplemental documents modal"
        size="large"
        severity="secondary"
        label="Cancel"
        variant="text"
        @click="$emit('closeDialog')"
      />
      <Button
        :disabled="disableSubmitButton"
        data-test="submit renewal"
        size="large"
        :label="confirmUploadDocumentsCtaText"
        @click="submitDocument"
      />
    </div>
    <NonPdfModal
      v-if="showNonPdfModal"
      v-model:visible="showNonPdfModal"
      @submit-renewal="submitDocument"
    />
  </div>
</template>

<script setup>
import { ref, computed, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import {
  reusableAcceptableUploadFileExtensions,
  trackSegmentEvent,
} from '@watchtowerbenefits/es-utils-public';
import { useAccountStore } from '@/stores/account.js';
import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
import { useProjectStore } from '@/stores/project.js';
import { useProductStore } from '@/stores/product.js';
import DocumentsService, { startSmartProposal } from '@/services/documents.js';
import { deleteSource } from '@/services/source.js';
import { splitProposalFileName } from '@/utils/fileUpload.js';
import { config } from '@/utils/config.js';

// Components
import Button from 'primevue/button';
import NonPdfModal from '@/components/Modals/StartOrUpdateRenewalModal/NonPdfModal.vue';
import UiFileUpload from '@/shared/components/UiFileUpload.vue';
import { useToast } from 'primevue/usetoast';

/**
 * Upload, edit, & delete files for rate pass renewal.
 *
 * @exports FileUploadModal
 */
defineOptions({
  name: 'FileUpload',
});

// Props
const props = defineProps({
  productIds: {
    type: Array,
    required: true,
  },
});
const emits = defineEmits(['refreshAndClose', 'submitDocument']);
// Constants
const apiUrl = config.VUE_APP_API_URL;
// Stores
const accountStore = useAccountStore();
const carrierInfoStore = useCarrierInfoStore();
const projectStore = useProjectStore();
const productStore = useProductStore();
const { id: carrierId } = storeToRefs(carrierInfoStore);
const { proposalDocumentId, proposals, projectId } = storeToRefs(projectStore);
const {
  isUploadRenewalRatePass,
  isSmartProposal,
  isAllNewCoverage,
  isMixOfNewAndRenewingCoverage,
  diveUnsupportedProductTypes,
  threeflowAssistProductSnapshot,
} = storeToRefs(productStore);
// Reactive State
const clonedProposals = ref(JSON.parse(JSON.stringify(proposals.value)) || []);
const uploadError = ref('');
const isDownloading = ref(false);
const isDeleting = ref(false);
const isSubmitting = ref(false);
const showNonPdfModal = ref(false);
const isUploading = ref(false);
const toast = useToast();
/**
 * Disables submit button if no proposals or already submitting, is uploading a
 * file, or deleting a file
 *
 * @returns {boolean}
 */
const disableSubmitButton = computed(() => {
  if (
    !proposals.value.length ||
    isSubmitting.value ||
    isUploading.value ||
    isDeleting.value
  )
    return true;

  return clonedProposals.value.every(
    ({ dive_rate_extraction_ids: ids }) => ids && ids.length > 0,
  );
});
const acceptableFileExtensions = computed(() =>
  reusableAcceptableUploadFileExtensions().concat(
    reusableAcceptableUploadFileExtensions().map((extension) =>
      extension.toUpperCase(),
    ),
  ),
);
/**
 * Return the correct text to display in the confirm upload documents button.
 *
 * @returns {string}
 */
const confirmUploadDocumentsCtaText = computed(() =>
  proposals.value.length
    ? 'Send to ThreeFlow'
    : 'Upload documents to send to ThreeFlow',
);
/**
 * On Upload Success: Remove the file from `uploadingFiles` and update proposals
 * in the store.
 *
 * @param {object} root0
 * @param {XMLHttpRequest} root0.xhr
 */
const onUploadSuccess = ({ xhr }) => {
  const data = JSON.parse(xhr.response);
  let proposal = data?.successfully_uploaded_sources[0];

  proposal = splitProposalFileName(proposal);
  clonedProposals.value = [].concat(proposal, clonedProposals.value);

  projectStore.setProposals(data.sources);
};
/**
 * Handles file deletion
 *
 * @param {object} root0
 * @param {number} root0.id
 */
const handleDeleteClick = async ({ id }) => {
  isDeleting.value = true;
  try {
    await deleteSource(id);
    projectStore.removeProposal(id);
    clonedProposals.value = clonedProposals.value.filter(
      (proposal) => proposal.id !== id,
    );
  } catch {
    toast.add({
      severity: 'error',
      detail: 'There was an error deleting the document. Please try again.',
    });
  } finally {
    isDeleting.value = false;
  }
};
/**
 * Checks if the project has any products that can be sent to ThreeFlow Assist
 *
 * @returns {boolean}
 */
const showThreeFlowAssistModal = () =>
  isAllNewCoverage.value || isMixOfNewAndRenewingCoverage.value
    ? diveUnsupportedProductTypes.value.length > 0
    : false;
const submitDocument = async () => {
  const proposalOrRenewal = isUploadRenewalRatePass.value
    ? 'renewal'
    : 'proposal';

  try {
    if (isUploadRenewalRatePass.value) {
      isSubmitting.value = true;
      await DocumentsService.startRenewal(proposalDocumentId.value, {
        product_ids: props.productIds,
      });
    }

    if (isSmartProposal.value) {
      isSubmitting.value = true;
      threeflowAssistProductSnapshot.value = [
        ...diveUnsupportedProductTypes.value,
      ];
      await startSmartProposal(proposalDocumentId.value);

      emits('submitDocument', showThreeFlowAssistModal());
    }

    // Send out a segment event in order to track auto-renewals or proposals
    // this is the first event of two necessary to track auto-renewals
    trackSegmentEvent(`Submit auto-${proposalOrRenewal}`, {
      project_id: +projectId.value,
      carrier_id: carrierId.value,
      user_carrier_id: accountStore.userInfo.id,
    });

    emits('refreshAndClose');
  } catch {
    isSubmitting.value = false;
    toast.add({
      severity: 'error',
      detail: `Not all of the products you selected for ${proposalOrRenewal} were successfully started.`,
    });
    emits('refreshAndClose');
  }
};
/** Downloads all uploaded files for the project. */
const downloadAllDocuments = async () => {
  const filename = `${proposalDocumentId.value}_proposals.zip`;

  isDownloading.value = true;

  try {
    const data = await DocumentsService.getAllDocumentSources(
      proposalDocumentId.value,
    );
    const blob = new Blob([new Uint8Array(data)], { type: 'application/zip' });
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  } catch {
    toast.add({
      severity: 'error',
      closable: true,
      detail:
        'There was an error downloading the documents zipfile, please try again.',
      life: 30000,
    });
  } finally {
    isDownloading.value = false;
  }
};
/**
 * Decide based on file type if we should display NonPdfModal
 *
 * @param {object} root0
 * @param {object} root0.file
 */
const shouldDisplayNonPdfModal = ({ file }) => {
  if (file.type !== 'application/pdf') {
    showNonPdfModal.value = true;
  }
};

onUnmounted(() => {
  uploadError.value = '';
});
</script>

<style scoped lang="scss">
li {
  display: flex;
  justify-content: space-between;
  min-height: 26px;
  padding: 16px 0;
  margin: 0;
  border-bottom: 1px dotted var(--tf-gray-light-medium);

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}
</style>
