<script setup>
import { ref, watch } from 'vue';
import {
  Accordion,
  AccordionPanel,
  AccordionHeader,
  AccordionContent,
  Button,
  Checkbox,
  Tag,
} from 'primevue';
import MultiSelectFilter from '../../../shared/components/MultiSelectFilter.vue';

const emit = defineEmits(['update:filters']);
// Props
const props = defineProps({
  filters: {
    type: Array,
    required: true,
  },
});
// Data
const appliedFilters = ref({
  ...props.filters.reduce((acc, filter) => {
    acc[filter.key] = filter.value;

    return acc;
  }, {}),
});
const removeFilter = (key, [value]) => {
  appliedFilters.value[key] = appliedFilters.value[key].filter(
    (filterValue) => filterValue !== value,
  );
};
// Methods
const applyFilter = ({ key, value }, remove) => {
  if (remove) {
    removeFilter(key, [value]);
  } else {
    appliedFilters.value[key] = Array.isArray(value)
      ? value
      : [...appliedFilters.value[key], value];
  }
};
const clearFilters = () => {
  Object.keys(appliedFilters.value).forEach((key) => {
    appliedFilters.value[key] = [];
  });
};

// Emit to the parent when the applied filters change
watch(
  () => appliedFilters.value,
  () => {
    emit('update:filters', appliedFilters.value);
  },
  { deep: true },
);
</script>

<template>
  <div>
    <header>
      <h3>Filters</h3>
      <Button label="Clear all" variant="text" @click="clearFilters" />
    </header>
    <div
      v-if="
        Object.keys(appliedFilters).some((key) => appliedFilters[key].length)
      "
      class="active-filters"
    >
      <template v-for="(value, key) in appliedFilters" :key="key">
        <Tag
          v-for="filterValue in value"
          :key="filterValue"
          severity="secondary"
          @click="removeFilter(key, value)"
        >
          {{ filterValue }}
          <i class="fa fa-times" />
        </Tag>
      </template>
    </div>
    <template v-for="(filter, index) in props.filters" :key="filter.label">
      <Accordion :value="[...Array(props.filters.length).keys()]" multiple>
        <AccordionPanel :value="index">
          <AccordionHeader>{{ filter.label }}</AccordionHeader>
          <AccordionContent>
            <MultiSelectFilter
              v-if="filter.type === 'multiselect'"
              v-model="appliedFilters[filter.key]"
              :filter="filter"
              @update:filter="applyFilter"
            />
            <template v-for="option in filter.options" v-else :key="option">
              <div class="checkbox-option">
                <Checkbox
                  v-model="filter.value"
                  class="checkbox"
                  :name="filter.key"
                  :value="option"
                  :input-id="option"
                  @change="
                    applyFilter(
                      { key: filter.key, value: option },
                      !$event.target.checked,
                    )
                  "
                />
                <label :for="option">{{ option }}</label>
              </div>
            </template>
          </AccordionContent>
        </AccordionPanel>
      </Accordion>
    </template>
  </div>
</template>

<style lang="scss" scoped>
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.35rem;
  border-style: solid;
  border-width: 0 0 1px;
  border-color: var(--p-accordion-content-border-color);
}

h3 {
  margin: 0;
}

.active-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--p-accordion-content-border-color);
}

.fa-times {
  margin-left: 0.5rem;
  cursor: pointer;
}

.checkbox-option {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.checkbox {
  margin-right: 0.5rem;
}
</style>
