import { useRouter } from 'vue-router';
import ServiceAccount from '@/services/account.js';

/**
 * Account composables
 *
 * @returns {object} Account composable
 */
export function useAccountComposable() {
  const router = useRouter();
  const inactiveLogout = async () => {
    /**
     * Call the logout service and then redirect the user to the sign in page
     * This gets provided to compnents so they can call it too
     */
    try {
      await ServiceAccount.signOut();
    } catch (error) {
      // still want to redirect the user to the sign in page even if the sign out fails
    }

    const redirectRoute = {
      name: 'SignIn',
    };

    // Append the current route as a redirect query parameter
    if (
      ['/', '/sign-out', '/sign-in'].includes(router.currentRoute.value.path)
    ) {
      redirectRoute.query = { redirect: router.currentRoute.value.path };
    }

    router.push(redirectRoute);
  };

  return {
    inactiveLogout,
  };
}
