<template>
  <section :class="{ active: active }">
    <AppMessage
      v-if="warningMessage"
      data-test="rate entry warning"
      class="rate-entry-warning"
      severity="info"
      closable
      @close="emit('dismissAlert')"
    >
      {{ warningMessage }}
    </AppMessage>
    <ContainerHeader
      :container="props.container"
      data-test="rate entry container header"
      @toggle="active = !active"
    >
      <template #btnSeparateClasses>
        <Button
          v-if="!isPlan && props.container.id.length > 1"
          :disabled="!active || route.meta.readonly || disableSeparateClasses"
          data-test="separate classes"
          variant="link"
          label="Separate classes"
          @click="separateClasses"
        />
      </template>
      <template v-if="description" #description>
        <p data-test="rate entry description">
          <template v-if="description.length > truncateLength">
            {{ description.substr(0, truncateLength) }}&hellip;
            <Button
              data-test="show full description"
              variant="link"
              label="full description"
              @click="showDescriptionDialog = !showDescriptionDialog"
            />
          </template>
          <template v-else>
            {{ description }}
          </template>
        </p>
      </template>
    </ContainerHeader>

    <div v-show="active" class="rate-entry-attribute-container">
      <template
        v-for="(rateAttribute, rateAttributeIndex) in props.container
          .rateAttributes"
        :key="`${container.id.join('_')}-${rateAttribute.id}`"
      >
        <RateEntryAttribute
          :container="props.container"
          :rate-attribute="rateAttribute"
          :rate-attribute-index="rateAttributeIndex"
        />
      </template>
    </div>

    <Dialog
      v-if="showDescriptionDialog"
      v-model:visible="showDescriptionDialog"
      :header="`Class description${container.containerNames.length > 1 ? 's' : ''}`"
      data-test="all rate entry class descriptions"
    >
      <div id="class-descriptions">
        <template
          v-for="(desc, idx) in container.description"
          :key="container.containerNames[idx]"
        >
          <h4 v-text="`Class ${container.containerNames[idx]}`" />
          <p class="class-description" v-text="desc" />
        </template>
      </div>
    </Dialog>
  </section>
</template>

<script setup>
import { ref, computed, provide, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useRateEntryStore } from '@/stores/rateEntry.js';
import { useProductStore } from '@/stores/product.js';
import ContainerHeader from '@/components/DataEntry/ContainerHeader.vue';
import { Button, Dialog } from 'primevue';
import AppMessage from '@/shared/components/AppMessage.vue';
import RateEntryAttribute from './RateEntryAttribute.vue';
/**
 * Rate Entry Container
 *
 * @exports RateEntryContainer
 */
// Emits
const emit = defineEmits(['dismissAlert']);
// Stores
const rateEntryStore = useRateEntryStore();
const productStore = useProductStore();
const route = useRoute();
// Props
const props = defineProps({
  container: {
    type: Object,
    required: true,
    default: () => {},
  },
  warningMessage: {
    type: String,
    default: null,
  },
});
// Variables
const active = ref(true);
const disableSeparateClasses = ref(false);
const showDescriptionDialog = ref(false);
const truncateLength = ref(200);
// Computed
const isPlan = computed(
  () => rateEntryStore.info.container_type.toLowerCase() === 'plan',
);
/**
 * ContainerIds is used throughout container child components
 *
 * @returns {object}
 */
const containerIds = computed(() => props.container.id);

// Provide
/**
 * Sends reactive containerIds and isPlan
 *
 * @returns {object}
 */
provide(
  'containerIds',
  computed(() => containerIds.value),
);
provide(
  'isPlan',
  computed(() => isPlan.value),
);
/**
 * Returns a joined string of the container descriptions array.
 *
 * @returns {string}
 */
const description = computed(() =>
  !isPlan.value ? props.container.description.join(', ') : null,
);

watch(
  () => rateEntryStore.drainingUpdateRequests,
  (newValue) => {
    disableSeparateClasses.value = newValue;
  },
);
/**
 * We need to pulse until any patching is successful. This can happen if the
 * user updates a value and clicks on the `Separate Classes` button If there is
 * still patching happening disable the button until the loader can pop up
 */
const separateClasses = async () => {
  const separate = async () => {
    rateEntryStore.separatedClassIds = containerIds.value;
    rateEntryStore.rateEntryContainers = [];
    rateEntryStore.classesAreSeparated = true;
    await rateEntryStore.getRateEntry({
      rollOutIds: containerIds.value,
      productId: productStore.productId,
    });
    rateEntryStore.rateErrors = [];
  };
  const checkPatching = () => {
    setTimeout(() => {
      if (rateEntryStore.editingRateAttributeValue) {
        disableSeparateClasses.value = true;
        checkPatching();
      } else {
        separate();
      }
    }, 50);
  };

  // Separate the classes is no patching being done
  if (!rateEntryStore.editingRateAttributeValue) {
    await separate();

    return;
  }

  checkPatching();
};
</script>

<style lang="scss" scoped>
section.active {
  border-bottom: 1px solid var(--tf-gray-medium);

  .dialog-plan-summary & {
    margin-bottom: 10px;
    border-bottom: none;
  }
}

.rate-entry-attribute-container {
  border-left: 1px solid var(--tf-gray-medium);
  border-right: 1px solid var(--tf-gray-medium);
}

.rate-entry-warning {
  margin-bottom: 1rem;
}

.rate-entry-warning.app-alert {
  margin-top: 30px;
  max-width: 862px;
}

form {
  border-bottom: 1px solid var(--tf-gray-medium);
}

strong {
  font-weight: 600;
}

hr {
  max-width: 1108px;
  margin: 0 auto 40px;
  border-color: var(--tf-gray-medium);
}

.policy-ids-list {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  label {
    margin-right: 5px;
  }
}

.policy-ids-input {
  text-align: left;
  max-width: 250px;
  font-size: 12px;
  color: var(--tf-gray);
}
</style>
