<script setup>
import { FormField } from '@primevue/forms';
import Password from 'primevue/password';
import Divider from 'primevue/divider';
import Message from 'primevue/message';
import { z } from 'zod';
import { zodResolver } from '@primevue/forms/resolvers/zod';
import { useModelValue } from '@/composables/useModelValue.js';

defineOptions({
  name: 'AuthPassword',
});

const props = defineProps({
  password: {
    type: String,
    default: '',
  },
  confirmPassword: {
    type: String,
    default: '',
  },
});
const emits = defineEmits([
  'update:password',
  'update:confirmPassword',
  'update:isValidPassword',
]);
const password = useModelValue(props, 'password', emits);
const confirmPassword = useModelValue(props, 'confirmPassword', emits);
const passwordValidationSchema = zodResolver(
  z
    .string()
    .min(10, { message: 'Minimum 10 characters.' })
    .refine(
      (value) => {
        // Define conditions
        const conditions = [
          /[a-z]/.test(value), // Lowercase
          /[A-Z]/.test(value), // Uppercase
          /[0-9]/.test(value), // Number
          /[$&+,:;=?@#|'<>.^*()%!-]/.test(value), // Special character
        ];
        // Count how many conditions pass
        const validCount = conditions.filter(Boolean).length;

        // Require at least 3/4 to be valid
        return validCount >= 3;
      },
      { message: 'Password must meet at least 3 of the 4 complexity rules.' },
    ),
);
const confirmPasswordValidationSchema = zodResolver(
  z.string().superRefine((value, context) => {
    emits('update:isValidPassword', false);
    // Check password match
    if (password.value) {
      if (!value) {
        context.addIssue({ message: 'Please confirm your password.' });
      } else if (password.value !== value) {
        context.addIssue({ message: 'Passwords do not match.' });
      } else {
        emits('update:isValidPassword', true);
      }
    }
  }),
);
</script>

<template>
  <FormField
    v-slot="$field"
    :resolver="passwordValidationSchema"
    class="password-container flex flex-col gap-2"
    name="password"
  >
    <label for="password-input">Password</label>
    <Password
      id="password-input"
      v-model="password"
      data-test="edit sign in password"
      fluid
      toggle-mask
      placeholder="Enter password"
    >
      <template #content>
        <div class="font-semibold text-xm mb-4">Pick a password</div>
      </template>
      <template #footer>
        <Divider />
        Password must be at least 10 characters
        <br />
        Password must contain 3 of the following:
        <ul class="password-rules">
          <li>at least one uppercase character (A-Z)</li>
          <li>at least one lowercase character (a-z)</li>
          <li>at least one digit (0-9)</li>
          <li>at least one special character ($)</li>
        </ul>
      </template>
    </Password>
    <Message
      v-if="$field?.invalid"
      severity="error"
      size="small"
      variant="simple"
      >{{ $field.error?.message }}
    </Message>
  </FormField>
  <FormField
    v-slot="$field"
    :resolver="confirmPasswordValidationSchema"
    class="password-container flex flex-col gap-2"
    name="confirmPassword"
  >
    <label for="confirm-password-input">Confirm password</label>
    <Password
      id="confirm-password-input"
      v-model="confirmPassword"
      data-test="edit sign in password"
      fluid
      toggle-mask
      :feedback="false"
      placeholder="Confirm password"
      validate-on-mount
      validate-on-blur
    />
    <Message
      v-if="$field?.invalid"
      severity="error"
      size="small"
      variant="simple"
      >{{ $field.error?.message }}
    </Message>
  </FormField>
</template>
