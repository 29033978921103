<template>
  <div class="limited-text-area" :class="{ error: isOverLimit }">
    <Textarea
      v-model="localValue"
      fluid
      :rows="rows"
      :placeholder="placeholder"
      data-test="limited text area input"
    />
    <span class="helper-text" data-test="helper text">
      {{ charactersHelperText }}
    </span>
  </div>
</template>

<script setup>
import { computed, watch, onMounted } from 'vue';
import Textarea from 'primevue/textarea';
import { useModelValue } from '@/composables/useModelValue.js';

defineOptions({
  name: 'LimitedTextArea',
});

/**
 * A text area that limits the number of characters entered. Can also change a
 * v-model bound boolean prop value if the limit is exceeded via the
 * `limitExceeded` prop.
 *
 * @exports LimitedTextArea
 */
const props = defineProps({
  /**
   * The string value of the textarea.
   *
   * @type {string}
   */
  modelValue: { type: String, required: true },

  /**
   * The number of rows to display in the textarea.
   *
   * @default 4
   * @type {number}
   */
  rows: { type: Number, default: 4 },

  /**
   * The placeholder text to display in the textarea.
   *
   * @default ''
   * @type {string}
   */
  placeholder: { type: String, default: '' },

  /**
   * The maximum number of characters allowed in the textarea. Users can still
   * type past the limit, but the textarea will be marked as an error.
   *
   * @default 256
   * @type {number}
   */
  characterLimit: {
    type: Number,
    default: 256,
    validator: (value) => value > 0,
  },

  /**
   * Determines if the textarea limit has been exceeded. Can be used in a
   * `v-model:limitExceeded` directive to sync with parent.
   *
   * @default false
   * @type {boolean}
   */
  limitExceeded: { type: Boolean, default: false },
});
const emit = defineEmits(['update:modelValue', 'update:limitExceeded']);
/** The local input value, bound to `modelValue`. */
const localValue = useModelValue(props, 'modelValue', emit);
/**
 * Displays the number of characters remaining in the text area. Also used for
 * additional logic in other computed properties.
 *
 * @returns {number}
 */
const charactersRemaining = computed(
  () => props.characterLimit - localValue.value.length,
);
/**
 * Determines if the characters entered in the text area exceed the
 * `characterLimit` prop.
 *
 * @returns {boolean}
 */
const isOverLimit = computed(
  () => localValue.value.length > props.characterLimit,
);
/**
 * Formatted text to display in the helper text span in the template.
 *
 * @returns {string}
 */
const charactersHelperText = computed(() => {
  let helperText = `${Math.abs(charactersRemaining.value)} character`;

  // Pluralize characters remaining if necessary
  if (Math.abs(charactersRemaining.value) !== 1) {
    helperText += 's';
  }

  helperText += isOverLimit.value ? ' over limit' : ' left';

  return helperText;
});

/**
 * Will update the `limitExceeded` prop if the characters entered in the text
 * area exceed the `characterLimit` prop.
 */
watch(isOverLimit, (newValue) => {
  emit('update:limitExceeded', newValue);
});

// Lifecycle Hooks

/**
 * Sets `limitExceeded` to whatever `isOverLimit` computes to after component
 * mount.
 */
onMounted(() => {
  emit('update:limitExceeded', isOverLimit.value);
});
</script>

<style lang="scss" scoped>
.limited-text-area {
  text-align: left;
}

.error {
  :deep(textarea) {
    border: 1px solid var(--tf-red);
  }

  span {
    color: var(--tf-red);
  }
}

// stylelint-disable-next-line no-descending-specificity
span {
  color: var(--tf-gray);
}
</style>
