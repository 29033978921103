import { defineStore } from 'pinia';
import { sortObjects } from '@watchtowerbenefits/es-utils-public';
import { getComments as getCommentsApi } from '@/services/comment.js';
import {
  clearCommentNotifications as clearCommentNotificationsApi,
  getCommentsNotifications as getCommentsNotificationsApi,
} from '@/services/notifications.js';

export const useCommentsStore = defineStore('comments', {
  state: () => ({
    commentsPanel: {
      visible: false,
      open: false,
    },
    comments: [],
    isLoaded: false,
    isLoading: false,
    showNewBadge: true,
    unreadComments: 0,
  }),

  actions: {
    addComment(comment) {
      this.comments.unshift(comment);
    },
    addReply(reply) {
      const comment = Array.isArray(reply) ? reply[0] : reply;
      const parentComment = this.comments.find(
        (parent) => parent.id.toString() === comment.parent_id,
      );

      parentComment.replies.unshift(comment);
    },
    clearCommentNotifications(projectId) {
      clearCommentNotificationsApi(projectId).then(() => {
        this.setCommentsNotification();
      });
    },
    async getComments(proposalDocumentId) {
      const setLoading = (loading = false) => {
        this.setCommentsLoading(loading);
        this.setCommentsLoaded(!loading);
      };

      setLoading(true);

      const data = await getCommentsApi(proposalDocumentId);
      let comments = data.comments || [];

      if (comments.length) {
        const uniqIds = [...new Set(comments.map(({ id }) => id))];

        comments = uniqIds.map((commentId) =>
          comments.find(({ id }) => id === commentId),
        );
      }

      this.setComments(sortObjects(comments, 'created_at', 'desc'));
      setLoading();
    },
    async getCommentsNotifications(projectId) {
      const data = await getCommentsNotificationsApi(projectId);

      this.setCommentsNotification(data.unread_notifications);
    },
    /**
     * Sets the visible and open booleans for panel by setting one first then
     * waiting for the open/close animation to finish (500ms) before setting the
     * other. We delay in order to add it to the dom and then trigger a class
     * change and a transition
     *
     * @param {boolean} open - The boolean to set the states to
     */
    toggleCommentsPanel(open) {
      if (open) {
        this.commentsPanel.visible = open;
        setTimeout(() => {
          this.commentsPanel.open = open;
        }, 500);
      } else {
        this.commentsPanel.open = open;
        setTimeout(() => {
          this.commentsPanel.visible = open;
        }, 500);
      }
    },
    setComments(comments = []) {
      this.comments = comments;
    },
    setCommentsLoaded(isLoaded = false) {
      this.isLoaded = isLoaded;
    },
    setCommentsLoading(isLoading = false) {
      this.isLoading = isLoading;
    },
    setCommentsNotification(unreadComments = 0) {
      this.unreadComments = unreadComments;
    },
    setShowNewBadge(showNewBadge = false) {
      this.showNewBadge = showNewBadge;
    },
  },
});
