<script setup>
/**
 * SSO Authentication
 *
 * @exports SsoSignIn
 */
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import ProgressSpinner from 'primevue/progressspinner';
import AccountService from '@/services/account.js';
import { deleteCookie, setCookie } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const router = useRouter();
const route = useRoute();
const isAuthenticating = ref(true);
/**
 * Handles authentication failure and redirects to sign-in page.
 *
 * @param {string} [error] - The error message (optional).
 */
const failedToAuthenticate = (error) => {
  const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
  const { projectId } = route.params;

  isAuthenticating.value = false;

  if (error) {
    deleteCookie(`${cookieNamespace}-auth-token`);
    deleteCookie(`${cookieNamespace}-user-roles`);
  }

  router.replace({
    name: projectId ? 'ProjectSignIn' : 'SignIn',
    params: { projectId },
    query: { error: 'auth' },
  });
};
const onCreated = async () => {
  const { auth_token: authToken, error } = route.query;

  if (!authToken || error) {
    failedToAuthenticate(error);

    return;
  }

  try {
    const signInData = await AccountService.getCurrentUser(authToken);
    const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
    const expirationTime = new Date();

    expirationTime.setHours(expirationTime.getHours() + 8);

    setCookie(`${cookieNamespace}-auth-token`, authToken, expirationTime);
    setCookie(
      `${cookieNamespace}-user-roles`,
      signInData.user.roles.map((role) => role.name),
      expirationTime,
    );

    AccountService.setSignedIn(signInData);
    isAuthenticating.value = false;

    const { redirect } = route.query;

    // Catch edge case and cached legacy issue
    if (redirect && !['/sign-out', '/sign-in'].includes(redirect)) {
      router.replace(redirect);

      return;
    }

    router.replace({ name: 'Dashboard' });
  } catch {
    failedToAuthenticate();
  }
};

onCreated();
</script>

<template>
  <section class="flex justify-center">
    <ProgressSpinner v-if="isAuthenticating" />
    <span>Authenticating SSO user...</span>
  </section>
</template>
