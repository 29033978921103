<template>
  <component :is="tag" class="quote-edit-item">
    <Badge v-if="showNewBadge && item.is_new" value="New" severity="success" />
    <p v-if="showEditor" data-test="carrier name">
      <strong>{{ item.carrier_name }}</strong>
    </p>
    <p v-else data-test="responsible party for edit">
      <strong>{{ responsibleParty }}</strong>
    </p>
    <p data-test="history product type name">
      {{ productTypeName }}
    </p>
    <p data-test="history attribute info">
      {{ attributeInfo }}
    </p>
    <p data-test="history from">From: {{ fromValue }}</p>
    <template v-if="attributeLink">
      <p>
        <RouterLink :to="attributeLink" data-test="to history update">
          To: {{ toValue }}
        </RouterLink>
      </p>
      <p>
        <RouterLink
          :to="attributeLink"
          class="view-change-link"
          data-test="history change"
        >
          View Change
        </RouterLink>
      </p>
    </template>
    <p v-else>
      <strong data-test="history to">To: {{ toValue }}</strong>
    </p>
    <p v-if="showEditor" data-test="responsible party for edit">
      Editor: {{ responsibleParty }}
    </p>
    <p>
      <span class="time">{{ formatDateAndTime(item.last_created_at) }}</span>
    </p>
  </component>
</template>

<script setup>
import { computed } from 'vue';
import Badge from 'primevue/badge';

import { formatDateAndTime } from '@watchtowerbenefits/es-utils-public';

/**
 * A component used to display individual changes to a quote, including what the
 * change was, who made it, and when.
 */
defineOptions({
  name: 'AppQuoteEditItem',
});

// Props
const props = defineProps({
  /** What element this should appear as */
  tag: {
    type: String,
    default: 'li',
  },
  /** Overrides if we should show new badge or not */
  showNewBadge: {
    type: Boolean,
    default: true,
  },
  /** The history item to display */
  item: {
    type: Object,
    required: true,
  },
  /** Route string to the page where the change was made */
  attributeLink: {
    type: String,
    default: '',
  },
  /**
   * Modifies the layout by showing the carrier name and the editor instead of
   * just 'userName, carrierName'.
   */
  showEditor: {
    type: Boolean,
    default: false,
  },
});
/**
 * Combine the first/last name and prepend the carrier name if it exists.
 *
 * @returns {string}
 */
const responsibleParty = computed(() => {
  const {
    broker,
    carrier,
    first_name: firstName,
    last_name: lastName,
  } = props.item.history_details.responsible_user;
  const userName = `${firstName} ${lastName}`;
  const responsibleCompany = broker?.name || carrier?.name || '';

  return `${userName}, ${responsibleCompany}`;
});
/**
 * Display product type if there's a label
 *
 * @returns {string}
 */
const productTypeName = computed(() =>
  props.item.product_type_name !== props.item.project_product_label
    ? `${props.item.product_type_name}, ${props.item.project_product_label}`
    : props.item.product_type_name,
);
/**
 * Display the attribute info including what product it belonged to as well as
 * container/value/subtype info.
 *
 * @returns {string}
 */
const attributeInfo = computed(() => {
  const details = props.item.history_details;
  let info = details.attribute_name;

  if (details.tier_subtype_name) {
    info += `, ${details.tier_subtype_name}`;
  }

  if (props.item.attributable_id !== 77) {
    info += ', ';

    if (details.container_type.toLowerCase() === 'class') {
      info += 'Class ';
    }

    info += `${details.container_name}`;
  }

  if (props.item.label && props.item.label !== 'composite') {
    info += `, ${props.item.label}`;
  }

  return info;
});
/**
 * Test to see if this is an attribute type of 'Rate Attribute'
 *
 * @returns {boolean}
 */
const isRateAttribute = computed(
  () => props.item.attributable_type.toLowerCase() === 'rateattribute',
);
/**
 * The text to be displayed for what the item was changed from If this is a Rate
 * Attribute: Float to 3 decimal points and add $
 *
 * @returns {string}
 */
const fromValue = computed(() => {
  const valueText = props.item.before_value || 'n/a';

  return isRateAttribute.value && props.item.before_value
    ? `$${parseFloat(valueText).toFixed(3)}`
    : valueText;
});
/**
 * The text to be displayed for what the item was changed to If this is a Rate
 * Attribute: Float to 3 decimal points and add $
 *
 * @returns {string}
 */
const toValue = computed(() => {
  const valueText = props.item.after_value || 'n/a';

  return isRateAttribute.value && props.item.after_value
    ? `$${parseFloat(valueText).toFixed(3)}`
    : valueText;
});
</script>

<style scoped>
.quote-edit-item {
  list-style: none;
  font-size: 14px;
  padding: 25px 16px;
  border-bottom: solid 1px var(--tf-gray-light-medium);
}

.time {
  color: var(--tf-gray);
}

.view-change-link {
  font-weight: normal;
}

p {
  margin: 0;
  padding: 2px 0;
}
</style>
