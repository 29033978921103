<template>
  <div class="document-category">
    <h4 v-if="title" v-text="title" />
    <slot>
      <ul>
        <DocumentManagerItem
          v-for="file in fileList"
          :key="file.id"
          :file="file"
          :show-button-label="true"
          @downloaded="emits('downloaded', { title, name: file.name })"
        />
      </ul>
    </slot>
  </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue';

// Lazy-load DocumentManagerItem component
const DocumentManagerItem = defineAsyncComponent(
  () => import('@/components/DocumentManagerItem.vue'),
);

defineOptions({
  name: 'RfpDocumentCategory',
});

/**
 * RFP Document Category
 *
 * @exports RfpDocumentCategory
 */
defineProps({
  fileList: {
    type: Array,
    default: () => [],
  },
  title: {
    type: String,
    default: null,
  },
});

const emits = defineEmits(['downloaded']);
</script>

<style lang="scss" scoped>
h4 {
  font-size: 16px;
}

.document-category {
  padding-bottom: 25px;
}
</style>
