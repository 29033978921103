// Vue
import { createApp, onMounted, h } from 'vue';
import App from '@/App.vue';
import '@/assets/styles/main.scss';
import '@/shared/assets/styles/main.scss';
// import '@watchtowerbenefits/cp-components/dist/style.css';
// import filters from '@/utils/filters';
import { config } from '@/utils/config.js';
import router from '@/router.js';

// packages
// import { Threeflow } from '@watchtowerbenefits/shared-components';
import { initializeSegment } from '@watchtowerbenefits/es-utils-public';

import {
  init as SentryInit,
  browserTracingIntegration as SentryBrowserTracing,
  setContext as SentrySetContext,
} from '@sentry/vue';
import LogRocket from 'logrocket';
import { createPinia } from 'pinia';
// import Icon from 'vue-awesome/components/Icon.vue';
// import { Notification } from 'element-ui';

// services
import Interceptor from '@/services/interceptor.js';
import ServiceAccount from '@/services/account.js';
import { getCoreApiStatus } from '@/services/coreApi.js';

// utils
import { initializeLD, parseLDConfig } from '@/utils/launchDarkly.js';

// PrimeVue
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import PrimeVue, { primeVueConfig } from '@/modules/primeVueConfig.js';

// directives
import loading from './directives/loading.js';

import { canUseThirdParty } from './utils/general.js';
import { logrocketPlugin } from './utils/piniaPlugins.js';

// Vue.prototype.$notify = Notification;

const {
  VUE_APP_ENV: appEnvironment,
  VUE_APP_SEGMENT_TOKEN: segmentToken,
  VITE_APP_NPM_PACKAGE_VERSION: appVersion,
} = config;
// some third party services we only want to run in non-features environments
const isNonFeaturesEnvironment = config.analyticsEnabled([
  'production',
  'staging',
  'qa',
  'demo',
]);
const pinia = createPinia();

pinia.use(logrocketPlugin);

if (isNonFeaturesEnvironment || canUseThirdParty('logrocket')) {
  LogRocket.init('r2e0ub/carrier-site', {
    console: {
      shouldAggregateConsoleErrors: true,
    },
    network: {
      requestSanitizer: (request) => {
        // if the request payload contains 'password'
        if (request?.body?.indexOf('password') !== -1) {
          // Scrub the request body
          // eslint-disable-next-line no-param-reassign
          request.body = null;
        }

        // return the request
        return request;
      },
    },
  });

  LogRocket.getSessionURL((sessionURL) => {
    // send the logrocket url to sentry so we can attach it to tickets
    if (isNonFeaturesEnvironment || canUseThirdParty('sentry')) {
      SentrySetContext('LogRocket', { sessionURL });
    }
    // When you generate the LogRocket session url, we call the Axios Interceptor to pass it on each network call
    Interceptor.addLogRocketURLToHeaders(sessionURL);
  });
}

const tryToInitSentry = (app) => {
  if (isNonFeaturesEnvironment || canUseThirdParty('sentry')) {
    SentryInit({
      app,
      dsn: 'https://464c9a91201b4b98b0de7d0d36da77bc@o97018.ingest.sentry.io/242048',
      tracePropagationTargets: ['threeflow.com', /^\//],
      ignoreErrors: [
        /Object Not Found Matching Id/i,
        /request failed with status code 401/i,
        /network error/i,
      ],
      integrations: [SentryBrowserTracing({ router })],
      environment: appEnvironment,
      release: `carrier_ui-${appEnvironment}@${appVersion}`,
      beforeSend: (event, { originalException, captureContext }) => {
        // we want to stop 401 errors from being sent to sentry
        const has401Status =
          captureContext?.error?.message?.match(/401/) ||
          originalException?.message?.match(/401/);

        // returning null discards the event entirely
        return has401Status ? null : event;
      },
    });
  }
};

// segment we still sometimes want to run in features environments for
// testing purposes, however we still send all data to the segment staging
// environment for all features sites
if (isNonFeaturesEnvironment || canUseThirdParty('segment')) {
  initializeSegment(segmentToken);
}

const createVueApp = () =>
  // eslint-disable-next-line implicit-arrow-linebreak
  createApp({
    setup() {
      /**
       * Once the app is mounted, we add the intercom-loaded class to the body
       * if Intercom is loaded, because we need to accommodate for the Intercom
       * chat widget in some instances.
       */
      onMounted(() => {
        if (isNonFeaturesEnvironment || canUseThirdParty('segment')) {
          /**
           * Polls for the Intercom script to load.
           *
           * @param {Function} callback - Not sure what this does
           */
          const pollForIntercom = (callback) => {
            let timesChecked = 0;
            const checkIntercom = () => {
              if (typeof window.Intercom === 'function') {
                callback(true);
              } else if (timesChecked < 10) {
                setTimeout(checkIntercom, 500);
                timesChecked += 1;
              } else {
                callback(false);
              }
            };

            checkIntercom();
          };

          pollForIntercom((isLoaded) => {
            if (isLoaded) {
              document.body.classList.add('intercom-loaded');
            }
          });
        }
      });

      // eslint-disable-next-line consistent-return
      return () => h(App);
    },
  });
const initApp = async () => {
  let LDconfig;

  try {
    const response = await getCoreApiStatus();

    // getCoreApiStatus is the only endpoint that's not parsed by the interceptor, because, when the interceptor fires,
    // Vue isn't initialized yet. So, we slightly double it up and do it here instead.
    LDconfig = parseLDConfig(response);
  } catch (error) {
    throw new Error('Health Check failed.');
  }

  const app = createVueApp(LDconfig);

  if (LDconfig) initializeLD(app, LDconfig);

  app.config.productionTip = false;
  app.provide('$ld', app.config.globalProperties.$ld);
  app.directive('tooltip', Tooltip);

  app.use(router);
  app.use(pinia);
  app.use(PrimeVue, primeVueConfig);
  app.use(ToastService);
  app.directive('loading', loading);

  tryToInitSentry(app);
  Interceptor.activate(app, router);

  app.mount('#app');
};

initApp();

if (window.Cypress) {
  // Add setSignedIn function to window.Cypress as persistMockSignIn so that the mockSignIn command
  // can properly set localStorage and cookies
  window.Cypress.persistMockSignIn = ServiceAccount.setSignedIn;
}
