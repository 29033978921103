<script setup>
/** Terms of use for ThreeFlow applications. */
import { onMounted, ref } from 'vue';
import Message from 'primevue/message';

const props = defineProps({
  /** The terms of use ID used in the API on mount. */
  termsId: {
    type: String,
    default: '11825817',
  },
});
const hasError = ref(false);
const termsOfUseContent = ref('');

onMounted(async () => {
  try {
    const response = await fetch(
      `https://www.iubenda.com/api/terms-and-conditions/${props.termsId}`,
    );

    if (!response.ok) {
      throw new Error('Network response was not ok.');
    } else {
      const { content } = await response.json();

      termsOfUseContent.value = content;
    }
  } catch {
    hasError.value = true;
  }
});
</script>

<template>
  <section class="terms-container" :class="{ 'has-error': hasError }">
    <Message
      v-if="hasError"
      :closable="false"
      data-test="terms of use error"
      description="There was a problem retrieving the terms of use."
      type="danger"
    />
    <!-- eslint-disable vue/no-v-html -->
    <span v-else data-test="terms of use content" v-html="termsOfUseContent" />
    <!-- eslint-enable vue/no-v-html -->
  </section>
</template>

<style scoped lang="scss">
.terms-container {
  width: 100%;
  max-width: 725px;
  padding: 30px 20px;

  &.has-error {
    max-width: none;
  }
}
</style>
