<template>
  <section
    v-if="productStore.currentProductIsLoaded"
    class="data-entry-container"
  >
    <RfpStatus data-test="rfp status" />
    <div class="data-entry-header">
      <div class="data-entry-header-left">
        <div>
          <div>Product</div>
          <Select
            id="productSelector"
            v-model="currentProduct"
            :options="productStore.products"
            option-label="project_product.label"
            option-value="id"
            :option-disabled="isOptionDisabled"
            data-test="product selector"
            @change="(id) => router.push({ params: { productId: id.value } })"
          />
        </div>
        <template v-if="showPolicyId">
          <div class="policy-ids-list">
            <div>Policy number</div>
            <div class="policy-ids-input">
              <InputText
                id="policyIdsInput"
                v-model="policyIdsString"
                @blur="updatePolicyIds"
              />
              Separate policy IDs with commas
            </div>
          </div>
        </template>
      </div>
      <Card v-if="!productStore.isStopLoss" id="rate-guarantee-container">
        <template #content>
          <div>Rate guarantee (in months)</div>
          <Form
            v-slot="$form"
            ref="form"
            label-width="120px"
            label-position="left"
          >
            <FormField
              :resolver="zodNumberResolver"
              :class="{ 'is-error': rateGuaranteeError }"
            >
              <label for="rateGuarantee"></label>
              <Select
                v-if="
                  rateEntryStore.info.rate_guarantee?.normalized_values.length
                "
                id="rateGuarantee"
                ref="rateGuarantee"
                v-model="localRateGuarantee"
                name="rateGuarantee"
                editable
                :disabled="isLoading || route.meta.readonly"
                :options="rateEntryStore.info.rate_guarantee.normalized_values"
                :invalid="isInvalid || !$form.valid"
                option-label="normalized_value"
                option-value="normalized_value"
                placeholder="Enter guarantee"
                data-test="guarantee field"
                @blur="doUpdateRateGuarantee()"
              />
              <InputText
                v-else
                id="rateGuarantee"
                ref="rateGuarantee"
                v-model="localRateGuarantee"
                name="rateGuarantee"
                :disabled="isLoading || route.meta.readonly"
                :invalid="isInvalid || !$form.valid"
                placeholder="Enter guarantee"
                data-test="guarantee field"
                @blur="doUpdateRateGuarantee()"
              />
              <Message
                v-if="!$form.valid"
                size="small"
                severity="error"
                variant="simple"
                >{{ $form.rateGuarantee.error.message }}
              </Message>
            </FormField>
          </Form>
        </template>
      </Card>
    </div>
    <Tabs :value="route.name">
      <TabList class="data-entry-tablist">
        <Tab
          v-for="step in steps"
          :key="`step-${step.value.text}`"
          :value="step.value.to.name"
          @click="router.push(step.value.to)"
        >
          {{ step.value.text }}
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel
          v-for="step in steps"
          :key="`step-panel-${step.value.text}`"
          :value="step.value.to.name"
        >
          <RouterView v-if="productStore.currentProduct" />
        </TabPanel>
      </TabPanels>
    </Tabs>
  </section>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
// Stores
import { useProductStore } from '@/stores/product.js';
import { useRateEntryStore } from '@/stores/rateEntry.js';
import { usePlanDesignStore } from '@/stores/planDesign.js';
import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
// Composables
import { useProjectComposable } from '@/composables/useProjectComposable.js';
import { useToast } from 'primevue/usetoast';
import { zodResolver } from '@primevue/forms/resolvers/zod';
import { z } from 'zod';
// Services
import productService, { patchProductPolicyIds } from '@/services/product.js';
// Components
import {
  Card,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Select,
  Message,
  InputText,
} from 'primevue';
import { Form, FormField } from '@primevue/forms';
import RfpStatus from '@/components/RfpOverview/RfpStatus.vue';

const route = useRoute();
const router = useRouter();
const toast = useToast();
const projectComposable = useProjectComposable();
// Data
const planDesignStore = usePlanDesignStore();
const rateEntryStore = useRateEntryStore();
const productStore = useProductStore();
const carrierInfoStore = useCarrierInfoStore();
const updatedPolicyIds = ref('');
const timer = ref(0);
const localRateGuarantee = ref(null);
const currentProduct = ref(null);
const zodNumberResolver = zodResolver(
  z.coerce.number({ message: 'Rate guarantee must be a number.' }),
);
// Computed
/**
 * Loading conditions
 *
 * @returns {boolean}
 */
const isLoading = computed(
  () =>
    rateEntryStore.loadingRateEntry &&
    !rateEntryStore.rateUpdateRequests.length,
);
/** Should the field have an invalid border */
const isInvalid = computed(
  () =>
    productStore.currentProduct.state === 'action_needed' &&
    !localRateGuarantee.value,
);
/**
 * Whether to show rate guarantee field with a red border
 *
 * @returns {boolean}
 */
const rateGuaranteeError = () =>
  !rateEntryStore.rateGuarantee.value &&
  !productStore.isProductNotStartedOrNotSubmitted;
/**
 * Show policy ids field on products for which the current carrier is the
 * inforce carrier
 */
const showPolicyId = computed(
  () =>
    productStore.currentProduct.project_product.inforce_carrier_id ===
    carrierInfoStore.id,
);
/** Format policy ids array into a string / update local data */
const policyIdsString = computed({
  get() {
    return productStore.currentProduct?.policy_ids.join(', ');
  },
  set(newValue) {
    updatedPolicyIds.value = newValue;
  },
});
// Methods
const isOptionDisabled = (option) => option.state === 'declined';
/** Patch modified policy ids */
const updatePolicyIds = async () => {
  if (updatedPolicyIds.value !== policyIdsString.value) {
    try {
      const { id: currentProductId } = productStore.currentProduct;

      await patchProductPolicyIds(
        currentProductId,
        updatedPolicyIds.value
          .split(',')
          .map((id) => id.trim())
          .filter((id) => id.length),
      );

      const { product } = await productService.getProduct(currentProductId);

      productStore.updateProduct(product);
      productStore.currentProduct = product;
    } catch {
      toast.add({
        closable: true,
        detail: 'Could not update policy ids.',
        severity: 'error',
      });
    } finally {
      updatedPolicyIds.value = policyIdsString.value;
    }
  }
};
/**
 * Object used to create the progress steps for the Plan Design button.
 *
 * @returns {object}
 */
const btnPlanDesign = computed(() => {
  const active = /^(PlanDesign|PlanDesignReview)$/i.test(route.name);
  const obj = {
    text: 'Plan design',
    to: {
      name: route.meta.readonly ? 'PlanDesignReview' : 'PlanDesign',
    },
    state: 'started',
    active,
  };

  if (planDesignStore.validPlanDesign && !active) {
    obj.btnIcon = 'fa-solid fa-check';
    obj.state = 'completed';
  } else {
    obj.btnText = '1';
  }

  return obj;
});
/**
 * Check if rate_guarantee exists and that the rates structure is valid.
 *
 * @returns {boolean}
 */
const validRates = computed(() =>
  productStore.isStopLoss
    ? rateEntryStore.validRateEntry
    : !!(
        rateEntryStore.validRateEntry &&
        productStore.currentProduct.rate_guarantee
      ),
);
/**
 * Object used to create the progress steps for the Rate Entry button.
 *
 * @returns {object}
 */
const btnRateEntry = computed(() => {
  const active = /^(RateEntry|RateEntryReview)$/i.test(route.name);
  const obj = {
    text: 'Rates',
    to: {
      name: route.meta.readonly ? 'RateEntryReview' : 'RateEntry',
    },
    state: rateEntryStore.validRateEntry ? 'completed' : 'not-started',
    active,
  };

  if (validRates.value && !active) {
    obj.btnIcon = 'fa-solid fa-check';
    obj.state = 'completed';
  } else {
    obj.btnText = '2';
  }

  return obj;
});
/**
 * Steps used to render tabs.
 *
 * @returns {Array}
 */
const steps = computed(() => [btnRateEntry, btnPlanDesign]);
// Methods
/**
 * Updates the store value
 *
 * @param {object} root0
 * @param {string} root0.value
 */
const updateRateGuarantee = ({ value }) => {
  // Debounce value updating and save
  clearTimeout(timer.value);

  timer.value = setTimeout(() => {
    rateEntryStore.rateGuarantee.value = value;
    rateEntryStore.validRateEntry = false;

    rateEntryStore.patchRateGuarantee({
      productId: productStore.productId,
      productState: productStore.productState,
    });
  }, 200);
};
const doUpdateRateGuarantee = () => {
  if (rateEntryStore.rateGuarantee.value !== localRateGuarantee.value) {
    try {
      z.coerce.number().parse(localRateGuarantee.value);
      updateRateGuarantee({ value: localRateGuarantee.value });
    } catch {
      // Ignore validation error
    }
  }
};
const loadData = async () => {
  await productStore.getCurrentProduct(route.params.productId);

  // Get Rate Entry
  rateEntryStore.clearRateEntry();
  await rateEntryStore.getRateEntry({ productId: route.params.productId });

  // Get Plan Design
  planDesignStore.clearPlanDesign();
  await planDesignStore.getPlanDesign(route.params.productId);

  try {
    await productStore.validateProduct(route.params.productId);
  } catch {
    // TODO rates, rate guarantee, or plan design is invalid
  }
};
const loadProductNormalizedValues = async (productId) => {
  try {
    await productStore.loadNormalizedValues(productId);
  } catch {
    toast.add({
      severity: 'error',
      detail:
        'There was an error loading the drop down values, typing in values is still available. Refresh to access the drop down values.',
    });
  }
};

// Watchers
watch(
  () => productStore.currentProduct,
  () => {
    currentProduct.value = productStore.currentProduct?.id;
  },
);

watch(
  () => rateEntryStore.rateGuarantee,
  () => {
    localRateGuarantee.value = rateEntryStore.rateGuarantee.value;
  },
);

watch(
  () => route.params.productId,
  async () => {
    if (!route.params.productId) {
      return;
    }
    try {
      await loadData();
    } catch {
      toast.add({
        detail: 'There was an error loading the product.',
        closable: true,
        severity: 'error',
      });
    }
    await loadProductNormalizedValues(route.params.productId);
  },
);

// Lifecycle
onMounted(async () => {
  try {
    await loadData();
  } catch {
    // Redirect the user back to MyQuotes if their renewal documents are still processing or they are unauthorized to access the product at this time.
    toast.add({
      severity: 'error',
      detail: 'There was an error loading the product.',
    });

    await router.push({ name: 'MyQuotes' });

    return;
  }

  if (route.params.productId) {
    await loadProductNormalizedValues(route.params.productId);
  }

  // The project data might already be loading from a parent component so escape early.
  if (
    !projectComposable.projectIsLoading &&
    !projectComposable.projectIsLoaded
  ) {
    projectComposable.getAllProjectData(route.params.projectId);
  }
});

onBeforeUnmount(() => {
  productStore.currentProduct = null;
  productStore.normalizedValues = [];
  productStore.currentProductIsLoaded = false;
  planDesignStore.clearPlanDesign();
  rateEntryStore.clearRateEntry();
});
</script>

<style lang="scss" scoped>
.data-entry-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  width: 100%;
}

.data-entry-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 0;
}

.data-entry-header-left {
  display: flex;
  column-gap: 1rem;
}

#rate-guarantee-container {
  background: var(--tf-gray-light);
}
</style>
