/* eslint-disable */
const spinnerSize = '30px';
const defaultHeight = '300px';
let heightHasChanged = false;
let overlayHeight = 0;

const updateLoadingState = (el, binding) => {
  const overlay = el.querySelector('.loading-overlay');
  const loadingText = overlay.querySelector('.loading-text');
  const [isLoading, text = ''] = Array.isArray(binding.value)
    ? binding.value
    : [binding.value];

  if (isLoading) {
    overlay.style.display = 'flex';
    el.style.pointerEvents = 'none';

    if (!el.style.height && el.offsetHeight === 0) {
      el.style.height = overlayHeight || defaultHeight;
      heightHasChanged = true;
    }

    overlay.style.height = `${el.scrollHeight}px`;

    loadingText.textContent = text;
  } else {
    overlay.style.display = 'none';
    el.style.pointerEvents = '';

    if (heightHasChanged) {
      el.style.height = '';
      heightHasChanged = false;
    }
  }
};

export default {
  mounted(el, binding) {
    const container = document.createElement('div');
    const overlay = document.createElement('div');
    const loadingSpinner = document.createElement('div');
    const loadingText = document.createElement('div');
    container.classList.add('loading-container');
    overlay.classList.add('loading-overlay');
    loadingSpinner.classList.add('loading-spinner');
    loadingText.classList.add('loading-text');

    Object.assign(container.style, {
      position: 'relative',
      display: 'flex',
      width: '100%',
      height: '100%',
    });

    // overlay styles
    Object.assign(overlay.style, {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      zIndex: '9999',
      display: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'all',
      flexDirection: 'column',
    });

    // spinner styles
    Object.assign(loadingSpinner.style, {
      width: spinnerSize,
      height: spinnerSize,
      border: '2px solid #f3f3f3',
      borderTop: '2px solid #6365f1',
      borderRadius: '50%',
      animation: 'spin 2s linear infinite',
    });

    // text styles
    Object.assign(loadingText.style, {
      marginTop: '8px',
      color: '#64748b',
      fontSize: '12px',
    });

    overlay.appendChild(loadingSpinner);
    if (loadingText) {
      overlay.appendChild(loadingText);
    }
    container.appendChild(overlay);
    el.insertBefore(container, el.firstChild);

    updateLoadingState(el, binding);

    if (document.querySelector('#loading-spinner-style')) return;

    const style = document.createElement('style');
    style.id = 'loading-spinner-style';
    style.innerHTML = `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `;
    document.head.appendChild(style);
  },

  updated(el, binding) {
    const overlay = el.querySelector('.loading-overlay');
    overlayHeight = overlay.offsetHeight;

    if (overlay) {
      updateLoadingState(el, binding);
    }
  },

  beforeUnmount(el) {
    const container = el.querySelector('.loading-container');
    if (container) {
      el.removeChild(container);
    }

    const styleTag = document.querySelector('#loading-spinner-style');
    if (styleTag) {
      styleTag.remove();
    }
  },
};
