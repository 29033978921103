<template>
  <section class="auth-form">
    <AuthPageHeader title="You have been successfully signed out." />
    <p>You'll be redirected to the sign-in page shortly.</p>
  </section>
</template>

<script setup>
/**
 * SignOut Page
 *
 * @exports SignOut
 */
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useQuoteEditsStore } from '@/stores/quoteEdits.js';
import { useAccountStore } from '@/stores/account.js';
import { useProjectStore } from '@/stores/project.js';
import { useProductStore } from '@/stores/product.js';
import { useProductTableStore } from '@/stores/productTable.js';
import ServiceAccount from '@/services/account.js';
import { deleteCookie } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

import AuthPageHeader from '@/shared/components/AuthPages/Header.vue';

defineOptions({
  name: 'UserSignOut',
});

const router = useRouter();
const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
const quoteEditsStore = useQuoteEditsStore();
const accountStore = useAccountStore();
const projectStore = useProjectStore();
const productStore = useProductStore();
const productTableStore = useProductTableStore();
/** Clears user session data, resets stores, and removes authentication tokens. */
const clearData = () => {
  // Reset all relevant store states
  projectStore.resetProjectState();
  productStore.resetProductState();
  productTableStore.resetProductTableState();
  quoteEditsStore.resetQuoteEditState();
  accountStore.setAuthStatus();
  accountStore.setUserEmail();
  accountStore.signOutUser();

  // Clear local storage
  window.localStorage.removeItem('auth');
  window.localStorage.removeItem('confirmed');

  // Delete authentication cookies
  deleteCookie(`${cookieNamespace}-auth-token`);
  deleteCookie(`${cookieNamespace}-user-roles`);
};

/** Redirect user to sign-in if inactive */
onMounted(async () => {
  clearData();

  await router.isReady();

  ServiceAccount.signOut()
    .then(() => {
      setTimeout(() => {
        router.push({ name: 'SignIn' });
      }, 5000);
    })
    .catch(() => {});
});
</script>
