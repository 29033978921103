<script setup>
import { computed } from 'vue';
import Message from 'primevue/message';

const props = defineProps({
  severity: {
    type: String,
    default: 'success',
    required: true,
  },
});
const icon = computed(() => {
  switch (props.severity) {
    case 'success':
      return 'fa-regular fa-check-circle';
    case 'info':
      return 'fa-regular fa-info-circle';
    case 'warn':
      return 'fa-regular fa-triangle-exclamation';
    case 'error':
      return 'fa-regular fa-hexagon-exclamation';
    default:
      return '';
  }
});
</script>

<template>
  <div class="flex justify-center">
    <Message v-bind="$attrs" :severity="severity">
      <template v-if="icon" #icon>
        <i :class="icon"></i>
      </template>
      <slot></slot>
    </Message>
  </div>
</template>
