import { defineStore } from 'pinia';
import projectService from '@/services/project.js';

export const useProjectContactsStore = defineStore('projectContacts', {
  state: () => ({
    isLoaded: false,
    carrierUsers: [],
    brokerUsers: [],
    existingEmails: [],
  }),
  actions: {
    /**
     * Get carrier and broker users.
     *
     * @param {number | string} projectId - The ID of the project.
     */
    async loadContacts(projectId) {
      try {
        this.isLoaded = false;
        const { broker_users: brokerUsers, carrier_users: carrierUsers } =
          await projectService.getCarrierContacts(projectId);

        this.carrierUsers = this.formatUsers(carrierUsers, 'carrier');
        this.brokerUsers = this.formatUsers(brokerUsers, 'broker');
        this.existingEmails = this.carrierUsers.map(({ email }) => email);
      } catch (error) {
        throw new Error('Failed to load contacts');
      } finally {
        this.isLoaded = true;
      }
    },
    /**
     * Map over the user objects to return only what we need.
     *
     * @param {Array} users - The list of user.
     * @param {string} type - The type of users, either 'carrier' or 'broker'.
     * @returns {Array} The formatted user objects.
     */
    formatUsers(users, type) {
      return users.map(({ user, created_at: createdAt }) => ({
        name: user.full_name,
        company: type === 'carrier' ? user.carrier.name : user.broker.name,
        email: user.email,
        dateAdded: new Date(createdAt).toLocaleDateString('en-US'),
      }));
    },
  },
});
