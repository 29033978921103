/**
 * Splits the proposal filename into name and extension for editing purposes.
 *
 * @param {object} proposal - The proposal object
 * @returns {object} - Proposal with `inputName` and `extension` separated
 */
export const splitProposalFileName = (proposal) => {
  const scopedProposal = { ...proposal, editing: false };

  // editing flags whether or not the user is editing the file name
  if (scopedProposal.name) {
    // we split the files name into parts so the user can edit the name but not the extension
    const extensionArray = scopedProposal.name.split('.');

    scopedProposal.extension = extensionArray[extensionArray.length - 1];
    extensionArray.splice(extensionArray.length - 1, 1);
    scopedProposal.inputName = extensionArray.join('.');
  } else {
    scopedProposal.inputName = '';
    scopedProposal.extension = '';
  }

  return scopedProposal;
};
