import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const normalizedEndpoint = `${config.VUE_APP_API_URL}/v1/carrier_portal`;
const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
const axiosConfig = () => getAxiosConfig(cookieNamespace);

/**
 * All rate entry related ajax calls
 *
 * @exports RateEntryService
 */
export default {
  /**
   * Copy rates from one class (or in force) to a specfic class group
   *
   * @param {number} productId
   * @param {Array} containerIds
   * @returns {Promise}
   */
  copyRatesFromInforce(productId, containerIds) {
    const url = `${normalizedEndpoint}/products/${productId}/copy_rates_from_inforce`;
    const data = {
      project_products_container_ids: containerIds,
    };

    return axios
      .post(url, data, axiosConfig())
      .then(({ data: ratesData }) => ratesData);
  },

  /**
   * Delete a proposal
   *
   * @param {object} root0
   * @param {number} root0.productId
   * @param {object} root0.rateAttribute
   * @returns {Promise}
   */
  deleteRateAttributeValues({ productId, rateAttribute }) {
    const url = `${normalizedEndpoint}/rate_attributes/${rateAttribute.attributeId}/rate_values`;
    const deleteConfig = axiosConfig();

    deleteConfig.data = {
      product_id: productId,
      project_products_container_ids: rateAttribute.containerIds,
    };

    return axios.delete(url, deleteConfig).then(({ data }) => data);
  },

  /**
   * Update tier group / rate options
   *
   * @param {object} root0
   * @param {number} root0.productId
   * @param {object} root0.rateAttribute
   * @returns {Promise}
   */
  patchTierGroup({ productId, rateAttribute }) {
    const url = `${normalizedEndpoint}/products/${productId}/rate_attributes/${rateAttribute.attributeId}/modify_rate_structure`;

    return axios
      .patch(
        url,
        {
          rate_basis: rateAttribute.rateBasis,
          tier_group_id: rateAttribute.tierGroupId,
          project_products_container_ids: rateAttribute.containerIds,
          type: rateAttribute.attributeType,
        },
        axiosConfig(),
      )
      .then(({ data }) => data);
  },

  /**
   * Get Rates for a Product
   *
   * @param {number} productId
   * @returns {Promise}
   */
  async getRateEntry(productId) {
    const url = `${normalizedEndpoint}/products/${productId}/rate_structure`;
    const { data } = await axios.get(url, axiosConfig());

    return data;
  },

  /**
   * Patch Rate Attribute
   *
   * @param {object} root0
   * @param {number} root0.productId
   * @param {object} root0.rateAttribute
   * @param {number} root0.subtypeId
   * @returns {Promise}
   */
  patchRateAttribute({ productId, rateAttribute, subtypeId }) {
    const url = `${normalizedEndpoint}/rate_attributes/${rateAttribute.attributeId}/rate_values`;
    const rateValues =
      rateAttribute.rateValues[subtypeId] ||
      rateAttribute.rateValues[rateAttribute.tierGroupId];

    return axios
      .patch(
        url,
        {
          product_id: productId,
          project_products_container_ids: rateAttribute.containerIds,
          rate_basis: rateAttribute.rateBasis,
          tier_group_id: Number.isNaN(Number(rateAttribute.tierGroupId))
            ? null
            : rateAttribute.tierGroupId,
          tier_subtype_id: Number.isNaN(Number(subtypeId)) ? null : subtypeId,
          type: rateValues.type,
          values: rateValues.values.map((value) => {
            const newValue = { ...value };

            newValue.value =
              newValue.value === '0.000' || newValue.value === 0
                ? 0 // 0 is a valid scenario we should cover for
                : Number(newValue.value) || null; // turn strings to Integers or null values

            return newValue;
          }),
        },
        axiosConfig(),
      )
      .then(({ data }) => data);
  },
  /**
   * Validate Rate Structure
   *
   * @param {number} productId
   * @returns {Promise<object>} A promise that resolves to the data from the
   *   server response.
   */
  validateRateEntry(productId) {
    const url = `${normalizedEndpoint}/products/${productId}/validate_rate_structure`;

    return axios
      .patch(url, {}, axiosConfig())
      .then(({ data }) => data)
      .catch(({ response }) => {
        throw response;
      });
  },
  /**
   * Validate Rate Guarantee value
   *
   * @param {number} productId
   * @returns {Promise<object>} A promise that resolves to the data from the
   *   server response.
   */
  validateRateGuarantee(productId) {
    const url = `${normalizedEndpoint}/products/${productId}/validate_rate_guarantee`;

    return axios
      .patch(url, {}, axiosConfig())
      .then(({ data }) => data)
      .catch(({ response }) => {
        throw response;
      });
  },
};
