<script setup>
import { onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAccountStore } from '@/stores/account.js';
import { useProjectComposable } from '@/composables/useProjectComposable.js';
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';

const route = useRoute();
const router = useRouter();
const { resetProject } = useProjectComposable();
const accountStore = useAccountStore();
const isCarrierExecutive = computed(() =>
  accountStore.roleNames.includes('carrier_executive'),
);
const dashboards = computed(() => {
  const baseDashboards = [
    {
      label: 'My opportunities',
      name: 'MyOpportunities',
    },
    {
      label: 'All opportunities',
      name: 'AllOpportunities',
    },
  ];

  if (isCarrierExecutive.value) {
    baseDashboards.push({
      label: 'Insights',
      name: 'Insights',
    });
  }

  return baseDashboards;
});

onMounted(() => {
  resetProject();
});
</script>

<template>
  <div id="container-dashboard">
    <div id="tabs-wrapper">
      <Tabs :value="route.name">
        <TabList>
          <Tab
            v-for="dashboard in dashboards"
            :key="dashboard.label"
            :value="dashboard.name"
            @click="
              router.push({
                name: dashboard.name,
                params,
              })
            "
          >
            {{ dashboard.label }}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel :value="route.name">
            <RouterView />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#container-dashboard {
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
}
</style>
