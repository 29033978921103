<script setup>
/**
 * Registration Form Component
 *
 * @exports RegisterForm
 */
import { ref, reactive, watch, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { Form, FormField } from '@primevue/forms';
import InputText from 'primevue/inputtext';
import Message from 'primevue/message';
import Select from 'primevue/select';
import Button from 'primevue/button';
import { z } from 'zod';
import AuthPageHeader from '@/shared/components/AuthPages/Header.vue';
import AuthPageFooter from '@/shared/components/AuthPages/Footer.vue';
import { zodResolver } from '@primevue/forms/resolvers/zod';

import AuthPassword from '@/shared/components/AuthPages/Password.vue';

defineOptions({
  name: 'AppRegisterForm',
});

const props = defineProps({
  accountService: {
    type: Object,
    default: () => ({}),
  },
  checkInvited: {
    type: Boolean,
    default: false,
  },
  documentId: {
    type: String,
    default: null,
  },
  employerName: {
    type: String,
    default: '',
  },
  invitedCarriers: {
    type: Number,
    default: 0,
  },
  linkForgot: {
    type: [String, Object],
    required: true,
  },
  linkLogin: {
    type: [String, Object],
    required: true,
  },
  linkRegisterConfirmation: {
    type: [String, Object],
    required: true,
  },
  projectId: {
    type: [String, Number],
    default: null,
  },
  showProjectInfo: {
    type: Boolean,
    default: true,
  },
  submittedCarriers: {
    type: Number,
    default: 0,
  },
  userInfo: {
    type: Object,
    default: () => ({}),
  },
});
const router = useRouter();
const route = useRoute();
const emit = defineEmits([
  'setUserEmail',
  'update:email',
  'update:firstName',
  'update:lastName',
  'update:role',
  'update:password',
  'setUserInfo',
]);
const customError = ref(null); // 'emailTaken' || 'notValid' || 'generic'
const notInvited = ref(false);
const roles = [
  'Executive or Corporate Sponsor',
  'Regional Leader',
  'Sales Executive',
  'Sales Coordinator',
  'Underwriter',
  'Account Manager',
];
const registrationForm = reactive({
  firstName: props.userInfo?.firstName || '',
  lastName: props.userInfo?.lastName || '',
  role: props.userInfo?.role || '',
  email: '',
  password: '',
  confirmPassword: '',
});

/**
 * Method to validate the email address and see if this user was invited to the
 * project
 *
 * @param {string} email
 * @returns {boolean}
 */
async function validateEmail(email) {
  if (!email || !props.checkInvited) {
    return true;
  }

  try {
    // check if the email is invited to the project
    await props.accountService?.checkInvitedToProject(email, props.projectId);

    return true;
  } catch (e) {
    notInvited.value = true;

    return false;
  }
}

const formValidationResolver = zodResolver(
  z.object({
    firstName: z.string().min(1, { message: 'Please enter your first name.' }),
    lastName: z.string().min(1, { message: 'Please enter your last name.' }),
    role: z.optional(
      z.string().min(1, { message: 'Please select your role.' }),
    ),
    email: z
      .string()
      .min(1, { message: 'Please enter your company email.' })
      .email({ message: 'Invalid email address.' })
      .refine((email) => validateEmail(email), {
        message:
          'This email is not invited. Ensure you’re using your company email and that it has been invited. Or contact support@threeflow.com.',
      }),
  }),
);

watch(
  () => registrationForm,
  (newValues, oldValues) => {
    if (newValues.email !== oldValues.email) {
      emit('setUserEmail', newValues.email);
      emit('update:email', newValues.email);
      notInvited.value = false;

      if (customError.value) {
        customError.value = null;
      }
    }

    if (newValues.firstName !== oldValues.firstName) {
      emit('update:firstName', newValues.firstName);
    }

    if (newValues.lastName !== oldValues.lastName) {
      emit('update:lastName', newValues.lastName);
    }

    if (newValues.role !== oldValues.role) {
      emit('update:role', newValues.role);
    }

    if (newValues.password !== oldValues.password) {
      emit('update:password', newValues.password);
    }
  },
  { deep: true },
);

onMounted(() => {
  // Persist email across login/forgot password/registration forms
  registrationForm.email = route.query.email || props.userInfo?.email;
});

/**
 * Validate the registrationForm and call the accountService/registerUser
 * method.
 */
function submitRegistration() {
  if (!props.accountService?.registerUser) {
    return;
  }

  const data = {
    firstName: registrationForm.firstName,
    lastName: registrationForm.lastName,
    department: registrationForm.role,
    email: registrationForm.email,
    password: registrationForm.password,
    projectId: props.projectId,
  };

  if (props.documentId) {
    data.documentId = props.documentId;
  }

  props.accountService
    .registerUser(data)
    .then(() => {
      router.push(props.linkRegisterConfirmation);
    })
    .catch(({ response }) => {
      const { code, message } = response.data;
      let customErrorType = 'generic';

      if (code === 'INVALID_PARAMS') {
        if (message === 'Email has already been taken') {
          customErrorType = 'emailTaken';
        } else if (message === 'Email is invalid') {
          customErrorType = 'notValid';
        }
      }

      customError.value = customErrorType;
    });
}
</script>

<template>
  <Form
    v-slot="$form"
    :initial-values="registrationForm"
    :resolver="formValidationResolver"
    name="register-form"
    label-position="top"
    class="auth-form register"
    validate-on-value-update
    validate-on-blur
    @submit="submitRegistration"
  >
    <AuthPageHeader title="Let’s get started by creating an account." />
    <p v-if="showProjectInfo">
      <span id="employer-info">
        When your {{ employerName }} proposal is underwritten, you’ll submit
        your quote here </span
      ><br />
      <span id="carrier-info">
        <strong>Heads up:</strong>
        <!-- eslint-disable-next-line max-len -->
        {{ submittedCarriers }} out of {{ invitedCarriers }} carriers invited
        have submitted their quotes.
      </span>
    </p>
    <div class="inputs-container">
      <FormField
        name="firstName"
        class="first-name-container flex flex-col gap-2"
        data-test="first name form item"
      >
        <label for="first-name">First name</label>
        <InputText
          id="first-name"
          v-model="registrationForm.firstName"
          data-test="edit first name"
          placeholder="Enter First Name"
          fluid
          @input="$emit('setUserInfo', { first_name: $event })"
        />
        <Message
          v-if="$form.firstName?.invalid"
          severity="error"
          size="small"
          variant="simple"
        >
          {{ $form.firstName.error?.message }}
        </Message>
      </FormField>

      <FormField
        name="lastName"
        class="last-name-container flex flex-col gap-2"
        data-test="last name form item"
      >
        <label for="last-name">Last name</label>
        <InputText
          id="last-name"
          v-model="registrationForm.lastName"
          data-test="edit last name"
          placeholder="Enter Last Name"
          fluid
          @input="$emit('setUserInfo', { last_name: $event })"
        />
        <Message
          v-if="$form.lastName?.invalid"
          severity="error"
          size="small"
          variant="simple"
        >
          {{ $form.lastName?.error?.message }}
        </Message>
      </FormField>

      <FormField
        name="role"
        class="role-container flex flex-col gap-2"
        data-test="role form item"
      >
        <label for="role-input">Role</label>
        <Select
          id="role-input"
          v-model="registrationForm.role"
          placeholder="Select a Role"
          data-test="edit role"
          :options="roles"
          fluid
          @input="$emit('setUserInfo', { role: $event })"
        />
        <Message
          v-if="$form.role?.invalid"
          severity="error"
          size="small"
          variant="simple"
        >
          {{ $form.role?.error?.message }}
        </Message>
      </FormField>

      <FormField
        :class="{ 'is-error': customError }"
        name="email"
        class="email-container flex flex-col gap-2"
        data-test="email form item"
      >
        <label for="email-input">Company email</label>
        <InputText
          id="email-input"
          v-model="registrationForm.email"
          placeholder="Enter email"
          data-test="edit email"
          fluid
        />
        <Message
          v-if="$form.email?.invalid"
          severity="error"
          size="small"
          variant="simple"
        >
          {{ $form.email?.error?.message }}
        </Message>
        <Message
          v-if="customError"
          severity="error"
          size="small"
          variant="simple"
        >
          <template v-if="customError === 'emailTaken'">
            Email has already been taken.
            <RouterLink :to="linkForgot"> Reset your password</RouterLink>
            .
          </template>
          <template v-else-if="customError === 'notValid'">
            Your company domain isn’t in our system. Make sure you typed your
            email address correctly.
          </template>
          <template v-else>
            There was a problem processing your request.</template
          >
        </Message>
      </FormField>
      <AuthPassword v-model:password="registrationForm.password" />
      <slot name="ctaSubmit">
        <!-- Since AuthPassword also uses FormField components, its fields are automatically connected to the main form.
             If any of its fields are invalid, the entire form is considered invalid as well. -->
        <Button
          :disabled="!$form.valid"
          name="submit-button"
          type="submit"
          label="Create account"
          data-test="submit account"
          severity="help"
          variant="outlined"
          fluid
        />
      </slot>
    </div>
    <p class="text-center">
      <RouterLink :to="linkLogin"> I already have an account.</RouterLink>
    </p>
    <AuthPageFooter />
  </Form>
</template>

<style lang="scss" scoped>
.password-rules {
  list-style-type: disc;
  padding-left: 20px;
  margin-top: 10px;

  li {
    margin-bottom: 5px;
  }
}
</style>
