<template>
  <div class="comments-tab">
    <CommentsContainer />
  </div>
</template>

<script setup>
import { onBeforeUnmount } from 'vue';
import { storeToRefs } from 'pinia';
import { useCommentsStore } from '@/stores/comments.js';
import { useProjectStore } from '@/stores/project.js';

import CommentsContainer from '@/components/Comments/index.vue';

/**
 * RFP Overview Comments
 *
 * @exports ProjectOverviewComments
 */
defineOptions({
  name: 'ProjectOverviewComments',
});

// Stores
const commentsStore = useCommentsStore();
const projectStore = useProjectStore();
const { clearCommentNotifications, setShowNewBadge } = commentsStore;
// Refs
const { unreadComments } = storeToRefs(commentsStore);
const { projectId } = storeToRefs(projectStore);

/** Clear comment notifications and new badges */
onBeforeUnmount(() => {
  if (unreadComments.value) {
    clearCommentNotifications(projectId.value);
    setShowNewBadge();
  }
});
</script>

<style lang="scss" scoped>
section {
  padding: 0 20px;
}
</style>
