<template>
  <div class="pdf-export-generator">
    <Button
      :disabled="isDisabled"
      :loading="renderingPdf"
      :size="buttonSize"
      :label="buttonTitle"
      data-test="export pdf"
      variant="text"
      severity="primary"
      icon="fa-solid fa-file-arrow-down"
      icon-pos="right"
      @click="startRender"
    />
    <ExportFile v-if="render" :filename="filename" @save="stopRender">
      <slot />
    </ExportFile>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import Button from 'primevue/button';
import ExportFile from './ExportFile.vue';

/**
 * PDF Export Component
 *
 * @exports TfPdfExport
 */

defineOptions({
  name: 'TfPdfExport',
});

defineProps({
  buttonSize: {
    type: String,
    default: 'text-icon',
  },
  buttonTitle: {
    type: String,
    default: 'export',
  },
  filename: {
    type: String,
    default: 'export.pdf',
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
});

// Reactive state
const render = ref(false);
const renderingPdf = ref(false);
/** Starts the render process. */
const startRender = () => {
  renderingPdf.value = true;
  render.value = true;
};
/** Stops the render process. */
const stopRender = () => {
  render.value = false;
  renderingPdf.value = false;
};
</script>
