<template>
  <Dialog
    :header="modalTitle"
    :closable="false"
    :visible="visible"
    modal
    data-test="products unsupported by dive and threeflow assist modal"
    style="width: 450px"
    @update:visible="closeDialog"
  >
    <p>
      {{ formatArrayIntoListOfStrings(tfAssistAndDiveUnsupportedProductTypes) }}
      not currently supported by Smart Proposals, so you need to enter rates and
      plan design manually.
    </p>
    <div class="flex justify-end">
      <Button
        data-test="close products unsupported by dive and threeflow assist modal"
        size="large"
        label="Done"
        severity="primary"
        @click="closeDialog"
      />
    </div>
  </Dialog>
</template>

<script setup>
import { computed } from 'vue';
import { formatArrayIntoListOfStrings } from '@/utils/general.js';

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

/**
 * Modal to alert the user that there is a product that is unsupported by DIVE
 * and ThreeFlow Assist in the current project
 *
 * @exports ThreeFlowAssistAndDiveUnsupportedModal
 */
defineOptions({
  name: 'ThreeFlowAssistAndDiveUnsupportedModal',
});

const props = defineProps({
  /**
   * Whether the modal is visible
   *
   * @type {boolean}
   */
  visible: { type: Boolean, default: false },

  /**
   * Array of unique product type strings that are unsupported by DIVE and
   * ThreeFlow Assist
   *
   * @type {Array}
   */
  tfAssistAndDiveUnsupportedProductTypes: { type: Array, required: true },
});
const emit = defineEmits(['update:visible']);
/** Computed modal title */
const modalTitle = computed(
  () =>
    `${
      props.tfAssistAndDiveUnsupportedProductTypes.length === 1
        ? props.tfAssistAndDiveUnsupportedProductTypes[0]
        : 'These Products'
    } Need${props.tfAssistAndDiveUnsupportedProductTypes.length === 1 ? 's' : ''} Your Help`,
);
/** Closes the modal */
const closeDialog = () => {
  emit('update:visible', false);
};
</script>
