<template>
  <div v-loading="[!isLoaded]" class="market-activity-tab">
    <section v-if="isLoaded" class="market-activity-table-container">
      <h3>Market activity</h3>
      <table>
        <thead>
          <tr>
            <th
              v-for="key in Object.keys(tableData)"
              :key="key"
              :class="key"
              :data-test="`${key} column header`"
              v-text="capitalize(key)"
            />
          </tr>
        </thead>
        <tbody data-test="market activity table">
          <tr v-for="(row, index) in totalRows" :key="index">
            <td>{{ tableData.submitted[index] }}</td>
            <td>{{ tableData.pending[index] }}</td>
            <td>{{ tableData.declined[index] }}</td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script setup>
import { computed, ref, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectStore } from '@/stores/project.js';
import { useProductTableStore } from '@/stores/productTable.js';
import ServiceDocument from '@/services/documents.js';
import capitalize from 'lodash/capitalize.js';
import { useRfpOverview } from '@/composables/useRfpOverview.js';

const projectStore = useProjectStore();
const productTableStore = useProductTableStore();
const { brokerHierarchy, documents } = storeToRefs(projectStore);
const { projectStatusFromProducts } = productTableStore;
const isLoaded = ref(false);
const tableData = ref({
  submitted: [],
  pending: [],
  declined: [],
});
const { hideMarketTab } = useRfpOverview();
/**
 * Evaluates the tableData data lengths to determine what the max number is.
 *
 * @returns {number}
 */
const totalRows = computed(() =>
  Math.max(
    tableData.value.submitted.length,
    tableData.value.pending.length,
    tableData.value.declined.length,
  ),
);

watch(
  () => brokerHierarchy.value.hasHierarchy,
  (hasHierarchy) => {
    if (hasHierarchy !== undefined && !hideMarketTab.value) {
      isLoaded.value = true;
    }
  },
  { immediate: true },
);

onMounted(async () => {
  const getFullDocuments = documents.value.map((document) =>
    ServiceDocument.getDocumentInfo(document.id),
  );
  const documentsData = await Promise.all(getFullDocuments);
  const inforceCarriers = [];

  documentsData.forEach(({ document }) => {
    if (document.document_type.toLowerCase() !== 'policy') {
      return;
    }
    inforceCarriers.push(
      ...new Set(
        document.products.map(
          (product) => product.project_product.inforce_carrier_id,
        ),
      ),
    );
  });

  documentsData.forEach(({ document }) => {
    if (document.document_type.toLowerCase() !== 'proposal') {
      return;
    }

    const status = projectStatusFromProducts(document.products);
    let carrierName = document.carrier.name;

    if (inforceCarriers.includes(document.carrier.id)) {
      carrierName += ' (in-force)';
    }

    // Possible Statuses: Not Started, Incomplete, Selected, Declined, Submitted
    if (['Selected', 'Submitted'].includes(status.badge)) {
      tableData.value.submitted.push(carrierName);
    } else if (status.badge === 'Declined') {
      tableData.value.declined.push(carrierName);
    } else {
      tableData.value.pending.push(carrierName);
    }
  });
});
</script>

<style lang="scss" scoped>
section {
  padding: 0 20px;
}

h3 {
  margin-bottom: 23px;
}

table {
  width: 100%;
  font-size: 14px;
  border-spacing: 8px 0;
  border-collapse: separate;
}

th,
td {
  padding: 7px;
  border-bottom: 1px solid #acacb7;
}

th {
  width: 33.33%;
  font-weight: 600;
}

.submitted {
  background-color: #caf1d8;
}

.pending {
  background-color: #faedc4;
}

.declined {
  background-color: #ffd0ce;
}
</style>
