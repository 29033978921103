import axios from 'axios';
import { config } from '@/utils/config.js';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';

const normalizedEndpoint = `${config.VUE_APP_API_URL}/v1/carrier_portal`;
const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
const axiosConfig = () => getAxiosConfig(cookieNamespace);

/**
 * See if there are any notifications for unread comments
 *
 * @param {number} projectId
 * @returns {Promise}
 */
export const getCommentsNotifications = (projectId) => {
  const url = `${normalizedEndpoint}/notifications/unread_comments/${projectId}`;

  return new Promise((resolve, reject) => {
    axios
      .get(url, axiosConfig())
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * Get notifications for unread quote edits
 *
 * @param {number} projectId
 * @returns {Promise}
 */
export const getEditsNotifications = (projectId) => {
  const url = `${normalizedEndpoint}/notifications/unread_edits/${projectId}`;

  return new Promise((resolve, reject) => {
    axios
      .get(url, axiosConfig())
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * Clear out the notifications for unread comments
 *
 * @param {number} projectId
 * @returns {Promise}
 */
export const clearCommentNotifications = (projectId) => {
  const url = `${normalizedEndpoint}/notifications/mark_all_comments_as_read`;

  return new Promise((resolve, reject) => {
    const data = {
      project_id: projectId,
    };

    axios
      .post(url, data, axiosConfig())
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * Clear out the notifications for unread quote edits
 *
 * @param {number} projectId
 * @returns {Promise}
 */
export const clearEditNotifications = (projectId) => {
  const url = `${normalizedEndpoint}/notifications/mark_all_edits_as_read`;

  return new Promise((resolve, reject) => {
    const data = {
      project_id: projectId,
    };

    axios
      .post(url, data, axiosConfig())
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
