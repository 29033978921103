<script setup>
/**
 * Sign in
 *
 * @exports SignIn
 */
import {
  ref,
  computed,
  inject,
  watch,
  onMounted,
  onBeforeUnmount,
  h,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { loadScript, unloadScript } from 'vue-plugin-load-script';

import Button from 'primevue/button';
import Message from 'primevue/message';
import AppSignIn from '@/shared/components/AuthPages/AppSignIn.vue';

import { useAccountStore } from '@/stores/account.js';
import { segmentData } from '@/utils/analytics.js';
import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';
import AccountService from '@/services/account.js';
import { workOsCarrierUi } from '@/utils/featureFlags.js';
import { useToast } from 'primevue/usetoast';

defineOptions({
  name: 'UserSignIn',
});

const props = defineProps({
  projectId: {
    type: [String, Number],
    default: null,
  },
});
const router = useRouter();
const route = useRoute();
const accountStore = useAccountStore();
const $ld = inject('$ld');
const toast = useToast();
const googleOauthIsLoaded = ref(false);
const googleOauthClient = ref(null);
const googleOauthCode = ref(null);
const provider = ref(null);
const debounceTimer = ref(0);
const email = ref('');
const isAuthorizing = ref(false);
const password = ref('');
const rememberMe = ref(false);
const authorizationUrl = ref(null);
const signInProps = computed(() => {
  const newProps = {
    linkForgot: { name: 'ForgotPassword' },
    linkRedirect: { name: 'Dashboard' },
    linkRegister: { name: 'RegistrationForm' },
  };

  if (props.projectId) {
    Object.keys(newProps).forEach((prop) => {
      newProps[prop] = {
        name: `Project${newProps[prop].name}`,
        params: { projectId: props.projectId },
      };
    });

    newProps.linkRedirect.name = 'RfpOverview';
    newProps.projectId = props.projectId;
  }

  return newProps;
});
/**
 * Determines if the WorkOS SSO method is enabled.
 *
 * @returns {boolean}
 */
const workOsCarrierUiEnabled = computed(() => $ld.checkFlags(workOsCarrierUi));

/** Redirect to authorizationUrl. */
function redirectToSSO() {
  window.location.assign(authorizationUrl.value);
}

/**
 * Sign in the user and redirect them to the appropriate dashboard or redirect
 * query.
 */
async function signInUser() {
  isAuthorizing.value = true;

  try {
    if (provider.value === 'google_oauth2') {
      await AccountService.signInWithGoogle(googleOauthCode.value);
    } else {
      await AccountService.authenticate(
        {
          email: email.value,
          password: password.value,
          project_id: props.projectId,
        },
        rememberMe.value,
      );
    }

    const { redirect } = route.query;
    const { projectId } = route.params;

    if (redirect && !['/sign-out', '/sign-in'].includes(redirect)) {
      await router.replace(redirect);

      return;
    }
    if (projectId) {
      await router.replace({ name: 'RfpOverview', params: { projectId } });

      return;
    }

    await router.replace(signInProps.value.linkRedirect);
  } catch (error) {
    toast.add({
      styleClass: 'sign-in-message',
      life: 10000,
      summary: 'Sign In Error',
      detail:
        error?.response?.data?.message ||
        'There was an error signing in. Please try again.',
      closable: true,
      severity: 'error',
    });
  } finally {
    isAuthorizing.value = false;
  }
}

/** Unload Google OAuth script before component is destroyed. */
function unloadGoogleOauth() {
  unloadScript('https://accounts.google.com/gsi/client');
  googleOauthIsLoaded.value = false;
}

/** Trigger popup for Google OAuth sign-in. */
function openGoogleOauthPopup() {
  googleOauthClient.value.requestCode();
}

/**
 * Handle Google OAuth response
 *
 * @param {object} response
 */
function handleGoogleOauthResponse(response) {
  if (response && !response.error) {
    googleOauthCode.value = response.code;
    signInUser();
  } else {
    toast.add({
      styleClass: 'sign-in-message',
      life: 10000,
      summary: 'Sign In Error',
      detail:
        'An issue occurred while trying to sign in with Google. Please try again or contact support.',
      closable: true,
      severity: 'error',
    });
  }
}

/** Display inactivity message if the user is signed out due to inactivity. */
function showInactivityMessage() {
  const detail = h('p', { attrs: { class: 'paragraph-message' } }, [
    h('span', null, 'You have been signed out due to inactivity,'),
    h('br', null, null),
    h('span', null, "or because you've signed in on another device."),
    h('br', null, null),
    h('span', null, 'All your changes are safe and sound.'),
  ]);

  toast.add({
    closable: true,
    detail,
    styleClass: 'large warning',
    severity: 'warning',
  });
}

/** Load Google OAuth script on-demand and initialize OAuth component. */
async function loadGoogleOauth() {
  try {
    await loadScript('https://accounts.google.com/gsi/client');

    googleOauthClient.value = window.google.accounts.oauth2.initCodeClient({
      client_id: config.VITE_APP_OAUTH_CLIENT_ID,
      scope: 'email profile',
      ux_mode: 'popup',
      callback: handleGoogleOauthResponse,
    });

    googleOauthIsLoaded.value = true;
  } catch {
    toast.add({
      styleClass: 'sign-in-message',
      life: 10000,
      message:
        'An issue occurred while trying to load Sign in with Google functionality. Please reload the page or contact support.',
      closable: true,
      severity: 'error',
    });
  }
}

/**
 * Debounced check for user provider.
 *
 * @param {string} newEmail
 */
function checkUserProvider(newEmail) {
  clearTimeout(debounceTimer.value);

  debounceTimer.value = setTimeout(async () => {
    const { provider: userProvider, authorization_url: auth } =
      await AccountService.getUserProvider(
        newEmail,
        workOsCarrierUiEnabled.value,
      );

    if (workOsCarrierUiEnabled.value && auth) {
      authorizationUrl.value = auth;
    } else {
      provider.value = userProvider;
    }

    if (userProvider === 'google_oauth2' && !googleOauthIsLoaded.value) {
      loadGoogleOauth();
    }
  }, 500);
}

/**
 * Watch email for value changes on the email address and process these in a
 * debounced fashion.
 *
 * @param {string} newValue
 */
watch(email, (newValue) => {
  checkUserProvider(newValue);
});

/**
 * Watches inactivity state and displays a message when inactive.
 *
 * @param {boolean} inactivity
 */
watch(
  () => accountStore.auth?.inactive,
  (inactivity) => {
    if (inactivity) {
      showInactivityMessage();
    }
  },
);

onMounted(() => {
  const { error } = route.query;

  if (error === 'auth') {
    toast.add({
      message: 'Failed to authenticate. Please try again, or contact support.',
      closable: true,
      severity: 'error',
    });
  }

  if (accountStore.auth?.inactive) {
    showInactivityMessage();
  }

  if (accountStore.auth.tempPasswordSent) {
    toast.add({
      message:
        'If the email you entered is associated with an account, you’ll receive a password reset link shortly.',
      closable: true,
      severity: 'success',
    });
    accountStore.setTempPasswordStatus(false);
  }
});

onBeforeUnmount(() => {
  toast.removeAllGroups();

  if (accountStore.auth?.confirmed) {
    trackSegmentEvent('Carrier User Sign In Successful', segmentData());
  }

  if (googleOauthIsLoaded.value) {
    unloadGoogleOauth();
  }
});
</script>

<template>
  <AppSignIn
    v-bind="{
      ...signInProps,
      userInfo: { email },
    }"
    v-model:email="email"
    v-model:password="password"
    v-model:remember-me="rememberMe"
    :show-sso-method="!!provider || !!authorizationUrl"
  >
    <template #ctaSubmit>
      <Button
        :disabled="!email || !password"
        :loading="isAuthorizing"
        data-test="submit sign in"
        type="submit"
        label="Sign in"
        severity="help"
        variant="outlined"
        fluid
        @click="signInUser"
      />
    </template>
    <template #ctaSsoSubmit>
      <Button
        v-if="provider === 'google_oauth2'"
        :loading="isAuthorizing"
        data-test="sign in with google"
        type="submit"
        label="Sign in with Google"
        severity="help"
        variant="outlined"
        fluid
        @click.prevent="openGoogleOauthPopup"
      />
      <Button
        v-else-if="authorizationUrl"
        :loading="isAuthorizing"
        data-test="sign in with sso"
        native-type="submit"
        label="Sign in with SSO"
        severity="help"
        variant="outlined"
        fluid
        @click.prevent="redirectToSSO"
      />
      <Message
        v-else-if="provider === 'saml'"
        closable
        severity="error"
        data-test="SAML SSO error"
        show-icon
      >
        To access ThreeFlow, you must sign in using your organization's SSO
        solution. If you continue to have issues, contact your workspace
        administrator or IT department.
      </Message>
      <Message
        v-else
        closable
        severity="error"
        data-test="generic SSO error"
        show-icon
      >
        An error occurred showing the SSO options for your specific account.
        Please contact ThreeFlow support.
      </Message>
    </template>
  </AppSignIn>
</template>
