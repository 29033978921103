<template>
  <div class="container-attributes">
    <TableHeader :readonly="route.meta.readonly">
      <template #title>
        {{ rateAttribute.name }}
      </template>
      <!-- Edit Plan Design Slot (when reviewing) -->
      <template #btnEdit>
        <RouterLink :to="{ name: 'RateEntry' }">
          <Button icon="fa-solid fa-pencil" variant="link" label="Edit rates" />
        </RouterLink>
      </template>
      <!-- Rate Options/Rate Basis -->
      <template #formElements>
        <!-- Layout of Rate Entry page -->
        <Form class="attribute-form">
          <div class="attribute-form-fields">
            <FormField v-if="showPlanTypeSelect">
              <div>Plan type:</div>
              <Select
                v-model="selectedTierGroupId"
                :options="props.container.tierGroups"
                option-label="name"
                option-value="id"
                :disabled="route.meta.readonly"
                placeholder="Select plan type"
                class="form-field"
                data-test="select plan type"
              />
              <p
                v-if="errorPlanType"
                class="text-error"
                v-text="errorPlanType"
              />
            </FormField>
            <FormField>
              <div>Rate options:</div>
              <Select
                v-model="attributeType"
                :options="rateOptions"
                option-label="label"
                option-value="value"
                :loading="readonly || rateEntryStore.drainingUpdateRequests"
                placeholder="Select rate option"
                class="form-field"
                data-test="rate-option-select"
              />
            </FormField>
            <FormField>
              <div>
                {{ `Rate basis${rateBasisOptions.length === 1 ? ':' : ''}` }}
              </div>
              <Select
                v-if="rateBasisOptions.length > 1"
                v-model="rateBasis"
                :options="rateBasisOptions"
                option-label="label"
                option-value="value"
                :disabled="readonly"
                placeholder="Select rate basis"
                class="form-field"
              />
              <span v-else>
                {{ rateBasis }}
              </span>
            </FormField>
          </div>
          <Button
            v-if="!productStore.isNewCoverage"
            :disabled="route.meta.readonly"
            data-test="copy inforce"
            icon="fa-sharp fa-files"
            label="Copy in-force rates"
            severity="secondary"
            @click="copyFromInforce"
          />
        </Form>
      </template>
    </TableHeader>
    <div v-if="!productStore.tierGroupsIsLoading" class="attributes-tables">
      <!-- Incumbent table -->
      <RateEntryTable
        v-if="policyValues.tier_group && !productStore.isNewCoverage"
        is-policy-table
        v-bind="{
          attributeType: attributeInfo.attributeType,
          incumbent: true,
          rateAttributeIndex,
          tierGroup: policyValues.tier_group,
        }"
      />
      <!-- Proposal/Renewal table -->
      <RateEntryTable
        v-bind="{
          attributeType: attributeInfo.attributeType,
          rateAttributeIndex,
          tierGroup: proposalValues.tier_group,
        }"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, provide, inject } from 'vue';
import { useRoute } from 'vue-router';
import { useRateEntryStore } from '@/stores/rateEntry.js';
import { useProductStore } from '@/stores/product.js';
import { reusableRateBasisOptions } from '@watchtowerbenefits/es-utils-public';
import { useToast } from 'primevue/usetoast';
import { Button, Select } from 'primevue';
import { Form, FormField } from '@primevue/forms';
import TableHeader from '@/components/DataEntry/TableHeader.vue';
import RateEntryTable from './RateEntryTable.vue';

/**
 * Rate Entry Attribute
 *
 * @exports RateEntryAttribute
 */
const route = useRoute();
// Stores
const rateEntryStore = useRateEntryStore();
const productStore = useProductStore();
// Inject
const toast = useToast();
const containerIds = inject('containerIds');
const isPlan = inject('isPlan');
// Props
const props = defineProps({
  /** Rate Entry Attribute containing the ID, name, and incumbent/proposal values */
  rateAttribute: {
    type: Object,
    required: true,
  },
  rateAttributeIndex: {
    type: Number,
    default: 0,
  },
  container: {
    type: Object,
    required: true,
  },
});
// Variables
const errorPlanType = ref(null);
/**
 * Show the age-banded value option only for dental, dental 2, vision, and stop
 * loss products
 *
 * @returns {boolean}
 */
const showAgeBandedValueOption = computed(
  () =>
    !/dental|vision|stop loss/i.test(
      productStore.currentProduct.product_type_name,
    ),
);
/**
 * StoreAttributeId is passed down to children so they can point to the
 * appropriate rateAttribute in the store
 *
 * @returns {string}
 */
const storeAttributeId = computed(
  () => `${containerIds.value.join('_')}_${props.rateAttribute.id}`,
);
/**
 * Attribute Information object from the store used for `attributeType` and
 * `rateBasis`
 *
 * @returns {object}
 */
const attributeInfo = computed(
  () => rateEntryStore.rateAttributes[storeAttributeId.value] || {},
);
const updatingRateAttributes = ref(false);
const rateOptions = ref([
  {
    value: 'CompositeRateValue',
    label: 'Composite',
  },
  ...[
    {
      value: 'AgeBandedRateValue',
      label: 'Age banded',
    },
    {
      value: 'CustomRateValue',
      label: 'Custom',
    },
  ].filter((option) => {
    if (
      option.value === 'AgeBandedRateValue' &&
      showAgeBandedValueOption.value
    ) {
      return true;
    }
    if (
      option.value === 'CustomRateValue' &&
      attributeInfo.value.policyAttributeType === 'CustomRateValue'
    ) {
      return true;
    }

    return false;
  }),
  ...productStore.rateTierGroups.map(
    ({ tier_group_name: name, tier_group_id: id }) => ({
      label: name,
      value: id,
    }),
  ),
]);
// Computed
/**
 * Show the plan type select control
 *
 * @returns {boolean}
 */
const showPlanTypeSelect = computed(
  () =>
    productStore.currentProduct &&
    /dental/i.test(productStore.currentProduct.product_type_name),
);
const selectedTierGroupId = computed({
  /**
   * The Tier Group ID for Low Plans
   *
   * @returns {number}
   */
  get() {
    return props.container.tierGroupId;
  },
  /**
   * Sets the actionName on new value
   *
   * @param {string | number} value
   */
  set(value) {
    // Reset any error messaging
    errorPlanType.value = null;
    try {
      rateEntryStore.patchRateEntryPlanValueTierGroup({
        containerId: props.container.id,
        productId: productStore.productId,
        tierGroupId: value,
      });
    } catch (error) {
      // There is no status text coming from the backend on this so putting this in for now.
      errorPlanType.value =
        error.status === 500
          ? 'Your request did not go through. Please select a new plan type to try again.'
          : error.statusText;
    }
  },
});
/**
 * Policy values used for the incumbent tables
 *
 * @returns {object}
 */
const policyValues = computed(() =>
  props.rateAttribute.products.find(
    (product) => product.document_type.toLowerCase() === 'policy',
  ),
);
/**
 * Proposal/renewal values used for the right tables
 *
 * @returns {object}
 */
const proposalValues = computed(() =>
  props.rateAttribute.products.find(
    (product) => product.document_type.toLowerCase() !== 'policy',
  ),
);
/**
 * Get the available Rate Basis Options based on the product type
 *
 * @returns {Array}
 */
const rateBasisOptions = computed(() =>
  reusableRateBasisOptions(rateEntryStore.info.product_type_id),
);
/**
 * Rate options select model Get: gets the attributeType from the store Set:
 * Construct the new attribute type and call patch to update all the subtypes
 * then sync from the API
 */
const attributeType = computed({
  get() {
    return (
      attributeInfo.value.originalTierGroupId ||
      attributeInfo.value.attributeType
    );
  },
  async set(newValue) {
    if (
      newValue ===
        rateEntryStore.rateAttributes[storeAttributeId.value].attributeType ||
      rateEntryStore.drainingUpdateRequests
    ) {
      return;
    }

    if (rateEntryStore.rateUpdateRequests.length) {
      rateEntryStore.rateUpdateRequests.splice(
        0,
        rateEntryStore.rateUpdateRequests.length,
      );
    }

    const type = Number.isNaN(Number(newValue))
      ? newValue
      : Object.values(
          rateEntryStore.rateAttributes[storeAttributeId.value].rateValues,
        )[0].type;

    await rateEntryStore.updateTierGroup({
      productId: productStore.productId,
      rateAttribute: {
        ...rateEntryStore.rateAttributes[storeAttributeId.value],
        tierGroupId: Number.isNaN(Number(newValue)) ? null : newValue,
        attributeType: type,
      },
    });
    rateEntryStore.rateErrors = [];
    updatingRateAttributes.value = false;
  },
});
/**
 * Rate basis select model GET: get the policy rate basis SET: update all the
 * subtypes with the new rate basis
 */
const rateBasis = computed({
  get() {
    let { rateBasis: basis } = attributeInfo.value;

    if (rateBasisOptions.value.length === 1) {
      basis = isPlan.value
        ? policyValues.value.tier_group.tier_subtypes[0].rate_value
            .formatted_rate_basis
        : policyValues.value.tier_group.tier_subtypes[0].rate_values[0]
            .formatted_rate_basis;
    }

    return basis;
  },
  async set(value) {
    if (
      !value ||
      value === rateEntryStore.rateAttributes[storeAttributeId.value].rateBasis
    ) {
      return;
    }

    updatingRateAttributes.value = true;
    let subtypes = [];

    if (attributeInfo.value.tierGroupId) {
      const tierGroup = productStore.rateTierGroups.find(
        ({ tier_group_id: id }) => id === attributeInfo.value.tierGroupId,
      );

      subtypes = tierGroup.tier_subtypes;
    } else {
      subtypes.push({ subtype_id: attributeType.value });
    }

    // Patch all the subtypes
    await Promise.all(
      subtypes.map(async (subtype) =>
        rateEntryStore.updateRateAttribute({
          productId: productStore.productId,
          storeAttributeId: storeAttributeId.value,
          subtypeId: subtype.subtype_id,
          rateBasis: value,
          rateValues:
            rateEntryStore.rateAttributes[storeAttributeId.value].rateValues,
        }),
      ),
    );

    // Re-up the rate entry data
    await rateEntryStore.getRateEntry({ productId: productStore.productId });

    rateEntryStore.rateErrors = [];
    updatingRateAttributes.value = false;
  },
});
/**
 * Boolean to determine the state of the tables (review or edit)
 *
 * @returns {boolean}
 */
const readonly = computed(() => route.meta.readonly);
// Methods
/**
 * Dispatch an action to the store to hit the server and copy over the inforce
 * rates (then update the store with new rates)
 */
const copyFromInforce = async () => {
  try {
    await rateEntryStore.copyInforceRate(
      productStore.productId,
      containerIds.value,
      productStore.productState,
    );
  } catch {
    toast.add({
      detail: 'Error copying inforce rates',
      severity: 'error',
    });
  }
};

// Provide
/**
 * Sends reactive storeAttributeId and containerId
 *
 * @returns {object}
 */
provide('storeAttributeId', storeAttributeId);
</script>

<style lang="scss" scoped>
.attributes-tables {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin: 0 30px;

  .dialog-plan-summary & {
    margin: 0;
  }
}

.container-attributes {
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid var(--tf-gray-medium);
}

.attribute-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.attribute-form-fields {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
}

.form-field {
  min-width: 160px;
}
</style>
