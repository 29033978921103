<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import Breadcrumb from 'primevue/breadcrumb';

const router = useRoute();
const home = computed(() => {
  if (router.meta.breadcrumbs?.length) return router.meta.breadcrumbs[0];

  return { icon: 'pi pi-home', to: '/' };
});
const items = computed(() => {
  if (router.meta.breadcrumbs?.length)
    return [
      ...router.meta.breadcrumbs.slice(1),
      {
        text: router.meta.title,
      },
    ];

  return [
    {
      text: router.meta.title,
    },
  ];
});
</script>

<template>
  <div class="breadcrumbs">
    <Breadcrumb :home="home" :model="items">
      <template #item="{ item }">
        <router-link v-if="item.to" :to="item.to">
          {{ item.text }}
        </router-link>
        <span v-else>{{ item.text }}</span>
      </template>
    </Breadcrumb>
  </div>
</template>

<style scoped>
.breadcrumbs {
  padding-bottom: 1rem;
}
</style>
