<template>
  <div class="top-comment">
    <CommentInput
      v-model="message"
      :api-config="apiConfig"
      :auto-save-timestamp="timeStamp"
      :project-id="projectId"
      :proposal-document-id="proposalDocumentId"
      :user-info="commentUserInfo"
      application-namespace="carrier_portal"
      custom-session-name="commentTopLevel"
      data-test="comment field"
      @commentAdded="onCommentAdded"
      @cancel="$emit('close')"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useCommentsStore } from '@/stores/comments.js';
import { useProjectStore } from '@/stores/project.js';
import { useAccountStore } from '@/stores/account.js';
import { segmentData } from '@/utils/analytics.js';
import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

import CommentInput from '@/components/Comment/Input.vue';

/**
 * Comments TopComment
 *
 * @exports TopComment
 */

defineOptions({
  name: 'TopComment',
});

const props = defineProps({
  autosaveData: {
    type: Object,
    default: () => ({
      message: null,
      timestamp: null,
    }),
  },
});
const emit = defineEmits(['close']);
const apiConfig = {
  baseUrl: config.VUE_APP_API_URL,
  cookieNamespace: config.VUE_APP_COOKIE_NAMESPACE,
};
// Stores
const commentsStore = useCommentsStore();
const projectStore = useProjectStore();
const accountStore = useAccountStore();
// Refs
const { projectId, proposalDocumentId } = storeToRefs(projectStore);
const { commentUserInfo } = storeToRefs(accountStore);
const message = ref(null);
const timeStamp = ref(null);

/**
 * Adds the comment to the store and emits to close.
 *
 * @param {Array} comments
 */
function onCommentAdded(comments) {
  commentsStore.addComment(comments[0]);
  emit('close');
  trackSegmentEvent('Send comment', segmentData());
}

/** Set the message and timestamp from the auto-save data */
onMounted(() => {
  message.value = props.autosaveData.message;

  if (props.autosaveData.timestamp) {
    timeStamp.value = props.autosaveData.timestamp.toString();
  }
});
</script>

<style lang="scss" scoped>
.top-comment {
  .comments-panel & {
    padding-top: 15px;
    padding-left: 5px;
  }
}

p {
  font-weight: bold;
  margin-bottom: 2px;
}

.el-textarea {
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
