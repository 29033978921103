<template>
  <section v-if="projectStore.projectIsLoaded" class="rfp-overview-container">
    <RfpStatus data-test="rfp status" />
    <div class="rfp-overview-tabs-container">
      <Tabs :value="route.name">
        <TabList class="rfp-overview-tabslist">
          <Tab
            v-for="{ name, title, badgeCount } in rfpOverviewTabs"
            :key="name"
            :value="name"
            :class="{
              'full-width-tab-pane': ['MyQuotes', 'RfpInfo'].includes(name),
              'is-updates-tab': name === 'UpdatesEdits',
            }"
            lazy
            @click="onTabClicked(name)"
          >
            {{ title }}
            <sup v-if="badgeCount">
              <Badge :value="badgeCount" severity="danger" />
            </sup>
          </Tab>
        </TabList>
        <TabPanels>
          <RouterView />
        </TabPanels>
      </Tabs>
      <!-- We only want the footer to be stick to the bottom on non-products/rfp-info tabs -->
      <!--      <RfpOverviewFooter v-if="!['MyQuotes', 'RfpInfo'].includes(route.name)" />-->
    </div>
  </section>
</template>

<script setup>
import { watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import Badge from 'primevue/badge';
import { useToast } from 'primevue/usetoast';
import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
import { useProjectStore } from '@/stores/project.js';
import { segmentData } from '@/utils/analytics.js';
import { useProjectComposable } from '@/composables/useProjectComposable.js';
import { useRfpOverview } from '@/composables/useRfpOverview.js';
// import RfpOverviewFooter from '@/components/RfpOverview/RfpFooter.vue'; // TODO: Migrate it in the end of RFP tasks
import RfpStatus from '@/components/RfpOverview/RfpStatus.vue';

defineOptions({
  name: 'RfpOverview',
});

const projectStore = useProjectStore();
const { project } = storeToRefs(projectStore);
const { getAllProjectData, checkInvited } = useProjectComposable();
const { rfpOverviewTabs } = useRfpOverview();
const route = useRoute();
const router = useRouter();
const toast = useToast();

/** Get all project data if project hasn't loaded or is loading */
onMounted(async () => {
  if (projectStore.projectIsLoading || projectStore.projectIsLoaded) return;
  await getAllProjectData(route.params.projectId);
});

/**
 * Send analytics and push to appropriate route name.
 *
 * @param {string | object} tabName - Name of the tab or the tab object
 *   (depending on ff)
 */
const onTabClicked = (tabName) => {
  if (tabName === route.name) return;

  const tabsForAnalytics = [
    'MyQuotes',
    'MarketActivity',
    'RfpInfo',
    'Comments',
  ];

  if (tabsForAnalytics.includes(tabName)) {
    trackSegmentEvent(`${tabName} clicked`, segmentData());
  }

  router.push({ name: tabName });
};
/** If the project is rfp_closed, redirect users with a toast. */
const projectClosedRedirect = () => {
  toast.add({
    closable: true,
    detail:
      'The opportunity you were trying to access is closed and no longer available.',
    severity: 'error',
    life: 5000,
  });
  router.push({ name: 'Dashboard' });
};

/** Watch for changes in project state. */
watch(
  () => project.value?.state,
  (stateValue) => {
    if (!stateValue) return;

    if (project.value.merged_into) {
      router.replace({
        name: 'RfpOverview',
        params: { projectId: project.value.merged_into },
      });
    } else if (stateValue === 'rfp_closed') {
      projectClosedRedirect();
    } else {
      checkInvited();
    }
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.rfp-overview-container {
  padding: 0 60px;
  width: 100%;
}

.rfp-overview-tabs-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;

  .rfp-overview-tabslist {
    margin-bottom: 20px;
  }
}
</style>
