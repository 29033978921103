<script setup>
import { computed, ref, watch } from 'vue';
import {
  Checkbox,
  IconField,
  InputIcon,
  InputText,
  ScrollPanel,
} from 'primevue';

// Emits
defineEmits(['update:modelValue']);
// Props
const props = defineProps({
  filter: {
    type: Object,
    required: true,
  },
  modelValue: {
    type: Array,
    required: true,
  },
});
const selection = ref(props.filter.value);
const search = ref('');
/**
 * Display a subset of options that match the search value, excluding already
 * selected options
 *
 * @returns {Array}
 */
const searchedOptions = computed(() => {
  if (!search.value) {
    return props.filter.options.slice(0, 20);
  }

  const regexStart = RegExp(
    ['^', search.value].join('').replace(/\W| /g, '.*'),
    'i',
  );
  const regexAll = RegExp(
    ['', search.value].join('').replace(/\W| /g, '.*'),
    'i',
  );
  const searchOptions = props.filter.options.filter(
    (option) =>
      regexAll.test(option) &&
      !selection.value.find((value) => value === option),
  );

  /** Rank matches by putting those that BEGIN with the match first */
  return searchOptions
    .sort((a, b) => {
      if (regexStart.exec(a).index === regexStart.exec(b).index) {
        return regexAll.exec(a).index < regexAll.exec(b).index ? -1 : 1;
      }

      return regexStart.exec(a).index < regexStart.exec(b).index ? -1 : 1;
    })
    .slice(0, 20);
});
/**
 * Format the option label by highlighting the part of the result that matches
 * the search
 *
 * @param {string} label
 * @returns {string}
 */
const formatLabel = (label) => {
  const regex = RegExp(
    ['', search.value].join('').replace(/\W| /g, '.*'),
    'id',
  );
  const { indices } = regex.exec(label);

  if (indices[0][0] !== indices[0][1]) {
    let formattedLabel = '';

    label.split('').forEach((letter, index) => {
      if (index === indices[0][0]) {
        formattedLabel += `<b>${letter}`;
      } else if (index === indices[0][1]) {
        formattedLabel += `</b>${letter}`;
      } else {
        formattedLabel += letter;
      }
    });

    return formattedLabel;
  }

  return label;
};

// Update checkbox selection when filter object (modelValue) changes
watch(
  () => props.modelValue,
  (newValue) => {
    selection.value = newValue;
  },
);
</script>

<template>
  <div class="multiselect-filter">
    <div class="multiselect-search">
      <IconField>
        <InputIcon class="fa fa-search" />
        <InputText
          v-model="search"
          :placeholder="`Filter ${filter.label.toLowerCase()}s`"
        />
      </IconField>
    </div>
    <ScrollPanel
      class="scroll-panel"
      :pt="{
        content: {
          style: 'padding-block-end: 0',
        },
      }"
    >
      <template v-for="option in searchedOptions" :key="option">
        <div class="multiselect-option">
          <Checkbox
            v-model="selection"
            class="checkbox"
            :name="filter.key"
            :value="option"
            :input-id="option"
            @change="$emit('update:modelValue', selection)"
          />
          <label :for="option" v-html="formatLabel(option)" />
        </div>
      </template>
    </ScrollPanel>
  </div>
</template>

<style lang="scss" scoped>
.multiselect-filter {
  display: flex;
  flex-direction: column;
}

.multiselect-search {
  margin-bottom: 1rem;
}

.multiselect-option {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.scroll-panel {
  min-height: 10px;
  max-height: 100px;
  overflow-y: auto;
}

.checkbox {
  margin-right: 0.5rem;
}
</style>
