<template>
  <div>
    <h3 data-test="product rate guarantee header" class="inner-header">
      Verify or change product rate guarantees
    </h3>
    <div id="rate-pass-edit">
      <p v-if="isUploadRenewalRatePass">
        Please verify and submit the product rate guarantees or select a new
        value from the list.
      </p>
    </div>
    <div id="rate-guarantee-edit">
      <h3 v-if="!isUploadRenewalRatePass">
        Product rate guarantees - # of months
      </h3>
      <p
        v-if="fetchError"
        data-test="fetch disclaimer"
        :class="{ 'fetch-error': fetchError }"
      >
        There was an error getting rate guarantees, you can still enter them
        manually.
      </p>
    </div>
    <Form id="rate-guarantees">
      <FormField v-for="id in selectedProducts" :key="id">
        <label :for="id">{{ proposalRateGuarantees[id].name }}</label>
        <div :class="{ error: proposalRateGuarantees[id].error }">
          <Select
            v-model="proposalRateGuarantees[id].rate_guarantee"
            :name="id"
            fluid
            editable
            :invalid="proposalRateGuarantees[id].error"
            :options="rateGuaranteeOptions"
            :disabled="
              proposalRateGuarantees[id].name.toLowerCase() === 'stop loss'
            "
            option-label="normalized_value"
            option-value="normalized_value"
            placeholder="Enter rate guarantee"
            :data-test="`edit ${proposalRateGuarantees[id].name} rate guarantee`"
            @update:model-value="inputValidator($event, id)"
          />
          <Message
            v-if="proposalRateGuarantees[id].error"
            severity="error"
            size="small"
            variant="simple"
          >
            Enter a value between 1 and 100
          </Message>
        </div>
      </FormField>
    </Form>
    <div class="btn-group-right">
      <Button
        data-test="close set rate gaurantee modal"
        size="large"
        severity="secondary"
        label="Cancel"
        variant="text"
        @click="$emit('closeDialog')"
      />
      <Button
        :disabled="!canConfirm"
        :loading="btnSaving"
        :label="
          isUploadRenewalRatePass ? 'Submit Rate Pass' : 'Verify & confirm'
        "
        size="large"
        data-test="confirm rate pass renew by product"
        @click="ratePassRenew"
      />
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useAccountStore } from '@/stores/account.js';
import { useProductStore } from '@/stores/product.js';
import { useProductTableStore } from '@/stores/productTable.js';
import { useNotificationsStore } from '@/stores/notifications.js';
import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
import ProductService from '@/services/product.js';
import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';

import { useToast } from 'primevue/usetoast';
import { Form, FormField } from '@primevue/forms';
import Button from 'primevue/button';
import Select from 'primevue/select';
import Message from 'primevue/message';

defineOptions({
  name: 'SetRateGuarantee',
});

/**
 * Allows you to set the rate guarantee per individual product.
 *
 * @exports SetRateGuarantee
 */
const props = defineProps({
  /**
   * Determines if the save button is in a saving state.
   *
   * @default false
   * @type {boolean}
   */
  btnSaving: { type: Boolean, default: false },

  /**
   * List of all products.
   *
   * @default [ ]
   * @type {Array}
   */
  products: { type: Array, default: () => [] },

  /**
   * List of selected products.
   *
   * @default [ ]
   * @type {Array}
   */
  selectedProducts: { type: Array, default: () => [] },

  /**
   * Determines if the enhancements feature is enabled.
   *
   * @default true
   * @type {boolean}
   */
  uploadEnhancementsEnabled: { type: Boolean, default: true },
});
const emit = defineEmits(['updateBtnSaving', 'refreshAndClose', 'updateStep']);
// Stores
const accountStore = useAccountStore();
const productStore = useProductStore();
const productTableStore = useProductTableStore();
const notificationsStore = useNotificationsStore();
const carrierInfoStore = useCarrierInfoStore();
const { carrierId } = storeToRefs(carrierInfoStore);
const { isUploadRenewalRatePass } = storeToRefs(productStore);
const { proposalRateGuarantees } = storeToRefs(productTableStore);
const { allProductsSubmittedDialogVisible } = storeToRefs(notificationsStore);
// Toast notification
const toast = useToast();
// State
const fetchError = ref(false);
const rateGuaranteeOptions = ref([
  { normalized_value: '12' },
  { normalized_value: '24' },
  { normalized_value: '36' },
  { normalized_value: '48' },
]);
/**
 * Determines if all rate guarantees are valid and saving is not in progress.
 *
 * @returns {boolean}
 */
const canConfirm = computed(
  () =>
    !props.btnSaving &&
    props.selectedProducts.every(
      (id) =>
        !proposalRateGuarantees.value[id]?.error &&
        proposalRateGuarantees.value[id]?.rate_guarantee !== '',
    ),
);
/**
 * Validates product rate guarantee input.
 *
 * @param {string} value - Rate guarantee value
 * @param {string} id - Product ID
 */
const inputValidator = (value, id) => {
  proposalRateGuarantees.value[id].error = +value <= 0 || +value >= 100;
};
/** Submits rate pass renewal. */
const ratePassRenew = async () => {
  emit('updateBtnSaving', true);

  const selectedRateGuarantees = {
    rate_guarantees: props.selectedProducts.map((id) => ({
      id,
      rate_guarantee: proposalRateGuarantees.value[id]?.rate_guarantee,
    })),
  };
  const showSuccessMessage = () => {
    toast.add({
      severity: 'success',
      detail:
        'You’ve successfully submitted selected products for renewal. Start a conversation with the broker in Comments.',
      life: 5000,
    });
  };
  const closeModal = () => {
    emit('updateBtnSaving', false);
    emit('refreshAndClose');
  };

  try {
    await ProductService.ratePassRenewals(selectedRateGuarantees);

    trackSegmentEvent('Submit rate pass renewal', {
      product_ids: props.selectedProducts,
      project_id: +accountStore.projectId,
      carrier_id: carrierId.value,
      user_carrier_id: accountStore.userInfo.id,
      user_email: accountStore.userInfo.email,
    });

    if (isUploadRenewalRatePass.value) {
      const allAltsSubmittedOrDeclined = props.products
        .filter(({ project_product: { alternative } }) => alternative)
        .every(({ state }) => state === 'completed' || state === 'declined');

      if (allAltsSubmittedOrDeclined) {
        allProductsSubmittedDialogVisible.value = true;
      }
      closeModal();
    } else if (props.uploadEnhancementsEnabled) {
      if (props.selectedProducts.length === props.products.length) {
        showSuccessMessage();
        closeModal();
      } else {
        emit('updateBtnSaving', false);
        emit('updateStep', 'three');
      }
    } else {
      showSuccessMessage();
      closeModal();
    }
  } catch {
    toast.add({
      severity: 'error',
      closable: true,
      detail:
        'Not all of the products you selected for renewal were successfully started.',
      life: 5000,
    });
    emit('refreshAndClose');
  } finally {
    if (!props.uploadEnhancementsEnabled) {
      emit('refreshAndClose');
    }
  }
};
</script>
<style scoped lang="scss">
#rate-pass-edit {
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

#rate-guarantees {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  div {
    width: 247px;
    margin-bottom: 20px;

    :deep(.el-input) {
      margin-bottom: 2px;
    }

    div {
      font-size: 12px;
      color: #999;
      text-align: left;
      margin: 0;
    }
  }

  .error {
    :deep(.el-textarea__inner) {
      border: 1px solid var(--tf-danger);
    }

    div {
      color: var(--tf-danger);
    }
  }

  :deep(#rate-guarantee-edit) {
    margin-bottom: 16px;

    h3 {
      font-size: 18px;
      text-align: left;
      font-weight: 700;
      margin: 0;
    }

    .fetch-error {
      margin: 0;
      color: var(--tf-danger);
    }
  }
}
</style>
