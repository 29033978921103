<script setup>
/**
 * Main App File
 *
 * @exports App
 */
import { ref, computed, watch, inject, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useLaunchDarklyStore } from '@/stores/launchDarkly.js';
import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
import { useProjectStore } from '@/stores/project.js';
import { useAccountStore } from '@/stores/account.js';
import ServiceAccount from '@/services/account.js';
import {
  identifySegmentUser,
  trackSegmentPage,
} from '@watchtowerbenefits/es-utils-public';
import { setUser as SentrySetUser } from '@sentry/vue';
import LogRocket from 'logrocket';
import { useToast } from 'primevue/usetoast';
import { canUseThirdParty } from '@/utils/general.js';
import { initGlobalToast } from '@/utils/globalToast.js';
import { config } from '@/utils/config.js';
import { useProjectComposable } from '@/composables/useProjectComposable.js';

import Toast from 'primevue/toast';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import GlobalNavigation from '@/components/GlobalNavigation/index.vue';
import AppResetPasswordForm from '@/shared/components/AuthPages/AppResetPasswordForm.vue';

defineOptions({
  name: 'App',
});

const $ld = inject('$ld');
const route = useRoute();
const router = useRouter();
const toast = useToast();
const { getAllProjectData } = useProjectComposable();
const useAnalytics = config.analyticsEnabled(['production', 'demo', 'qa']);
const accountStore = useAccountStore();
const projectStore = useProjectStore();
const launchDarklyStore = useLaunchDarklyStore();
const carrierInfoStore = useCarrierInfoStore();
const { userInfo, accountIsLoaded, auth } = storeToRefs(accountStore);
const { config: launchDarklyConfig } = storeToRefs(launchDarklyStore);
const { project, employerName, proposalDocumentId } = storeToRefs(projectStore);
const isValidPassword = ref(false);
const isUpdating = ref(false);
const password = ref('');
const directTraffic = ref(true);
/**
 * The pixel offset to use depending on whether or not the route is the
 * MedicalQuotes page.
 *
 * @returns {string}
 */
// const contentTopOffset = computed(() =>
//   route.name === 'MedicalQuotes' ? '0' : '56px',
// );
/**
 * Evaluates the route meta pageType to see if it's an authentication or quote
 * submission page.
 *
 * @returns {boolean}
 */
const isAuthenticatedPage = computed(
  () => !['authentication', 'quoteSubmission'].includes(route.meta.pageType),
);
/**
 * Evaluates the route meta pageType to see if it's a non-authentication page
 * and the account info is loaded.
 *
 * @returns {boolean}
 */
const showRoute = computed(
  () => !isAuthenticatedPage.value || accountIsLoaded.value,
);
/**
 * Returns the class for the page type
 *
 * @returns {string}
 */
const pageTypeClass = computed(() =>
  isAuthenticatedPage.value ? 'authenticated-page' : 'unauthenticated-page',
);
/** Updates password */
const updatePassword = async () => {
  isUpdating.value = true;
  try {
    await ServiceAccount.changePassword({ password: password.value });
    toast.add({
      closable: true,
      detail: 'Success! Your password has been updated.',
      type: 'success',
    });
    userInfo.value.temp_password = false;
  } catch {
    toast.add({
      life: 10000,
      detail:
        'There was an error updating your password. Please contact support.',
      closable: true,
      type: 'error',
    });
  } finally {
    isUpdating.value = false;
    password.value = '';
  }
};

/** Watches route changes to dynamically update page titles and analytics. */
watch(
  () => route.fullPath,
  (newPath) => {
    const to = route;

    if (to.meta.aliasTitles) {
      const tabName = newPath.split('/').pop();
      const titlePrefix =
        to.meta.aliasTitles[tabName] || to.meta.aliasTitles.root;

      document.title = `${titlePrefix} | ThreeFlow`;
    } else {
      document.title = to.meta.title
        ? `${to.meta.title} | ThreeFlow`
        : 'ThreeFlow';
    }

    if (useAnalytics) {
      const pagePayload = {
        name: route.name,
        path: route.path,
        url: window.location.href,
        project_id: project.value?.id,
        project_name: employerName.value ?? null,
        proposal_id: proposalDocumentId.value,
        direct_traffic: directTraffic.value,
      };

      trackSegmentPage(route.name, pagePayload);
    }

    directTraffic.value = false;
  },
  { immediate: true },
);

/**
 * This watcher will trigger an "identify" call to several services which can
 * then attribute actions/events/views to a user. userInfo.id is set to null on
 * sign out, so we check that it exists first because we only want to make these
 * identity calls for a signed in user.
 *
 * @param {number} newId
 */
watch(
  () => userInfo.value.id,
  (newId) => {
    if (newId) {
      if (useAnalytics || canUseThirdParty('logrocket')) {
        const {
          id,
          email,
          roles,
          first_name: firstName,
          last_name: lastName,
        } = userInfo.value;

        LogRocket.identify(id.toString(), {
          email,
          name: `${firstName} ${lastName}`,
          roles: roles.map(({ name }) => name).join(', '),
        });
      }

      const { id: carrierId, name: carrierName, logoUrl } = carrierInfoStore;

      if (useAnalytics || canUseThirdParty('sentry')) {
        SentrySetUser({
          ...userInfo.value,
          carrier: { id: carrierId, name: carrierName, logoUrl },
          projectId: project.value?.id,
        });
      }

      if (useAnalytics || canUseThirdParty('segment')) {
        identifySegmentUser({
          ...userInfo.value,
          carrier_name: carrierName,
          carrier_id: carrierId ?? null,
        });
      }
    }
  },
);

/**
 * Update launch darkly identity whenever user key is changed and has a value
 *
 * @param {string} newKey
 */
watch(
  () => launchDarklyConfig.value.user?.key,
  (newKey) => {
    if (!newKey) return;
    $ld.identify({
      hash: launchDarklyConfig.value.options.hash,
      newUser: launchDarklyConfig.value.user,
    });
  },
);
/** Set all user specific classes */
onMounted(async () => {
  // Detect IE/Edge browsers and set classes
  const uaString = navigator.userAgent;
  const match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(uaString);

  if (match) {
    const ieVersion = parseInt(match[2], 10);

    document
      .querySelector('body')
      .classList.add(ieVersion < 12 ? 'is-ie' : 'is-ie-edge');
  }

  // Wait for the router to be ready before checking the user's authentication status
  await router.isReady();

  initGlobalToast(toast); // Store the toast instance globally

  // If they're signing in or out, don't bother
  if (
    ['SignIn', 'SignOut', 'ProjectSignIn', 'ProjectSignOut'].includes(
      route.name,
    )
  )
    return;

  // Try and sign in the user and populate the carrierUserInfo store
  try {
    const data = await ServiceAccount.getCurrentUser();

    ServiceAccount.setSignedIn(data);

    if (route.params.projectId && auth.value.status) {
      getAllProjectData(route.params.projectId);
    }
  } catch {
    window.localStorage.removeItem('auth');
  }

  // It’s better to initialize the current watcher inside `onMounted` to avoid issues when triggering `getAllProjectData` before `ServiceAccount.getCurrentUser`.
  // This approach ensures that project data is properly initialized and prevents potential issues.
  /**
   * Runs getAllProjectData with new projectId if projectId changes
   *
   * @param {string} newValue
   */
  watch(
    () => route.params.projectId,
    async (newValue) => {
      if (
        newValue &&
        !['SignIn', 'SignOut', 'ProjectSignIn', 'ProjectSignOut'].includes(
          route.name,
        )
      ) {
        await getAllProjectData(newValue);
      }
    },
  );
});
</script>

<template>
  <div id="appNew" :class="['app', pageTypeClass]">
    <template v-if="isAuthenticatedPage">
      <header v-if="route.name !== 'MedicalQuotes'" class="w-full">
        <GlobalNavigation />
      </header>
      <!-- Show Password Reset Modal -->
      <Dialog
        :visible="accountIsLoaded && userInfo.temp_password"
        modal
        :close-on-escapee="false"
        :close-on-click-modal="false"
        :closable="false"
        class="reset-password-dialog"
        header="Update password"
        data-test="password change"
      >
        <AppResetPasswordForm
          v-model:password="password"
          @update:is-valid-password="isValidPassword = $event"
        >
          <template #ctaSubmit>
            <Button
              :disabled="!isValidPassword"
              :loading="isUpdating"
              data-test="confirm password update"
              label="Update password"
              type="submit"
              severity="help"
              variant="outlined"
              fluid
              @click="updatePassword"
            />
          </template>
        </AppResetPasswordForm>
      </Dialog>
      <!-- Show Password Reset Modal -->
    </template>
    <div id="app-content">
      <RouterView v-if="showRoute" />
      <Toast />
      <Toast position="bottom-right" group="bottom-right" />
      <Toast position="top-center" group="top-center" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* TODO: When converting the MM code, check to see if this style can be rewritten.
Rather than using !important here I think we should add a class to #appNew by updating
pageTypeClass and put the styles in the _global.scss file */
/* #app-content {
  top: v-bind(contentTopOffset) !important;
} */
</style>
