<template>
  <svg width="260" height="184" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M245.186 39.419a8.063 8.063 0 0 0-8.056-8.05H41.32a8.056 8.056 0 0 0-8.05 8.05v123.626h211.916V39.419Zm-6.798 115.34a1.494 1.494 0 0 1-1.488 1.494H41.532a1.495 1.495 0 0 1-1.494-1.494V39.655a1.494 1.494 0 0 1 1.494-1.494H236.9a1.492 1.492 0 0 1 1.488 1.494v115.104Z"
      fill="#fff"
    />
    <path
      d="M235.406 41.143H43.044v112.122h192.362V41.143ZM129.109 170.775h24.999a4.84 4.84 0 0 0 4.802-4.754h-34.604a4.84 4.84 0 0 0 4.803 4.754Z"
      fill="#fff"
    />
    <path
      d="M161.904 166.033a7.799 7.799 0 0 1-7.796 7.767h-24.999a7.793 7.793 0 0 1-7.785-7.736H22.207v6.653a8.062 8.062 0 0 0 8.057 8.057h217.929a8.06 8.06 0 0 0 8.05-8.057v-6.653l-94.339-.031Z"
      fill="#fff"
    />
    <path
      d="M235.406 41.143h-5.667v112.122h5.667V41.143ZM256.243 172.687v-6.654h-5.667v6.654a39.38 39.38 0 0 1-1.373 7.984 8.065 8.065 0 0 0 7.04-7.984Z"
      fill="#A3A5F9"
    />
    <path
      d="M236.9 38.161H41.532a1.494 1.494 0 0 0-1.494 1.494v115.104a1.498 1.498 0 0 0 .938 1.393c.184.074.382.11.58.107h195.368a1.498 1.498 0 0 0 1.488-1.494V39.655a1.498 1.498 0 0 0-.444-1.063 1.491 1.491 0 0 0-1.068-.43Zm-1.494 115.11H43.062V41.143h192.344v112.128Z"
      fill="#2638A6"
    />
    <path
      d="M257.737 163.045h-9.569V39.419a11.048 11.048 0 0 0-11.038-11.032H41.32a11.05 11.05 0 0 0-11.038 11.032v123.626h-9.563a1.495 1.495 0 0 0-1.494 1.494v8.148a11.053 11.053 0 0 0 11.039 11.038h217.929a11.051 11.051 0 0 0 11.032-11.038v-8.148a1.493 1.493 0 0 0-1.488-1.494ZM33.27 39.419a8.057 8.057 0 0 1 8.05-8.05h195.81a8.064 8.064 0 0 1 8.056 8.05v123.626H33.27V39.419Zm125.676 126.614a4.834 4.834 0 0 1-1.451 3.359 4.832 4.832 0 0 1-3.387 1.383h-24.999a4.836 4.836 0 0 1-4.803-4.754l34.64.012Zm97.321 6.654a8.062 8.062 0 0 1-8.05 8.056H30.264a8.062 8.062 0 0 1-8.057-8.056v-6.654h99.117a7.793 7.793 0 0 0 7.785 7.767h24.999a7.797 7.797 0 0 0 7.784-7.737h94.357l.018 6.624Z"
      fill="#2638A6"
    />
    <path d="M177.927 50.972h-77.694v91.817h77.694V50.972Z" fill="#7A7FF5" />
    <path
      d="M149.124 83.229a10.997 10.997 0 0 1-9.919 3.2 11.004 11.004 0 0 1-7.193-16.891 11.01 11.01 0 0 1 9.182-4.933v11.002l7.93 7.622Z"
      fill="#C1C1C1"
    />
    <path
      d="M149.124 83.229a10.998 10.998 0 0 0 2.196-11.935 10.991 10.991 0 0 0-10.126-6.689v11.002l7.93 7.622ZM110.963 101.308h56.252a1.493 1.493 0 0 0 .994-2.514 1.494 1.494 0 0 0-.994-.468h-56.252a1.494 1.494 0 0 0 0 2.982ZM167.197 108.1h-56.252a1.494 1.494 0 0 0 0 2.988h56.252a1.493 1.493 0 1 0 0-2.988ZM167.197 117.881h-56.252a1.492 1.492 0 0 0-1.399 1.491 1.495 1.495 0 0 0 1.399 1.491h56.252a1.497 1.497 0 0 0 1.399-1.491 1.495 1.495 0 0 0-1.399-1.491ZM142.748 127.661h-31.785a1.493 1.493 0 0 0-.994 2.513c.26.277.615.445.994.469h31.785a1.495 1.495 0 0 0 0-2.982Z"
      fill="#fff"
    />
    <path
      d="M179.421 145.771H98.745a1.495 1.495 0 0 1-1.494-1.494v-94.8a1.487 1.487 0 0 1 1.494-1.487h80.676a1.488 1.488 0 0 1 1.488 1.488v94.799a1.494 1.494 0 0 1-1.488 1.494Zm-79.188-2.982h77.694V50.972h-77.694v91.817ZM37.094 22.573a1.332 1.332 0 0 1-1.305-1.078c-.108-.577-2.513-14.227 7.281-21.221a1.327 1.327 0 1 1 1.543 2.155c-8.43 6.025-6.23 18.452-6.208 18.576a1.323 1.323 0 0 1-1.079 1.546 1.228 1.228 0 0 1-.232.022ZM10.597 63.75c-.09.01-.18.01-.27 0a1.327 1.327 0 0 1-1.03-1.569c1.558-7.544 9.994-8.439 14.238-7.868a1.332 1.332 0 0 1-.355 2.636c-.405-.054-9.833-1.224-11.284 5.77a1.326 1.326 0 0 1-1.3 1.03ZM23.357 46.214a1.339 1.339 0 0 1-1.138-.636c-.307-.501-7.67-12.265-20.522-8.552a1.33 1.33 0 0 1-1.689-1.421 1.326 1.326 0 0 1 .95-1.128c14.832-4.311 23.451 9.581 23.537 9.7a1.33 1.33 0 0 1-.447 1.821 1.29 1.29 0 0 1-.69.216ZM26.108 29.476a1.327 1.327 0 0 1-1.143-2.005c.086-.156 2.233-4.214-3.528-9.97a9.844 9.844 0 0 0-3.139-2.155 5.98 5.98 0 0 1-1.725 3.508l-.103.097c-1.72 1.806-3.91 1.585-5.183.437l-.097-.097c-1.149-1.272-1.37-3.465.437-5.184l.102-.108a6.07 6.07 0 0 1 3.5-1.719 9.652 9.652 0 0 0-2.157-3.13c-5.76-5.756-9.827-3.612-9.994-3.515a1.344 1.344 0 0 1-1.807-.5 1.32 1.32 0 0 1 .47-1.8c.237-.14 5.932-3.352 13.208 3.933a11.643 11.643 0 0 1 3.144 5.2 11.588 11.588 0 0 1 5.221 3.159c7.292 7.285 4.083 12.933 3.937 13.202a1.325 1.325 0 0 1-1.143.647ZM13.121 17.453c.318.243.846.27 1.429-.345l.124-.118c.559-.56.907-1.294.987-2.08a3.404 3.404 0 0 0-2.06.97l-.14.145c-.588.582-.588 1.11-.34 1.428Z"
      fill="#2638A6"
    />
  </svg>
</template>
