<template>
  <div class="rfp-info-tab">
    <div class="rfp-content">
      <div class="rfp-column">
        <h3>RFP information</h3>
        <RfpTable
          v-if="project && project.rfp_info"
          :hide-params="['market_activity']"
          :info="project.rfp_info"
          :project-type="project.type"
          :has-stop-loss="hasStopLoss"
        />
        <div class="rfp-info-copy-link-container">
          <CopyLinkContainer
            v-if="project"
            :text="`${project.employer.name} share link:`"
            :link="`${carrierPortalDomain}/project/${projectId}`"
            data-test="share link container"
          />
        </div>
      </div>
      <RfpDocuments data-test="rfp documents column">
        <template #title>
          <h3>RFP documents</h3>
        </template>
      </RfpDocuments>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectStore } from '@/stores/project.js';
import { isStopLossProduct } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';
import RfpDocuments from '@/components/RfpDocuments/index.vue';
import CopyLinkContainer from '@/shared/components/CopyLinkContainer.vue';
import RfpTable from '@/shared/components/RfpTable.vue';

defineOptions({
  name: 'RfpInfo',
});

const projectStore = useProjectStore();
const { project, projectId } = storeToRefs(projectStore);
const carrierPortalDomain = ref(
  config.VUE_APP_CARRIER_PORTAL_DOMAIN.replace('https://', ''),
);
const hasStopLoss = computed(
  () =>
    project.value.project_products?.some((product) =>
      isStopLossProduct(product),
    ) || false,
);
</script>

<style lang="scss" scoped>
h3 {
  margin-bottom: 23px;
}

.rfp-column {
  margin: 0 20px 23px;

  @media (min-width: 1024px) {
    width: calc(50% - 40px);
  }
}

.rfp-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;

  @media (min-width: 1024px) {
    flex-flow: row wrap;
  }
}
</style>
