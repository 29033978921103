<template>
  <Dialog
    header="Decline products"
    :visible="visible"
    :closable="false"
    modal
    data-test="start or update renewal dialog"
    style="width: 620px"
  >
    <div>
      <h4 data-test="decline header" class="inner-header">
        Select products to decline
      </h4>
      <div class="product-list">
        <h4 data-test="decline products header">Products</h4>
        <ul data-test="product list">
          <li v-for="product in products" :key="product.id">
            <Checkbox
              v-model="selectedProducts"
              :value="product.id"
              :input-id="product.id"
              :data-test="`${productLabel(product)} checkbox`"
              @change="
                triggerProductSelectionAnalyticsEvent($event, product.id)
              "
            />
            <label :for="product.id" class="ml-2">{{
              productLabel(product)
            }}</label>
          </li>
        </ul>
      </div>
      <Button
        class="select-all-cta"
        variant="text"
        data-test="select all products to decline"
        label="Select all"
        @click="selectAllProducts"
      />
      <div class="flex justify-end gap-2">
        <Button
          label="Cancel"
          severity="secondary"
          variant="text"
          @click="emit('update:visible', false)"
        />
        <Button
          :disabled="noProductsSelected"
          icon="fa-solid fa-arrow-circle-right"
          icon-pos="right"
          size="large"
          label="Next"
          data-test="confirm declined products selected"
          @click="handleNextStep"
        />
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useProductStore } from '@/stores/product.js';
import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
import { productLabel } from '@/utils/product.js';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
/**
 * Start or update renewal modal dialog. Conditionally displays Rate Pass/Rate
 * Guarantee form or Upload Renewal documents form.
 *
 * @exports SelectDeclineModal
 */
defineOptions({
  name: 'SelectDeclineModal',
});

// Props & Emits
const props = defineProps({
  visible: { type: Boolean, default: false },
  products: { type: Array, default: () => [] },
});
const emit = defineEmits(['closeDialog']);
// Store
const productStore = useProductStore();
// State
const selectedProducts = ref([]);
// Computed
/**
 * Evaluate if any products are selected in step one.
 *
 * @returns {boolean}
 */
const noProductsSelected = computed(() => selectedProducts.value.length === 0);
// Methods
/**
 * Move the modal to the next step, either step three if no products selected or
 * which opens the "Rate Guarantees" template and closes the "Select Products"
 * template. Then trigger an analytics call.
 */
const handleNextStep = () => {
  productStore.productsToDecline = props.products.filter(({ id }) =>
    selectedProducts.value.includes(id),
  );
  selectedProducts.value = [];
  emit('closeDialog');
};
/** Selects all listed products. */
const selectAllProducts = () => {
  selectedProducts.value = props.products.map(({ id }) => id);
};
/**
 * Trigger an analytics call if the product is selected in the "Select Products"
 * modal.
 *
 * @param {boolean} checked
 */
const triggerProductSelectionAnalyticsEvent = (checked) => {
  if (checked) {
    trackSegmentEvent('Product selected from rate pass', {
      product_ids: selectedProducts.value,
    });
  }
};

// Watch for changes in `selectedProducts`
watch(selectedProducts, (newVal) => {
  if (newVal.length) {
    triggerProductSelectionAnalyticsEvent(true);
  }
});
</script>

<style lang="scss" scoped>
.inner-header {
  margin-top: 10px;
}

.product-list {
  border: 1px solid var(--tf-gray-medium);
  margin-bottom: 8px;

  h4 {
    padding: 8px 16px;
    border-bottom: 1px solid var(--tf-gray-medium);
    margin-bottom: 0;
    background: var(--tf-gray-light);
  }

  ul {
    li {
      padding: 8px 16px;
      border-bottom: 1px solid var(--tf-gray-medium);

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
