/**
 * Product label formatting
 *
 * @param {object} product
 * @param {string} product.project_product
 * @param {string} product.product_type_name
 * @returns {string}
 */
export const productLabel = ({
  project_product: projectProduct,
  product_type_name: name,
}) => {
  const label = projectProduct.label.replace(
    new RegExp(`^${name} *-* *`, 'i'),
    '',
  );

  return `${name}${label.length ? ` - ${label}` : ''}`;
};

/**
 * List of product types that are not supported by dive, but ARE supported by
 * ThreeFlow Assist This list supports both strings and regex; if a string is
 * passed in, it will be compared case-insensitively
 */
export const diveUnsupportedProductTypes = [
  'stop loss',
  'critical illness',
  /paid leave/,
];

/** List of product types that are not supported by dive OR ThreeFlow Assist */
export const tfAssistAndDiveUnsupportedProductTypes = ['absence management'];

export const inProgressSteps = [
  'in_progress',
  'in_progress_pending_confirmation',
  'in_progress_modifying',
  'editing',
  'editing_plan_design',
  'pending_validation',
  'pending_review',
  'action_needed',
  'automation_locked',
  'threeflow_assist',
];

/**
 * Product firm quote stop loss state check
 *
 * @param {string} stopLossState
 * @returns {boolean}
 */
export const firmQuoteCheck = (stopLossState) =>
  /firm_quote_requested|firm_quote_submitted|firm_quote_expired/i.test(
    stopLossState,
  );
