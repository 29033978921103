<template>
  <Dialog
    ref="containerRef"
    :append-to-body="appendToBody"
    :header="'Supplemental documents'"
    data-test="upload renewal dialog"
    modal
    :closable="false"
    :visible="visible"
    class="manage-proposals-dialog"
    @update:visible="$emit('update:visible', false)"
  >
    <UiFileUpload
      :url="`${apiUrl}/v1/carrier_portal/documents/${proposalDocumentId}/upload_sources`"
      :documents="clonedProposals"
      name="files[]"
      :is-deleting="isDeleting"
      :is-downloading="isDownloading"
      :file-extensions="acceptableFileExtensions"
      @delete-document="handleDeleteClick"
      @update:is-uploading="isUploading = $event"
      @download-all-documents="downloadAllDocuments"
      @upload-success="onUploadSuccess"
    >
      <template #header>
        <p>
          ThreeFlow will find rate and plan design information from your
          uploaded PDF document and enter it in your quote.
          <Button
            data-test="upload pdf help link"
            as="a"
            href="https://support.threeflow.com/en/articles/9179732-how-do-i-save-a-document-as-a-pdf"
            target="_blank"
            variant="link"
            label="How to save my .doc or .xls as a PDF."
          />
        </p>
      </template>
      <template #acceptedFileTypes>
        <p>
          Other documents accepted (.pdf, .doc, .xls, .rtf files) will be added
          as supplemental documents. File size is limited to 20MB.
        </p>
      </template>
    </UiFileUpload>

    <div class="flex justify-end gap-2 mt-4">
      <Button
        data-test="close supplemental documents modal"
        size="large"
        severity="secondary"
        label="Cancel"
        variant="text"
        @click="$emit('update:visible', false)"
      />
      <Button
        data-test="send to threeflow"
        size="large"
        label="Send to ThreeFlow"
        @click="$emit('update:visible', false)"
      />
    </div>
  </Dialog>
</template>

<script setup>
import { ref, computed, onBeforeUnmount } from 'vue';
import { storeToRefs } from 'pinia';
import { useToast } from 'primevue/usetoast';
import { reusableAcceptableUploadFileExtensions } from '@watchtowerbenefits/es-utils-public';
import DocumentService from '@/services/documents.js';
import { deleteSource } from '@/services/source.js';
import { useProjectStore } from '@/stores/project.js';
import { splitProposalFileName } from '@/utils/fileUpload.js';

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import UiFileUpload from '@/shared/components/UiFileUpload.vue';

import { config } from '@/utils/config.js';

defineOptions({
  name: 'ProposalManagerModal',
});

const props = defineProps({
  appendToBody: {
    type: Boolean,
  },
  proposals: {
    type: Array,
    default: () => [],
  },
  visible: {
    type: Boolean,
    required: true,
  },
});

defineEmits(['update:visible']);

const projectStore = useProjectStore();
const { proposalDocumentId } = storeToRefs(projectStore);
// Constants
const apiUrl = config.VUE_APP_API_URL;
// composable
const toast = useToast();
// refs
const uploadError = ref('');
const isUploading = ref(null);
const isDownloading = ref(false);
const isDeleting = ref(false);
const containerRef = ref(null);
const clonedProposals = ref(JSON.parse(JSON.stringify(props.proposals)) || []);
// computed
const proposalManagerParentElement = computed(
  () => containerRef.value?.$el.parentElement,
);
const acceptableFileExtensions = computed(() =>
  reusableAcceptableUploadFileExtensions().concat(
    reusableAcceptableUploadFileExtensions().map((extension) =>
      extension.toUpperCase(),
    ),
  ),
);
// Methods
/**
 * Clicks the upload component to trigger the file navigator.
 *
 * @param {Event} event
 */
const clickUploadArea = (event) => {
  event.preventDefault();
  const uploadDragger = proposalManagerParentElement.value.querySelector(
    '.p-fileupload-content',
  );

  if (uploadDragger) {
    uploadDragger.click();
  }
};
/**
 * Call the Proposal Service and delete this file from the server then update
 * the store
 *
 * @param {object} file
 * @param {string | number} file.id
 */
const handleDeleteClick = async ({ id }) => {
  isDeleting.value = true;
  try {
    await deleteSource(id);
    projectStore.removeProposal(id);
    clonedProposals.value = clonedProposals.value.filter(
      (proposal) => proposal.id !== id,
    );
  } catch {
    toast.add({
      severity: 'error',
      detail: 'There was an error deleting the document. Please try again.',
    });
  }

  isDeleting.value = false;
};
/**
 * On Upload Success: Remove the file from `uploadingFiles` and update proposals
 * in the store.
 *
 * @param {object} response
 * @param {XMLHttpRequest} response.xhr
 */
const onUploadSuccess = ({ xhr }) => {
  const data = JSON.parse(xhr.response);
  let proposal = data?.successfully_uploaded_sources[0];

  proposal = splitProposalFileName(proposal);
  clonedProposals.value = [].concat(proposal, clonedProposals.value);

  projectStore.setProposals(data.sources);
};
/** Downloads all files that have been uploaded for this project */
const downloadAllDocuments = async () => {
  const filename = `${proposalDocumentId.value}_proposals.zip`;

  isDownloading.value = true;
  try {
    const data = await DocumentService.getAllDocumentSources(
      proposalDocumentId.value,
    );
    const u8 = new Uint8Array(data);
    const blob = new Blob([u8], { type: 'application/zip' });
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  } catch {
    toast.add({
      closable: true,
      severity: 'error',
      detail:
        'There was an error downloading the documents zip file, please try again.',
      life: 30000,
    });
  } finally {
    isDownloading.value = false;
  }
};

/** Remove all listeners and upload errors */
onBeforeUnmount(() => {
  // Remove event listeners
  const newFileLink =
    proposalManagerParentElement.value?.querySelector('a.select-new-file');

  if (newFileLink) {
    newFileLink.removeEventListener('click', clickUploadArea);
  }

  // Clear errors
  uploadError.value = '';
});

// split up the proposal file name by extension for each proposal
clonedProposals.value = clonedProposals.value?.map(splitProposalFileName);
</script>

<style lang="scss">
.manage-proposals-dialog {
  max-width: 650px;
  width: 90%;
}
</style>
