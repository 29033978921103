import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const normalizedEndpoint = `${config.VUE_APP_API_URL}/v1/carrier_portal`;
const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
const axiosConfig = () => getAxiosConfig(cookieNamespace);

/**
 * Delete source
 *
 * @param {number} sourceId
 * @returns {Promise}
 */
export const deleteSource = (sourceId) => {
  const url = `${normalizedEndpoint}/sources/${sourceId}`;

  return new Promise((resolve, reject) => {
    axios
      .delete(url, axiosConfig())
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * Get the encoded data for a specfic pdf
 *
 * @param {number} sourceId
 * @returns {Promise}
 */
export const getSource = (sourceId) => {
  const url = `${normalizedEndpoint}/sources/${sourceId}`;

  return new Promise((resolve, reject) => {
    axios
      .get(url, axiosConfig())
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
