<template>
  <div
    :class="[
      { 'pdf-export-container': isPdfRender },
      { 'is-reply': isReply },
      'tf-comment-container',
    ]"
  >
    <AppMessage
      v-if="showSuccessMessage"
      data-test="comment success notification"
      :closable="false"
      severity="success"
    >
      {{ successMessage }}
    </AppMessage>
    <Badge
      v-if="showLocalNewBadge"
      :class="{ indent: isReply }"
      size="small"
      status="success"
      value="new"
    />
    <p
      :class="[
        'sender-recipient',
        {
          'pdf-export-block': isPdfRender,
          indent: isReply,
        },
      ]"
    >
      <strong>{{ userName }}</strong>
      <template v-if="commentRecipient">
        to <strong>{{ commentRecipient }}</strong>
      </template>
    </p>
    <p
      :class="[
        'date',
        {
          'pdf-export-block': isPdfRender,
          indent: isReply,
        },
      ]"
      v-text="formatDateAndTime(comment.created_at)"
    />
    <div class="comment">
      <template v-for="(paragraphs, index) in commentParagraphs">
        <p
          v-for="(p, pIndex) in paragraphs"
          :key="`${index}-${pIndex}`"
          :class="{
            'no-break':
              paragraphs.length > 1 && pIndex !== paragraphs.length - 1,
            'pdf-export-block': isPdfRender,
            indent: isReply,
            'is-last':
              index === commentParagraphs.length - 1 &&
              pIndex === paragraphs.length - 1,
          }"
          v-text="p.trim()"
        />
      </template>
    </div>
    <Button
      v-show="!showCommentBox && showReplyButton"
      severity="primary"
      variant="text"
      icon="fa-solid fa-reply"
      icon-pos="right"
      size="text-icon"
      label="Reply"
      :class="{ indent: isReply }"
      @click="showCommentBox = true"
    />
    <CommentInput
      v-show="showCommentBox && !isPdfRender"
      v-model="commentText"
      :auto-save-timestamp="autoSaveTimestamp"
      :comment="comment"
      :inline-success="inlineSuccess"
      :parent-id="commentParentId"
      :project-id="projectId"
      :proposal-document-id="proposalDocumentId"
      :user-info="userInfo"
      @cancel="onCancel"
      @commentAdded="onCommentAdded"
    />
    <div v-show="orderedReplies.length">
      <Comment
        v-for="(commentReply, index) in orderedReplies"
        :key="commentReply.id"
        :broker="broker"
        :comment="commentReply"
        :documents="documents"
        :is-reply="true"
        :is-pdf-render="isPdfRender"
        :parent-id="commentParentId"
        :proposal-document-id="proposalDocumentId"
        :project-id="projectId"
        :show-reply-button="!index"
        :user-info="userInfo"
        @commentAdded="onCommentAdded"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import {
  sortObjects,
  formatDateAndTime,
} from '@watchtowerbenefits/es-utils-public';

import Badge from 'primevue/badge';
import Button from 'primevue/button';
import AppMessage from '@/shared/components/AppMessage.vue';
import CommentInput from './Input.vue';

defineOptions({
  name: 'Comment',
});

const props = defineProps({
  broker: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  comment: {
    type: Object,
    default: () => ({}),
  },
  documents: {
    type: Array,
    default: () => [],
  },
  inlineSuccess: {
    type: Boolean,
    default: true,
  },
  isReply: {
    type: Boolean,
    default: false,
  },
  isPdfRender: {
    type: Boolean,
    default: false,
  },
  parentId: {
    type: Number,
    default: null,
  },
  projectId: {
    type: [Number, String],
    required: true,
    default: '',
  },
  proposalDocumentId: {
    type: Number,
    default: null,
  },
  showNewBadge: {
    type: Boolean,
    default: true,
  },
  showReplyButton: {
    type: Boolean,
    default: true,
  },
  userInfo: {
    type: Object,
    required: true,
    default: () => ({
      firstName: '',
      lastName: '',
      organization: '',
      type: 'broker',
    }),
  },
});
const emit = defineEmits(['commentAdded']);
// Refs
const autoSaveTimestamp = ref('');
const commentText = ref('');
const showSuccessMessage = ref(false);
const showCommentBox = ref(false);
// Computed
/**
 * Splits the comment content by `\n\n` into an array to use in the template.
 *
 * @returns {Array}
 */
const commentParagraphs = computed(() =>
  props.comment.content.split('\n\n').map((p) => p.split('\n')),
);
/**
 * If this is a top level comment, we should reply to this comment. If this is a
 * reply, we should take the parentId prop
 *
 * @returns {string}
 */
const commentParentId = computed(() =>
  props.isReply ? props.parentId : props.comment.id,
);
/**
 * Determines if the user is a broker based on this.userInfo type.
 *
 * @returns {boolean}
 */
const isBrokerUser = computed(() => props.userInfo.type === 'broker');
/**
 * Determines if the user is a carrier based on this.userInfo type.
 *
 * @returns {boolean}
 */
const isCarrierUser = computed(() => props.userInfo.type === 'carrier');
/**
 * Return organization name, if set, regardless of user type
 *
 * @returns {string}
 */
const organizationName = computed(() => {
  const brokerName = props.comment.user.broker?.name;
  const carrierName = props.comment.user.carrier?.name;

  return carrierName || brokerName || null;
});
/**
 * Evaluates broker and carrier data to decide what to return in this.fullName.
 *
 * @returns {boolean}
 */
const isSameOrganization = computed(
  () =>
    (isBrokerUser.value &&
      props.userInfo.organization === props.comment.user.broker?.name) ||
    (isCarrierUser.value &&
      props.userInfo.organization === props.comment.user.carrier?.name),
);
/**
 * Return organization name, if set, regardless of user type
 *
 * @returns {string}
 */
const userName = computed(() => {
  const { user } = props.comment;
  let name = `${user.first_name} ${user.last_name}`;

  if (!isSameOrganization.value) {
    name += ` at ${organizationName.value}`;
  }

  return name;
});
/**
 * If this is a top level comment, we should reply to this comment. If this is a
 * reply, we should take the parentId prop
 *
 * @returns {string}
 */
const commentRecipient = computed(() => {
  const recipient = props.documents.find(
    (doc) => doc.id === props.comment.commentable_id,
  );

  if (!recipient) return null;

  if (props.comment.user.broker?.name) {
    return recipient.carrier?.name;
  }
  if (isBrokerUser.value) {
    return props.userInfo.organization;
  }

  return props.broker.name;
});
/**
 * Return organization name, if set, regardless of user type
 *
 * @returns {Array}
 */
const orderedReplies = computed(() =>
  props.comment.replies
    ? sortObjects(props.comment.replies, 'created_at', 'desc')
    : [],
);
/**
 * Evaluates the comment and showNewBadge prop to decide to show the "new"
 * badge.
 *
 * @returns {boolean}
 */
const showLocalNewBadge = computed(
  () => props.comment.is_new && props.showNewBadge,
);
/**
 * Returns the success message based on user type.
 *
 * @returns {string}
 */
const successMessage = computed(() =>
  isBrokerUser.value
    ? 'Your comment has been sent to the carrier(s)'
    : 'Your comment has been sent to your broker.',
);

/*
 * Check if we saved a comment in session storage that wasn't submitted and also sort any replies.
 * If this is a newly added comment, show the alert for 5 seconds.
 */
onMounted(() => {
  const savedComment = window.sessionStorage.getItem(
    `comment-${props.comment.id}`,
  );

  if (savedComment) {
    const commentObject = JSON.parse(savedComment);

    autoSaveTimestamp.value = commentObject.timestamp;
    commentText.value = commentObject.message;
    showCommentBox.value = true;
  }

  if (props.comment.showSuccess) {
    showSuccessMessage.value = true;

    setTimeout(() => {
      showSuccessMessage.value = false;
    }, 5000);
  }
});

// Methods
/** Clear out the comment */
function clearData() {
  autoSaveTimestamp.value = '';
  commentText.value = '';
}

/** Toggle the reply box for this comment */
function onCancel() {
  showCommentBox.value = false;
  clearData();
}

/**
 * Toggle the reply box for this comment and emit the reply to the parent
 *
 * @param {event} $event
 */
function onCommentAdded($event) {
  emit('commentAdded', $event);
  showCommentBox.value = false;
  clearData();
}
</script>

<style scoped lang="scss">
@use '@/shared/assets/styles/variables';

%hideOnPdf {
  .pdf-export-container & {
    display: none;
  }
}

.tf-comment-container {
  padding: 15px 15px 15px 0;
  font-size: 14px;
  line-height: 20px;
  border-top: solid 1px var(--tf-gray-light-medium);

  &:first-child {
    border-top: 0;
  }

  &.is-reply:first-child {
    .el-alert + .name {
      padding-top: 14px;
    }
  }

  > .tf-badge {
    margin-bottom: 10px;
  }
}

.date {
  color: var(--tf-gray);
  font-style: italic;
}

.el-alert {
  margin: 0 0 15px;

  @extend %hideOnPdf;
}

.alert-new.alert-simple {
  margin: 0;
  padding: 5px 10px;

  @extend %hideOnPdf;
}

.tf-button.icon-reply {
  margin-top: 12px;

  @extend %hideOnPdf;
}

// frustrating amount of specifity needed for these
.indent,
.alert-new.alert-simple.indent,
.tf-button.indent {
  margin-left: 35px;
}

p {
  margin: 0;
  line-height: 20px;

  .comment & {
    margin-bottom: 20px;

    &.no-break {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.pdf-export-block {
    width: 530px;
    font-size: 12px;
    line-height: 16px;

    &.indent {
      width: 495px;
    }

    &.is-last {
      margin-bottom: 16px;
    }

    &.sender-recipient {
      padding-top: 16px;
      // Dashed lines currently break the UI (https://watchtower.atlassian.net/browse/BA-1563)
      // border-top: 2px var(--tf-gray-medium) dashed;
      border-top: 1px solid var(--tf-gray-medium);

      .pdf-export-header + & {
        padding-top: 0;
        border-top: 0;
      }
    }
  }
}
</style>
