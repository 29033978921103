<script setup>
import { ref, onMounted } from 'vue';
import { Form } from '@primevue/forms';
import Button from 'primevue/button';
import { useModelValue } from '@/composables/useModelValue.js';

import AuthPassword from '@/shared/components/AuthPages/Password.vue';

// Props
const props = defineProps({
  accountService: {
    type: Object,
    default: () => ({}),
  },
  /* Adding the following two props for completeness' sake (we are
   passing it as a v-model prop on the component when it is used);
   the value is not consumed by the component, but this way the
   fact that it IS to be passed as a v-model prop if the
   accountService isn't passed is documented. */
  password: {
    type: String,
    default: '',
  },
  isValidPassword: {
    type: Boolean,
    default: false,
  },
});
// Emits
const emits = defineEmits([
  'update:password',
  'update:isValidPassword',
  'passwordUpdated',
]);
// Local state
const password = useModelValue(props, 'password', emits);
const submitting = ref(false);

// Clear password on mount
onMounted(() => {
  setTimeout(() => {
    password.value = '';
  }, 100);
});
/**
 * Validate the resetPasswordForm Then call the accountService/changePassword
 * method and emit an event if successful
 *
 * @param {object} root0
 * @param {boolean} root0.valid
 * @param {object} root0.states
 * @returns {boolean}
 */
const submitResetPassword = async ({ valid, states }) => {
  if (!props.accountService?.changePassword) {
    return valid;
  }

  submitting.value = true;

  if (valid) {
    props.accountService.changePassword(states).then(() => {
      submitting.value = false;
      emits('passwordUpdated');
    });
  } else {
    this.submitting = false;
  }

  return valid;
};
</script>

<template>
  <section class="reset-password-container">
    <h4>Please choose a new password for your&nbsp;account.</h4>
    <Form
      v-slot="$form"
      label-position="top"
      class="reset-password"
      data-test="reset password"
      validate-on-submit
      @submit="submitResetPassword"
    >
      <AuthPassword
        v-model:password="password"
        @update:is-valid-password="emits('update:isValidPassword', $event)"
      />
      <div class="btn-group">
        <slot name="ctaSubmit">
          <!-- Since AuthPassword also uses FormField components, its fields are automatically connected to the main form.
               If any of its fields are invalid, the entire form is considered invalid as well. -->
          <Button
            :disabled="!$form.valid"
            :loading="submitting"
            label="Update Password"
            type="submit"
            severity="help"
            variant="outlined"
            fluid
          />
        </slot>
      </div>
    </Form>
  </section>
</template>

<style lang="scss" scoped>
@use '@/shared/assets/styles/mixins';

.el-form-item {
  margin-bottom: 5px;
}

.btn-group {
  display: flex;
  margin-top: 25px;
}

.el-input {
  max-width: 300px;
}

h4 {
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
}

p {
  margin-bottom: 5px;
}
</style>
