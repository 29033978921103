<template>
  <PvButton
    v-if="showButton"
    class="back-to-top"
    icon="fa-solid fa-chevron-up"
    label="To the top"
    @click="goToTop"
  />
</template>

<script setup>
import PvButton from 'primevue/button';
import { ref, computed, onBeforeUnmount } from 'vue';

/** Back To Top Button */
defineOptions({
  name: 'BackToTop',
});

const timer = ref(null);
const scrollPosition = ref(0);
const showButton = computed(() => scrollPosition.value > 250);
/** Scroll event handler */
const handleScroll = () => {
  if (Number.isInteger(timer.value)) return;

  timer.value = setTimeout(() => {
    scrollPosition.value = window.scrollY;
    clearTimeout(timer.value);
    timer.value = null;
  }, 100);
};
/** Scrolls to top of page on click */
const goToTop = () => {
  const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
  const behavior = mediaQuery?.matches ? 'auto' : 'smooth';

  window.scrollTo({
    top: 0,
    behavior,
  });
};

/** Adds scroll listener */
window.addEventListener('scroll', handleScroll);

/** Removes scroll listener and clears timeout */
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll, false);
  clearTimeout(timer.value);
});
</script>
<style lang="scss" scoped>
.back-to-top {
  position: fixed;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}
</style>
