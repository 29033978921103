<template>
  <Dialog
    :visible="visible"
    :closable="false"
    :header="modalTitle"
    modal
    style="width: 620px"
    @update:visible="closeDialog"
  >
    <div
      v-if="
        allProductsNotStarted &&
        isUploadRenewalRatePass &&
        !threeflowAssistSupported
      "
      class="renewal-form-to-display-radios"
    >
      <div class="flex gap-2">
        <RadioButton
          v-model="renewalFormToDisplay"
          data-test="show Upload Renewal documents form"
          input-id="upload-documents"
          value="uploadDocuments"
        />
        <label for="upload-documents">Upload Renewal documents</label>
      </div>
      <div class="flex gap-2">
        <RadioButton
          v-model="renewalFormToDisplay"
          data-test="show Rate pass form"
          value="ratePass"
        />
        <label for="rate-pass"
          >Rate pass for all products without documents</label
        >
      </div>
    </div>
    <p v-if="renewalFormToDisplay !== 'ratePass'">
      ThreeFlow will find rate and plan design information from your uploaded
      PDF document and enter it in your quote.
      <Button
        data-test="upload pdf help link"
        as="a"
        href="https://support.threeflow.com/en/articles/9179732-how-do-i-save-a-document-as-a-pdf"
        target="_blank"
        variant="link"
        label="How to save my .doc or .xls as a PDF."
      />
    </p>
    <template v-if="renewalFormToDisplay === 'uploadDocuments'">
      <FileUpload
        :product-ids="baseProductIds"
        @closeDialog="closeDialog()"
        @refresh-and-close="refreshDataAndClose"
        @submit-document="emits('submitDocument', $event)"
      />
    </template>

    <template v-if="renewalFormToDisplay === 'ratePass'">
      <SetRateGuarantee
        :selected-products="baseProductIds"
        :products="products"
        :btn-saving="btnSaving"
        @update-btn-saving="btnSaving = $event"
        @refresh-and-close="refreshDataAndClose"
      />
    </template>
  </Dialog>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectStore } from '@/stores/project.js';
import { useProductStore } from '@/stores/product.js';
import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import RadioButton from 'primevue/radiobutton';
import FileUpload from './FileUpload.vue';
import SetRateGuarantee from './SetRateGuarantee.vue';

/**
 * Start or update renewal modal dialog. Conditionally displays Rate Pass/Rate
 * Guarantee form or Upload Renewal documents form.
 *
 * @exports StartOrUpdateRenewalModal
 */
defineOptions({
  name: 'StartOrUpdateRenewalModal',
});

const props = defineProps({
  /**
   * Determines if all products are in "Not Started" state.
   *
   * @type {boolean}
   */
  allProductsNotStarted: { type: Boolean, required: true },

  /**
   * Controls the visibility of the modal.
   *
   * @default false
   * @type {boolean}
   */
  visible: { type: Boolean, default: false },

  /**
   * List of products associated with the modal.
   *
   * @default [ ]
   * @type {Array}
   */
  products: { type: Array, default: () => [] },

  /**
   * Document ID associated with the renewal process.
   *
   * @type {number}
   */
  documentId: { type: Number, required: true },
});
const emits = defineEmits(['update:visible', 'closeDialog', 'submitDocument']);
// Store Access
const carrierInfoStore = useCarrierInfoStore();
const projectStore = useProjectStore();
const productStore = useProductStore();
const { id: carrierId } = storeToRefs(carrierInfoStore);
const { projectId } = storeToRefs(projectStore);
const { isUploadRenewalRatePass, threeflowAssistSupported } =
  storeToRefs(productStore);
// Reactive Variables
const btnSaving = ref(false);
const renewalFormToDisplay = ref('uploadDocuments');
/**
 * If every product is in a Not Started state, the modal is a Start Renewal
 * modal Otherwise, it's an Update Renewal Modal.
 *
 * @returns {boolean}
 */
const isUpdateModal = computed(
  () => !props.products.every(({ state }) => /not_started/i.test(state)),
);
/**
 * Display "Start Renewal/Quote" if every product is in the Not Started state
 * Otherwise, display "Update Renewal/Quote".
 *
 * @returns {string}
 */
const modalTitle = computed(() => {
  const quoteOrRenewal = productStore.smartDocType(true);
  const updateOrStart = isUpdateModal.value ? 'Update' : 'Start';

  return threeflowAssistSupported.value
    ? 'Send to ThreeFlow Assist'
    : `${updateOrStart} ${quoteOrRenewal}`;
});
/**
 * Filter out any alternatives and condense the products into an array of
 * product IDs.
 *
 * @returns {Array}
 */
const baseProductIds = computed(() =>
  // eslint-disable-next-line camelcase
  props.products.reduce((acc, { id, project_product }) => {
    // eslint-disable-next-line camelcase
    if (!project_product?.alternative) {
      acc.push(id);
    }

    return acc;
  }, []),
);
/** Disables saving state and closes the dialog. */
const closeDialog = () => {
  btnSaving.value = false;

  emits('closeDialog', renewalFormToDisplay.value);
  emits('update:visible', false);
};
/** Updates the project info, refreshes the page, and closes the dialog. */
const refreshDataAndClose = async () => {
  const action = isUpdateModal.value ? 'update' : 'start';

  await projectStore.getDocumentsAndRefreshProducts(
    carrierId.value,
    projectId.value,
    action,
  );
  closeDialog();
};

// Lifecycle Hooks
onMounted(() => {
  const startOrUpdateModalOpenedMessage = modalTitle.value.replace(
    /^(Start|Update)/,
    '$1 Smart',
  );

  trackSegmentEvent(startOrUpdateModalOpenedMessage, {
    project_id: projectId.value,
  });
});
</script>

<style lang="scss" scoped>
.renewal-form-to-display-radios {
  display: flex;
  flex-direction: column;

  > div {
    margin-bottom: 10px;
  }

  .el-radio {
    color: inherit;
    font-weight: 700;

    &:not(first-child) {
      margin: 0;
    }
  }
}
</style>
