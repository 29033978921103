import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const normalizedEndpoint = `${config.VUE_APP_API_URL}/v1/carrier_portal`;
const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
const axiosConfig = () => getAxiosConfig(cookieNamespace);

/**
 * Get the Product Tier Groups to construct Rate Options
 *
 * @param {number} productTypeId
 * @returns {Promise}
 */
export const getProductTierGroups = async (productTypeId) => {
  const { data } = await axios.get(
    `${normalizedEndpoint}/product_types/${productTypeId}/tier_groups`,
    axiosConfig(),
  );

  return data;
};

/**
 * Patch product details such as policy ids
 *
 * @param {number} productId
 * @param {Array} policyIds
 * @returns {object}
 */
export const patchProductPolicyIds = async (productId, policyIds) => {
  const url = `${normalizedEndpoint}/products/${productId}`;
  const { data } = await axios.patch(
    url,
    { policy_ids: policyIds },
    axiosConfig(),
  );

  return data;
};

/**
 * Patch product to transition to a new state from threeflow assist.
 *
 * @param {number} productId
 * @returns {Promise}
 */
export const patchProductThreeflowAssistState = async (productId) => {
  const url = `${normalizedEndpoint}/products/${productId}/finish_threeflow_assist`;

  return axios.patch(url, {}, axiosConfig());
};

/**
 * Start a product
 *
 * @param {object} root
 * @param {number} root.productId
 * @param {string} root.endpointState
 * @param {object} root.declinedReason
 * @returns {Promise}
 */
export const patchProductState = async ({
  productId,
  endpointState,
  declinedReason,
}) => {
  const url = `${normalizedEndpoint}/products/${productId}/${endpointState}`;
  const payload = endpointState === 'decline' ? declinedReason : {};
  const { data } = await axios.patch(url, payload, axiosConfig());

  return data;
};
/**
 * Set a product as ready complete
 *
 * @param {number} productId
 * @returns {Promise}
 */
export const patchSubmitQuote = async (productId) => {
  const url = `${normalizedEndpoint}/products/${productId}/submit`;
  const { data } = await axios.patch(url, {}, axiosConfig());

  return data;
};

/**
 * Patch user saved preferences for manual rate entry via proposalDocumentId
 *
 * @param {number} proposalDocumentId
 * @returns {object}
 */
export const patchUserSavedPreferences = async (proposalDocumentId) => {
  const url = `${normalizedEndpoint}/documents/${proposalDocumentId}/enter_manually`;
  const { data } = await axios.patch(url, {}, axiosConfig());

  return data;
};

/**
 * All product related ajax calls
 *
 * @exports ProductService
 */
export default {
  getProductTierGroups,
  patchProductState,
  patchSubmitQuote,

  /**
   * Get the normalized values to populate dropdowns on the plan design page
   *
   * @param {number} productId
   * @returns {Promise}
   */
  getNormalizedValues(productId) {
    const url = `${normalizedEndpoint}/products/${productId}/normalized_values`;

    return axios.get(url, axiosConfig()).then(({ data }) => data);
  },

  /**
   * Get Rates/Categories/Classes for a Product
   *
   * @param {number} productId
   * @returns {Promise}
   */
  getProduct(productId) {
    const url = `${normalizedEndpoint}/products/${productId}`;

    return axios.get(url, axiosConfig()).then(({ data }) => data);
  },

  /**
   * Get all the different product types for a Carrier to offer used for Carrier
   * Management
   *
   * @returns {Promise}
   */
  getProductTypes() {
    const url = `${config.VUE_APP_API_URL}/v1/carrier_portal/admin/product_types`;

    return axios.get(url, axiosConfig()).then(({ data }) => data);
  },

  /**
   * Get the Plan Design Completeness for all the products
   *
   * @param {number} documentId
   * @returns {Promise}
   */
  getPlanDesignCompleteness(documentId) {
    const url = `${config.VUE_APP_API_URL}/v1/carrier_portal/documents/${documentId}/plan_design_completeness_percentages`;

    return axios.get(url, axiosConfig()).then(({ data }) => data);
  },

  /**
   * Start a Rate Pass Renewal per product
   *
   * @param {object} rateGuarantees
   * @returns {Promise}
   */
  ratePassRenewals(rateGuarantees) {
    const url = `${normalizedEndpoint}/products/rate_pass_renewals`;

    return axios
      .patch(url, rateGuarantees, axiosConfig())
      .then(({ data }) => data);
  },
};
