<script setup>
/**
 * Resend Forgot Password
 *
 * @exports ResendConfirmation
 */
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useAccountStore } from '@/stores/account.js';
import ServiceAccount from '@/services/account.js';
import AppResendConfirmation from '@/shared/components/AuthPages/AppResendConfirmation.vue';

defineOptions({
  name: 'ResendConfirmation',
});

const route = useRoute();
const accountStore = useAccountStore();
const { userInfo } = storeToRefs(accountStore);
const accountService = ServiceAccount;
/**
 * Evaluate the route params and return an object with all of the correct data.
 *
 * @returns {object}
 */
const resendConfirmationProps = computed(() => {
  const { projectId } = route.params;
  const props = {
    linkConfirmation: { name: 'RegisterConfirmation' },
    linkLogin: { name: 'ProjectSignIn' },
    linkRegister: { name: 'RegistrationForm' },
  };

  if (projectId) {
    Object.keys(props).forEach((key) => {
      props[key] = {
        name: `Project${props[key].name}`,
        params: { projectId },
      };
    });

    props.projectId = projectId;
  }

  return props;
});
</script>

<template>
  <section>
    <AppResendConfirmation
      v-bind="{
        accountService,
        ...resendConfirmationProps,
        userInfo,
      }"
    />
  </section>
</template>
