import { computed, ref, watch } from 'vue';

/* eslint-disable jsdoc/valid-types */
/* eslint-disable default-param-last */
/**
 * Composable to handle `v-model` behavior for a prop. This function creates a
 * reactive value that syncs with a prop while allowing local modifications and
 * default values.
 *
 * @param {object} props - The component's props object.
 * @param {string} [propName] - The name of the prop used for v-model.
 * @param {Function} emit - The emit function to trigger updates.
 * @param {any} [defaultValue] - The default value if the prop is undefined.
 * @returns {import('vue').ComputedRef<any>} A computed property that acts as a
 *   two-way binding.
 */
export function useModelValue(
  props,
  propName = 'modelValue',
  emit,
  defaultValue = null,
) {
  /* eslint-enable default-param-last */
  // Create a local reference for the value, falling back to the defaultValue if undefined
  const localValue = ref(props[propName] || defaultValue);

  // Watch for changes in the prop and update the local value accordingly
  watch(
    () => props[propName],
    (newValue) => {
      if (newValue !== localValue.value) {
        localValue.value = newValue; // Sync local value with prop when it changes
      }
    },
  );

  /**
   * Computed property to handle the v-model behavior:
   *
   * - Reads from the prop if defined, otherwise uses localValue.
   * - Emits an update event when changed, ensuring reactivity.
   */
  return computed({
    get() {
      return props[propName] !== undefined ? props[propName] : localValue.value;
    },
    set(value) {
      localValue.value = value; // Update local value
      emit(`update:${propName}`, value); // Emit event for parent to update prop
    },
  });
}
