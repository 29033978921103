<template>
  <div class="table-display-options">
    <div>
      <h4><slot name="title" /></h4>
    </div>
    <slot name="formElements" />
    <template v-if="!props.readonly">
      <slot name="highlightSource" />
      <!-- Slot for the Copy button for Stop Loss products -->
      <slot name="copyFrom" />
      <!-- Show deviation checkbox is a slot so it's custom. -->
      <slot name="showDeviations" />
    </template>
    <span v-else class="edit-values-btn-container">
      <slot name="btnEdit" />
    </span>
  </div>
</template>

<script setup>
/**
 * Table Header
 *
 * @exports TableHeader
 */
// Props
const props = defineProps({
  /** Determines if Edit Button or Highlight Deviations checkbox should show. */
  readonly: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" scoped>
.table-display-options {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 30px;
  margin-bottom: 1rem;
  background-color: var(--tf-gray-light);

  div:last-child {
    text-align: right;
  }

  h4,
  .edit-values-btn-container,
  .el-form-item {
    margin-bottom: 15px;
  }

  h4 {
    .dialog-plan-summary & {
      font-size: 14px;
    }
  }

  .dialog-plan-summary & {
    flex-direction: column;
    margin: 0 0 30px;
  }
}
/* TODO: Handle this without disable */
/* stylelint-disable no-descending-specificity */
.edit-values-btn-container {
  display: flex;
  justify-content: flex-end;
  min-height: 18px;
}
/* stylelint-enable no-descending-specificity */

// stylelint-disable-next-line selector-max-compound-selectors
:deep(.el-select .el-input.el-input--medium .el-input__inner) {
  // without this the Rate Options dropdown doesn't line up....b/c....things
  // stylelint-disable-next-line declaration-no-important
  padding-right: 30px !important;
  margin-right: 12px;
}

.has-source-dropdown {
  :deep(.el-form-item__content) {
    /* Needs !important to override higher specificity from ElFormItem */
    // stylelint-disable-next-line declaration-no-important
    margin-left: 30px !important;
    transform: translateY(25px);
  }
}
</style>
