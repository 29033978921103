<template>
  <div class="rfp-status-container">
    <UiBreadcrumbs />
    <div
      id="rfp-overview-status-content"
      :class="isUploadRenewalRatePass ? 'upload-renewal-on' : ''"
    >
      <ProgressSpinner v-if="!proposalDocument" />
      <div
        v-if="proposalDocument && project"
        id="proposal-info"
        class="flex items-center gap-4"
      >
        <h1 data-test="employer name header" class="m-0">
          {{ project.employer?.name }}
        </h1>
        <p v-if="products?.length" class="justify-items-center m-0">
          <Badge
            :severity="stateTextAndClass.status"
            :value="stateTextAndClass.text"
            size="large"
            data-test="opportunity status badge"
          />
          <span
            v-if="dueDate.appendText"
            id="due-date"
            data-test="due date"
            :class="{ 'text-danger': dueDate.dayDifference < 0 }"
            v-text="dueDate.appendText"
          />
        </p>
      </div>

      <Dialog
        :visible="allProductsSubmittedDialogVisible"
        class="products-submitted-dialog"
        data-test="submitted dialog"
        modal
        @close="allProductsSubmittedDialogVisible = false"
      >
        <img :src="productsSubmittedIcon" />
        <h2 data-test="dialog header">
          All products have been submitted
          <template v-if="broker.name"> to {{ broker.name }}</template>
          !
        </h2>
        <p>
          You can continue to refine and track your quote and collaborate with
          your broker here.
        </p>
        <Button
          icon="
            fa-solid
            fa-comments"
          size="text"
          label="Start a conversation"
          data-test="start conversation"
          @click="onStartAConversationClick"
        />
      </Dialog>
      <div class="btn-group">
        <Button
          v-tooltip.left="{
            value:
              (isWatching ? 'Unsubscribe to no longer' : 'Subscribe to') +
              ' receive email notifications related to this RFP.',
            pt: { root: { class: 'custom-tooltip' } },
          }"
          :icon="`fa-solid ${isWatching ? 'fa-solid fa-bell' : 'fa-regular fa-bell'}`"
          rounded
          outlined
          :disabled="isProcessingDocuments"
          :loading="isSavingWatch"
          :severity="isWatching ? 'danger' : 'primary'"
          data-test="subscribe to rfp"
          size="small"
          @click="toggleWatch"
        />
        <Button
          :icon="`fa-solid ${isUploadDropdownOpen ? 'fa-solid fa-file' : 'fa-regular fa-file'}`"
          rounded
          outlined
          :disabled="isProcessingDocuments"
          data-test="open upload menu"
          size="small"
          @click="togglePopover"
        />
        <Popover
          ref="op"
          :offset="-9"
          data-test="upload options"
          placement="bottom-end"
          popper-class="upload-popover"
          @hide="isUploadDropdownOpen = false"
          @show="isUploadDropdownOpen = true"
        >
          <ul class="popover-body">
            <li
              id="btn-supplemental-documents"
              data-test="supplemental documents"
              @click="isModalSupplementalDocumentsOpen = true"
            >
              Supplemental documents
            </li>
            <li v-if="isCsvQuoteEnabled" @click="isModalQuoteFileOpen = true">
              Quote file
            </li>
          </ul>
        </Popover>
      </div>
      <ProposalManagerModal
        v-if="proposalDocument && proposals"
        v-model:visible="isModalSupplementalDocumentsOpen"
        :proposals="proposals"
        data-test="proposal manager"
      />
      <QuoteFileModal
        v-if="isModalQuoteFileOpen"
        v-model:visible="isModalQuoteFileOpen"
        data-test="edit quote file"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useNotificationsStore } from '@/stores/notifications.js';
import { useProjectStore } from '@/stores/project.js';
import { useProductStore } from '@/stores/product.js';
import { useProjectContactsStore } from '@/stores/projectContacts.js';
import { useProductTableStore } from '@/stores/productTable.js';
import ServiceProject from '@/services/project.js';
import productsSubmittedIcon from '@/assets/products-submitted.svg';
import { segmentData } from '@/utils/analytics.js';
import {
  isStopLossProduct,
  formatSimpleDate,
  trackSegmentEvent,
} from '@watchtowerbenefits/es-utils-public';
import ProposalManagerModal from '@/components/Modals/ProposalManagerModal.vue';
import QuoteFileModal from '@/components/Modals/QuoteFileModal/index.vue';
import { inProgressSteps } from '@/utils/product.js';
import Button from 'primevue/button';
import Badge from 'primevue/badge';
import Dialog from 'primevue/dialog';
import Popover from 'primevue/popover';
import ProgressSpinner from 'primevue/progressspinner';
import UiBreadcrumbs from '@/shared/components/UiBreadcrumbs.vue';
import { useToast } from 'primevue/usetoast';

defineOptions({
  name: 'RfpStatus',
});

const dayInMillisecond = 1000 * 60 * 60 * 24;
const router = useRouter();
const toast = useToast();
// Stores
const notificationsStore = useNotificationsStore();
const projectStore = useProjectStore();
const productStore = useProductStore();
const projectContactsStore = useProjectContactsStore();
const { loadContacts } = projectContactsStore;
const productTableStore = useProductTableStore();
// Store Refs
const { allProductsSubmittedDialogVisible } = storeToRefs(notificationsStore);
const {
  broker,
  csvQuoteEnabled,
  project,
  projectId,
  proposalDocument,
  proposals,
} = storeToRefs(projectStore);
const { products, isProcessingDocuments, isUploadRenewalRatePass } =
  storeToRefs(productStore);
// Reactive Data
const isUploadDropdownOpen = ref(false);
const isModalSupplementalDocumentsOpen = ref(false);
const isModalQuoteFileOpen = ref(false);
const isSavingWatch = ref(false);
const alertWatching = ref(null);
const op = ref(null);
// Computed Properties
/**
 * Evaluate the isCsvQuoteEnabled feature flag.
 *
 * @returns {boolean}
 */
const isCsvQuoteEnabled = computed(
  () =>
    csvQuoteEnabled.value &&
    products.value.some((product) => isStopLossProduct(product)),
);
/**
 * Returns the class and text for the `state badge` in the subHeader
 *
 * @returns {object}
 */
const dueDate = computed(() => {
  const due = new Date(project.value.due_date);
  const today = new Date();
  let appendText = '';

  today.setHours(0, 0, 0, 0);

  const dayDifference = Math.round((due - today) / dayInMillisecond);

  if (
    products.value.some(
      (product) =>
        ['not_started', ...inProgressSteps].includes(product.state) &&
        !productTableStore.notSoldToThisCarrier(product.project_product),
    )
  ) {
    appendText = '(past due)';

    if (dayDifference === 0) {
      appendText = '(Due today)';
    } else if (dayDifference > 0) {
      appendText = ` (${dayDifference} days)`;
    }

    appendText = `Due ${formatSimpleDate(project.value.due_date)} ${appendText}`;
  }

  return { due, dayDifference, appendText };
});
/**
 * Evaluate 'is_watching' from this.project to determine styles and unsubscribe
 * or subscribe patch call.
 *
 * @returns {boolean}
 */
const isWatching = computed(() => project.value.is_watching);
/**
 * Returns the class and text for the `state badge` in the subHeader
 *
 * @returns {object}
 */
const stateTextAndClass = computed(() => {
  const { badge } = productTableStore.projectStatusFromProducts(products.value);
  let status = 'notify';

  if (badge === 'Submitted') status = 'success';
  if (badge === 'Incomplete') status = 'warn';
  if (badge === 'Declined') status = 'error';

  return { text: badge, status };
});
// Methods
const togglePopover = (event) => {
  op.value?.toggle(event);
};
/**
 * If the user clicks 'start a conversation' in the allProductsSubmittedDialog -
 * we need to close it and open the comments tab
 */
const onStartAConversationClick = () => {
  allProductsSubmittedDialogVisible.value = false;
  router.push({ name: 'Comments' });
};
/**
 * Call the "patchCarrierProjectSubscription" to fire the subscribe or
 * unsubscribe action based on the current project state. Update the new value
 * in the project state within the store and API, then show appropriate
 * $message.
 */
const toggleWatch = async () => {
  const key = 'is_watching';
  const actionType = isWatching.value ? 'unsubscribe' : 'subscribe';
  const onLoaded = (type = 'success') => {
    const eventTitle =
      actionType === 'subscribe'
        ? 'Subscribe to opportunity'
        : 'Unsubscribe from opportunity';

    trackSegmentEvent(eventTitle, segmentData());

    let detail = `You’ll ${isWatching.value ? 'now' : 'no longer'} receive email notifications related to this RFP.`;

    if (type === 'error') {
      detail =
        'We are not able to process your request. Please try again or contact support@threeflow.com for help.';
    }

    isSavingWatch.value = false;
    alertWatching.value = {
      closable: true,
      detail,
      severity: type,
      life: 5000,
    };

    toast.add(alertWatching.value);
  };

  isSavingWatch.value = true;

  if (alertWatching.value) {
    toast.remove(alertWatching.value);
    alertWatching.value = null;
  }

  await nextTick();
  ServiceProject.patchCarrierProjectSubscription(
    projectId.value,
    actionType,
    null, // no email needed for this call
    ({ project: serviceProject }) => {
      projectStore.setProjectInfoParameter({ key, value: serviceProject[key] });
      onLoaded();
      loadContacts(projectId.value); // reload Contacts tab table data
    },
    () => onLoaded('error'),
  );
};
</script>

<style lang="scss" scoped>
.custom-tooltip {
  // stylelint-disable-next-line declaration-no-important
  transform: translateX(10px) !important; /* Moves tooltip 10px to the right */
}

.rfp-status-container {
  padding-top: 40px;
  padding-bottom: 40px;
}

#rfp-overview-status-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  &.upload-renewal-on {
    background: var(--tf-white);
  }
}

.btn-group {
  button {
    margin-left: 15px;
  }
}

#due-date {
  display: block;

  @media (min-width: 600px) {
    display: inline-block;
    margin-left: 10px;
  }
}

#proposal-info {
  h1,
  p {
    white-space: nowrap;
  }

  p {
    margin: 0;
    line-height: 1.8;
  }
}

.text-danger {
  font-weight: 700;
}

// stylelint-disable-next-line no-descending-specificity
h1 {
  font-size: 24px;
  font-weight: 600;
  color: var(--tf-gray-dark);
  margin: 0;
}

:deep(.products-submitted-dialog) {
  text-align: center;

  h2 {
    font-weight: 600;
    margin-bottom: 15px;
  }

  // stylelint-disable-next-line no-descending-specificity
  p {
    margin-bottom: 20px;
  }
}

.popover-body {
  li {
    padding: 12px 16px;
    cursor: pointer;

    &:hover,
    &:active {
      background-color: var(--tf-blue-light);
    }
  }
}
</style>
