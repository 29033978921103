<script setup>
/**
 * AppResendConfirmation Component
 *
 * @exports AppResendConfirmation
 */
import { onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Button from 'primevue/button';
import AuthPageHeader from '@/shared/components/AuthPages/Header.vue';
import AuthPageFooter from '@/shared/components/AuthPages/Footer.vue';

defineOptions({
  name: 'AppResendConfirmation',
});

const props = defineProps({
  documentId: {
    type: String,
    default: null,
  },
  accountService: {
    type: Object,
    default: () => ({}),
  },
  linkConfirmation: {
    type: [String, Object],
    required: true,
  },
  linkLogin: {
    type: [String, Object],
    required: true,
  },
  linkRegister: {
    type: [String, Object],
    required: true,
  },
  projectId: {
    type: [String, Number],
    default: null,
  },
  userInfo: {
    type: Object,
    default: () => ({}),
  },
});
const router = useRouter();
const route = useRoute();

/** If the email address isn't passed, redirect to the login page. */
onMounted(() => {
  if (!props.userInfo?.email) {
    router.replace(route.query.redirect || props.linkLogin);
  }
});

/** Call the AccountService to send a new confirmation email. */
const resendConfirmation = async () => {
  if (!props.accountService?.resendConfirmation) {
    return;
  }

  try {
    await props.accountService.resendConfirmation({
      documentId: props.documentId,
      email: props.userInfo?.email,
      projectId: props.projectId,
    });

    const redirectURL = route.query.redirect || props.linkConfirmation;

    await router.replace(redirectURL);
  } catch (e) {
    // TODO: replace with proper error handling
  }
};
</script>

<template>
  <section id="resend-confirmation-container" class="auth-form">
    <AuthPageHeader title="I didn’t receive the temporary password email." />
    <ol>
      <li>
        Is the {{ userInfo.email }} your correct email without typos? If not,
        you can
        <RouterLink :to="linkRegister">
          restart the account creation process.</RouterLink
        >
      </li>
      <li>Check your spam folder</li>
      <li>Add email@threeflow.com to your contacts</li>
    </ol>
    <p><strong>Or we can try again:</strong></p>
    <p>{{ userInfo.email }}</p>
    <p>
      <slot name="ctaSubmit">
        <Button
          label="Resend confirmation email"
          severity="help"
          variant="outlined"
          @click="resendConfirmation"
        />
      </slot>
    </p>
    <AuthPageFooter />
  </section>
</template>

<style scoped lang="scss">
.auth-form {
  max-width: 570px;
  text-align: left;
}

ol {
  margin-bottom: 25px;
  padding-left: 20px;
  list-style: decimal;
}

li {
  font-size: 14px;
  line-height: 1.86;
}

p {
  margin: 0 0 15px;
}
</style>
