import { computed } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useRouter, useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useBrokerUpdatesStore } from '@/stores/brokerUpdates.js';
import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
import { useQuoteEditsStore } from '@/stores/quoteEdits.js';
import { useCommentsStore } from '@/stores/comments.js';
import { useNotificationsStore } from '@/stores/notifications.js';
import { useProjectStore } from '@/stores/project.js';
import { useAccountStore } from '@/stores/account.js';
import ServiceAccount from '@/services/account.js';

/** @returns {object} Project composable */
export function useProjectComposable() {
  const ToastService = useToast();
  const router = useRouter();
  const route = useRoute();
  const brokerUpdatesStore = useBrokerUpdatesStore();
  const { id: carrierId } = storeToRefs(useCarrierInfoStore()); // TODO: use storeToRefs approach and destruct store to get needed data instead of computed
  const quoteEditsStore = useQuoteEditsStore();
  const commentsStore = useCommentsStore();
  const notificationsStore = useNotificationsStore();
  const projectStore = useProjectStore();
  const accountStore = useAccountStore();
  const unreadEdits = computed(() => notificationsStore.unreadEdits);
  const unreadBrokerUpdates = computed(() => brokerUpdatesStore.notifications);
  const quoteEditsFetched = computed(() => quoteEditsStore.quoteEditsFetched);
  const commentsIsLoaded = computed(() => commentsStore.isLoaded);
  const projectId = computed(() => projectStore.projectId);
  const brokerIsLoaded = computed(() => projectStore.brokerIsLoaded);
  const brokerIsLoading = computed(() => projectStore.brokerIsLoading);
  const project = computed(() => projectStore.project);
  const proposalDocument = computed(() => projectStore.proposalDocument);
  const proposalsIsLoaded = computed(() => projectStore.proposalsIsLoaded);
  const proposalsIsLoading = computed(() => projectStore.proposalsIsLoading);
  const inforceDocumentId = computed(() => projectStore.inforceDocumentId);
  const invitedToProject = computed(() => accountStore.invitedToProject);
  const userInfo = computed(() => accountStore.userInfo);
  const projectIsLoaded = computed(
    () =>
      brokerIsLoaded.value &&
      projectStore.documentsIsLoaded &&
      projectStore.localProjectIsLoaded &&
      proposalsIsLoaded.value,
  );
  const projectIsLoading = computed(
    () =>
      brokerIsLoading.value ||
      projectStore.documentsIsLoading ||
      projectStore.localProjectIsLoading ||
      proposalsIsLoading.value,
  );
  const checkInvited = async () => {
    if (invitedToProject.value !== null) return;

    projectStore.setDocumentsLoaded(true);

    try {
      const { invited } = await ServiceAccount.checkInvitedToProject(
        userInfo.value.email,
        projectId.value,
      );

      accountStore.invitedToProject = invited;

      if (!invited) {
        router.push({ name: 'Dashboard' });
        ToastService.add({
          severity: 'warn',
          summary: 'Invitation Error',
          detail: 'You have not been invited to this RFP.',
        });
      }
    } catch (error) {
      // console.log(error)
    }
  };
  const createToast = () => {
    ToastService.add({
      severity: 'warn',
      summary: 'RFP Update',
      detail: 'Your RFP has been recently updated.',
      life: 10000,
      content: 'View updates',
      onClick: () => {
        const routePath =
          route.meta.pageType === 'dataEntry'
            ? `${route.fullPath}/broker-updates`
            : { name: 'UpdatesEdits' };

        router.push(routePath);
      },
    });
  };
  const getAllProjectData = async (projectIdVal) => {
    await Promise.all([
      projectStore.getProjectInfo(projectIdVal),
      projectStore.getBrokerInfo(projectIdVal),
      projectStore.getProjectDocuments(carrierId.value, projectIdVal),
      commentsStore.getCommentsNotifications(projectIdVal),
      notificationsStore.getEditsNotifications(projectIdVal),
      brokerUpdatesStore.getUpdatesAndNotifications(projectIdVal),
    ]);

    if (!proposalDocument.value) return;

    const { id } = proposalDocument.value;

    await Promise.all([
      quoteEditsStore.getQuoteEdits(id),
      projectStore.getProposalDocument(id),
      projectStore.getPolicyDocument(inforceDocumentId.value),
    ]);

    if (unreadBrokerUpdates.value) createToast();
  };
  const resetProject = () => {
    const testAndReset = [
      ['localProjectIsLoaded'],
      ['quoteEditsFetched', 'resetQuoteEditState'],
    ];

    accountStore.invitedToProject = null;
    notificationsStore.unreadEdits = null;
    brokerUpdatesStore.notifications = 0;

    if (commentsIsLoaded.value) commentsStore.$reset();
    projectStore.resetProjectState();

    testAndReset.forEach(([test, action]) => {
      if (projectStore[test]) projectStore[action]();
    });
  };

  return {
    projectIsLoaded,
    projectIsLoading,
    checkInvited,
    getAllProjectData,
    resetProject,
    createToast,

    unreadEdits,
    unreadBrokerUpdates,
    quoteEditsFetched,
    commentsIsLoaded,
    carrierId,
    projectId,
    brokerIsLoaded,
    brokerIsLoading,
    project,
    proposalDocument,
    proposalsIsLoaded,
    proposalsIsLoading,
    inforceDocumentId,
    invitedToProject,
    userInfo,
  };
}
