import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const normalizedEndpoint = `${config.VUE_APP_API_URL}/v1/carrier_portal`;
const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
const axiosConfig = () => getAxiosConfig(cookieNamespace);

/**
 * All proposal/source related ajax calls
 *
 * @exports ProposalService
 */
export default {
  /**
   * Update the name of the proposal file
   *
   * @param {number} sourceId
   * @param {string} name
   * @returns {Promise}
   */
  updateProposalName(sourceId, name) {
    const url = `${normalizedEndpoint}/sources/${sourceId}`;

    return axios
      .patch(url, { name }, axiosConfig())
      .then((response) => response);
  },
  /**
   * Submits a csv Quote
   *
   * @param {number | string} csvQuoteId
   * @param {number | string} documentId
   * @returns {Promise}
   */
  submitCsvQuote(csvQuoteId, documentId) {
    const url = `${normalizedEndpoint}/csv_bid_submissions/${csvQuoteId}/submit`;

    return axios
      .patch(url, { document_id: documentId }, axiosConfig())
      .then(({ data }) => data);
  },
  /**
   * Checks to see if csv file is valid for carrier quote submission
   *
   * @param {number | string} csvQuoteId
   * @param {number | string} documentId
   * @returns {Promise}
   */
  validateCsvFile(csvQuoteId, documentId) {
    const url = `${normalizedEndpoint}/csv_bid_submissions/${csvQuoteId}/validate_integration`;

    return axios
      .patch(url, { document_id: documentId }, axiosConfig())
      .then(({ data }) => data);
  },
  /**
   * Will check the specific csv_quote_submission previously uploaded to see if
   * all the product values meet all specified requirements on the backend
   *
   * @param {number | string} csvQuoteId
   * @param {number | string} documentId
   * @returns {Promise}
   */
  validateCsvQuoteValues(csvQuoteId, documentId) {
    const url = `${normalizedEndpoint}/csv_bid_submissions/${csvQuoteId}/validate_bid_values`;

    return axios
      .patch(url, { document_id: documentId }, axiosConfig())
      .then(({ data }) => data);
  },
};
