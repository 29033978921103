<template>
  <nav>
    <div>
      <RouterLink
        v-if="carrierInfoStore.logoUrl && !isAdmin"
        :to="{ name: 'Dashboard' }"
      >
        <img
          id="carrier-logo"
          :data-test="`${carrierInfoStore?.name?.toLowerCase()} logo`"
          :src="carrierInfoStore.logoUrl"
          :alt="`${carrierInfoStore.name} logo`"
        />
      </RouterLink>
      <img
        v-if="isAdmin"
        id="admin-logo"
        src="@/assets/logo.svg"
        alt="Threeflow 3 logo"
      />
      <div
        v-if="route.meta.pageType === 'dataEntry'"
        id="project-product-selector"
      ></div>
    </div>
    <div class="flex justify-between">
      <span
        v-if="route.meta.pageType === 'dataEntry'"
        class="proposal-name"
        v-text="employerName"
      />
      <GlobalHamburgerMenu />
    </div>
  </nav>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
import { useProjectStore } from '@/stores/project.js';
import { useAccountStore } from '@/stores/account.js';

import GlobalHamburgerMenu from '@/components/GlobalNavigation/GlobalHamburgerMenu.vue';

defineOptions({
  name: 'GlobalNavigation',
});

// Stores
const carrierInfoStore = useCarrierInfoStore();
const projectStore = useProjectStore();
const accountStore = useAccountStore();
const route = useRoute();
// Store References
const { employerName } = storeToRefs(projectStore);
const { roleNames } = storeToRefs(accountStore);
/**
 * Does user roles contain admin
 *
 * @returns {boolean}
 */
const isAdmin = computed(() => roleNames.value?.includes('admin'));
</script>

<style lang="scss" scoped>
nav {
  justify-content: space-between;
  border-bottom: 1px solid var(--tf-gray-medium);

  &,
  > div {
    display: flex;
    align-items: center;
  }
}

#carrier-logo,
#admin-logo {
  height: 50px;
  margin-left: 20px;
}

#project-product-selector {
  width: 400px;
  margin-left: 30px;
}

.el-menu.el-menu--horizontal {
  border-bottom: 0;
}

.proposal-name {
  margin-right: 10px;
  font: {
    size: 16px;
    weight: bold;
  }
  color: var(--tf-gray-dark);

  @media (max-width: 767px) {
    display: none;
  }
}

.nav-button {
  margin-right: 25px;

  :deep(.button-text > span) {
    margin-right: 10px;
  }
}
</style>
