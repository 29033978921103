import { VueLd } from 'vue-ld'; // Plugin to integrate Launch Darkly into Vue apps
// eslint-disable-next-line import/extensions
import {
  developmentFlagValues,
  defaultFlagValues,
} from '@/utils/featureFlags.js';
import { canUseThirdParty } from '@/utils/general.js';
import { config } from '@/utils/config.js';
import { trackLogRocketEvent } from '@/utils/analytics.js';

/**
 * Launch darkly configuration functions including initialization and identify
 * calls for when a user changes
 *
 * @exports launchDarklyUtils
 */

// When we're in a local environment, we want to stub flags by default (use the developmentFlagValues values)
// however if we run a local build with `serve:with --third_parties=launchDarkly`, we don't stub and instead use
// the values from the launch darkly QA environment.
const shouldStubFlags =
  config.featureFlagsDisabled(['development', 'test', 'ci']) &&
  !canUseThirdParty('launchDarkly');

/**
 * Adds checkFlags method to the global LaunchDarkly object to allow us to
 * declare default flags in case a flag cannot be found or a connection to
 * LaunchDarkly cannot be established
 *
 * @param {string} flagName
 * @param {object} ld - The LaunchDarkly object
 * @returns {number | string | boolean}
 */
export const checkFlags = (flagName, ld) => {
  if (window.Cypress?.cypressOverrideFlags?.hasOwnProperty(flagName)) {
    return window.Cypress.cypressOverrideFlags[flagName];
  }

  const { ready, flags } = ld;
  // Unable to call hasOwnProperty on a global variable, so object.keys used instead
  const ldFlagNameExists = flags && Object.keys(flags).includes(flagName);
  const defaultFlagNameExists = Object.prototype.hasOwnProperty.call(
    defaultFlagValues,
    flagName,
  );

  if (shouldStubFlags || (ready && ldFlagNameExists)) {
    if (flags[flagName]) trackLogRocketEvent(flagName);

    return flags[flagName];
  }

  // If a flag cannot be found or a connection to LaunchDarkly cannot be established and there is
  // no defaultFlagValue in the utility file, then the feature flag's value falls back to false.
  // Whether or not we want it to be true or false by default is a conversation for the team
  return defaultFlagNameExists ? defaultFlagValues[flagName] : false;
};

/**
 * Initializes Launch Darkly with an anonymous user and the clientSideId that
 * corresponds with the the current environment. Also passes any custom options
 * to the Launch Darkly client. Leverages dashHudsons Vue-Ld Vue plugin to
 * interface with Launch Darkly: https://github.com/dashhudson/vue-ld
 *
 * @param {object} app - The Vue application instance.
 * @param {object} configuration - Configuration for LaunchDarkly
 *   initialization.
 * @param {string} configuration.clientSideId - The LaunchDarkly client-side ID
 *   for the current environment.
 * @param {object} [configuration.options] - Additional options to pass to the
 *   LaunchDarkly client.
 * @param {object} [configuration.user] - User object for LaunchDarkly to target
 *   specific flags.
 */
export const initializeLD = (app, { clientSideId, options, user }) => {
  app.use(VueLd, {
    clientSideId,
    options,
    user,
    // If flagsStub is provided, the ldClient will not be initialized and $ld.flags will be set to the provided stub (developmentFlagValues)
    flagsStub: shouldStubFlags ? developmentFlagValues : undefined,
  });
  // eslint-disable-next-line no-param-reassign
  app.config.globalProperties.$ld.checkFlags = (flagName) =>
    checkFlags(flagName, app.config.globalProperties.$ld);
};

/**
 * The identify method notifies Launch Darkly that the user has changed and
 * provides their related userInfo so that Launch Darkly can conditionally
 * render flags based on the user
 *
 * @param {object} app - The Vue application instance.
 * @param {object} configuration - Configuration for LaunchDarkly
 *   initialization.
 */
export const identifyLDUser = (app, configuration) => {
  app.config.globalProperties.$ld.identify({
    hash: configuration.options.hash,
    newUser: configuration.user,
  });
};

/**
 * Parse the LD-CONFIGURATION header on every Core API response as a JSON object
 *
 * @param {object} response
 * @param {object} response.headers
 * @returns {object}
 */
export const parseLDConfig = ({ headers = {} }) =>
  JSON.parse(headers['ld-configuration']);
