<template>
  <div class="product-value-validation">
    <h4>Processed quote</h4>
    <section
      v-for="(proposals, type) in csvQuoteStore.proposalsByProductType"
      :key="type"
    >
      <h5>
        {{ type }}
      </h5>
      <ul>
        <li v-for="{ label, valid } of proposals" :key="label">
          <span
            :class="[
              valid ? 'fa-check' : 'fa-xmark',
              valid ? 'is-primary' : 'is-danger',
            ]"
            class="fa-solid"
            data-test="proposal validation"
          />
          <h6>
            {{ label }}
          </h6>
          <div v-if="!valid" class="product-error">
            An error occurred and this product will not be submitted.<br />
            If you would like to submit this product, delete the document and
            try again.
          </div>
        </li>
      </ul>
    </section>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useCsvQuoteStore } from '@/stores/csvQuote.js';
import { useProjectStore } from '@/stores/project.js';
import ProposalService from '@/services/proposal.js';
import { flattenObjectValues } from '@/utils/file.js';

defineOptions({
  name: 'QuoteFileSubmit',
});
// ✅ Define Props
defineProps({
  parentStep: {
    required: true,
    validator: (propValue) => ['upload', 'submit'].includes(propValue),
  },
});
// ✅ Store Instances
const csvQuoteStore = useCsvQuoteStore();
const projectStore = useProjectStore();
// ✅ Reactive State
const { alert } = storeToRefs(csvQuoteStore);
const { proposalDocumentId } = storeToRefs(projectStore);
const validateCsvQuoteValues = async () => {
  const errorMessages = [];

  try {
    const response = await ProposalService.validateCsvQuoteValues(
      csvQuoteStore.id,
      proposalDocumentId.value,
    );
    const {
      csv_bid_submission: {
        bid_evaluation: { product_types: productTypes },
      },
    } = response;
    // Flatten the deeply nested response
    const flattenedProductTypes = Object.entries(productTypes).reduce(
      (typeAcc, [productName, { product_proposals: proposals }]) => {
        proposals.forEach((proposal) => {
          if (!proposal.valid) {
            const proposalErrors = flattenObjectValues(proposal.errors);

            errorMessages.push(...proposalErrors);
          }
        });

        return { ...typeAcc, [productName]: proposals };
      },
      {},
    );

    csvQuoteStore.setQuote({ proposalsByProductType: flattenedProductTypes });

    if (errorMessages.length) {
      alert.value = {
        type: 'danger',
        messages: errorMessages,
      };
    }
  } catch (error) {
    csvQuoteStore.$reset();
    alert.value = {
      type: 'danger',
      messages: [
        'Something went wrong when attempting to validate the values from the uploaded CSV. Please reupload the CSV file and try again.',
      ],
    };
  }
};

// ✅ Call validation on mount
onMounted(validateCsvQuoteValues);
</script>

<style lang="scss" scoped>
:deep() {
  h5,
  h6 {
    font: {
      weight: 400;
      size: 16px;
    }
  }
}

li {
  position: relative;
  color: var(--tf-gray);
  margin: 30px 0;
  padding-left: 22px;

  &:first-child {
    margin-top: 0;
  }

  .product-error {
    color: var(--tf-red);
    font-size: 12px;
  }
}

svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  transform: translateY(4px);
}
</style>
