<template>
  <div :class="[{ 'has-label': showLabel }, 'comment-input-container']">
    <p v-if="showLabel">
      <strong>{{ label }}</strong>
    </p>
    <transition name="autosave-fade">
      <p
        v-if="timestamp"
        class="timestamp"
        v-text="`Autosaved at ${formattedTimestamp}`"
      />
    </transition>
    <div class="el-textarea">
      <!--
          We're using a textarea here to "solve" https://watchtower.atlassian.net/browse/SUP-166
        -->
      <textarea
        v-model="commentValue"
        :disabled="isSaving"
        data-test="edit comment"
        class="el-textarea__inner"
        placeholder="Enter your comment"
        rows="6"
      />
    </div>
    <div class="btn-group align-end">
      <Button
        severity="secondary"
        data-test="cancel comment"
        @click="onClickCancel"
      >
        Cancel
      </Button>
      <Button
        :disabled="!commentValue || disableSubmit"
        :loading="isSaving"
        data-test="send comment"
        severity="primary"
        @click="onClickSubmit"
      >
        Send
      </Button>
    </div>
    <p v-if="errorOnSubmit" class="text-error" v-text="errorOnSubmit" />
  </div>
</template>
<script setup>
import { ref, computed, onBeforeUnmount, onMounted } from 'vue';
import { formatDateAndTime } from '@watchtowerbenefits/es-utils-public';
import { postComment } from '@/services/comment.js';

import Button from 'primevue/button';
import { useToast } from 'primevue/usetoast';

/**
 * The Comments input component.
 *
 * @exports CommentInput
 */

const props = defineProps({
  apiConfig: {
    type: Object,
    default: () => ({}),
  },
  autoSaveTimestamp: {
    type: String,
    default: null,
  },
  comment: {
    type: Object,
    default: () => ({}),
  },
  commentableIds: {
    type: Array,
    default: () => [],
  },
  customSessionName: {
    type: String,
    default: null,
  },
  disableSubmit: {
    type: Boolean,
    default: false,
  },
  inlineSuccess: {
    type: Boolean,
    default: true,
  },
  parentId: {
    type: Number,
    default: null,
  },
  projectId: {
    type: [Number, String],
    required: true,
  },
  proposalDocumentId: {
    type: Number,
    default: null,
  },
  saveSession: {
    type: Boolean,
    default: true,
  },
  showLabel: {
    type: Boolean,
    default: true,
  },
  userInfo: {
    type: Object,
    default: () => ({
      firstName: '',
      lastName: '',
      organization: '',
      type: 'broker',
    }),
  },
  modelValue: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['update:modelValue', 'cancel', 'commentAdded']);
const toast = useToast();
// Refs
const errorOnSubmit = ref(null);
const timestamp = ref('');
const isSaving = ref(false);
const timer = ref(0);
// Computed
/**
 * Get(): returns the v-model from the parent set(): emits to the parent new
 * model.
 *
 * @returns {string}
 */
const commentValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
    // eslint-disable-next-line no-use-before-define
    if (props.saveSession) updateSession(value);
  },
});
/**
 * Formats this.autoSaveTimestamp to use in the template.
 *
 * @returns {string}
 */
const formattedTimestamp = computed(() => formatDateAndTime(timestamp.value));
/**
 * Formats a default label based on user info.
 *
 * @returns {string}
 */
const label = computed(() => {
  const { firstName, lastName, organization } = props.userInfo;

  return `${firstName} ${lastName} at ${organization}`;
});
/**
 * Formats this.autoSaveTimestamp to use in the template.
 *
 * @returns {string}
 */
const sessionName = computed(
  () => props.customSessionName || `comment-${props.comment.id}`,
);

/**
 * The parent controls if there is an inital timestamp from session so we should
 * pass it as a prop there and set it on create here.
 */
onMounted(() => {
  if (props.autoSaveTimestamp) {
    timestamp.value = props.autoSaveTimestamp;
  }
});

onBeforeUnmount(() => {
  if (props.saveSession) clearTimeout(timer.value);
});
// Methods
/**
 * Debounce on value change and update the session storage every 3 second unless
 * the user is typing.
 *
 * @param {string} message
 */
function updateSession(message) {
  clearTimeout(timer.value);
  timer.value = setTimeout(() => {
    if (isSaving.value) return;

    timestamp.value = new Date().toISOString();

    const commentObject = JSON.stringify({
      message,
      projectId: props.projectId,
      timestamp: timestamp.value,
    });

    window.sessionStorage.setItem(sessionName.value, commentObject);
  }, 3000);
}

/** Removes the session and emits 'cancel' to the parent. */
function onClickCancel() {
  if (props.saveSession) {
    window.sessionStorage.removeItem(sessionName.value);
    clearTimeout(timer.value);
  }
  emit('cancel');
}

/** Submits the comment and emits 'commentAdded' to the parent. */
function onClickSubmit() {
  let commentableIds = props.proposalDocumentId || props.comment.commentable_id;

  if (props.commentableIds.length) commentableIds = props.commentableIds;

  isSaving.value = true;
  errorOnSubmit.value = null;

  postComment(
    {
      content: commentValue.value,
      commentableIds,
      parentId: props.parentId,
    },
    props.apiConfig,
  )
    .then((data) => {
      const newComments = Array.isArray(data.comments)
        ? data.comments
        : [data.comment];
      const orderedComments = newComments.map((comment) => ({
        ...comment,
        showSuccess: props.inlineSuccess,
      }));

      if (!props.inlineSuccess) {
        toast.add({
          closable: true,
          detail: 'Your comment has been sent to your broker.',
          severity: 'success',
          life: 5000,
        });
      }

      orderedComments.forEach(() => {
        window.sessionStorage.removeItem(sessionName.value);
      });

      emit('commentAdded', orderedComments);
    })
    .catch(() => {
      errorOnSubmit.value = 'There was an error submitting the comment.';
    })
    .finally(() => {
      isSaving.value = false;

      if (props.saveSession) clearTimeout(timer.value);
    });
}
</script>

<style scoped lang="scss">
@use '@/shared/assets/styles/variables';
@use '@/shared/assets/styles/mixins';

p {
  margin-bottom: 0;
}

.autosave-fade {
  &-enter-active,
  &-leave-active {
    transition: all 0.75s;
    max-height: 50px;
  }

  &-enter,
  &-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    max-height: 0;
  }
}

.timestamp {
  color: var(--tf-gray);
  text-align: right;
}

.comment-input-container.has-label {
  margin-top: 12px;
}

.el-textarea {
  margin: 10px 0 15px;

  &__inner {
    width: 100%;
  }
}

.text-error {
  text-align: right;
}

.btn-group {
  gap: 1rem;
}
</style>
