<template>
  <Dialog
    header="Firm Quote Requested"
    modal
    :visible="visible"
    :closable="false"
    data-test="firm quote dialog"
    style="width: 470px"
    @update:visible="closeDialog"
  >
    <p data-test="firm quote header" class="inner-header">
      Enter the date when your firm quote expires:
    </p>
    <Form class="firm-quote-form">
      <FormField>
        <label class="form-label" v-text="'Expiration Date:'" />
        <InputText
          ref="input-datepicker"
          v-model="dateInputModel"
          data-test="expiration date"
          class="firm-quote-date-input"
          placeholder="Pick a date"
          fluid
          @blur="setDate"
          @change="setDate"
        />
        <DatePicker
          v-model="selectedDate"
          inline
          :min-date="minAvailableDate"
          calendar-container="calendar-content"
          @input="dateSelected"
        />
      </FormField>
    </Form>
    <div class="btn-group-right">
      <Button
        data-test="close firm quote requested modal"
        size="large"
        severity="secondary"
        label="Cancel"
        variant="text"
        @click="closeDialog()"
      />
      <Button
        size="large"
        :disabled="saving || isBeforeToday"
        label="Firm Quote"
        data-test="confirm firm quote selected"
        @click="submit"
      />
    </div>
  </Dialog>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';

import Dialog from 'primevue/dialog';
import { Form, FormField } from '@primevue/forms';
import DatePicker from 'primevue/datepicker';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { useProductStore } from '@/stores/product.js';
import DocumentServices from '@/services/documents.js';
import { segmentData } from '@/utils/analytics.js';
import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
import dayjs from 'dayjs';

defineOptions({
  name: 'FirmQuoteDateModal',
});

// Props & Emits
const props = defineProps({
  visible: { type: Boolean, default: false },
  products: { type: Array, required: true },
});
const emit = defineEmits(['update:visible']);
// Store
const productStore = useProductStore();
// State
const saving = ref(false);
const selectedDate = ref(null);
const dateInputModel = ref(null);
// const
const minAvailableDate = new Date();

minAvailableDate.setDate(minAvailableDate.getDate() + 1); // set min available day for tomorrow

// Computed
/**
 * Determines if selectedDate day is before today.
 *
 * @returns {boolean}
 */
const isBeforeToday = computed(() =>
  dayjs().isAfter(selectedDate.value, 'day'),
);
// Methods
/** Set selectedDate when the user types in a date ONLY if it's a valid date */
const setDate = () => {
  const newDate = new Date(dateInputModel.value);

  if (!Number.isNaN(newDate) && /\d+\/\d+\/\d+/.test(dateInputModel.value)) {
    selectedDate.value = newDate;
  }
};
/** Set dateInputModel when the user selects a date */
const dateSelected = () => {
  if (selectedDate.value) {
    const newDate = new Date(selectedDate.value);

    dateInputModel.value = `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`;
  } else {
    dateInputModel.value = null;
  }
};
/** Hide the dialog */
const closeDialog = () => {
  emit('update:visible', false);
};
/**
 * Sends a API call to patch the firm quote (quote) using and updates the store
 * to show the success/error message and sends analytics.
 */
const submit = async () => {
  saving.value = true;

  try {
    const updatedProducts = await Promise.all(
      props.products.map(async ({ document_id: id }) => {
        const {
          document: { products },
        } = await DocumentServices.patchFirmQuote({
          date: selectedDate.value,
          documentId: id,
        });

        return products;
      }),
    );

    productStore.setProducts(updatedProducts[0]);
    trackSegmentEvent('Firm quote date done', segmentData());
  } catch {
    // Handle errors if necessary
  } finally {
    saving.value = false;
    closeDialog();
  }
};

// Lifecycle hook
onMounted(() => {
  const firmQuoteDate = props.products.find(
    ({ firm_quote_expires_on: exp }) => exp,
  )?.firm_quote_expires_on;

  if (firmQuoteDate) {
    selectedDate.value = new Date(firmQuoteDate);
  } else {
    const startDate = new Date();

    startDate.setDate(startDate.getDate() + 14);
    selectedDate.value = startDate;
  }

  dateSelected();
});

// Watch for updates to `selectedDate`
watch(selectedDate, dateSelected);
</script>

<style lang="scss" scoped>
.inner-header {
  margin-bottom: 20px;
}

.firm-quote-form {
  display: flex;
  justify-content: center;
}

.firm-quote-date-input {
  display: block;
  max-width: 300px;
  margin: 10px 0;
}
</style>
