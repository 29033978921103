<template>
  <svg
    fill="none"
    height="111"
    viewBox="0 0 125 111"
    width="125"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <filter
      id="a"
      color-interpolation-filters="sRGB"
      filterUnits="userSpaceOnUse"
      height="110"
      width="124.007"
      x=".496826"
      y=".5"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feBlend
        in="SourceGraphic"
        in2="BackgroundImageFix"
        mode="normal"
        result="shape"
      />
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dx="-7" dy="5" />
      <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
      />
      <feBlend
        in2="shape"
        mode="normal"
        result="effect1_innerShadow_1517_114"
      />
    </filter>
    <g filter="url(#a)">
      <path
        d="m121.638 93.9558c3.878 6.6862-.989 15.0442-8.734 15.0442h-100.8093c-7.7593 0-12.603918-8.371-8.73402-15.0442l50.40532-86.94331c3.8793-6.689379 13.5957-6.677259 17.4681 0z"
        fill="#f7912a"
      />
    </g>
    <g stroke="#42474c" stroke-width="3">
      <path
        d="m121.638 93.9558c3.878 6.6862-.989 15.0442-8.734 15.0442h-100.8093c-7.7593 0-12.603918-8.371-8.73402-15.0442l50.40532-86.94331c3.8793-6.689379 13.5957-6.677259 17.4681 0z"
      />
      <path
        d="m62.6632 78.1992c-5.3368 0-9.6632 4.3041-9.6632 9.6133s4.3264 9.6133 9.6632 9.6133 9.6632-4.3041 9.6632-9.6133-4.3264-9.6133-9.6632-9.6133zm-9.1744-37.5547 1.5583 28.4219c.0729 1.3299 1.1783 2.3711 2.5171 2.3711h10.198c1.3388 0 2.4442-1.0412 2.5171-2.3711l1.5583-28.4219c.0787-1.4366-1.071-2.6445-2.5171-2.6445h-13.3148c-1.4461 0-2.5956 1.2079-2.5169 2.6445z"
        fill="#fff"
      />
    </g>
  </svg>
</template>
