<template>
  <div :class="['broker-update', { 'pdf-export-block': isPdfRender }]">
    <Badge v-if="update.is_new" size="small" severity="success" value="new" />
    <p v-if="update.object_changes && update.object_changes.due_date">
      <span class="label" v-text="`Due date updated:`" />
      {{ formatSimpleDate(update.object_changes.due_date) }}
    </p>
    <template
      v-for="createdItem in update.source_changes.created"
      :key="`file-${createdItem.id}`"
    >
      <p>
        <span
          class="label"
          v-text="`New ${createdItem.file_type_mapping} uploaded:`"
        />
        <Button
          v-if="!createdItem.deleted"
          :loading="currentlyDownloadingSource[createdItem.id]"
          :label="createdItem.file_name"
          variant="text"
          @click="downloadSource(createdItem)"
        />
        <template v-else>
          {{ createdItem.file_name }}
          <span class="download-deleted">(file deleted)</span>
        </template>
      </p>
      <p
        v-if="showDownloadErrorForSource[createdItem.id]"
        :key="`download-error-${createdItem.id}`"
        class="download-error"
      >
        There was a problem downloading the file.
      </p>
    </template>
    <p
      v-for="destroyedItem in update.source_changes.destroyed"
      :key="destroyedItem.id"
    >
      <span
        class="label"
        v-text="`${destroyedItem.file_type_mapping} Deleted:`"
      />
      {{ destroyedItem.file_name }}
    </p>
    <p v-if="updateNotes">
      <span class="label" v-text="'Update notes:'" />
      <span v-html="updateNotes" />
    </p>
    <p class="user-info">
      {{ formatDateAndTime(update.ended_at) }}
      <br />
      {{ userInfo }}
    </p>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import {
  formatSimpleDate,
  formatDateAndTime,
} from '@watchtowerbenefits/es-utils-public';
import { getSource } from '@/services/source.js';

import Button from 'primevue/button';
import Badge from 'primevue/badge';

/**
 * Broker updates
 *
 * @exports BrokerUpdate
 */
defineOptions({
  name: 'BrokerUpdate',
});

const props = defineProps({
  isPdfRender: {
    type: Boolean,
    default: false,
  },
  update: {
    type: Object,
    default: () => ({
      is_new: false,
      object_changes: {},
    }),
  },
});
const emit = defineEmits(['downloaded']);
const currentlyDownloadingSource = ref({});
const showDownloadErrorForSource = ref({});
/**
 * Get the user info of the update
 *
 * @returns {string}
 */
const userInfo = computed(() => {
  const { user } = props.update;
  let info = '';

  if (user.first_name) {
    info += user.first_name;
  }

  if (user.last_name) {
    info += ` ${user.last_name}`;
  }

  if (user.organization && user.organization.name) {
    info += `, ${user.organization.name}`;
  }

  return info;
});
/**
 * Get the update notes with line breaks
 *
 * @returns {string}
 */
const updateNotes = computed(() =>
  props.update.notes
    ? props.update.notes.replace(/(?:\r\n|\r|\n)/g, '<br>')
    : '',
);
/**
 * On click of the download button, calls `getSource()` from the source service
 *
 * @param {object} source
 */
const downloadSource = async (source) => {
  currentlyDownloadingSource.value = {
    ...currentlyDownloadingSource.value,
    [source.id]: true,
  };

  try {
    const response = await getSource(source.id);
    // Get base64 content
    const base64String = response.data.source.content.replace(/\s/g, '');
    const byteCharacters = window.atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });

    currentlyDownloadingSource.value = {
      ...currentlyDownloadingSource.value,
      [source.id]: false,
    };
    showDownloadErrorForSource.value = {
      ...showDownloadErrorForSource.value,
      [source.id]: false,
    };

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(
        blob,
        response.data.source.file_file_name,
      );
    } else {
      const downloadLink = document.createElement('a');

      downloadLink.value = 'Download';
      downloadLink.target = '_blank';
      downloadLink.download = response.data.source.file_file_name;
      downloadLink.href = URL.createObjectURL(blob);

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      emit('downloaded');
    }
  } catch {
    currentlyDownloadingSource.value = {
      ...currentlyDownloadingSource.value,
      [source.id]: false,
    };
    showDownloadErrorForSource.value = {
      ...showDownloadErrorForSource.value,
      [source.id]: true,
    };
  }
};
</script>

<style lang="scss" scoped>
.broker-update {
  margin-left: 28px;
  padding: 30px 0;
  border-bottom: 1px solid var(--tf-gray-light-medium);

  &.pdf-export-block {
    width: 100%;
  }
}

.tf-button.tf-button--text {
  justify-content: flex-start;

  .pdf-export-block & {
    color: var(--tf-gray-dark);
  }
}

.download-deleted {
  color: var(--tf-danger);
}

.el-alert.alert-new {
  margin: 0;

  .pdf-export-block & {
    display: none;
  }
}

.label {
  display: block;
  font-weight: 600;

  .pdf-export-block & {
    font-weight: 700;
  }
}

.user-info {
  margin-top: 20px;
  color: var(--tf-gray);
}

p {
  line-height: 22px;
}

.download-error {
  margin-top: 0;
  color: var(--tf-danger);
  font-size: 12px;
}
</style>
