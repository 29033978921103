<script setup>
/**
 * Register
 *
 * @exports Register
 */
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useAccountStore } from '@/stores/account.js';
import accountService from '@/services/account.js';
import AppRegisterForm from '@/shared/components/AuthPages/AppRegisterForm.vue';

defineOptions({
  name: 'UserRegistration',
});

const route = useRoute();
const accountStore = useAccountStore();
const { userInfo } = storeToRefs(accountStore);
const { setUserEmail, setUserInfo } = accountStore;
/**
 * Evaluate the route params and returns an object with all of the correct data.
 *
 * @returns {object}
 */
const formProps = computed(() => {
  const { projectId } = route.params;
  const props = {
    linkForgot: { name: 'ForgotPassword' },
    linkLogin: { name: 'SignIn' },
    linkRegisterConfirmation: { name: 'RegisterConfirmation' },
  };

  if (projectId) {
    Object.keys(props).forEach((key) => {
      props[key] = {
        name: `Project${props[key].name}`,
        params: { projectId },
      };
    });

    props.projectId = projectId;
  }

  return props;
});
</script>

<template>
  <section>
    <AppRegisterForm
      v-bind="{
        ...formProps,
        accountService,
        showProjectInfo: false,
        userInfo,
      }"
      @set-user-email="setUserEmail"
      @set-user-info="setUserInfo"
    />
  </section>
</template>
