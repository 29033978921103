import { computed, inject } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useNotificationsStore } from '@/stores/notifications.js';
import { useBrokerUpdatesStore } from '@/stores/brokerUpdates.js';
import { useCommentsStore } from '@/stores/comments.js';
import { useProjectStore } from '@/stores/project.js';
import {
  hideMarketActivityTab,
  eventsContactTab,
} from '@/utils/featureFlags.js';

/**
 * Composable that provides data and methods for the RfpOverview component.
 *
 * @returns {object}
 */
export function useRfpOverview() {
  const router = useRouter();
  const $ld = inject('$ld');
  // Stores
  const notificationsStore = useNotificationsStore();
  const brokerUpdatesStore = useBrokerUpdatesStore();
  const commentsStore = useCommentsStore();
  const projectStore = useProjectStore();
  // Store refs
  const { unreadComments } = storeToRefs(commentsStore);
  const { unreadEdits } = storeToRefs(notificationsStore);
  const { notifications } = storeToRefs(brokerUpdatesStore);
  const { broker, brokerHierarchy } = storeToRefs(projectStore);
  /**
   * If the broker either IS a broker parent or HAS a broker parent that is
   * deemed restricted to see the Market Activity tab, this will return true.
   * The feature flag consists of a comma separated string of parent broker IDs,
   * or 'none' if the flag is disabled.
   *
   * @returns {boolean}
   */
  const hideMarketTab = computed(() => {
    const idsAsString = $ld.checkFlags(hideMarketActivityTab);

    if (idsAsString === 'none' || !brokerHierarchy.value.hasHierarchy) {
      return false;
    }

    // If the broker has a hierarchy but no parent_id, then the broker IS the parent of its hierarchy.
    const hierarchyParentId = brokerHierarchy.value.parentId ?? broker.value.id;
    // Convert the comma separated string of broker ids into an array of numbers.
    const restrictedParentIds = idsAsString
      .replace(/\s/, '')
      .split(',')
      .map((id) => +id);

    return restrictedParentIds.includes(hierarchyParentId);
  });
  /**
   * Determine if the events contact tab feature flag is on or off.
   *
   * @returns {boolean}
   */
  const eventsContactTabEnabled = computed(() =>
    $ld.checkFlags(eventsContactTab),
  );
  /**
   * Evaluates the RfpOverview route's children and creates an item with
   * badgeCount, name, path, and title for each that is primarily used for
   * navigation.
   *
   * @returns {Array}
   */
  const rfpOverviewTabs = computed(() => {
    const { routes } = router.options;
    const index = routes.findIndex(({ name }) => name === 'RfpOverview');

    if (index === -1) return [];

    return routes[index].children
      .map(({ meta, name, path }) => {
        const { title } = meta;
        let badgeCount = 0;

        if (
          name === 'UpdatesEdits' &&
          (notifications.value || unreadEdits.value)
        ) {
          badgeCount = notifications.value + unreadEdits.value;
        }
        if (name === 'Comments') {
          badgeCount = unreadComments.value;
        }

        return {
          badgeCount,
          name,
          path,
          title,
          id: name,
          label: title,
        };
      })
      .filter(({ name }) => {
        if (name === 'MarketActivity' && hideMarketTab.value) {
          return false;
        }

        return !(name === 'Contacts' && !eventsContactTabEnabled.value);
      });
  });

  return {
    hideMarketTab,
    eventsContactTabEnabled,
    rfpOverviewTabs,
  };
}
