<template>
  <div class="rate-table">
    <template v-if="!loading">
      <h5 v-if="!props.rateAttributeIndex">
        <img
          class="logo"
          :src="
            incumbent
              ? productStore.currentProduct.project_product.inforce_product
                  .carrier.logo_url
              : carrierInfoStore.logoUrl
          "
          :alt="
            incumbent
              ? productStore.currentProduct.project_product.inforce_product
                  .carrier.name
              : carrierInfoStore.name
          "
        />
      </h5>
      <Form
        :ref="setFormRef"
        :initial-values="formModel"
        :validate-on-rule-change="false"
        :resolver="resolver"
        label-width="100px"
        @submit.prevent
      >
        <table :class="[{ stacked: isStacked }, 'table-data-entry']">
          <thead>
            <template v-if="isStacked">
              <tr>
                <!-- Only show the subtype cell for non-composite rate attribute type -->
                <th
                  v-if="props.attributeType !== compositeType"
                  class="title"
                  :data-test="`rate entry table ${props.attributeType} subtype`"
                  v-text="props.attributeType"
                />
                <th class="title">
                  <!-- Show subtype dropdown for !incumbent, !readonly -->
                  <!-- and tier groups with multiple subtypes -->
                  <Select
                    v-if="!incumbent && !readonly && subtypeOptions.length"
                    v-model="stackedAttributeType"
                    :options="stackedAttributeOptions"
                    option-label="label"
                    option-value="value"
                    placeholder="Select type"
                    data-test="select type dropdown"
                  />
                  <template v-else>
                    {{
                      stackedAttributeType === ageBandedType
                        ? 'Age'
                        : tierGroupName(stackedAttributeType)
                    }}
                  </template>
                </th>
                <th
                  :class="[{ 'proposed-title': !incumbent }, 'title']"
                  v-text="incumbent ? 'In-Force rates' : 'Proposed rates'"
                />
              </tr>
            </template>
            <template v-else>
              <!-- Titles for Tier Groups that have multiple `subtypes` -->
              <tr v-if="tierSubtypes.length > 1">
                <th
                  v-for="subtype in tierSubtypes"
                  :key="subtype.name"
                  colspan="2"
                  class="sub-type-header"
                  :data-test="`rate entry table ${props.attributeType} subtype`"
                  v-text="subtype.name"
                />
              </tr>
              <!-- Titles for `subtypes`-->
              <tr>
                <!-- IF: Composite, Age Banded, and Custom -->
                <template
                  v-if="
                    [compositeType, ageBandedType, customType].includes(
                      props.attributeType,
                    )
                  "
                >
                  <th
                    class="title"
                    :data-test="`rate entry table ${props.attributeType} subtype`"
                  >
                    {{
                      attributeType === ageBandedType
                        ? 'Age'
                        : tierGroupName(props.attributeType)
                    }}
                  </th>
                  <th :class="[{ 'proposed-title': !incumbent }, 'title']">
                    {{ incumbent ? 'In-Force rates' : 'Proposed rates' }}
                  </th>
                </template>
                <!-- ELSE: need to loop through the subtypes  -->
                <template
                  v-for="(subtype, idx) in tierGroupInfo?.tier_subtypes"
                  v-else
                  :key="`${subtype.name}-${idx}-label`"
                >
                  <th class="title">
                    <Select
                      v-if="
                        !incumbent &&
                        !readonly &&
                        ![compositeType, ageBandedType, customType].includes(
                          props.attributeType,
                        )
                      "
                      v-bind="{
                        modelValue: subtypeOptions[idx],
                      }"
                      :options="subtypeSelectOptions(subtype)"
                      option-label="label"
                      option-value="value"
                      placeholder="Select type"
                      @change="updateSubtypeData($event, subtype.id)"
                    />
                    <template v-else>
                      <!-- If not age banded, print && filter subtype type -->
                      <template v-if="subtypeOptions[idx] !== ageBandedType">
                        {{ tierGroupName(subtypeOptions[idx]) }}
                      </template>
                      <!-- Otherwise, print `Age` -->
                      <template v-else> Age </template>
                    </template>
                  </th>
                  <th
                    :class="[{ 'proposed-title': !incumbent }, 'title']"
                    v-text="incumbent ? 'In-Force rates' : 'Proposed rates'"
                  />
                </template>
              </tr>
            </template>
          </thead>
          <tbody v-if="tableRows.length">
            <template v-if="isStacked">
              <template v-for="subtype in tableRows">
                <tr
                  v-for="(value, idx) in subtype.rateValue?.values"
                  :key="`${subtype.name}-${value.display_label}`"
                >
                  <!-- First td in stacked only shows on non-composite and only the first row.  -->
                  <td
                    v-if="!idx && subtype.name !== compositeType"
                    :rowspan="subtype.rateValue?.values.length"
                    v-text="subtype.name"
                  />
                  <RateEntryTdLabel
                    :key="`${props.rateAttributeIndex}-${storeAttributeId}-${idx}-${value.label}`"
                    :label="value.display_label"
                    :subtype-type="stackedAttributeType"
                  />
                  <RateEntryTdValue
                    :cell-data="value"
                    :field-state="
                      formRef?.states[
                        `${props.rateAttributeIndex}-${storeAttributeId}-${subtype.name}-${value.label}`
                      ]
                    "
                    :form-key="`${props.rateAttributeIndex}-${storeAttributeId}-${subtype.name}-${value.label}`"
                    :readonly="incumbent || readonly"
                    :subtype-id="subtype.id"
                  />
                </tr>
              </template>
            </template>
            <template v-else>
              <tr
                v-for="(cellGroup, idx) in tableRows"
                :key="`${storeAttributeId}-${idx}`"
              >
                <!-- Each cellGroup is grouped in the table as 2 columns in the table -->
                <template
                  v-for="(group, groupIdx) in cellGroup"
                  :key="`${storeAttributeId}-${groupIdx}-${group.label}`"
                >
                  <RateEntryTdLabel
                    :label="group.display_label"
                    :subtype-type="
                      subtypeOptions[groupIdx] || props.attributeType
                    "
                  />
                  <RateEntryTdValue
                    :field-state="
                      formRef?.states[
                        `${props.rateAttributeIndex}-${tierSubtypes[groupIdx].name}-${group.label}-${groupIdx}`
                      ]
                    "
                    :form-key="`${props.rateAttributeIndex}-${tierSubtypes[groupIdx].name}-${group.label}-${groupIdx}`"
                    :cell-data="group"
                    :readonly="readonly"
                    :subtype-id="tierSubtypes[groupIdx].id"
                  />
                </template>
              </tr>
            </template>
          </tbody>
        </table>
      </Form>
    </template>
  </div>
</template>

<script setup>
import { computed, inject, ref, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
import { useRateEntryStore } from '@/stores/rateEntry.js';
import { useProductStore } from '@/stores/product.js';
import { reusableAgeBandedValues } from '@watchtowerbenefits/es-utils-public';
import { Select } from 'primevue';
import { Form } from '@primevue/forms';
import { zodResolver } from '@primevue/forms/resolvers/zod';
import { z } from 'zod';
import RateEntryTdLabel from './RateEntryTdLabel.vue';
import RateEntryTdValue from './RateEntryTdValue.vue';

const ageBandedType = 'AgeBandedRateValue';
const compositeType = 'CompositeRateValue';
const customType = 'CustomRateValue';
const compositeRow = {
  comparison_flag: 'deviation_detected',
  display_label: 'Composite',
  label: 'composite',
  value: null,
  volume: null,
};
/**
 * Rate Entry Table
 *
 * @exports RateEntryTable
 */
// Inject
const storeAttributeId = inject('storeAttributeId');
const isPlan = inject('isPlan');
// Utils
const route = useRoute();
const tierGroupName = (value) => {
  let name = value;

  switch (value) {
    case 'CompositeRateValue':
      name = 'Composite';
      break;
    case 'AgeBandedRateValue':
      name = 'Age banded';
      break;
    case 'CustomRateValue':
      name = 'Custom';
      break;
    default:
      name = value;
  }

  return name;
};
// Stores
const rateEntryStore = useRateEntryStore();
const productStore = useProductStore();
const carrierInfoStore = useCarrierInfoStore();
// Props
const props = defineProps({
  /** Don't need to do form validation stuff on policy tables */
  isPolicyTable: {
    type: Boolean,
    default: false,
  },
  /**
   * Rate Attribute type only required for the proposal document to determine
   * table layout
   */
  attributeType: {
    type: String,
    // eslint-disable-next-line vue/valid-define-props
    default: compositeType,
  },
  /** Boolean used to set readonly for incumbent table */
  incumbent: {
    type: Boolean,
    default: false,
  },
  /** Number used to display if the carrier icons should show. */
  rateAttributeIndex: {
    type: Number,
    default: 0,
  },
  /** Tier Group name + subtypes used to loop through for table rows */
  tierGroup: {
    type: Object,
    default: () => ({}),
  },
});
// Variables
const formRef = ref(null);
const loading = ref(false);
const resolver = ref(null);
// Computed
/**
 * Age Banded array from shared repo for creating Age Banded Rows
 *
 * @returns {Array}
 */
const ageBanded = computed(() => reusableAgeBandedValues());
/**
 * Boolean to determine layout
 *
 * @returns {boolean}
 */
const isComposite = computed(() => props.attributeType === compositeType);
/**
 * Boolean to determine the state of the tables (review or edit)
 *
 * @returns {boolean}
 */
const readonly = computed(() => {
  const reviewingProposal = !props.incumbent && route.meta.readonly;

  return props.incumbent || reviewingProposal;
});
/**
 * Computed subtype data for 'banded' tier groups
 *
 * @returns {object}
 */
const tierGroupInfo = computed(() => {
  let tgi = {};

  if (
    ![ageBandedType, compositeType, customType].includes(props.attributeType)
  ) {
    const updatedTierGroup = productStore.rateTierGroups.find(
      (tierGroup) => tierGroup.tier_group_name === props.attributeType,
    );

    if (updatedTierGroup) {
      const {
        tier_group_id: id,
        tier_group_layout: layout,
        tier_group_name: name,
        tier_subtypes: subtypes,
      } = updatedTierGroup;

      tgi = {
        id,
        layout,
        name,
        /* eslint-disable no-shadow */
        tier_subtypes: subtypes.map(
          ({ subtype_id: id, subtype_name: name }) => ({
            id,
            name,
          }),
        ),
      };
    }
  }

  return tgi;
});
const isStacked = computed(() => tierGroupInfo.value.layout === 'stacked');
const stackedAttributeType = computed({
  /**
   * Attribute type of a stacked tier group layout. For non-incumbents, you can
   * grab any rate value id id since all the attribute types should all be the
   * same. non-stacked layouts do not need this info.
   *
   * @returns {string}
   */
  get() {
    let attributeType;

    // if product is stopLoss only Composite is allowed
    if (props.attributeType === compositeType || productStore.isStopLoss) {
      return compositeType;
    }

    if (props.incumbent) {
      const subtype = props.tierGroup.tier_subtypes[0];

      if (isPlan.value) {
        attributeType = subtype.rate_value.type;
      } else {
        attributeType = subtype.rate_values[0].values[0].type;
      }
    } else {
      const rateAttribute =
        rateEntryStore.rateAttributes[storeAttributeId.value];
      const subtypeId = tierGroupInfo.value.tier_subtypes[0].id;

      attributeType = rateAttribute.rateValues[subtypeId]?.type;
    }

    return attributeType;
  },
  /**
   * Creates stacked attribute types
   *
   * @param {string} value
   */
  async set(value) {
    tierGroupInfo.value.tier_subtypes.forEach((subtype) =>
      rateEntryStore.updateRateAttribute({
        productId: productStore.productId,
        storeAttributeId: storeAttributeId.value,
        subtypeId: subtype.id,
        rateValues: {
          [subtype.id]: {
            type: value,
            values:
              value === compositeType
                ? [compositeRow]
                : // eslint-disable-next-line no-use-before-define
                  ageBanded.value.map((age, i) => addAgeBandedRow(i)),
          },
        },
      }),
    );

    rateEntryStore.rateErrors = [];
  },
});
const stackedAttributeOptions = computed(() =>
  [
    {
      value: compositeType,
      label: tierGroupName(compositeType),
    },
    {
      value: ageBandedType,
      label: tierGroupName(ageBandedType),
    },
    {
      value: customType,
      label: tierGroupName(customType),
    },
  ].filter(({ value }) => {
    if (value === ageBandedType) {
      return !productStore.isStopLoss.value;
    }
    if (value === customType) {
      return stackedAttributeType.value === customType;
    }

    return true;
  }),
);
/**
 * Computed tier subtypes
 *
 * @returns {Array}
 */
const tierSubtypes = computed(() => {
  const tierSubtypes = [];

  if (!isComposite.value) {
    props.tierGroup.tier_subtypes.forEach((subtype) => {
      // Push subtype option to this.subtypeOptions (only if there are subtype)
      if (props.tierGroup.tier_subtypes.length > 1) {
        tierSubtypes.push({
          id: subtype.id,
          name: subtype.name,
        });
      } else {
        tierSubtypes.push({
          id: props.attributeType,
          name: props.attributeType,
        });
      }
    });
  } else {
    tierSubtypes.push({
      id: compositeType,
      name: compositeType,
    });
  }

  return tierSubtypes;
});
/**
 * Populates the subtype options select
 *
 * @returns {Array}
 */
const subtypeOptions = computed(() => {
  const subtypeOptions = [];

  if (!isComposite.value) {
    props.tierGroup.tier_subtypes.forEach((subtype) => {
      let subtypeValue;

      if (isPlan.value) {
        subtypeValue = subtype.rate_value;
      } else {
        [subtypeValue] = subtype.rate_values;
      }

      if (props.tierGroup.tier_subtypes.length > 1) {
        // Stack options only need one the subtype option
        const existingOption =
          isStacked.value && !subtypeOptions.includes(subtypeValue.type);

        // Push options if not stacked or stacked && !exists
        if (!isStacked.value || existingOption) {
          subtypeOptions.push(subtypeValue.type);
        }
      }
    });
  }

  return subtypeOptions;
});
/**
 * Computed table rows
 *
 * @returns {Array}
 */
const tableRows = computed(() => {
  const tableRows = [];
  const rowValues = []; // Used to create each cell grouping in the row
  let values = [];
  let maxLength = 0;

  if (!props.tierGroup) {
    tableRows.push([]);
  } else if (isComposite.value) {
    if (isStacked.value) {
      tableRows.push({
        id: compositeType,
        name: compositeType,
        rateValueType: compositeType,
        rateValue: props.tierGroup.tier_subtypes[0].rate_value,
      });
    } else {
      tableRows.push([
        isPlan.value
          ? props.tierGroup.tier_subtypes[0].rate_value.values[0]
          : props.tierGroup.tier_subtypes[0].rate_values[0].values[0],
      ]);
    }
  } else {
    props.tierGroup.tier_subtypes.forEach((subtype, idx) => {
      let id;

      if (isPlan.value) {
        id = subtype.rate_value.id;
        values = subtype.rate_value.values;
      } else {
        id = subtype.rate_values[0].id;
        values = subtype.rate_values[0].values;
      }

      // IF: add all values for each `tier subtype`
      // ELSE: replace maxLength of table rows if greater than previous `tier subtype`
      if (isStacked.value) {
        maxLength += values.length;
      } else {
        maxLength = values.length > maxLength ? values.length : maxLength;
      }

      // IF: Push modified subtype object to tableRows
      // ELSE: Store values of each subtype to loop through later
      if (isStacked.value) {
        tableRows.push({
          id: subtype.id,
          name: subtype.name,
          rateValue: isPlan.value ? subtype.rate_value : subtype.rate_values[0],
        });
      } else {
        rowValues.push({ id: id || idx, values });
      }
    });

    if (!isStacked.value) {
      for (let i = 0; i <= maxLength - 1; i += 1) {
        const rows = [];

        // Loop through each subtype
        rowValues.forEach((rowSubtype, index) => {
          rows[index] = rowSubtype.values[i] || {};
        });
        tableRows.push(rows);
      }
    }
  }

  return tableRows;
});
/**
 * Form model for validation against
 *
 * @returns {object}
 */
const formModel = computed(() =>
  Object.fromEntries(
    tableRows.value.flatMap((row) => {
      if (Array.isArray(row)) {
        const formFields = [];

        tierSubtypes.value.forEach(({ id, name }) => {
          formFields.push(
            ...row.map(({ label, value: v }, i) => {
              const formKey = `${props.rateAttributeIndex}-${name}-${label}-${i}`;

              return [
                formKey,
                !Number.isNaN(parseFloat(v))
                  ? parseFloat(v)
                  : rateEntryStore.rateAttributes[
                      storeAttributeId.value
                    ].rateValues[id].values.find(
                      ({ label: attributeLabel }) => label === attributeLabel,
                    )?.value,
              ];
            }),
          );
        });

        return formFields;
      }

      return row.rateValue.values.map(({ value: v, label }) => {
        const formKey = `${props.rateAttributeIndex}-${storeAttributeId.value}-${row.name}-${label}`;

        return [formKey, v];
      });
    }),
  ),
);
// Methods
const subtypeSelectOptions = (subtype) =>
  [
    {
      value: compositeType,
      label: tierGroupName(compositeType),
    },
    {
      value: ageBandedType,
      label: tierGroupName(ageBandedType),
    },
    {
      value: customType,
      label: tierGroupName(customType),
    },
  ].filter(({ value }) => {
    if (value === ageBandedType) {
      return !productStore.isStopLoss.value;
    }
    if (value === customType) {
      return (
        (subtype.rate_values && subtype.rate_values[0].type === customType) ||
        (subtype.rate_value && subtype.rate_value.type === customType)
      );
    }

    return true;
  });
/**
 * Configure the rate entry ElForm instance to use validation. Populate
 * rateErrors in the store with a list of component refs
 */
const getInvalidFields = () => {
  if (props.isPolicyTable || !formRef.value) return;
  /**
   * Populate/sync rateErrors with the list of ElFormItem elements with
   * validation errors
   */
  const fieldKeys = Object.keys(formModel.value).filter(
    (key) => key !== 'undefined',
  );
  const fields = [
    ...fieldKeys.map((key) => ({
      prop: key,
      value: formModel.value[key],
    })),
  ];
  const existingErrorFields = rateEntryStore.rateErrors.map((field) => field());
  const fieldOrder = tableRows.value.flatMap((row) => {
    if (Array.isArray(row)) {
      const formFields = [];

      tierSubtypes.value.forEach(({ name }) => {
        formFields.push(...row.map(({ label }, i) => `${name}-${label}-${i}`));
      });

      return formFields;
    }

    return row.rateValue.values.map(({ label }) => `${row.name}-${label}`);
  });

  fields.sort((a, b) => {
    if (fieldOrder.indexOf(a.prop) > fieldOrder.indexOf(b.prop)) {
      return 1;
    }
    if (fieldOrder.indexOf(a.prop) < fieldOrder.indexOf(b.prop)) {
      return -1;
    }

    return 0;
  });

  const invalidFields = fields
    .filter(
      ({ value, prop }) =>
        Number.isNaN(parseFloat(value)) &&
        document.querySelector(`[name="${prop}"]`),
    )
    .map(({ prop }) => document.querySelector(`[name="${prop}"]`))
    .filter((field) => field);
  const formFields = fieldKeys
    .map((key) => document.querySelector(`[name="${key}"]`))
    .filter((field) => field);

  if (!invalidFields.every((field) => existingErrorFields.includes(field))) {
    const updatedErrors = rateEntryStore.rateErrors.filter((field) => {
      if (!document.contains(field())) return false; // Field doesn't exist
      if (!formFields.includes(field())) return true; // Field is from another form

      return !invalidFields.map(({ name }) => name).includes(field().name);
    });

    rateEntryStore.rateErrors = [
      ...updatedErrors,
      ...invalidFields.map((field) => () => field),
    ];
  }
};
const setFormRef = (field) => {
  if (!props.isPolicyTable) {
    formRef.value = field;
  }
};
/**
 * Method used to add a age banded cell group Pass the `idx` to create the
 * appropriate age label.
 *
 * @param {number} idx
 * @returns {object}
 */
const addAgeBandedRow = (idx) => ({
  comparison_flag: 'deviation_detected',
  display_label:
    ageBanded.value[idx] === 'age_80_plus'
      ? '80+'
      : ageBanded.value[idx].replace('age_', '').replace('_', '-'),
  label: ageBanded.value[idx],
  value: null,
  volume: null,
});
/**
 * Debounce the patch calls made to the API so that changes made to multiple
 * inputs in quick succession don't get fired to the API every time. We only
 * need the latest change made in a given value column to be sent to the API.
 *
 * @param {object} newAttributes
 */
const updateAttributes = async (newAttributes) => {
  rateEntryStore.rateUpdateRequests.push(
    ((payload) => async (rateAttributes) => {
      // Do the patch
      const newRateAttributes = await rateEntryStore.updateRateAttribute(
        {
          ...payload,
        },
        rateAttributes,
      );

      // and pass it to (potentially) the next update event IIFE
      return newRateAttributes[payload.storeAttributeId];
    })({
      ...newAttributes,
    }),
  );

  rateEntryStore.drainUpdateRequests();
};
/**
 * Method used when updating the subtier type from the table header dropdown.
 *
 * @param {string} rateValueType
 * @param {number} id
 */
const updateSubtypeData = (rateValueType, id) => {
  const maxRowLength = subtypeOptions.value.includes(ageBandedType)
    ? ageBanded.value.length
    : 1;
  const values = [];
  let i = 0;
  let subtypeObj;

  for (i; i < maxRowLength; i += 1) {
    if (rateValueType.value === compositeType) {
      // Update 0 array cellGroupIdx to composite && empty/{} for everything else in the cells
      subtypeObj = !i ? compositeRow : {};
    } else {
      subtypeObj = addAgeBandedRow(i);
    }

    // The case for this is if you have a combination of composite/age-banded rows,
    // We're pushing an empty object to the row (for layout)
    // But we don't want to push empty objects to the store.
    if (Object.keys(subtypeObj).length) {
      values.push(subtypeObj);
    }
  }

  updateAttributes({
    productId: productStore.productId,
    storeAttributeId: storeAttributeId.value,
    tierGroupId: props.tierGroup.id,
    subtypeId: id,
    rateValues: {
      [id]: {
        type: rateValueType.value,
        values,
      },
    },
  });
};

// Watch
watch(
  () => rateEntryStore.rateErrors,
  (newVal) => {
    /** Update rateErrors with invalid fields */
    if (newVal.length === 0) {
      getInvalidFields();
    }
  },
);

watch(
  () => formModel.value,
  () => {
    resolver.value = zodResolver(
      z.object(
        Object.fromEntries(
          Object.keys(formModel.value).map((key) => [
            key,
            z.coerce
              .number({ message: 'Rates must be numerical.' })
              .refine(
                (v) =>
                  !Number.isNaN(
                    parseFloat(v) && !(v === formModel.value[key]?.value),
                  ),
                {
                  message: 'Rates must be numerical.',
                },
              ),
          ]),
        ),
      ),
    );
    getInvalidFields();
  },
  { immediate: true },
);

onMounted(() => {
  getInvalidFields();
});
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  margin-bottom: 20px;
}

.logo {
  display: block;
  height: 60px;
  margin: 0 auto;
}

.rate-table {
  flex: 1;
  flex-direction: column;

  &:first-child {
    margin-right: 20px;

    .dialog-plan-summary & {
      margin-right: 0;
    }
  }
}

.title {
  padding: {
    left: 6px;
    right: 6px;
  }
  background: var(--tf-gray-light);

  .stacked & {
    text-align: left;
    padding: 0 20px;
    white-space: nowrap;
  }
}

.proposed-title {
  font-weight: bold;
}

tbody,
th {
  border: 1px solid var(--tf-gray-light-medium);
}

h5 {
  display: flex;
  align-items: center;
  height: 80px;
  margin-bottom: 0;
  border: 1px solid var(--tf-gray-light-medium);
}

td {
  vertical-align: top;
}
</style>
