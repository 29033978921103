<template>
  <Dialog
    :visible="visible"
    :closable="false"
    modal
    style="width: 450px"
    @update:visible="onBeforeClose()"
  >
    <div class="warning-icon-container flex items-center justify-center">
      <img :src="declineIcon" alt="Decline icon" />
    </div>
    <h2 data-test="decline confirmation text">
      <template v-if="productsToDecline?.length === 1 && !decliningAllProducts">
        Are you sure you want to decline to quote for
        <template v-if="isStopLossProduct(productsToDecline[0])">
          this stop loss option?
        </template>
        <template v-else>
          this
          {{ lowercaseExceptAcronyms(productsToDecline[0].product_type_name) }}
          <template v-if="productsToDecline[0].project_product.alternative">
            &ndash;
            {{
              lowercaseExceptAcronyms(
                productsToDecline[0].project_product.label,
              )
            }}
          </template>
          product?
        </template>
      </template>
      <template v-else-if="decliningMultipleProducts && !decliningAllProducts">
        Are you sure you want to decline to quote for these products?
      </template>
      <template v-else>
        Are you sure you want to decline to quote on all products?
      </template>
    </h2>

    <p>
      This reason will be sent to the broker 10 minutes after declining. You can
      undo declined to quote products in the My quotes section.
    </p>
    <h4 class="reason-for-declining-label">Select a reason for declining:</h4>

    <div class="decline-reason-options" data-test="reasons for declining">
      <div
        v-for="option in predefinedOptions"
        :key="option"
        class="flex justify-start gap-2"
      >
        <RadioButton
          v-model="predefinedReason"
          :input-id="`option-${option}`"
          :value="option"
        />
        <label :for="`option-${option}`" class="text-left">{{
          option === 'Other' ? 'Other reason' : option
        }}</label>
      </div>
    </div>

    <LimitedTextArea
      v-if="predefinedReason === 'Other'"
      id="reason-for-declining"
      v-model:limit-exceeded="limitExceeded"
      v-model="userDefinedReason"
      data-test="decline reason"
    />

    <div class="flex justify-end gap-2">
      <Button
        label="Cancel"
        severity="secondary"
        variant="text"
        @click="closeDeclineToQuoteDialog"
      />
      <Button
        :disabled="!canDecline"
        :loading="btnDeclining"
        :label="declineButtonText"
        severity="danger"
        @click="declineAction"
      />
    </div>
  </Dialog>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useProductStore } from '@/stores/product.js';
import { useProjectStore } from '@/stores/project.js';
import { storeToRefs } from 'pinia';
import DocumentServices from '@/services/documents.js';
import { lowercaseExceptAcronyms } from '@/utils/regex.js';
import {
  isStopLossProduct,
  trackSegmentEvent,
} from '@watchtowerbenefits/es-utils-public';
import { segmentData } from '@/utils/analytics.js';
import declineIcon from '@/assets/decline.svg';
import Dialog from 'primevue/dialog';
import RadioButton from 'primevue/radiobutton';
import Button from 'primevue/button';
import LimitedTextArea from '../LimitedTextArea.vue';

defineOptions({
  name: 'DeclineToQuoteModal',
});

// Props & Emits
const props = defineProps({
  visible: { type: Boolean, default: false },
  productsToDecline: { type: Array, default: () => [] },
});
const emit = defineEmits(['update:visible']);
const toast = useToast();
// Store Instances
const productStore = useProductStore();
const projectStore = useProjectStore();
// State
const btnDeclining = ref(false);
const userDefinedReason = ref('');
const predefinedReason = ref('');
const limitExceeded = ref(false);
const { proposalDocumentId } = storeToRefs(projectStore);
const { productsToDecline: storeProductsToDecline, products } =
  storeToRefs(productStore);
/**
 * Makes sure that the char limit isn't exceeded, the button hasn't been clicked
 *
 * @returns {boolean}
 */
const canDecline = computed(() => !limitExceeded.value && !btnDeclining.value);
/**
 * Evaluate whether the products that the user has selected to decline are all
 * of the products available on the project. If so, then we will be declining
 * the Document/RFP as a whole instead of individual products.
 *
 * @returns {boolean}
 */
const decliningAllProducts = computed(
  () => props.productsToDecline?.length === products.value.length,
);
/**
 * Evaluate whether the products that the user has selected to decline are all
 * of the products available on the project. If so, then we will be declining
 * the Document/RFP as a whole instead of individual products.
 *
 * @returns {boolean}
 */
const decliningMultipleProducts = computed(
  () => props.productsToDecline?.length > 1,
);
/**
 * Changes the decline button template text depending on whether the user is
 * declining to quote on one or multiple products, versus an entire RFP
 *
 * @returns {string}
 */
const declineButtonText = computed(() => {
  if (props.productsToDecline.length && !decliningAllProducts.value) {
    return `Decline ${decliningMultipleProducts.value ? 'products' : 'product'}`;
  }

  return 'Decline to Quote';
});
/**
 * Creates an object that will be passed to the appropriate declineToQuote
 * service method
 *
 * @returns {object}
 */
const declineReason = computed(() => {
  const elaboration = userDefinedReason.value || 'No reason given';

  return predefinedReason.value && predefinedReason.value !== 'Other'
    ? {
        declined_reason: predefinedReason.value,
        declined_reason_elaboration: null,
      }
    : { declined_reason: 'Other', declined_reason_elaboration: elaboration };
});
/**
 * Options for each radio button in declining an RFP or product
 *
 * @returns {Array}
 */
const predefinedOptions = [
  'Rates Uncompetitive',
  'Non-preferred (or unfavorable) industry',
  'Size segment outside carrier min/max threshold',
  'Unable to meet plan design requirements',
  'Quoted (and lost) a particular group too frequently in the past',
  'Other',
];
/** Emit an event to close this decline to quote dialog */
const closeDeclineToQuoteDialog = () => {
  btnDeclining.value = false;
  storeProductsToDecline.value = [];
  emit('update:visible', false);
};
/** Sends event to segment */
const sendAnalytics = () => {
  trackSegmentEvent('Clicked Decline to Quote', segmentData());
};
/**
 * Makes sure that the char limit isn't exceeded, the button hasn't been clicked
 *
 * @returns {boolean}
 */
const declineDocument = async () => {
  btnDeclining.value = true;
  try {
    const document = await DocumentServices.declineDocument(
      proposalDocumentId.value,
      declineReason.value,
    );

    productStore.setProducts(document.products);
    projectStore.setProposalDocument(document);
    sendAnalytics();
  } catch {
    toast.add({
      closable: true,
      detail: 'There was an error declining this proposal. Please try again.',
      severity: 'error',
    });
  } finally {
    closeDeclineToQuoteDialog();
  }
};
/** Call the product service to decline a product or multiple products */
const declineProduct = async () => {
  btnDeclining.value = true;
  try {
    await Promise.all(
      props.productsToDecline.map(async ({ id }) => {
        await productStore.updateProductState({
          productId: id,
          endpointState: 'decline',
          declinedReason: declineReason.value,
        });
      }),
    );
    sendAnalytics();
  } catch {
    toast.add({
      closable: true,
      detail:
        'Not all of the products you selected were successfully declined.',
      severity: 'error',
    });
  } finally {
    closeDeclineToQuoteDialog();
  }
};
/** If you click the 'x' make sure the dialog closes */
const onBeforeClose = () => {
  btnDeclining.value = false;
  storeProductsToDecline.value = [];
  emit('update:visible', false);
};
/**
 * Determine which decline action to take depending on whether or not there is
 * more than one product to decline.
 *
 * @returns {boolean}
 */
const declineAction = computed(() =>
  decliningAllProducts.value ? declineDocument : declineProduct,
);
</script>

<style lang="scss" scoped>
.warning-icon-container {
  background: var(--tf-red-light);
  height: 95px;
  border-radius: 6px;
  margin-bottom: 20px;
}

img {
  width: 67px;
  height: 78px;
  margin: 0 auto;
}

p {
  margin: 23px 0;
}

h4.reason-for-declining-label {
  padding-left: 50px;
}

.decline-reason-options {
  display: flex;
  flex-direction: column;
  padding: 0 50px 20px;
}
</style>
