<script setup>
/**
 * Shared sign in component with functionality to slot in secondary sign-in
 * methods.
 *
 * @exports AppSignIn
 */
import { ref, reactive, computed, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import { Form, FormField } from '@primevue/forms';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import Password from 'primevue/password';
import Message from 'primevue/message';
import Button from 'primevue/button';
import AuthPageHeader from '@/shared/components/AuthPages/Header.vue';
import AuthPageFooter from '@/shared/components/AuthPages/Footer.vue';

import { zodResolver } from '@primevue/forms/resolvers/zod';
import { z } from 'zod';

defineOptions({
  name: 'AppSignIn',
});

const props = defineProps({
  /** URL for the forgot password link */
  linkForgot: {
    type: [String, Object],
    default: '',
  },
  /** URL for the account registration link */
  linkRegister: {
    type: [String, Object],
    default: '',
  },
  /** URL for the terms & conditions link */
  linkTerms: {
    type: [String, Object],
    default: '',
  },
  /** UserInfo object from a store to show current email (if needed) */
  userInfo: {
    type: Object,
    default: () => ({}),
  },
  /** Initial values for the form */
  email: {
    type: String,
    default: '',
  },
  password: {
    type: String,
    default: '',
  },
  rememberMe: {
    type: Boolean,
    default: false,
  },
  /**
   * Determine whether or not to hide the password field and show the SSO method
   * slot
   */
  showSsoMethod: {
    type: Boolean,
    default: false,
  },
  /**
   * Determine if email field is hidden or not (this is used for use cases where
   * the SSO method is the only sign in method allowed.)
   */
  hideEmail: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits([
  'update:email',
  'update:password',
  'update:rememberMe',
]);
const autofilledPassword = ref(false);
const signInForm = reactive({
  email: '',
  password: '',
  rememberMe: false,
  showPassword: false,
});
const resolverEmail = zodResolver(
  z
    .string()
    .min(1, { message: 'Email is required.' })
    .email({ message: 'Invalid email address.' }),
);
const resolverPassword = zodResolver(
  z.string().min(1, { message: 'Please input your password.' }),
);
/**
 * If the user has provided both their email and password
 *
 * @returns {boolean}
 */
const validFields = computed(() => signInForm.email && signInForm.password);
/**
 * There is a chrome bug that doesn't fire a change event when a field is auto
 * filled we can however check the animation change from being auto filled
 * however we still can't access the value of the password field but since we
 * know it was autofilled lets just enable the button
 *
 * @param {event} e
 */
const checkAnimation = (e) => {
  if (e.animationName === 'onAutoFillStart') {
    autofilledPassword.value = true;
  }
};

/** Watch `signInForm` fields and emit updates */
watch(
  () => ({
    email: signInForm.email,
    password: signInForm.password,
    rememberMe: signInForm.rememberMe,
  }),
  (newValues, oldValues) => {
    if (newValues.email !== oldValues.email) {
      emit('update:email', newValues.email);
    }
    if (newValues.password !== oldValues.password) {
      emit('update:password', newValues.password);
    }
    if (newValues.rememberMe !== oldValues.rememberMe) {
      emit('update:rememberMe', newValues.rememberMe);
    }
  },
  { deep: true },
);

const route = useRoute();

onMounted(() => {
  const { email, pw } = route.query;

  if (email && pw) {
    signInForm.email = email;
    signInForm.password = pw;
    signInForm.showPassword = true;

    return;
  }

  // We want the email address to persist across all sign in/forgotPW/registration forms
  signInForm.email = props.userInfo?.email;
  const savedEmail = window.localStorage.getItem('email');

  if (savedEmail) {
    signInForm.rememberMe = true;
  }
  // if we don't have an email already check if we "remembered one"
  if (!signInForm.email && savedEmail) {
    signInForm.email = savedEmail;
  }
});
</script>

<template>
  <Form
    :initial-values="signInForm"
    name="auth-form"
    label-position="top"
    class="auth-form sign-in"
    data-test="sign-in form"
  >
    <AuthPageHeader title="Sign In" />
    <div class="inputs-container">
      <FormField
        v-if="!hideEmail"
        v-slot="$field"
        class="email-container flex flex-col gap-2"
        name="email"
        :resolver="resolverEmail"
        validate-on-value-update
        validate-on-blur
      >
        <label for="app-sign-in-email">Email Address</label>
        <!-- Changing `autofilledPassword` for both inputs is required for disabling the button -->
        <InputText
          id="app-sign-in-email"
          v-model="signInForm.email"
          data-test="edit sign in email"
          placeholder="Enter email"
          @keydown="autofilledPassword = false"
        />
        <Message
          v-if="$field?.invalid"
          severity="error"
          size="small"
          variant="simple"
          >{{ $field.error?.message }}
        </Message>
      </FormField>
      <template v-if="!showSsoMethod">
        <FormField
          v-slot="$field"
          class="password-container flex flex-col gap-2"
          name="password"
          :resolver="resolverPassword"
          validate-on-value-update
          validate-on-blur
        >
          <label for="app-sign-in-password">Password</label>
          <Password
            id="app-sign-in-password"
            v-model="signInForm.password"
            data-test="edit sign in password"
            fluid
            toggle-mask
            placeholder="Enter password"
            :feedback="false"
            @animationstart="checkAnimation($event)"
            @keydown="autofilledPassword = false"
          />
          <Message
            v-if="$field?.invalid"
            severity="error"
            size="small"
            variant="simple"
            >{{ $field.error?.message }}
          </Message>
        </FormField>
        <!-- Checkbox row -->
        <div class="flex justify-between">
          <FormField class="remember-me" name="remember-user">
            <Checkbox
              v-model="signInForm.rememberMe"
              binary
              data-test="toggle remember me"
            />
            <label>Remember me</label>
          </FormField>
          <RouterLink
            id="link-forgot-password"
            :to="linkForgot"
            data-test="to forgot password form"
          >
            Forgot password?
          </RouterLink>
        </div>
        <!-- Submit Btn -->
        <slot name="ctaSubmit">
          <Button
            :disabled="!validFields && !autofilledPassword"
            data-test="submit sign in"
            name="submit-button"
            type="submit"
            label="Sign in"
            severity="help"
            variant="outlined"
            fluid
            class="mb-4"
          />
        </slot>
      </template>
      <div v-if="showSsoMethod && $slots.ctaSsoSubmit">
        <slot name="ctaSsoSubmit" />
      </div>
    </div>
    <!-- terms -->
    <p v-if="linkTerms" class="terms-of-use text-center mb-4">
      By signing in, you agree to
      <RouterLink :to="linkTerms" target="_blank" data-test="to terms of use">
        Terms of use
      </RouterLink>
    </p>
    <!-- Extra CTAs -->
    <p v-if="linkRegister" class="text-center mb-4">
      Don't have an account?
      <RouterLink :to="linkRegister" data-test="to create account form">
        Create an account
      </RouterLink>
    </p>
    <AuthPageFooter />
  </Form>
</template>

<style lang="scss" scoped>
#link-forgot-password {
  margin-bottom: 30px;
}

/* stylelint-disable declaration-no-important */
.toggle-password {
  padding: {
    top: 5px !important;
    bottom: 5px !important;
  }
}
/* stylelint-enable declaration-no-important */
</style>

<style>
.forgot-pw-message {
  max-width: 690px;
}

:-webkit-autofill {
  animation-name: onAutoFillStart;
}

:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}

/* stylelint-disable block-no-empty  */
@keyframes onAutoFillStart {
}

@keyframes onAutoFillCancel {
}

/* stylelint-enable block-no-empty  */
</style>
