<template>
  <div class="rfp-quote-updates-tab tab-content">
    <BrokerUpdatesContainer class="h-full" />
    <QuoteEditsContainer class="h-full" heading="Quote edits" />
  </div>
</template>

<script setup>
import { onBeforeUnmount } from 'vue';
import { storeToRefs } from 'pinia';
import { useNotificationsStore } from '@/stores/notifications.js';
import { useProjectStore } from '@/stores/project.js';
import { clearEditNotifications } from '@/services/notifications.js';

// Components
import QuoteEditsContainer from '@/components/QuoteEdits/QuoteEditsContainer.vue';
import BrokerUpdatesContainer from '@/components/BrokerUpdates/BrokerUpdatesContainer.vue';

defineOptions({
  name: 'UpdatesEdits',
});

// Stores
const notificationsStore = useNotificationsStore();
const projectStore = useProjectStore();
// Store References
const { unreadEdits } = storeToRefs(notificationsStore);
const { projectId } = storeToRefs(projectStore);
/**
 * If the quote edits container is opened, we should clear out the notifications
 * for this user.
 */
const clearQuoteEditsNotifications = async () => {
  if (unreadEdits.value) {
    await clearEditNotifications(projectId.value);
    notificationsStore.unreadEdits = null;
  }
};

/**
 * Clear all quote edit notifications if there are any before the component is
 * unmounted.
 */
onBeforeUnmount(() => {
  clearQuoteEditsNotifications();
});
</script>

<style lang="scss" scoped>
.h-full {
  height: 100%;
}
.rfp-quote-updates-tab {
  position: relative;
  flex-grow: 1;

  @media (min-width: 650px) {
    display: flex;
    flex-direction: row;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    border-right: 1px solid var(--tf-gray-light-medium);
    content: '';
  }
}

.broker-updates-container,
.history-updates-container {
  width: 50%;
}

.broker-updates-container {
  padding: 38px 55px 0 20px;
  height: 100%;
}

.history-updates-container {
  padding: 38px 20px 0 55px;
}
</style>
