<template>
  <header :class="['pdf-export-block', 'pdf-export-header']">
    <h1>{{ employerName }}</h1>
    <h2>{{ title }} as of {{ today }}</h2>
    <div v-if="showFilters" class="applied-filters">
      <template v-for="filter in renderedFilters">
        <p v-if="filter.values.length" :key="filter.type">
          <strong>{{ filter.type }}</strong
          >: {{ filter.values.join('; ') }}
        </p>
      </template>
    </div>
  </header>
</template>

<script setup>
import { computed } from 'vue';
import moment from 'moment';

/**
 * PdfExportHeader
 *
 * @exports TfPdfExportHeader
 */
defineOptions({
  name: 'TfPdfExportHeader',
});

const props = defineProps({
  employerName: {
    type: String,
    required: true,
  },
  filters: {
    type: Object,
    default: () => ({}),
  },
  title: {
    type: String,
    required: true,
  },
});
/**
 * Generates today's date for the template.
 *
 * @returns {string}
 */
const today = computed(() => moment().format('MM/DD/YYYY'));
/**
 * Converts the filter object to an array of objects with `{ type, values }`.
 *
 * @returns {Array}
 */
const renderedFilters = computed(() =>
  Object.keys(props.filters).map((type) => ({
    type,
    values: props.filters[type],
  })),
);
/**
 * Looks for any of the filters in `renderedFilters` to see if they have values.
 *
 * @returns {boolean}
 */
const showFilters = computed(
  () =>
    renderedFilters.value.length &&
    renderedFilters.value.some((filter) => filter.values.length),
);
</script>

<style lang="scss" scoped>
@use '@/shared/assets/styles/variables';

header {
  border-bottom: 2px var(--tf-light-gray) solid;
  padding-bottom: 20px;
  text-align: left;
  width: calc(100% - 80px);
}

h1,
h2 {
  font-weight: bold;
  color: var(--tf-black);
}

h1 {
  font-size: 20px;
}

h2 {
  font-size: 16px;
}

.applied-filters {
  background: var(--tf-extra-light-gray-1);
  color: var(--tf-black);
  padding: 9px 12px;
  font-size: 12px;
  line-height: 18px;

  strong {
    text-transform: capitalize;
  }
}
</style>
