<template>
  <div
    v-loading="[!quoteEditsFetched]"
    :class="{ 'is-panel': $route.meta.pageType === 'dataEntry' }"
    data-test="history updates container"
    class="history-updates-container"
  >
    <AppMessage
      v-if="quoteEditsError"
      class="loading-error-disclaimer"
      severity="danger"
    >
      There was an error getting the quote edits.
    </AppMessage>
    <div
      v-else-if="quoteEditsFetched"
      class="quote-edits-container"
      data-test="quote edits"
    >
      <header>
        <h3 :class="{ 'has-dates': dates.length }" v-text="'Quote edits'" />
        <p v-if="dates.length" class="panel-paragraph-heading">
          This is a record of confirmed edits to your offer, which is also
          visible to your broker.
        </p>
      </header>
      <div v-if="dates.length" class="filter-btns">
        <Button
          v-if="!filterOpen"
          data-test="open quote edit filters"
          icon="fa-solid fa-filter"
          icon-pos="right"
          variant="text"
          label="Open filters"
          data-dont-close-panel
          @click="openFilters"
        />
      </div>
      <!-- Filter section -->
      <div
        v-if="dates.length"
        :class="{ open: filterOpen }"
        class="filters"
        data-test="filters panel"
      >
        <div class="filters-inner">
          <Button
            v-if="filterOpen"
            class="close-btn"
            icon="fa-solid fa-xmark"
            icon-pos="right"
            size="icon"
            data-dont-close-panel
            data-test="close filters"
            @click="closeFilters"
          />

          <p>I only want to see:</p>
          <div v-if="productFilters.length" class="product-filters">
            <h3>Product</h3>
            <div
              v-for="product in productFilters"
              :key="product.id"
              class="flex gap-2"
            >
              <Checkbox
                v-model="productFiltersSelected"
                :input-id="product.id"
                :value="product.id"
                :name="product.id"
                @change="filterHistory"
              />
              <label :for="product.id">
                {{ product.project_product.product_type_name }}
                <template v-if="product.project_product.alternative">
                  - {{ product.project_product.label }}
                </template>
              </label>
            </div>
          </div>
          <div class="author-filters">
            <h3>Author</h3>
            <div
              v-for="author in authorFilters"
              :key="author.id"
              class="flex gap-2"
            >
              <Checkbox
                v-model="authorFiltersSelected"
                :input-id="author.id"
                :value="author.id"
                :name="author.id"
                @change="filterHistory"
              />
              <label :for="author.id">
                {{ author.first_name }} {{ author.last_name }}
              </label>
            </div>
          </div>
          <Button
            v-if="filterOpen"
            class="reset-btn"
            icon="fa-solid fa-redo"
            size="text"
            label="Reset filters"
            data-test="reset quote edit filters"
            severity="danger"
            @click="resetFilters"
          />
        </div>
      </div>
      <!-- Quote edits -->
      <TfQuoteEditsExport
        :dates="filteredDates"
        :employer-name="employerName"
        :filters="filters"
        :project-id="Number(projectId)"
        button-title="Export quote edits"
        @click="sendAnalytics()"
      />
      <section v-for="(date, index) in filteredDates" :key="index">
        <ul>
          <AppQuoteEditItem
            v-for="item in date"
            :key="item.id"
            v-bind="{
              attributeLink: getAttributeLink(item),
              item,
              showEditor: true,
            }"
          />
        </ul>
      </section>
      <!-- No results section -->
      <template v-if="dates">
        <div
          v-if="dates.length && !filteredDates.length"
          class="empty-state filtered-out"
          data-test="no quote edits to display"
        >
          <img :src="emptyIcon" alt="Filtered out icon" />
          <p>
            There are no quote edits that match your selected filters. Try
            selecting fewer filters.
          </p>
        </div>
        <!-- Empty section -->
        <div v-if="!dates.length" class="empty-state-container">
          <div class="empty-state">
            <img :src="emptyIcon" alt="Empty icon" />
            <p v-if="proposalDocument.state === 'finalized'">
              Once you’ve submitted your quote, this will serve as a record of
              your confirmed edits to your offer. This will also be visible to
              your broker.
            </p>
            <p v-else>
              Once you’ve submitted your quote, this will serve as a record of
              your confirmed edits to your offer. This will also be visible to
              your broker.
            </p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { useQuoteEditsStore } from '@/stores/quoteEdits.js';
import { useNotificationsStore } from '@/stores/notifications.js';
import { useProjectStore } from '@/stores/project.js';
import { useProductStore } from '@/stores/product.js';
import { storeToRefs } from 'pinia';
import { uniqBy } from 'lodash';

import { segmentData } from '@/utils/analytics.js';
import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
import { clearEditNotifications } from '@/services/notifications.js';

import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import AppMessage from '@/shared/components/AppMessage.vue';
import AppQuoteEditItem from '@/shared/components/AppQuoteEditItem.vue';
import TfQuoteEditsExport from '@/shared/components/TfPdfExport/QuoteEdits/index.vue';
import emptyIcon from '@/assets/check-list-record.svg';

/**
 * Quote Edits Container
 *
 * @exports QuoteEditsContainer
 */

defineOptions({
  name: 'QuoteEditsContainer',
});

// Stores
const quoteEditsStore = useQuoteEditsStore();
const notificationsStore = useNotificationsStore();
const projectStore = useProjectStore();
const productStore = useProductStore();
// Store State
const { unreadEdits } = storeToRefs(notificationsStore);
const { quoteEditsError, dates, quoteEditsFetched } =
  storeToRefs(quoteEditsStore);
const { productFiltersSelected: storedProductFiltersSelected } =
  storeToRefs(quoteEditsStore);
const { employerName, projectId, proposalDocument } = storeToRefs(projectStore);
const { products } = storeToRefs(productStore);
// Reactive Variables
const authorFilters = ref([]);
const authorFiltersSelected = ref([]);
const filteredDates = ref([]);
const filterOpen = ref(false);
const productFilters = ref([]);
const productFiltersSelected = ref([]);
/**
 * Creates the filters based on the `productFiltersSelected`
 *
 * @returns {object}
 */
const filters = computed(() => {
  if (!products.value.length) return {};

  return {
    products: productFiltersSelected.value.map((selectedId) => {
      const product = products.value.find(({ id }) => id === selectedId);
      const projectProduct = product.project_product;
      let name = product.product_type_name;

      if (projectProduct.alternative || name !== projectProduct.label) {
        name += `, ${projectProduct.label}`;
      }

      return name;
    }),
    author: authorFiltersSelected.value.map((selectedId) => {
      const author = authorFilters.value.find(({ id }) => id === selectedId);

      return `${author.first_name} ${author.last_name}`;
    }),
  };
});
// Methods
/** Call the notifications service to clear edit notifications */
const containerClearEditNotifications = async () => {
  await clearEditNotifications(projectId.value);
  unreadEdits.value = null;
};

/** Apply the filters to our dates prop and create a list of filtered dates */
function filterHistory() {
  // If no filters are selected, use full dates list
  if (
    !authorFiltersSelected.value.length &&
    !productFiltersSelected.value.length
  ) {
    filteredDates.value = dates.value;

    return;
  }

  filteredDates.value = dates.value.reduce((acc, date) => {
    // eslint-disable-next-line camelcase
    const filteredEdits = date.filter(({ history_details, product_id }) => {
      // eslint-disable-next-line camelcase
      const authorId = history_details.responsible_user.id;

      return (
        (!authorFiltersSelected.value.length ||
          authorFiltersSelected.value.includes(authorId)) &&
        (!productFiltersSelected.value.length ||
          productFiltersSelected.value.includes(product_id))
      );
    });

    if (filteredEdits.length) acc.push(filteredEdits);

    return acc;
  }, []);
}

/** Get a list to populate the filters */
function getFilterLists() {
  // First, create a bunch of variables to handle filtering logic.
  const authorFiltersTemp = [];
  let productFiltersTemp = [];

  authorFilters.value = [];
  productFilters.value = [];

  // Only proceed if data exists
  if (dates.value) {
    dates.value.forEach((date) => {
      date.forEach((edit) => {
        authorFiltersTemp.push(edit.history_details.responsible_user);
      });
    });

    productFiltersTemp = products.value.slice(0);

    // Filter author array to only unique users
    authorFilters.value = uniqBy(authorFiltersTemp, (e) => e.id);
    productFilters.value = productFiltersTemp;

    filterHistory();
  }
}

/** Open the filter drawer */
const openFilters = () => {
  filterOpen.value = true;
};
/** Close the filter drawer */
const closeFilters = () => {
  filterOpen.value = false;
};
/** Close the filter drawer and set all filters to selected */
const resetFilters = () => {
  authorFiltersSelected.value = [];
  productFiltersSelected.value = [];
  filteredDates.value = dates.value;
};
/**
 * On quote level history, we provide links to go to a specific product, this
 * determines the link.
 *
 * @param {object} item
 * @returns {string}
 */
const getAttributeLink = (item) => {
  const attributeType =
    item.attributable_type.toLowerCase() === 'plandesignattribute' &&
    item.history_details.attribute_name.toLowerCase() !== 'rate guarantee'
      ? 'plan'
      : 'rate';

  return `/project/${projectId.value}/product/${item.product_id}/${attributeType}/review`;
};
/** Sends event to segment */
const sendAnalytics = () => {
  trackSegmentEvent('Export RFP edits', segmentData());
};

/** When the dates prop changes we want to recreate our list of filter options */
watch(dates, getFilterLists);
/**
 * If the products haven't loaded let we also recreate our filter options
 * (because know we have a list of products to relate them to)
 */
watch(products, getFilterLists);
/** Changes the selected product filters and if filter is open */
watch(storedProductFiltersSelected, (newVal) => {
  if (newVal.length) {
    productFiltersSelected.value = [].concat(newVal);
  }

  filterOpen.value = Boolean(newVal.length);
  filterHistory();
});

/**
 * When the component is created we look at the data passed in and call
 * getFilterLists() this function filters our data (though at this point we
 * don't need to), it also creates the array to use for filters this doesn't do
 * much if this is on page reload (we have to call on the dates watch as well)
 */
onMounted(() => {
  quoteEditsStore.getQuoteEdits(proposalDocument.value.id);
  if (unreadEdits.value) {
    containerClearEditNotifications();
  }

  productFiltersSelected.value = [].concat(storedProductFiltersSelected.value);

  if (productFiltersSelected.value.length) {
    filterOpen.value = true;
  }

  getFilterLists();
});

/** Reset selected product filters to none */
onBeforeUnmount(() => {
  quoteEditsStore.setProductsFiltersSelected([]);
});
</script>

<style lang="scss" scoped>
/* TODO: Handle this without disable. Turning it off in the whole file because there's four of them. */
/* stylelint-disable no-descending-specificity */

:deep(.pdf-export-generator) {
  padding: 20px 0 0 27px;
}

section {
  padding-left: 11px;

  &:first-of-type {
    margin-top: 5px;
  }

  &:last-child {
    li:last-child {
      border-bottom: 0;
    }
  }
}

header {
  border-bottom: 1px solid var(--tf-gray-light-medium);

  .is-panel & {
    text-align: center;
    margin: 0;
    padding: 0;
  }

  h3 {
    margin-bottom: 25px;

    &.has-dates {
      margin-bottom: 14px;
    }

    .is-panel & {
      font-size: 24px;
      margin-bottom: 25px;
      margin-top: 49px;
      line-height: 24px;
    }
  }

  p {
    color: var(--tf-gray);
    line-height: 18px;
    margin-top: 0;
    padding-bottom: 25px;
  }
}

.empty-state {
  padding: 10px 0;

  &.filtered-out {
    text-align: center;

    .filters:not(.open) + & img {
      margin-top: 58px;
    }
  }
}

.empty-state-container {
  display: flex;
  align-items: center;
  align-self: center;
  flex-grow: 1;
  text-align: center;
  max-height: calc(100vh - 342px);
  width: 100%;
  min-height: 200px;

  p {
    font-size: 18px;
    line-height: 26px;
  }

  .panel & {
    display: flex;
    padding-top: 0;

    p {
      padding-left: 26px;
      padding-right: 35px;
    }
  }

  img {
    width: 74px;
    height: 88px;
  }
}

.filters {
  background: var(--tf-gray-light);
  border-bottom: 1px solid var(--tf-gray-light-medium);
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;

  .el-checkbox {
    display: block;
    margin-bottom: 8px;

    + .el-checkbox {
      margin-left: 0;
    }

    :deep(&__label) {
      color: var(--tf-gray-dark);
    }
  }

  &.open {
    max-height: initial;
    border-bottom: 1px solid var(--tf-gray-light-medium);

    + .empty-state img {
      margin-top: 58px;
      transition: margin 0.1s;
    }
  }

  .filters-inner {
    padding: 12px 16px 30px 30px;
    text-align: right;

    > div {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      text-align: left;
      box-sizing: border-box;

      &.single {
        width: 100%;
      }
    }

    p {
      text-align: left;
      color: var(--tf-gray);
      margin-top: 0;
    }

    .close-btn {
      margin-left: auto;
    }
  }

  h3 {
    font-size: 14px;
    text-transform: uppercase;
    color: var(--tf-blue-dark);
    margin-bottom: 16px;
  }
}

.filter-btns {
  padding: 17px 27px;
}

.history-updates-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  p {
    color: var(--tf-gray);
  }
}

.quote-edits-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.loading-error-disclaimer {
  margin-top: 20px !important; //stylelint-disable-line declaration-no-important
}

.is-panel {
  width: 100%;
  text-align: left;
  min-width: 100vw;

  @media (min-width: 557px) {
    min-width: 557px;
  }
}

.reset-btn {
  margin: 14px 0 0 auto !important; // stylelint-disable-line declaration-no-important
}
</style>
