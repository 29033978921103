<template>
  <header
    :class="{
      'upload-renewal': productStore.isUploadRenewalRatePass,
    }"
  >
    <div>
      <h3>
        <span>{{ props.container.name }}</span>
        <slot name="btnSeparateClasses" />
      </h3>
      <slot name="description" />
    </div>
    <div class="btn-group">
      <slot name="btnCopyInforce" />
      <div
        v-if="productStore.isSmartProposal"
        class="btn-expansion"
        @click="$emit('toggle')"
      >
        <i class="fa-solid fa-chevron-right btn-expansion-icon" size="2xl" />
      </div>
    </div>
  </header>
</template>

<script setup>
import { useProductStore } from '@/stores/product.js';
/**
 * Container Header
 *
 * @exports ContainerHeader
 */
// Emits
defineEmits(['toggle']);
// Stores
const productStore = useProductStore();
// Props
const props = defineProps({
  container: {
    type: Object,
    required: true,
  },
});
</script>

<style lang="scss" scoped>
header {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  padding: 30px;
  border-top: 1px solid var(--tf-gray-medium);
  border-left: 1px solid var(--tf-gray-medium);
  border-right: 1px solid var(--tf-gray-medium);
  background-color: var(--tf-gray-light);

  .dialog-plan-summary & {
    padding: 0 0 30px;
    border: none;
    background-color: transparent;
  }
}

.btn-group {
  margin-left: auto;
}

.btn-expansion {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid var(--tf-gray);
  cursor: pointer;

  .active & {
    .btn-expansion-icon {
      transform: rotate(90deg);
    }
  }

  /* TODO: Handle this without disable */
  /* stylelint-disable no-descending-specificity */
  .btn-expansion-icon {
    transition: all 0.25s;
    transform: rotate(-90deg);
  }
}
/* stylelint-enable no-descending-specificity */

h3 {
  font-size: 20px;

  span {
    display: inline-block;
    margin-right: 15px;
  }

  .is-plan-summary & {
    margin-bottom: 0;
    font-size: 16px;
  }
}

:deep(.icon-chevron-right) {
  svg {
    margin-left: 8px;
  }
}

h4 {
  margin-bottom: 0;
}
</style>
