<template>
  <div
    v-loading="[!updates]"
    :class="{ 'is-panel': $route.meta.pageType === 'dataEntry' }"
    class="broker-updates-container"
    data-test="rfp updates container"
  >
    <template v-if="updates">
      <h3>RFP updates</h3>
      <TfPdfExport
        button-title="Export RFP updates"
        :is-disabled="!updates.length"
        :filename="`rfp_updates_${projectId}.pdf`"
        @click.native="sendAnalytics()"
      >
        <TfPdfExportHeader :employer-name="employerName" title="RFP updates" />
        <BrokerUpdate
          v-for="update in updates"
          :key="update.id"
          :update="update"
          :is-pdf-render="true"
          class="pdf-export-block"
        />
      </TfPdfExport>
      <div
        v-if="!updates.length"
        class="empty-state-container"
        data-test="empty container"
      >
        <div class="empty-state">
          <img :src="emptyIcon" alt="Empty icon" />
          <p>There have been no updates to this RFP.</p>
        </div>
      </div>
      <div class="broker-updates">
        <BrokerUpdate
          v-for="update in updates"
          :key="update.id"
          :update="update"
        />
      </div>
    </template>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useBrokerUpdatesStore } from '@/stores/brokerUpdates.js';
import { useProjectStore } from '@/stores/project.js';
import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
import { segmentData } from '@/utils/analytics.js';

// Components
import emptyIcon from '@/assets/check-list-record.svg';
import TfPdfExportHeader from '@/shared/components/TfPdfExport/Header.vue';
import TfPdfExport from '@/shared/components/TfPdfExport/index.vue';
import BrokerUpdate from './BrokerUpdate.vue';

// Stores
const brokerUpdatesStore = useBrokerUpdatesStore();
const projectStore = useProjectStore();
// Store References
const { updates } = storeToRefs(brokerUpdatesStore);
const { employerName, projectId } = storeToRefs(projectStore);
/** Sends event to segment */
const sendAnalytics = () => {
  trackSegmentEvent('Export RFP updates', segmentData());
};

/** When component is mounted, we clear out all the broker edit notifications */
onMounted(() => {
  brokerUpdatesStore.markNotificationsAsRead(projectId.value);
});
</script>

<style lang="scss" scoped>
:deep(.pdf-export-generator) {
  padding: 20px 0 0 27px;
}

.broker-updates {
  &-container {
    display: flex;
    flex-direction: column;

    p {
      text-align: center;
      line-height: 1.29;
      color: var(--tf-gray);
      margin-bottom: 10px;
    }
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: 0;
  }
}

.empty-state {
  margin: 0 auto;
  padding: 10px 0;

  &-container {
    display: flex;
    align-items: center;
    align-self: center;
    flex-grow: 1;
    text-align: center;
    max-height: calc(100vh - 342px);
    width: 100%;
    min-height: 200px;

    p {
      font-size: 18px;
      line-height: 26px;
    }

    .is-panel & {
      max-height: none;
      display: flex;
      padding-top: 0;

      p {
        padding-left: 26px;
        padding-right: 35px;
      }
    }

    img {
      width: 74px;
      height: 88px;
    }
  }
}

h3 {
  margin-bottom: 0;
  padding-bottom: 25px;
  border-bottom: 1px solid var(--tf-gray-light-medium);

  .is-panel & {
    text-align: center;
    padding: 50px 0 25px;
    font-size: 24px;
    line-height: 24px;
  }
}

.is-panel {
  width: 100%;
  text-align: left;
  min-width: 100vw;

  @media (min-width: 557px) {
    min-width: 557px;
  }
}
</style>
