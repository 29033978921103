<template>
  <header>
    <h3>Comments</h3>
    <p>
      Post updates or questions to the broker team. You’ll see their responses
      here.
    </p>
    <div v-show="!showTopCommentBox" class="btn-container">
      <Button
        data-test="create new comment"
        size="large"
        label="Send comment"
        @click="toggleTopComment"
      />
    </div>

    <TopComment
      v-show="showTopCommentBox"
      :autosave-data="autosaveData"
      data-test="new comment section"
      @close="onClose"
    />
  </header>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectStore } from '@/stores/project.js';

import Button from 'primevue/button';
import TopComment from './TopComment.vue';

/**
 * Comments Header
 *
 * @exports CommentsHeader
 */
defineOptions({
  name: 'CommentsHeader',
});

// Stores
const projectStore = useProjectStore();
const { projectId } = storeToRefs(projectStore);
// Refs
const autosaveData = ref({});
const showTopCommentBox = ref(false);

// Methods
/** Toggles the top comment component */
function toggleTopComment() {
  showTopCommentBox.value = !showTopCommentBox.value;
}

/**
 * Get any top level comments, set them in the store and toggle the comment
 * open.
 */
function getSessionStorage() {
  const savedComment = window.sessionStorage.getItem('commentTopLevel');

  if (savedComment) {
    const commentObject = JSON.parse(savedComment);
    const isDifferentProject = +commentObject.projectId !== +projectId.value;

    if (isDifferentProject) {
      window.sessionStorage.removeItem('commentTopLevel');

      return;
    }

    autosaveData.value = commentObject;
    toggleTopComment();
  }
}

/** Clear out the local autosave data and toggle the top comment component. */
function onClose() {
  autosaveData.value.message = null;
  autosaveData.value.timestamp = null;
  toggleTopComment();
}

/** Get session storage if any exists */
onMounted(() => {
  getSessionStorage();
});
</script>

<style lang="scss" scoped>
header {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px var(--tf-gray-light-medium);

  .is-panel & {
    margin-bottom: 21px;
    padding-bottom: 19px;
  }
}

h3 {
  margin-bottom: 10px;

  .is-panel & {
    text-align: center;
    margin-top: 17px;
    font-size: 24px;
  }
}

p {
  margin-bottom: 20px;
  color: var(--tf-gray);
  padding-bottom: 20px;
  border-bottom: 1px solid var(--tf-gray-light-medium);
}

.btn-container {
  text-align: center;
}
</style>
