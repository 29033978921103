<template>
  <section id="page-content">
    <section id="data-entry-container">
      <div class="data-entry-landing">
        <template v-if="rateEntryStore.isLoaded">
          <div class="data-entry-containers" data-test="entry container">
            <RateEntryContainer
              v-for="container in rateEntryStore.rateEntryContainers"
              :key="container.id.join('_')"
              :container="container"
              :warning-message="
                !notificationsStore.reviewAlertDismissed && warningMessage
                  ? warningMessage
                  : null
              "
              @dismiss-alert="notificationsStore.reviewAlertDismissed = true"
            />
          </div>
          <footer :class="[{ 'review-footer': route.meta.readonly }]">
            <Card>
              <template #content>
                <div class="btn-group">
                  <Button
                    label="My Quotes"
                    data-test="back button"
                    icon="fa-solid fa-circle-left"
                    class="icon-left"
                    @click="sendMyQuotesAnalytics"
                  />
                  <Button
                    v-if="showReviewPlanDesignSecondaryButton"
                    label="Review Plan Design"
                    data-test="continue secondary"
                    @click="() => router.push({ name: 'PlanDesign' })"
                  />
                  <Button
                    v-if="showSubmitQuoteSecondaryButton"
                    label="Submit Quote"
                    data-test="submit quote secondary"
                    @click="handleSubmitQuote"
                  />
                </div>
                <div data-test="stepper helptext" class="stepper-helptext">
                  {{ rateErrorCountText }}
                </div>
                <div class="btn-group">
                  <Button
                    v-if="route.meta.readonly"
                    icon="check"
                    icon-pos="right"
                    label="Done"
                    @click="router.push({ name: 'RfpOverview' })"
                  />
                  <Button
                    v-else-if="showNextButton"
                    label="Next"
                    data-test="next error button"
                    class="submit-button"
                    icon="fa-solid fa-circle-right"
                    icon-pos="right"
                    @click="() => nextError()"
                  />
                  <template v-else>
                    <template v-if="!planDesignStore.isPlanDesignValid">
                      <Button
                        data-test="continue"
                        class="submit-button"
                        icon="fa-solid fa-circle-right"
                        icon-pos="right"
                        label="Review Plan Design"
                        type="primary"
                        @click="router.push({ name: 'PlanDesign' })"
                      />
                    </template>
                    <Button
                      v-else
                      id="btn-submit-quote"
                      :disabled="!canSubmit || validatingProduct"
                      :loading="validatingProduct"
                      icon="fa-solid fa-circle-right"
                      icon-pos="right"
                      class="submit-button"
                      data-test="submit quote"
                      label="Submit Quote"
                      @click="onSubmitQuote"
                    />
                  </template>
                </div>
              </template>
            </Card>
          </footer>
        </template>
      </div>
    </section>
  </section>
</template>

<script setup>
import {
  ref,
  computed,
  watch,
  nextTick,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
// Stores
import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
import { useNotificationsStore } from '@/stores/notifications.js';
import { useRateEntryStore } from '@/stores/rateEntry.js';
import { useProjectStore } from '@/stores/project.js';
import { useProductStore } from '@/stores/product.js';
import { usePlanDesignStore } from '@/stores/planDesign.js';
// Components
import { Card, Button } from 'primevue';
import RateEntryContainer from '@/components/RateEntry/RateEntryContainer.vue';
// Utils
import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
import { useToast } from 'primevue/usetoast';

/**
 * Rate Entry for the product page.
 *
 * @exports RateEntryLanding
 */
// Utils
const route = useRoute();
const router = useRouter();
const toast = useToast();
// Stores
const carrierInfoStore = useCarrierInfoStore();
const notificationsStore = useNotificationsStore();
const rateEntryStore = useRateEntryStore();
const projectStore = useProjectStore();
const productStore = useProductStore();
const planDesignStore = usePlanDesignStore();
// Variables
const destroyProductId = ref(null);
const displayAlertReview = ref(false);
const validatingProduct = ref(false);
const warningMessage = ref(null);
const currentError = ref(-1);
const fieldSwitchTimer = ref(null);
// Computed
/**
 * Don't show Next button on Not Started products
 *
 * @returns {boolean}
 */
const showNextButton = computed(
  () =>
    !rateEntryStore.isRateEntryValid &&
    (productStore.isSmartProposal ||
      !productStore.isProductNotStartedOrNotSubmitted),
);
/**
 * Only show secondary button for smart proposals
 *
 * @returns {boolean}
 */
const showSubmitQuoteSecondaryButton = computed(
  () =>
    productStore.isSmartProposal &&
    !planDesignStore.isPlanDesignValid &&
    rateEntryStore.isRateEntryValid,
);
/**
 * Show secondary review plan design button
 *
 * @returns {boolean}
 */
const showReviewPlanDesignSecondaryButton = computed(
  () => !planDesignStore.isPlanDesignValid && showNextButton.value,
);
/**
 * Returns a boolean to determine if the continue button is enabled based on if
 * all proposal inputs. For stop loss products, we ignore the rateGuarantee.
 *
 * @returns {boolean}
 */
const canSubmit = computed(() => {
  if (rateEntryStore.loadingRateEntry) {
    return false;
  }

  return productStore.currentProduct && rateEntryStore.isRateEntryValid;
});
/**
 * Error count text for stepper.
 *
 * @returns {string}
 */
const rateErrorCountText = computed(() => {
  if (productStore.isProductNotStartedOrNotSubmitted) {
    return '';
  }

  if (rateEntryStore.rateErrors?.length) {
    const rateFieldCount = Object.values(rateEntryStore.rateAttributes)
      .map((attr) =>
        Object.values(attr.rateValues).map(({ values }) => values.length),
      )
      .flat()
      .reduce((sum, count) => sum + count, 0);

    return `Missing ${rateEntryStore.rateErrors.length} of ${rateFieldCount} rates`;
  }

  if (!productStore.isStopLoss && !rateEntryStore.rateGuarantee?.value) {
    return 'Missing rate guarantee';
  }

  return 'Rates are complete';
});
/** Sends my quotes analytics call. */
const sendMyQuotesAnalytics = () => {
  trackSegmentEvent(
    `Smart ${productStore.smartDocType(true)} back to My Quotes`,
    {
      project_id: route.params.projectId,
    },
  );
  router.push({ name: 'RfpOverview' });
};
/**
 * Index of the currently focused error field
 *
 * @returns {number}
 */
const currentErrorIndex = () =>
  rateEntryStore.rateErrors.findIndex(
    (field) => field() === document.activeElement,
  );
/** Advance focus to the next invalid field. */
const nextError = () => {
  // If the user clicked the next button we can kill the timeout
  // since we're going to set the value ourselves at the end of this method.
  clearTimeout(fieldSwitchTimer.value);
  if (
    !productStore.isStopLoss &&
    !rateEntryStore.rateGuarantee?.value &&
    !rateEntryStore.rateErrors.length
  ) {
    document.querySelector('#rateGuarantee').focus();
  } else if (
    currentError.value >= rateEntryStore.rateErrors.length - 1 &&
    !rateEntryStore.rateGuarantee?.value &&
    !productStore.isStopLoss
  ) {
    document.querySelector('#rateGuarantee').focus();
  } else if (rateEntryStore.rateErrors.length) {
    const index =
      currentError.value >= rateEntryStore.rateErrors.length - 1
        ? 0
        : currentError.value + 1;

    rateEntryStore.rateErrors[index]().focus();
  }
  // Save the current error field index
  currentError.value = currentErrorIndex();
};
/**
 * We need to pulse until the rate guarantee has patched successfully If the
 * Rate Guarantee is still getting patched, then we put up a fake loader until
 * done.
 */
const onSubmitQuote = () => {
  const checkPatching = () => {
    setTimeout(() => {
      if (
        rateEntryStore.patchingGuarantee ||
        rateEntryStore.editingRateAttributeValue ||
        !canSubmit.value
      ) {
        checkPatching();
      } else {
        // eslint-disable-next-line no-use-before-define
        handleSubmitQuote();
      }
    }, 200);
  };

  checkPatching();
};
/** Validate Both Plan Design && Rate Entry before submitting the form */
const handleSubmitQuote = async () => {
  // disable the submit button to prevent double clicking
  validatingProduct.value = true;

  try {
    await productStore.validateProductAndSubmitQuote(productStore.productId);
  } catch {
    // eslint-disable-next-line no-use-before-define
    validateError();

    return;
  }
  const quoteOrRenewal = productStore.isSmartProposal ? 'Proposal' : 'Renewal';

  trackSegmentEvent(`Submit Smart ${quoteOrRenewal} from footer clicked`, {
    product_id: productStore.productId,
    product_type: productStore.currentProduct.product_type_name,
    carrier_name: carrierInfoStore.carrierName,
    carrier_id: carrierInfoStore.carrierId,
    project_id: projectStore.projectId,
    proposal_id: projectStore.proposalDocumentId,
  });

  router.push({ name: 'RfpOverview' });
};
/**
 * Puts page in state to show error and activate Submit button on validation
 * error for resubmit
 */
const validateError = () => {
  validatingProduct.value = false;
  toast.add({
    closable: true,
    detail:
      'Your request did not go through. Please check the Rate Entry values for any errors.',
    severity: 'error',
  });
};

// Watchers
watch(
  () => rateEntryStore.rateErrors,
  () => {
    if (rateEntryStore.rateErrors.length) {
      currentError.value = currentErrorIndex();
    }
  },
);

// Lifecycle
onMounted(async () => {
  // If the product is in a completed state and they haven't dismissed the alert.
  if (
    productStore.currentProduct?.state === 'completed' &&
    !notificationsStore.reviewAlertDismissed
  ) {
    displayAlertReview.value = true;
    warningMessage.value =
      'Your quote is hidden from brokers while you make edits. Click Submit to make your quote visible to them.';
  }

  // destroyProductId needs to be set as the productId to be used in the beforeDestroy lifecycle.
  destroyProductId.value = productStore.productId;

  /**
   * Wait for rate errors to be populated, do some stuff, then destroy the
   * watcher. We only want this to fire once, on load, but not immediately. Only
   * once the rate errors are loaded.
   */
  const unwatch = watch(
    () => rateEntryStore.rateErrors,
    () => {
      if (rateEntryStore.rateErrors.length > 0) {
        // Focus on the first error field
        if (currentError.value < 0) nextError();
        document.querySelectorAll('input, textarea').forEach((field) => {
          // When the user focuses a field we need to store which element in rateErrors the field is.
          field.addEventListener('focus', () => {
            currentError.value = currentErrorIndex();
          });
          // When the field is blurred, we need to wait a moment for the click handler to (potentially) run
          // then re-set the current error field index. This mostly handles setting the currentError value
          // back to 0 if the user clicks out of the field rather than clicking Next or Back
          field.addEventListener('blur', () => {
            fieldSwitchTimer.value = setTimeout(() => {
              nextTick(() => {
                currentError.value = currentErrorIndex();
              });
            }, 200);
          });
        });
        unwatch();
      }
    },
  );
});

/** Validate rate entry then clear it */
onBeforeUnmount(async () => {
  // Validate the Rate Entry on destroy instead of covering every page exit
  // Only do this when clicking on continue or if they've filled out the form
  // and then left the page w/o clicking continue. This is sort of edge casey but want to cover it.
  if (canSubmit.value && !rateEntryStore.validRateEntry) {
    try {
      await rateEntryStore.validateRateEntry(destroyProductId.value);
    } catch (e) {
      // We are ignoring any error state here b/c we don't want to show anything
      // But we want to avoid uncaught promises
    }
  }

  // If a user leaves any rate entry values blank before moving back to plan design, we want to
  // make sure the edit state is reset back to false as an empty value leaves the edit state as true
  rateEntryStore.editingRateAttributeValue = false;
  rateEntryStore.rateErrors = [];
});
</script>

<style lang="scss" scoped>
.data-entry-landing {
  margin-bottom: 100px;
}

.btn-group {
  display: flex;
  column-gap: 1rem;
}

footer {
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  :deep(.p-card, .p-card-body) {
    background-color: transparent;
  }
  :deep(.p-card-content) {
    background: var(--tf-gray-dark);
    border-radius: var(--p-select-border-radius);
    color: var(--tf-white);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: stretch;
    // space for Intercom icon.
    padding: 1rem 90px 1rem 1rem;
  }

  .app-button {
    background: rgba(255, 255, 255, 0.2);
    border-color: var(--tf-white);
    border-width: 1px;
  }

  .stepper-helptext {
    text-transform: uppercase;
    border-left: 1px solid var(--tf-white);
    border-right: 1px solid var(--tf-white);
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .submit-button {
    border-color: #a3a5f9;
    background: rgba(163, 165, 249, 0.2);
  }
}

#link-return-to-plan {
  display: flex;
  flex: 1;
  justify-content: flex-start;
}
</style>
