<template>
  <td
    :class="{
      'cell-empty': !props.label,
      'cell-composite': isComposite,
    }"
    width="25%"
  >
    <template v-if="props.label && !isComposite">
      {{ ageBanded(props.label) }}
    </template>
  </td>
</template>

<script setup>
import { computed } from 'vue';
/**
 * Rate Entry Table Cell Label
 *
 * @exports RateEntryTdLabel
 */
const props = defineProps({
  /** Subtype cell group label */
  label: {
    type: String,
    default: '',
  },
  /** Subtype Type used to determine the label layout */
  subtypeType: {
    type: String,
    default: 'CompositeRateValue',
  },
});
const isComposite = computed(() => props.subtypeType === 'CompositeRateValue');
const ageBanded = (value) => {
  if (value === 'age_80_plus') {
    return '80+';
  }

  return value.replace('age_', '').replace('_', '-');
};
</script>

<style scoped lang="scss">
.cell-composite:not(.cell-empty) {
  /* https://www.coffee-break-designs.com/labs/svg_stripe_generator/ */
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPjxkZWZzPjxwYXR0ZXJuIGlkPSJwYXR0ZXJuX3F4UUNPMSIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgd2lkdGg9IjQuNSIgaGVpZ2h0PSI0LjUiIHBhdHRlcm5UcmFuc2Zvcm09InJvdGF0ZSg0NSkiPjxsaW5lIHgxPSIwIiB5PSIwIiB4Mj0iMCIgeTI9IjQuNSIgc3Ryb2tlPSIjQjRCNEI0IiBzdHJva2Utd2lkdGg9IjEiLz48L3BhdHRlcm4+PC9kZWZzPiA8cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI3BhdHRlcm5fcXhRQ08xKSIgb3BhY2l0eT0iMSIvPjwvc3ZnPg==');
  background-repeat: repeat;
}
</style>
