<template>
  <div
    v-loading="[isLoading]"
    :class="[{ 'is-panel': isPanel }, 'comments-container']"
  >
    <template v-if="isLoaded">
      <CommentsHeader />
      <TfCommentsExport
        :broker="broker"
        :comments="comments"
        :documents="[proposalDocument]"
        :employer-name="employerName"
        :project-id="Number(projectId)"
        :is-disabled="!comments.length"
        :user-info="commentUserInfo"
        button-title="Export comments"
        data-test="export comments"
        @click.native="sendAnalytics()"
      />
      <div v-if="comments.length" class="comments-list-container">
        <Comment
          v-for="comment in comments"
          :key="comment.id"
          application-namespace="carrier_portal"
          :broker="broker"
          :comment="comment"
          :project-id="projectId"
          :proposal-document-id="proposalDocumentId"
          :show-new-badge="showNewBadge"
          :user-info="commentUserInfo"
          @commentAdded="addReply($event[0])"
        />
      </div>
      <div v-else class="empty-state-container">
        <div class="empty-state">
          <i class="fa-solid fa-comments" />
          <p>Send comments or questions to {{ broker.name }}.</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useCommentsStore } from '@/stores/comments.js';
import { useProjectStore } from '@/stores/project.js';
import { useAccountStore } from '@/stores/account.js';
import { segmentData } from '@/utils/analytics.js';
import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';

import TfCommentsExport from '@/shared/components/TfPdfExport/Comments.vue';
import Comment from '@/components/Comment/index.vue';
import CommentsHeader from './CommentsHeader.vue';

const route = useRoute();
// Stores
const commentsStore = useCommentsStore();
const projectStore = useProjectStore();
const accountStore = useAccountStore();
const { getComments, clearCommentNotifications, addReply, setShowNewBadge } =
  commentsStore;
// Refs
const { comments, showNewBadge, isLoaded, isLoading } =
  storeToRefs(commentsStore);
const {
  broker,
  projectId,
  employerName,
  proposalDocumentId,
  proposalDocument,
  proposalsIsLoaded,
} = storeToRefs(projectStore);
const { commentUserInfo } = storeToRefs(accountStore);
const isPanel = computed(() => route.meta.pageType === 'dataEntry');

watch(
  proposalsIsLoaded,
  (loaded) => {
    if (loaded) {
      getComments(proposalDocumentId.value);
      clearCommentNotifications(route.params.projectId);
    }
  },
  { immediate: true },
);

// Methods
/**
 * Clears out the sessionStorage replied comments that aren't saved before the
 * project loads. We put it here to avoid moving it later.
 */
function clearSessionComments() {
  // This one clears any unsaved replies
  const commentsData = Object.keys(window.sessionStorage)
    .filter((key) => key.startsWith('comment-'))
    .reduce((acc, key) => {
      acc[key] = JSON.parse(window.sessionStorage.getItem(key));

      return acc;
    }, {});

  Object.values(commentsData).forEach((comment) => {
    if (parseInt(projectId.value, 10) !== parseInt(comment.projectId, 10)) {
      window.sessionStorage.removeItem(comment);
    }
  });
}

/** Sends event to segment */
function sendAnalytics() {
  trackSegmentEvent('Export comments', segmentData());
}

/**
 * On creation we need to clear any session comments from other projects and
 * clear out any comment notifications
 */
onMounted(() => {
  clearSessionComments();
});
/**
 * When we exit out of the comments panel, we want to clear all new badges
 * assuming they've been read
 */
onBeforeUnmount(() => {
  if (isPanel.value) {
    setShowNewBadge();
  }
});
</script>

<style lang="scss" scoped>
.comments-container {
  padding: 0 20px;

  &.is-panel {
    text-align: left;
    padding: 18px 33px;
    min-width: 100vw;
    box-sizing: border-box;

    @media (min-width: 557px) {
      min-width: 557px;
    }
  }
}

.empty-state-container {
  display: flex;
  align-self: center;
  flex-grow: 1;
  width: 100%;
  min-height: 200px;
}

.empty-state {
  text-align: center;
  margin: 0 auto;
  align-self: center;
  padding: 10px 0;
  color: var(--tf-gray-light-medium);

  svg {
    width: 107px;
    height: 83px;
    margin: 0 auto 22px;
  }

  p {
    font-size: 18px;
    line-height: 1.33;
    color: var(--tf-gray);
    max-width: 448px;
    margin: 0 auto;
  }
}
</style>
