/* eslint-disable */
import PrimeVue from 'primevue/config';
import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura';

export const primeVueConfig = {
  theme: {
    preset: definePreset(Aura, {
      semantic: {
        primary: {
          50: '{indigo.50}',
          100: '{indigo.100}',
          200: '{indigo.200}',
          300: '{indigo.300}',
          400: '{indigo.400}',
          500: '{indigo.500}',
          600: '{indigo.600}',
          700: '{indigo.700}',
          800: '{indigo.800}',
          900: '{indigo.900}',
          950: '{indigo.950}',
        },
      },
      components: {
        datatable: {
          headerCell: {
            background: '#F8FAFC',
            selectedBackground: '#F8FAFC',
            selectedColor: '{content.color}',
          },
          row: {
            hoverBackground: 'var(--p-indigo-50)',
          },
        },
        breadcrumb: {
          padding: 0,
        },
      },
    }),
    options: {
      darkModeSelector: 'none',
    },
  },
};

export default PrimeVue;
