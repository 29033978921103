import { defineStore } from 'pinia';
import ProposalService from '@/services/proposal.js';
import { useQuoteEditsStore } from '@/stores/quoteEdits.js';
import { useNotificationsStore } from '@/stores/notifications.js';
import { useProjectStore } from '@/stores/project.js';
import { useProductStore } from '@/stores/product.js';

export const useCsvQuoteStore = defineStore('csvQuote', {
  state: () => ({
    alert: {
      type: '',
      messages: [],
    },
    file: {},
    id: null,
    isCsvValidated: false,
    isLoading: false,
    proposalsByProductType: {},
    uploadProgress: 0,
  }),

  actions: {
    /**
     * Attempts to submit the uploaded csv quote and updates the products that
     * are returned from the response
     *
     * @param {number} csvQuoteId - The id given to a csv quote submission
     *   object
     * @returns {Promise}
     */
    async submitCsvQuote(csvQuoteId) {
      this.resetAlert();
      this.setQuote({ isLoading: true });

      const projectStore = useProjectStore();

      try {
        const { updated_products: updatedProducts } =
          await ProposalService.submitCsvQuote(
            csvQuoteId,
            projectStore.proposalDocumentId,
          );
        const products = updatedProducts.map((product) => {
          // find the matching product in the store
          const existingProduct = useProductStore().products.find(
            ({ id }) => product.id === id,
          );

          // update the existing product with the new states and other updates that come down from the response
          // note this doesn't update PDVs or rate values stored, as these are already pulled from an API call whenever
          // a user navigates to the plan design/rates routes and do not need to be updated in this current view
          return {
            ...existingProduct,
            ...product,
          };
        });

        products.forEach((product) => {
          useProductStore().updateProduct(product);
          const notificationsStore = useNotificationsStore();

          // 'finalized' means that all products have been declined or submitted
          if (product.document_state === 'finalized') {
            notificationsStore.allProductsSubmittedDialogVisible = true;
            // Update the proposal Document in the store
            projectStore.setProposalDocumentState(product.document_state);
          } else {
            notificationsStore.setAlertsQuotesTab({ product });
          }
          const quoteEditsStore = useQuoteEditsStore();

          quoteEditsStore.getQuoteEdits(product.document_id);
        });
      } catch (error) {
        this.alert = {
          type: 'error',
          messages: [
            'Something went wrong when attempting to submit your quote. Please try again.',
          ],
        };
        throw error;
      } finally {
        this.setQuote({ isLoading: false });
      }
    },
    setQuote(newProps = {}) {
      Object.assign(this, { ...newProps });
    },
    resetAlert() {
      const alert = { type: '', messages: [] };

      Object.assign(this.alert, { ...alert });
    },
  },

  getters: {
    /**
     * Checks to see if at least one proposal is valid
     *
     * @param {object} state
     * @returns {boolean}
     */
    isQuoteValuesValid(state) {
      return Object.values(state.proposalsByProductType).some((proposals) =>
        proposals.some(({ valid }) => valid),
      );
    },
  },
});
