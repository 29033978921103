/**
 * Global Toast Utility for PrimeVue
 *
 * Why we did this:
 *
 * - `useToast()` from PrimeVue **only works inside Vue components**.
 * - Pinia stores and other utility files **cannot directly use `useToast()`**.
 * - Instead of passing `useToast()` manually in every component, we **initialize
 *   it once** in `App.vue` and store it globally.
 * - This makes the toast **accessible anywhere** (components, Pinia stores) via
 *   `getGlobalToast()`.
 */

let toastInstance = null;

/**
 * Initializes the global toast instance. This should only be called once in
 * `App.vue` during `onMounted()`.
 *
 * @param {object} toast - The instance of `useToast()` from PrimeVue.
 */
export const initGlobalToast = (toast) => {
  if (!toastInstance) {
    toastInstance = toast;
  }
};

/**
 * Retrieves the global toast instance. Call this function anywhere in the app
 * to use PrimeVue's toast.
 *
 * @returns {object} The PrimeVue `useToast()` instance.
 * @throws {Error} If the toast has not been initialized.
 */
export const getGlobalToast = () => {
  if (!toastInstance) {
    throw new Error(
      'Toast is not initialized. Call initGlobalToast() in App.vue.',
    );
  }

  return toastInstance;
};
