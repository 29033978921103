<template>
  <span>
    <TfPdfExport
      :button-size="buttonSize"
      :button-title="buttonTitle"
      :is-disabled="!dates.length"
      :filename="`quote-edits_${projectId}.pdf`"
    >
      <ExportHeader
        :employer-name="employerName"
        :filters="filters"
        title="Quote Edits History"
      />
      <QuoteEditsExportRow
        v-for="(left, index) in edits.left"
        :key="index"
        :left-edit="left"
        :right-edit="edits.right[index]"
      />
    </TfPdfExport>
  </span>
</template>

<script setup>
import { computed } from 'vue';
import QuoteEditsExportRow from './Row.vue';
import ExportHeader from '../Header.vue';
import TfPdfExport from '../index.vue';

/**
 * Quote Edits Export
 *
 * @exports TfQuoteEditsExport
 */
defineOptions({
  name: 'TfQuoteEditsExport',
});

const props = defineProps({
  buttonSize: {
    type: String,
    default: 'text-icon',
  },
  buttonTitle: {
    type: String,
    default: 'export',
  },
  dates: {
    type: Array,
    default: () => [],
  },
  employerName: {
    type: String,
    default: null,
  },
  filters: {
    type: Object,
    default: () => ({}),
  },
  projectId: {
    type: Number,
    default: null,
  },
});
/**
 * Returns a version of the quote edits structure split across columns
 *
 * @returns {object}
 */
const edits = computed(() => {
  const columnEdits = {
    left: [],
    right: [],
  };

  props.dates
    .reduce((acc, date) => acc.concat(date), [])
    .forEach((edit, index) => {
      columnEdits[index % 2 ? 'right' : 'left'].push({
        ...edit,
        position: index,
      });
    });

  return columnEdits;
});
</script>
