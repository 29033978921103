/* eslint-disable camelcase */
import { defineStore } from 'pinia';
import groupBy from 'lodash/groupBy.js';
/* eslint-disable import/no-cycle */
import { useRateEntryStore } from '@/stores/rateEntry.js';
import { useNotificationsStore } from '@/stores/notifications.js';
import { useProjectStore } from '@/stores/project.js';
import { useProductTableStore } from '@/stores/productTable.js';
import { usePlanDesignStore } from '@/stores/planDesign.js';
/* eslint-enable import/no-cycle */
import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
import { useAccountStore } from '@/stores/account.js';
import { useQuoteEditsStore } from '@/stores/quoteEdits.js';
import productService, {
  getProductTierGroups,
  patchProductState,
  patchSubmitQuote,
} from '@/services/product.js';
import {
  isStopLossProduct,
  trackSegmentEvent,
} from '@watchtowerbenefits/es-utils-public';
import capitalize from 'lodash/capitalize.js';
import {
  productLabel,
  diveUnsupportedProductTypes,
  tfAssistAndDiveUnsupportedProductTypes,
} from '@/utils/product.js';

import { arrayHasItem } from '@/utils/general.js';

const arrayHasAlts = (arr) =>
  arr.some(({ project_product: { alternative } }) => alternative);
const hasAlts = (arr) => arr.length > 1 && arrayHasAlts(arr);
const isNotSold = ({ project_product: pp }) => /not sold/i.test(pp.sold_status);

export const useProductStore = defineStore('product', {
  state: () => ({
    normalizedValues: [],
    tierGroupsIsLoading: false,
    rateTierGroups: [],
    currentProductIsLoaded: false,
    currentProduct: null,
    products: [],
    productsToDecline: [],
    threeflowAssistProductSnapshot: [],
  }),
  getters: {
    documentType: (state) =>
      state.currentProduct?.document_type.toLowerCase() ?? null,
    isNewCoverage: (state) =>
      !state.currentProduct?.project_product.prior_coverage,
    isRenewalProduct: (state) =>
      state.currentProduct?.document_type.toLowerCase() === 'renewal',
    isStopLoss: (state) =>
      state.currentProduct && isStopLossProduct(state.currentProduct),
    productId: (state) => state.currentProduct?.id ?? null,
    productState: (state) => state.currentProduct?.state ?? null,
    currentProductPreviouslySubmitted: (state) =>
      ['completed', 'editing_plan_design', 'editing'].includes(
        state.currentProduct?.state,
      ),
    isAllNewCoverage: (state) =>
      state.products.every((product) => !state.isInforceCarrier(product)),
    isMixOfNewAndRenewingCoverage: (state) =>
      state.products.some((product) => state.isInforceCarrier(product)) &&
      state.products.some((product) => !state.isInforceCarrier(product)),
    notDeclinedProducts: (state) =>
      state.products.filter((product) => product.state !== 'declined'),
    hasStopLossWithAlts: (state) =>
      state.products.some(
        (product) =>
          isStopLossProduct(product) && product.project_product.alternative,
      ),
    /**
     * Check if a product is Not Started or Not Submitted
     *
     * @param {object} state
     * @returns {boolean}
     */
    isProductNotStartedOrNotSubmitted(state) {
      return /not_started|in_progress_modifying|in_progress|editing/i.test(
        state.productState,
      );
    },
    /**
     * Maps the products array and returns the proposal type
     *
     * @param {object} state
     * @returns {string}
     */
    proposalDocumentType: (state) => {
      let type = 'Proposal/Renewal';

      if (
        state.notDeclinedProducts.every(
          (product) => product.document_type.toLowerCase() === 'proposal',
        )
      ) {
        type = 'Proposal';
      } else if (
        state.notDeclinedProducts.every(
          (product) => product.document_type.toLowerCase() === 'renewal',
        )
      ) {
        type = 'Renewal';
      }

      return type;
    },
    /**
     * Maps over the products array and returns products that are completed and
     * stop loss
     *
     * @param {object} state
     * @returns {Array}
     */
    completedStopLossProducts: (state) =>
      state.products.filter(
        (product) =>
          isStopLossProduct(product) && product.state === 'completed',
      ),
    /**
     * Maps over the products array and returns a list of product type name
     * strings that are unsupported by DIVE, but still supported by ThreeFlow
     * Assist.
     *
     * @param {object} state
     * @returns {Array}
     */
    diveUnsupportedProductTypes(state) {
      if (!state.isSmartProposal) return [];

      const productTypes = state.notDeclinedProducts
        .reduce((unsupportedProducts, product) => {
          if (
            product.state === 'not_started' &&
            isNotSold(product) &&
            (arrayHasItem(
              product.product_type_name,
              diveUnsupportedProductTypes,
            ) ||
              (product.project_product.alternative &&
                !arrayHasItem(
                  product.product_type_name,
                  tfAssistAndDiveUnsupportedProductTypes,
                )))
          ) {
            unsupportedProducts.push(productLabel(product));
          }

          return unsupportedProducts;
        }, [])
        .concat(state.nonThreeflowAssistBaseProductTypesWithAlts);

      return [...new Set(productTypes)].sort();
    },
    // Optional chaining is used here to prevent Unit Tests that do not use this getter from crashing when run.
    // Here's the ticket for a more permanent solution: https://watchtower.atlassian.net/browse/LC-1027
    isProcessingDocuments: (state) =>
      (state.isUploadRenewalRatePass || state.isSmartProposal) &&
      state.products.some(
        ({ state: productState }) => productState === 'automation_locked',
      ),
    /**
     * Evaluate whether the current carrier is the incumbent for all Products
     * for the project being displayed on the My Quotes page. Does not include
     * alternatives.
     *
     * @param {object} state
     * @returns {boolean}
     */
    isIncumbentForAllProducts(state) {
      return state.notDeclinedProducts
        .filter((product) => !product.project_product.alternative)
        .every((product) => state.isInforceCarrier(product));
    },
    /**
     * Checks if the isIncumbentForAllProducts getter is true, indicating that
     * the project is a renewal.
     *
     * @param {object} state
     * @returns {boolean}
     */
    isUploadRenewalRatePass(state) {
      return state.isIncumbentForAllProducts;
    },
    /**
     * Checks if the isIncumbentForAllProducts getter is false, indicating that
     * the project is a proposal.
     *
     * @param {object} state
     * @returns {boolean}
     */
    isSmartProposal(state) {
      return !state.isIncumbentForAllProducts;
    },
    /**
     * Only used for Smart Renewals or Proposals, returns type of document
     *
     * @param {object} state
     * @returns {string}
     */
    smartDocType(state) {
      const type = state.isSmartProposal ? 'quote' : 'renewal';

      return (cap = false) => (cap ? capitalize(type) : type);
    },
    /**
     * Are all products in a smart proposal stop loss, paid leave or critical
     * illness.
     *
     * @param {object} state
     * @returns {boolean}
     */
    allThreeflowAssistProducts(state) {
      return (
        state.isSmartProposal &&
        state.notDeclinedProducts.every((product) =>
          arrayHasItem(product.product_type_name, diveUnsupportedProductTypes),
        )
      );
    },
    /**
     * Some products in a smart proposal stop loss, paid leave, or critical
     * illness.
     *
     * @param {object} state
     * @returns {boolean}
     */
    someThreeflowAssistProducts(state) {
      return (
        state.isSmartProposal &&
        state.notDeclinedProducts.some((product) =>
          arrayHasItem(product.product_type_name, diveUnsupportedProductTypes),
        )
      );
    },
    /**
     * Products in a smart proposal is mix of stop loss or critical illness with
     * other products, no alts and not started.
     *
     * @param {object} state
     * @returns {boolean}
     */
    threeflowAssistSupportedNoAlts(state) {
      return (
        state.allThreeflowAssistProducts &&
        state.notDeclinedProducts.every(
          (product) =>
            /not_started/i.test(product.state) &&
            !product.project_product.alternative,
        )
      );
    },
    /**
     * Are all products in a smart proposal stop loss or critical illness and
     * not started.
     *
     * @param {object} state
     * @returns {boolean}
     */
    threeflowAssistSupported(state) {
      return (
        state.allThreeflowAssistProducts &&
        state.notDeclinedProducts.some((product) =>
          /not_started/i.test(product.state),
        )
      );
    },
    /**
     * Returns not started base products with alts.
     *
     * @param {object} state
     * @returns {Array}
     */
    nonThreeflowAssistBaseProductTypesWithAlts(state) {
      if (!arrayHasAlts(state.products)) return [];
      const groupedProducts = groupBy(state.products, 'product_type_name');

      return Object.keys(groupedProducts).reduce(
        (unsupportedProducts, productType) => {
          const productArr = groupedProducts[productType];

          if (hasAlts(productArr)) {
            const baseProduct = productArr.find(
              ({ project_product: { alternative } }) => !alternative,
            );

            if (
              baseProduct?.state === 'not_started' &&
              isNotSold(baseProduct) &&
              !arrayHasItem(
                productLabel(baseProduct),
                diveUnsupportedProductTypes,
              )
            ) {
              unsupportedProducts.push(productLabel(baseProduct));
            }
          }

          return unsupportedProducts;
        },
        [],
      );
    },
  },
  actions: {
    /** Reset the product state */
    resetProductState() {
      this.$reset();
    },
    /**
     * Fetches the normalized values for a product.
     *
     * @param {number} productId
     * @returns {Array}
     */
    async loadNormalizedValues(productId) {
      this.normalizedValues = [];

      try {
        const {
          product: { attributes },
        } = await productService.getNormalizedValues(productId);

        this.normalizedValues = attributes;

        return attributes;
      } catch {
        throw new Error('Unable to load normalized values');
      }
    },
    /**
     * Dispatches two actions to validate the rate entry and plan design for a
     * product
     *
     * @param {number} productId
     * @returns {Promise}
     */
    async validateProduct(productId) {
      const rateEntryStoreValidation =
        useRateEntryStore().validateRateEntry(productId);
      const conditionalValidations = [];

      if (!this.isSmartProposal) {
        conditionalValidations.push(
          usePlanDesignStore().validatePlanDesign(productId),
        );
      } else if (!this.isStopLoss) {
        conditionalValidations.push(
          useRateEntryStore().validateRateGuarantee(productId),
        );
      }

      const validationsToRun = [
        rateEntryStoreValidation,
        ...conditionalValidations,
      ];

      await Promise.all(validationsToRun);
      // invalid validation is coming back as 400 so the caller needs to catch this to avoid
      // js errors. All error messaging is done on a component level
    },
    /**
     * Get a product's details
     *
     * @param {number | string} productId
     * @returns {Promise}
     */
    async loadProductDetails(productId) {
      if (!productId) {
        return undefined;
      }
      const { product } = await productService.getProduct(productId);

      return product;
    },
    /**
     * Loads the tier groups for a product
     *
     * @param {number} productTypeId
     * @returns {Promise}
     */
    async loadProductTierGroups(productTypeId) {
      this.tierGroupsIsLoading = true;
      this.tierGroups = [];
      if (!productTypeId) {
        this.tierGroupsIsLoading = false;

        return undefined;
      }

      try {
        const { tier_groups: tierGroups } =
          await getProductTierGroups(productTypeId);
        const { rate_tier_groups: rateTierGroups } = tierGroups;

        this.rateTierGroups = rateTierGroups;

        return tierGroups;
      } catch {
        return undefined;
      } finally {
        this.tierGroupsIsLoading = false;
      }
    },
    /**
     * Get the everything necessary to show the plan summary.
     *
     * @param {object} root0
     * @param {number} root0.productId
     * @param {number} root0.productTypeId
     */
    async getPlanSummary({ productId, productTypeId }) {
      const rateEntry = useRateEntryStore().getRateEntry({ productId });
      const productTierGroups = this.loadProductTierGroups(productTypeId);
      const productDetails = this.loadProductDetails(productId).then(
        (product) => {
          this.currentProduct = product;
        },
      );
      const planDesign = usePlanDesignStore().getPlanDesign(productId);

      try {
        Promise.all([rateEntry, productTierGroups, productDetails, planDesign]);
      } catch (error) {
        throw new AggregateError('Error getting plan summary');
      }
    },
    /**
     * Get the current product, load the tier groups, set the plan design and
     * rate entry and get the normalized values.
     *
     * @param {number} productId
     * @param {boolean} loadProduct
     */
    async getCurrentProduct(productId = null, loadProduct = true) {
      if (loadProduct) {
        this.currentProductIsLoaded = false;

        this.currentProduct = null; // Reset the currentProduct state
      }

      // we have an alert on review pages that can be dismissed - since we're setting the 'current product' - we can reset it to it's default state
      useNotificationsStore().reviewAlertDismissed = false;

      try {
        const product = await this.loadProductDetails(productId);
        const productTypeId = product.project_product.product_type_id;

        this.currentProduct = product;
        this.loadProductTierGroups(productTypeId);
        usePlanDesignStore().validPlanDesign = product.valid_plan_design;
        useRateEntryStore().validRateEntry = product.valid_rate_structure;
      } catch {
        throw new Error('Unable to load product details');
      } finally {
        if (loadProduct) this.currentProductIsLoaded = true;
      }
    },
    /**
     * Update the product state and if the product is declined, update the
     * declined reason In the case of declining or undeclining a product, the
     * currentProduct will be null so we don't need to update the state The
     * state will be handled in the project store
     *
     * @param {object} param0
     * @param {number} param0.productId
     * @param {string} param0.endpointState
     * @param {string} param0.declinedReason
     * @returns {object}
     */
    async updateProductState({ productId, endpointState, declinedReason }) {
      const { product } = await patchProductState({
        productId,
        endpointState,
        declinedReason,
      });

      if (
        !['decline', 'undecline'].includes(endpointState) &&
        !!this.currentProduct
      ) {
        // Update the local store product state in `currentProduct` and in `products`
        this.currentProduct.state = product.state;
      }
      this.updateProduct(product);
      // Update the proposal Document in the store
      const projectStore = useProjectStore();

      projectStore.setProposalDocumentState(product.document_state);

      return product;
    },
    /**
     * Submit the quote for a single product
     *
     * @param {number} productId
     */
    async submitQuote(productId) {
      try {
        const { product } = await patchSubmitQuote(productId);

        // Update the local store product state in `products` and if user is on the Rate Entry page also update product state in `currentProduct`
        if (this.currentProduct) {
          this.currentProduct.state = product.state;
        }
        this.updateProduct(product);
        if (this.isUploadRenewalRatePass) {
          await useProductTableStore().getProductRateGuarantees();
        }

        useQuoteEditsStore().getQuoteEdits(product.document_id);

        // If all products have been declined or submitted
        if (product.document_state === 'finalized') {
          // Send out a segment event in order to track auto-renewals
          // this is the second event of two necessary to track auto-renewals
          if (useProjectStore().isRenewalProject) {
            trackSegmentEvent('Renewal project finished', {
              project_id: useProjectStore().projectId,
              carrier_id: useCarrierInfoStore().id,
              user_carrier_id: useAccountStore().userInfo.id,
            });
          }
          useNotificationsStore().allProductsSubmittedDialogVisible = true;
          useProjectStore().setProposalDocumentState(product.document_state);
        }
      } catch {
        throw new Error('Unable to submit quote');
      }
    },
    /**
     * Update an individual product by replacing it in the products array
     *
     * @param {object} updatedProduct
     */
    updateProduct(updatedProduct) {
      const index = this.products.findIndex(
        (product) => product.id === updatedProduct.id,
      );

      this.products.splice(index, 1, updatedProduct);
    },
    /**
     * Handles the alert quotes for stop loss products with a firm quote
     * requested Checks if products has non-alts of the same product type Checks
     * for product alts that do not have a base product and adds a notification
     * if so (edge case)
     *
     * @param {Array} products
     */
    setProducts(products) {
      const { setAlertQuotesTab } = useNotificationsStore();
      const projectStore = useProjectStore();

      // Add notification if broker has requested a firm quote (quote).
      if (
        products
          .filter((product) => isStopLossProduct(product))
          .some((product) => product.stop_loss_state === 'firm_quote_requested')
      ) {
        setAlertQuotesTab({
          text: `${projectStore.broker.name} has requested a firm quote.`,
          type: 'warning',
        });
      }

      // Iterate over products to see if said product is an alternative and there are non-alternatives of the same product type.
      const noBaseProduct = products.some(
        ({ project_product: projectProduct }) =>
          projectProduct.alternative &&
          !products.some(
            ({
              project_product: { alternative, product_type_id: productTypeId },
            }) =>
              productTypeId === projectProduct.product_type_id && !alternative,
          ),
      );

      // Add notification if there are alternative products without base products
      // Very edge-case
      if (noBaseProduct) {
        setAlertQuotesTab({
          text: 'We are experiencing a technical issue with this project. Contact support.',
          type: 'warning',
        });
      }

      // This is to add 1 property to the products in the products state array. We need to know if
      // a product group has alternatives and if any of those alternatives was sold.
      if (arrayHasAlts(products)) {
        const groupedProducts = groupBy(products, 'product_type_name');
        const hasSoldProduct = (arr) =>
          arr.some(
            (product) =>
              !!(
                product.project_product?.sold_carrier_id ||
                product.sold_carrier_id
              ),
          );
        const productIdsWithSoldAlts = [];

        Object.keys(groupedProducts).forEach((productType) => {
          const productArr = groupedProducts[productType];

          if (hasAlts(productArr)) {
            if (hasSoldProduct(productArr)) {
              productArr.forEach((prod) => {
                if (!prod.is_sold) productIdsWithSoldAlts.push(prod.id);
              });
            }
          }
        });
        /* eslint-enable camelcase */
        /* eslint-disable no-param-reassign */
        products.forEach((product) => {
          product.baseOrAlternativeWasSold = productIdsWithSoldAlts.includes(
            product.id,
          );
        });
      }
      /* eslint-enable no-param-reassign */
      this.products = products;
    },
    /**
     * Returns if the passed product is inforce for the current carrier
     *
     * @param {object} product
     * @param {object} product.project_product
     * @returns {boolean}
     */
    isInforceCarrier(product) {
      if (!product) return null;

      return (
        product.project_product.inforce_carrier_id === useCarrierInfoStore().id
      );
    },
    /**
     * Validate Both Plan Design && Rate Entry before submitting the quote
     *
     * @param {number} productId
     */
    async validateProductAndSubmitQuote(productId) {
      await this.validateProduct(productId);

      if (
        (!this.isSmartProposal &&
          (!usePlanDesignStore().validPlanDesign ||
            !useRateEntryStore().validRateEntry)) ||
        (this.isSmartProposal && !useRateEntryStore().validRateEntry)
      ) {
        throw new Error('Invalid plan design or rate values.');
      }

      await this.submitQuote(productId);
    },
  },
});
