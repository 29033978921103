<script setup>
/**
 * Email Confirmation
 *
 * @exports EmailConfirmation
 */
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Button from 'primevue/button';
import { useAccountStore } from '@/stores/account.js';
import AccountService from '@/services/account.js';
import AuthPageHeader from '@/shared/components/AuthPages/Header.vue';

defineOptions({
  name: 'EmailConfirmation',
});

const router = useRouter();
const route = useRoute();
const accountStore = useAccountStore();
const expiredEmail = ref(false);

/**
 * We then for your token (from your confirmation email) and call the
 * accountService/confirmEmail method. If the call works, we send you on to the
 * project overview or dashboard depending on where you're coming from.
 * Otherwise we check if your token expired and show you the expiration message
 * with a button to resend.
 */
onMounted(async () => {
  const { projectId, token } = route.params;
  const { email, redirect } = route.query;
  const targetRoute =
    redirect ||
    (projectId
      ? {
          name: 'RfpOverview',
          params: { projectId },
        }
      : { name: 'Dashboard' });

  try {
    await AccountService.confirmEmail({ token });
    accountStore.setUserEmail(email);

    await router.replace(targetRoute);
  } catch {
    expiredEmail.value = true;
  }
});

/** Call service to send a new confirmation email. */
const resendConfirmation = async () => {
  const { projectId } = route.params;
  const { email, redirect } = route.query;

  await AccountService.resendConfirmation({ email, projectId });

  let targetRoute = redirect;

  if (!targetRoute) {
    targetRoute = projectId
      ? { name: 'ProjectRegisterConfirmation', params: { projectId } }
      : { name: 'RegisterConfirmation' };
  }

  await router.replace(targetRoute);
};
</script>

<template>
  <section class="auth-form">
    <template v-if="expiredEmail">
      <AuthPageHeader
        title="Uh-oh, looks like your confirmation email expired."
      />
      <p class="text-center">
        We’ll send a fresh one to {{ route.query.email }}. <br />
        Be sure to use this one.
        <br />
        <br />
        <Button
          label="Resend confirmation email"
          severity="help"
          variant="outlined"
          fluid
          @click="resendConfirmation"
        />
      </p>
    </template>
  </section>
</template>
