<template>
  <div class="pdf-export-markup">
    <slot />
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';

/**
 * PDF Export
 *
 * @exports TfPdfExport
 */

defineOptions({
  name: 'TfPdfExport',
});

const props = defineProps({
  filename: {
    type: String,
    default: 'export.pdf',
  },
});
const emit = defineEmits(['save']);
const pages = ref([]);
/**
 * Get element height including margin and padding
 *
 * @param {object} element
 * @returns {number}
 */
const getHeight = (element) =>
  [
    'height',
    'margin-top',
    'margin-bottom',
    'padding-top',
    'padding-bottom',
  ].reduce(
    (height, propertyName) =>
      height +
      Number(
        document.defaultView
          .getComputedStyle(element, '')
          .getPropertyValue(propertyName)
          .replace(/px$/, ''),
      ),
    0,
  );
/**
 * Create new DIV element
 *
 * @param {string} className
 * @returns {object}
 */
const generateDiv = (className) => {
  const div = document.createElement('div');

  if (className) {
    div.className = className;
  }

  return div;
};
/**
 * Generate PDF via JsPDF
 *
 * @returns {object}
 */
const generatePdf = () => {
  const pdfDocument = document.implementation.createHTMLDocument('pdf-export');

  pdfDocument.className = 'pdf-export-document';

  let pdfPage = generateDiv('pdf-export-page');
  let pageHeight = 40; // Design has 40px margin all around
  let blocksInDocument = 0;
  let lastBlockOnPage = 0;
  const blocks = [];

  document.querySelectorAll('.pdf-export-block').forEach((block) => {
    const cloneBlock = block.cloneNode(true);

    cloneBlock.setAttribute('block-height', getHeight(block));
    blocks.push(cloneBlock);
  });

  while (blocks.length > 0) {
    const block = blocks.shift();
    const blockHeight = Number(block.getAttribute('block-height'));

    if (pageHeight + blockHeight > 730) {
      lastBlockOnPage = blocksInDocument;
      pages.value.push(pdfPage);
      pdfPage = generateDiv('pdf-export-page');
      pageHeight = 40;
    }

    block.style.position = 'absolute';
    block.style.left = '40px';
    block.style.top = `${pageHeight}px`;
    pdfPage.appendChild(block);
    pageHeight += blockHeight;
    blocksInDocument += 1;
  }

  if (blocksInDocument > lastBlockOnPage + 1) {
    pages.value.push(pdfPage);
  }

  pages.value.forEach((page, index) => {
    const pagination = generateDiv('pagination');

    pagination.innerHTML = `Page ${index + 1} of ${pages.value.length}`;
    page.appendChild(pagination);
    pdfDocument.body.appendChild(page);
  });

  return pdfDocument.body;
};
/**
 * Export PDF via JsPDF. jest/mocha (well, node) can't deal with this version of
 * jsPDF at this time so we're cheating here and using require. TODO:
 * Conditionally load node version here; note that that library does not expose
 * a save() method because Node is expected to handle it's own file saving
 *
 * - If we want to test the result, we'd have to account for that some other way
 */
const exportPdf = () => {
  const pdfFile = new JsPDF('p', 'pt', 'letter');

  if (!window.html2canvas) {
    window.html2canvas = html2canvas;
  }

  pdfFile.html(generatePdf(), {
    callback: () => {
      let pdfPages = pdfFile.internal.getNumberOfPages();

      if (pdfPages > pages.value.length) {
        for (; pdfPages > pages.value.length; pdfPages -= 1) {
          pdfFile.deletePage(pdfPages);
        }
      }

      pdfFile.save(props.filename);
      emit('save');
    },
    html2canvas: {
      scale: 1,
      logging: false, // Disable console logs
    },
  });
};

// Lifecycle Hook: Equivalent to `mounted`
onMounted(() => {
  exportPdf();
});
</script>

<style lang="scss">
/**
  * This file is intentially unscoped since there are divs being created in the methods.
  */
@use '@/shared/assets/styles/variables';

.pdf-export-document {
  width: 610px;
}

.pdf-export-markup {
  position: fixed;
  top: -1000em;
  left: -1000em;
  width: 630px;
}

.pdf-export-page {
  position: relative;
  width: 610px;
  height: 792px;
  padding: 0;
  margin: 0;
  font-family: Arial, sans-serif;
}

.pagination {
  position: absolute;
  bottom: 30px;
  right: 40px;
  font-size: 10px;
  color: var(--tf-gray-dark);
}
</style>
